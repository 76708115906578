import * as React from 'react'
import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Checkbox, CheckboxGroup, Collapse, Flex, Text } from '@chakra-ui/react'

import {
  Label,
  PrimitiveColors,
  SemanticColors,
  Spacing,
  Term,
} from '@enechain/ecloud-designsystem'
import {
  GeneratorType,
  GeneratorType_Type,
  RenewableEnergyAndTrackingStatus_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { Position_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { SelectBox } from '../../common'

type Props = {
  values: GeneratorType_Type[]
  options: GeneratorType[]
  onChange: (value: GeneratorType_Type[]) => void
  selectedPositionType?: Position_Type
  selectedTrackingStatus?: RenewableEnergyAndTrackingStatus_Type
  isDisabled?: boolean
  isRequired?: boolean
  error?: string
}

export const GeneratorForm: React.FC<Props> = ({
  values,
  options,
  onChange,
  selectedPositionType,
  selectedTrackingStatus,
  isDisabled,
  isRequired = true,
  error,
}) => {
  const { t } = useTranslation(['common', 'domain'])

  const excludeUnspecifiedGeneratorTypes = useMemo(() => {
    return options.filter(({ type }) => type !== GeneratorType_Type.UNSPECIFIED)
  }, [options])

  const isTrackingStatus = useMemo(
    () =>
      selectedTrackingStatus ===
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING,
    [selectedTrackingStatus],
  )

  const isAllCheckedGeneratorType = useMemo(() => {
    return (
      values.length > 0 &&
      excludeUnspecifiedGeneratorTypes.every((generatorType) =>
        values.includes(generatorType.type),
      )
    )
  }, [values, excludeUnspecifiedGeneratorTypes])

  switch (selectedPositionType) {
    case Position_Type.BID:
      return (
        <Collapse animateOpacity in={isTrackingStatus}>
          <Term>
            <Label required={isRequired}>
              {t('domain:shared.generator.title')}
            </Label>
            <CheckboxGroup
              isDisabled={isDisabled}
              onChange={(value: string[]): void =>
                onChange(value.map((v) => Number(v)))
              }
              value={values}
            >
              <Flex gap={Spacing[4]}>
                <Checkbox
                  colorScheme="green"
                  isChecked={isAllCheckedGeneratorType}
                  isDisabled={isDisabled}
                  onChange={(): void => {
                    if (isAllCheckedGeneratorType) {
                      return onChange([])
                    }
                    onChange(
                      excludeUnspecifiedGeneratorTypes.map(({ type }) => type),
                    )
                  }}
                >
                  {t('common:form.label.no_select')}
                </Checkbox>
                {Object.values(excludeUnspecifiedGeneratorTypes).map(
                  (generatorType) => (
                    <Checkbox
                      key={generatorType.type}
                      colorScheme="green"
                      disabled={isAllCheckedGeneratorType || isDisabled}
                      value={generatorType.type}
                    >
                      {generatorType.displayName}
                    </Checkbox>
                  ),
                )}
              </Flex>
            </CheckboxGroup>
            <Text color={SemanticColors.Text.negativeMid}>{error}</Text>
          </Term>
        </Collapse>
      )
    case Position_Type.OFFER:
    default:
      return (
        <Collapse animateOpacity in={isTrackingStatus}>
          <Term>
            <Label required={isRequired}>
              {t('domain:shared.generator.title')}
            </Label>
            <SelectBox<number>
              maxWidth="25rem"
              backgroundColor={PrimitiveColors.white['']}
              isDisabled={isDisabled}
              onChange={(value): void =>
                onChange(value !== undefined ? [Number(value)] : [])
              }
              selectable={Object.fromEntries(
                options.length > 0
                  ? options.map(({ type, displayName }) => [
                      Number(type),
                      displayName,
                    ])
                  : [],
              )}
              selectedValue={values[0]}
            />
            <Text color={SemanticColors.Text.negativeMid}>{error}</Text>
          </Term>
        </Collapse>
      )
  }
}
