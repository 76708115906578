import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { OrderBook } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/model_pb'
import {
  VolumeAndUnitPriceModel,
  VolumeUnit,
  convertVolumeAndUnitPriceModel,
} from '@enechain/jcex-ui'
import { formatYYYYMMdd, stringUtils } from '@enechain/jcex-utils'

import {
  OrderModelForNFCOrderBook,
  convertOrderModelListForNFCOrderBookModel,
} from '~/trader/pages/OrderBookList/models/NonFITNFC/order'
import { GrayOutFilter } from '~/trader/pages/OrderBookList/types/nfcFilter'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Params = {
  orderBooks: OrderBook[]
  filters: GrayOutFilter
  volumeUnit: VolumeUnit
}

export type NFCOrderBookModel = {
  renewableEnergyAndTrackingStatus: RenewableEnergyAndTrackingStatus_Type
  deliveryFiscalYear: {
    year: number
    displayName: string
  }
  orders: OrderModelForNFCOrderBook[]
  bestBid: Maybe<VolumeAndUnitPriceModel>
  bestOffer: Maybe<VolumeAndUnitPriceModel>
  lastUnitPrice: Maybe<string>
  lastDealtAt: Maybe<string>
  isGrayOutAllItem: boolean
}

export function convertNFCOrderBookModelList({
  orderBooks,
  filters,
  volumeUnit,
}: Params): NFCOrderBookModel[] {
  return orderBooks.map((order) => {
    const orderModel = convertOrderModelListForNFCOrderBookModel({
      orders: order.orders,
      filters,
      volumeUnit,
    })

    return {
      renewableEnergyAndTrackingStatus:
        order.renewableEnergyAndTrackingStatus?.type ??
        RenewableEnergyAndTrackingStatus_Type.UNSPECIFIED,
      deliveryFiscalYear: {
        year: order.deliveryFiscalYear?.year ?? 0,
        displayName: order.deliveryFiscalYear?.displayName ?? '',
      },
      orders: orderModel,
      bestBid: convertVolumeAndUnitPriceModel(order.bestBid, volumeUnit),
      bestOffer: convertVolumeAndUnitPriceModel(order.bestOffer, volumeUnit),
      lastUnitPrice:
        order.last?.unitPrice !== undefined &&
        stringUtils.isNotEmpty(order.last.unitPrice)
          ? order.last.unitPrice
          : undefined,
      lastDealtAt:
        order.lastDealtAt !== undefined
          ? formatYYYYMMdd(order.lastDealtAt.toDate(), '/')
          : undefined,
      isGrayOutAllItem: orderModel.every((order) => order.isGrayOutTarget),
    }
  })
}
