import { t } from 'i18next'
import { z } from 'zod'

import { ProductType_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

export const createNFCOrderSchema = z.object({
  productType: z.literal(ProductType_Type.NFC),
  positionType: z.number(),
  renewableEnergyAndTrackingStatusType: z
    .number()
    .optional()
    .refine((v) => v !== undefined, {
      params: { i18n: 'validation:errors.custom.required_select' },
    }),
  prefectureIds: z.array(z.number()),
  generatorIds: z.array(z.number()),
  deliveryFiscalYear: z
    .number()
    .optional()
    .refine((v) => v !== undefined, {
      params: { i18n: 'validation:errors.custom.required_select' },
    }),
  unitPrice: z
    .string()
    .refine((v) => !isNaN(Number(v)) && v?.length > 0 && Number(v) > 0, {
      params: { i18n: 'validation:errors.custom.positive_value' },
    })
    .refine(
      (v) => {
        const decimalRegex = /^\d+(\.\d{1,2})?$/
        return !isNaN(Number(v)) && v?.length > 0 && decimalRegex.test(v)
      },
      {
        params: {
          i18n: {
            key: 'validation:errors.custom.too_small',
            values: { value: `0.01 ${t('common:unit.yen_per_kwh')}` },
          },
        },
      },
    ),
  volume: z
    .string()
    .refine(
      (v) => !isNaN(Number(v)) && Number(v) > 0 && Number.isInteger(Number(v)),
      {
        params: { i18n: 'validation:errors.custom.positive_integer' },
      },
    ),
})

export type NFCCreateOrderSchema = z.infer<typeof createNFCOrderSchema>
