import { t } from 'i18next'
import { ZodIssueCode, z } from 'zod'

import { Position_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { stringUtils } from '@enechain/jcex-utils'

export const updateOrderSchema = z
  .object({
    positionType: z.number(),
    deliveryFiscalYear: z.number({
      required_error: t('validation:errors.custom.required_select'),
    }),
    creditCertifiedNumber: z.string().optional(),
    jCreditCertificationId: z.string().optional(),
    productId: z.number({}).optional(),
    prefectureIds: z
      .array(z.number(), {
        required_error: t('validation:errors.custom.required_select'),
      })
      .min(1, {
        message: t('validation:errors.custom.required_select'),
      }),
    unitPrice: z
      .string()
      .refine(
        (v) =>
          !isNaN(Number(v)) && Number.isInteger(Number(v)) && Number(v) > 0,
        {
          params: { i18n: 'validation:errors.custom.positive_integer' },
        },
      ),
    volume: z
      .string()
      .refine(
        (v) =>
          !isNaN(Number(v)) && Number.isInteger(Number(v)) && Number(v) > 0,
        {
          params: { i18n: 'validation:errors.custom.positive_integer' },
        },
      ),
  })
  .refine((arg) => arg.productId !== undefined, {
    params: { i18n: 'validation:errors.custom.required_select' },
    path: ['productId'],
  })
  .superRefine((arg, ctx) => {
    if (
      arg.positionType === Position_Type.OFFER &&
      stringUtils.isEmpty(arg.creditCertifiedNumber)
    ) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        params: {
          i18n: {
            key: 'validation:errors.invalid_type_received_undefined_with_path',
            values: { path: t('validation:creditCertifiedNumber') },
          },
        },
        path: ['creditCertifiedNumber'],
      })
      return false
    }
    return true
  })

export type UpdateOrderSchema = z.infer<typeof updateOrderSchema>
