import deepmerge from 'deepmerge'
import i18next, { Resource } from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

import { DEFAULT_LANGUAGE } from './constants/language'
import enCommon from './locales/en/common.json'
import enComponent from './locales/en/component.json'
import enDomain from './locales/en/domain.json'
import enValidation from './locales/en/validation.json'
import jaCommon from './locales/ja/common.json'
import jaComponent from './locales/ja/component.json'
import jaDomain from './locales/ja/domain.json'
import jaValidation from './locales/ja/validation.json'

type I18nProps = {
  resources: Resource
  debug?: boolean
  validationNs?: string[]
}

const resources: Resource = {
  en: {
    common: enCommon,
    component: enComponent,
    domain: enDomain,
    validation: enValidation,
  },
  ja: {
    common: jaCommon,
    component: jaComponent,
    domain: jaDomain,
    validation: jaValidation,
  },
}

const detector = new I18nextBrowserLanguageDetector(null, {
  order: ['navigator'],
  excludeCacheFor: ['cookie', 'localStorage'],
})

export function i18n({
  resources: resourcesProps,
  debug = false,
  validationNs = ['validation'],
}: I18nProps): typeof i18next {
  void i18next
    .use(detector)
    .use(initReactI18next)
    .init({
      resources: deepmerge(resources, resourcesProps),
      fallbackLng: DEFAULT_LANGUAGE,
      ns: Object.keys(resources).map((key) => key),
      defaultNS: 'common',
      debug,
      interpolation: {
        escapeValue: false,
      },
    })

  z.setErrorMap(makeZodI18nMap({ t: i18next.t, ns: validationNs }))
  return i18next
}
