import { useCallback } from 'react'

import { useEffectOnce } from 'react-use'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

export type VolumeUnit = 'kwh' | 'hundred_million_kwh' | 'mw'

/**
 * @enum {VolumeUnit} value は i18n の key に対応
 */
export const VOLUME_UNIT_TYPES = {
  KWH: 'kwh',
  HUNDRED_MILLION_KWH: 'hundred_million_kwh',
  MW: 'mw',
} as const

const DEFAULT_UNIT: VolumeUnit = VOLUME_UNIT_TYPES.KWH

const VOLUME_UNIT_KEY = 'volumeUnit'

function findUnit(): VolumeUnit {
  const currentUnit = localStorage.getItem(VOLUME_UNIT_KEY)

  if (currentUnit == null) {
    localStorage.setItem(VOLUME_UNIT_KEY, DEFAULT_UNIT)
    return DEFAULT_UNIT
  }

  return currentUnit as VolumeUnit
}

const volumeUnitState = atom<VolumeUnit>({
  key: VOLUME_UNIT_KEY,
  default: findUnit(),
})

type MutationReturnType = {
  setUnitState: (value: VolumeUnit) => void
}

export function useVolumeUnitQueries(): VolumeUnit {
  const unitKey = useRecoilValue(volumeUnitState)
  const setState = useSetRecoilState(volumeUnitState)

  const localStorageUnitItem = findUnit()

  // 正常動作では発火しないが、念の為互換性を保つために残しておき、diff があれば再レンダリングされる
  useEffectOnce((): void => {
    if (unitKey !== localStorageUnitItem) {
      setState(localStorageUnitItem)
    }
  })

  return unitKey
}

export function useVolumeUnitMutations(): MutationReturnType {
  const setState = useSetRecoilState(volumeUnitState)

  const update = useCallback(
    (value: VolumeUnit) => {
      localStorage.setItem(VOLUME_UNIT_KEY, value)
      setState(value)
    },
    [setState],
  )

  return {
    setUnitState: update,
  }
}
