// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file jcex/bff/jcredit/trader/v1/model.proto (package jcex.bff.jcredit.trader.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { JCreditCertification, Product } from "../../model/v1/model_pb.js";
import { DeliveryFiscalYear, OrderStatus, Organization, Position, Prefecture, Trader, VolumeAndUnitPrice } from "../../../shared/model/v1/model_pb.js";

/**
 * 注文板のモデル
 *
 * @generated from message jcex.bff.jcredit.trader.v1.OrderBook
 */
export class OrderBook extends Message<OrderBook> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 2;
   */
  product?: Product;

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 3;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * 注文
   *
   * @generated from field: repeated jcex.bff.jcredit.trader.v1.Order orders = 4;
   */
  orders: Order[] = [];

  /**
   * Best Bid
   *
   * @generated from field: optional jcex.bff.shared.model.v1.VolumeAndUnitPrice best_bid = 5;
   */
  bestBid?: VolumeAndUnitPrice;

  /**
   * Best Offer
   *
   * @generated from field: optional jcex.bff.shared.model.v1.VolumeAndUnitPrice best_offer = 6;
   */
  bestOffer?: VolumeAndUnitPrice;

  /**
   * 直近約定
   *
   * @generated from field: optional jcex.bff.shared.model.v1.VolumeAndUnitPrice last = 7;
   */
  last?: VolumeAndUnitPrice;

  /**
   * 約定日時
   *
   * @generated from field: optional google.protobuf.Timestamp last_dealt_at = 8;
   */
  lastDealtAt?: Timestamp;

  constructor(data?: PartialMessage<OrderBook>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.OrderBook";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product", kind: "message", T: Product },
    { no: 3, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 4, name: "orders", kind: "message", T: Order, repeated: true },
    { no: 5, name: "best_bid", kind: "message", T: VolumeAndUnitPrice, opt: true },
    { no: 6, name: "best_offer", kind: "message", T: VolumeAndUnitPrice, opt: true },
    { no: 7, name: "last", kind: "message", T: VolumeAndUnitPrice, opt: true },
    { no: 8, name: "last_dealt_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderBook {
    return new OrderBook().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderBook {
    return new OrderBook().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderBook {
    return new OrderBook().fromJsonString(jsonString, options);
  }

  static equals(a: OrderBook | PlainMessage<OrderBook> | undefined, b: OrderBook | PlainMessage<OrderBook> | undefined): boolean {
    return proto3.util.equals(OrderBook, a, b);
  }
}

/**
 * 注文のモデル
 *
 * @generated from message jcex.bff.jcredit.trader.v1.Order
 */
export class Order extends Message<Order> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 2;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 3;
   */
  product?: Product;

  /**
   * クレジット認証番号
   *
   * @generated from field: optional string credit_certified_number = 4;
   */
  creditCertifiedNumber?: string;

  /**
   * 都道府県のリスト
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture prefectures = 5;
   */
  prefectures: Prefecture[] = [];

  /**
   * 全都道府県選択フラグ
   *
   * @generated from field: bool is_all_prefectures_selected = 6;
   */
  isAllPrefecturesSelected = false;

  /**
   * 売買区分
   *
   * @generated from field: jcex.bff.shared.model.v1.Position position = 7;
   */
  position?: Position;

  /**
   * 数量と単価
   *
   * @generated from field: jcex.bff.shared.model.v1.VolumeAndUnitPrice volume_and_unit_price = 8;
   */
  volumeAndUnitPrice?: VolumeAndUnitPrice;

  /**
   * 注文ステータス
   *
   * @generated from field: jcex.bff.shared.model.v1.OrderStatus order_status = 9;
   */
  orderStatus?: OrderStatus;

  /**
   * 担当トレーダー
   *
   * @generated from field: jcex.bff.shared.model.v1.Trader assigned_trader = 10;
   */
  assignedTrader?: Trader;

  /**
   * 作成日時
   *
   * @generated from field: google.protobuf.Timestamp created_at = 11;
   */
  createdAt?: Timestamp;

  /**
   * 最終更新者
   *
   * @generated from field: jcex.bff.shared.model.v1.Trader last_updated_by = 12;
   */
  lastUpdatedBy?: Trader;

  /**
   * 最終更新日時
   *
   * @generated from field: google.protobuf.Timestamp last_updated_at = 13;
   */
  lastUpdatedAt?: Timestamp;

  /**
   * 備考
   *
   * @generated from field: string note = 14;
   */
  note = "";

  /**
   * Jクレ認証情報
   *
   * @generated from field: optional jcex.bff.jcredit.model.v1.JCreditCertification j_credit_certification = 15;
   */
  jCreditCertification?: JCreditCertification;

  constructor(data?: PartialMessage<Order>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.Order";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 3, name: "product", kind: "message", T: Product },
    { no: 4, name: "credit_certified_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "prefectures", kind: "message", T: Prefecture, repeated: true },
    { no: 6, name: "is_all_prefectures_selected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "position", kind: "message", T: Position },
    { no: 8, name: "volume_and_unit_price", kind: "message", T: VolumeAndUnitPrice },
    { no: 9, name: "order_status", kind: "message", T: OrderStatus },
    { no: 10, name: "assigned_trader", kind: "message", T: Trader },
    { no: 11, name: "created_at", kind: "message", T: Timestamp },
    { no: 12, name: "last_updated_by", kind: "message", T: Trader },
    { no: 13, name: "last_updated_at", kind: "message", T: Timestamp },
    { no: 14, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "j_credit_certification", kind: "message", T: JCreditCertification, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Order {
    return new Order().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJsonString(jsonString, options);
  }

  static equals(a: Order | PlainMessage<Order> | undefined, b: Order | PlainMessage<Order> | undefined): boolean {
    return proto3.util.equals(Order, a, b);
  }
}

/**
 * 約定のモデル
 *
 * @generated from message jcex.bff.jcredit.trader.v1.Deal
 */
export class Deal extends Message<Deal> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 成約ID
   *
   * @generated from field: string deal_code = 2;
   */
  dealCode = "";

  /**
   * 約定日時
   *
   * @generated from field: google.protobuf.Timestamp dealt_at = 3;
   */
  dealtAt?: Timestamp;

  /**
   * 買い注文
   *
   * @generated from field: jcex.bff.jcredit.trader.v1.DealOrder bid_deal_order = 4;
   */
  bidDealOrder?: DealOrder;

  /**
   * 売り注文
   *
   * @generated from field: jcex.bff.jcredit.trader.v1.DealOrder offer_deal_order = 5;
   */
  offerDealOrder?: DealOrder;

  /**
   * クレジット認証番号
   *
   * @generated from field: string credit_certified_number = 6;
   */
  creditCertifiedNumber = "";

  /**
   * 数量・単価
   *
   * @generated from field: jcex.bff.shared.model.v1.VolumeAndUnitPrice volume_and_unit_price = 7;
   */
  volumeAndUnitPrice?: VolumeAndUnitPrice;

  /**
   * 受渡日
   *
   * @generated from field: google.protobuf.Timestamp delivery_date = 8;
   */
  deliveryDate?: Timestamp;

  /**
   * 作成日時
   *
   * @generated from field: google.protobuf.Timestamp created_at = 9;
   */
  createdAt?: Timestamp;

  /**
   * 契約先組織
   *
   * @generated from field: jcex.bff.shared.model.v1.Organization counter_party = 10;
   */
  counterParty?: Organization;

  constructor(data?: PartialMessage<Deal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.Deal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "dealt_at", kind: "message", T: Timestamp },
    { no: 4, name: "bid_deal_order", kind: "message", T: DealOrder },
    { no: 5, name: "offer_deal_order", kind: "message", T: DealOrder },
    { no: 6, name: "credit_certified_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "volume_and_unit_price", kind: "message", T: VolumeAndUnitPrice },
    { no: 8, name: "delivery_date", kind: "message", T: Timestamp },
    { no: 9, name: "created_at", kind: "message", T: Timestamp },
    { no: 10, name: "counter_party", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Deal {
    return new Deal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Deal {
    return new Deal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Deal {
    return new Deal().fromJsonString(jsonString, options);
  }

  static equals(a: Deal | PlainMessage<Deal> | undefined, b: Deal | PlainMessage<Deal> | undefined): boolean {
    return proto3.util.equals(Deal, a, b);
  }
}

/**
 * 約定注文のモデル
 *
 * @generated from message jcex.bff.jcredit.trader.v1.DealOrder
 */
export class DealOrder extends Message<DealOrder> {
  /**
   * 注文
   *
   * @generated from field: jcex.bff.jcredit.trader.v1.Order order = 1;
   */
  order?: Order;

  /**
   * 仲介手数料 カンマ区切りでフォーマットされた状態でレスポンスされる
   *
   * @generated from field: string broking_fee = 2;
   */
  brokingFee = "";

  /**
   * 仲介高合計 カンマ区切りでフォーマットされた状態でレスポンスされる
   *
   * @generated from field: string total_broking_fee = 3;
   */
  totalBrokingFee = "";

  constructor(data?: PartialMessage<DealOrder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.DealOrder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order", kind: "message", T: Order },
    { no: 2, name: "broking_fee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_broking_fee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DealOrder {
    return new DealOrder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DealOrder {
    return new DealOrder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DealOrder {
    return new DealOrder().fromJsonString(jsonString, options);
  }

  static equals(a: DealOrder | PlainMessage<DealOrder> | undefined, b: DealOrder | PlainMessage<DealOrder> | undefined): boolean {
    return proto3.util.equals(DealOrder, a, b);
  }
}

/**
 * 約定した自身の注文のモデル
 *
 * @generated from message jcex.bff.jcredit.trader.v1.DealtOrder
 */
export class DealtOrder extends Message<DealtOrder> {
  /**
   * 約定ID
   *
   * @generated from field: string deal_id = 1;
   */
  dealId = "";

  /**
   * 　成約ID
   *
   * @generated from field: string deal_code = 2;
   */
  dealCode = "";

  /**
   * 売買区分
   *
   * @generated from field: jcex.bff.shared.model.v1.Position position = 3;
   */
  position?: Position;

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 4;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 5;
   */
  product?: Product;

  /**
   * 都道府県
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture prefectures = 6;
   */
  prefectures: Prefecture[] = [];

  /**
   * クレジット認証番号
   *
   * @generated from field: string credit_certified_number = 7;
   */
  creditCertifiedNumber = "";

  /**
   * 数量・単価
   *
   * @generated from field: jcex.bff.shared.model.v1.VolumeAndUnitPrice volume_and_unit_price = 8;
   */
  volumeAndUnitPrice?: VolumeAndUnitPrice;

  /**
   * 契約先の組織名
   *
   * @generated from field: jcex.bff.shared.model.v1.Organization counter_party_organization = 9;
   */
  counterPartyOrganization?: Organization;

  /**
   * 成約日時
   *
   * @generated from field: google.protobuf.Timestamp dealt_at = 10;
   */
  dealtAt?: Timestamp;

  constructor(data?: PartialMessage<DealtOrder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.DealtOrder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "position", kind: "message", T: Position },
    { no: 4, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 5, name: "product", kind: "message", T: Product },
    { no: 6, name: "prefectures", kind: "message", T: Prefecture, repeated: true },
    { no: 7, name: "credit_certified_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "volume_and_unit_price", kind: "message", T: VolumeAndUnitPrice },
    { no: 9, name: "counter_party_organization", kind: "message", T: Organization },
    { no: 10, name: "dealt_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DealtOrder {
    return new DealtOrder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DealtOrder {
    return new DealtOrder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DealtOrder {
    return new DealtOrder().fromJsonString(jsonString, options);
  }

  static equals(a: DealtOrder | PlainMessage<DealtOrder> | undefined, b: DealtOrder | PlainMessage<DealtOrder> | undefined): boolean {
    return proto3.util.equals(DealtOrder, a, b);
  }
}

