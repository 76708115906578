import {
  SemanticColors as DSSemanticColors,
  PrimitiveColors,
} from '@enechain/ecloud-designsystem'

export const appColors = {
  aquaBlue: '#597EFF',
  aquaBlueMid: '#597EFF33',
  natureGreen: '#00B0B2',
  natureGreenMid: '#00B0B240',
  natureGreenLow: '#00B0B21A',
  midnightBlue: '#001D5D',
}

export const SemanticColors = {
  ...DSSemanticColors,
  App: appColors,
  Text: {
    ...DSSemanticColors.Text,
    accentPrimary: appColors.midnightBlue,
  },
  Object: {
    ...DSSemanticColors.Object,
    accentPrimary: appColors.midnightBlue,
  },
  Surface: {
    ...DSSemanticColors.Surface,
    accentPrimary: appColors.midnightBlue,
    infoLow: appColors.natureGreenLow,
    bid: '#F6FAF5',
    offer: '#FEF8F8',
  },
  Hover: {
    ...DSSemanticColors.Hover,
    accentPrimaryHigh: '#000B22',
    accentPrimaryMid: appColors.natureGreenMid,
    accentPrimaryLow: appColors.natureGreenLow,
  },
  Border: {
    ...DSSemanticColors.Border,
    accentPrimary: appColors.midnightBlue,
  },
  Press: {
    ...DSSemanticColors.Press,
    accentPrimaryHigh: PrimitiveColors.black[''],
  },
} as const
