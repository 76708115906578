import { useLoaderData } from 'react-router-dom'

import { GetOrderDetailOptionResponse } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'

import { OrderDetailPageLoaderData } from '~/trader/pages/JCreditOrderDetail/loader'

type Result = {
  options: GetOrderDetailOptionResponse
}

export function useUpdateOrderOption(): Result {
  const { optionData } = useLoaderData() as OrderDetailPageLoaderData

  return {
    options: optionData,
  }
}
