import * as React from 'react'

import { Image, Skeleton } from '@chakra-ui/react'

import { BorderRadius } from '@enechain/ecloud-designsystem'

type ProfileImageProps = {
  src: string | undefined
  size: string
  borderRadius?: string
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  src,
  size,
  borderRadius = BorderRadius.radiiBase,
}) => {
  return (
    <Image
      boxSize={size}
      borderRadius={borderRadius}
      objectFit="cover"
      alt="Profile"
      fallback={<Skeleton boxSize={size} borderRadius={borderRadius} />}
      src={src}
    />
  )
}
export default React.memo(ProfileImage)
