import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { Divider, Typography } from '@enechain/ecloud-designsystem'

export const EmptyTableBody: React.FC = () => {
  const { t } = useTranslation('common')
  return (
    <>
      <Divider />
      <Flex alignItems="center" justifyContent="center" minHeight="48px">
        <Text {...Typography.textMd}>{t('error.empty_data')}</Text>
      </Flex>
    </>
  )
}
