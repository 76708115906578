import { Order } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/model_pb'
import {
  Position,
  Position_Type,
  Prefecture_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { stringUtils } from '@enechain/jcex-utils'

import { GrayOutFilter } from '~/trader/pages/OrderBookList/types/jCreditFilter'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Params = {
  orders: Order[]
  filters: GrayOutFilter
}

type VolumeAndUnitPrice = { unitPrice: string; volume: string }

export type OrderModelForJCreditOrderBook = Pick<Order, 'id'> & {
  bid: Maybe<VolumeAndUnitPrice>
  offer: Maybe<VolumeAndUnitPrice>
  isGrayOutTarget: boolean
  creditCertifiedNumber: string
  prefectureIds: Prefecture_Type[]
}

export function convertOrderModelForJCreditOrderBook({
  orders,
  filters,
}: Params): OrderModelForJCreditOrderBook[] {
  return orders.map((order) => {
    const position =
      order.position ??
      new Position({
        type: Position_Type.UNSPECIFIED,
        displayName: '',
      })

    const hasMatchingPrefecture = order.prefectures.some((prefecture) =>
      filters.prefectures.includes(prefecture.type),
    )

    const isGrayOutTarget = !hasMatchingPrefecture

    return {
      id: order.id,
      bid:
        position.type === Position_Type.BID
          ? {
              unitPrice: order.volumeAndUnitPrice?.unitPrice ?? '',
              volume: getOrderVolumeWithTBD(order.volumeAndUnitPrice?.volume),
            }
          : undefined,
      offer:
        position.type === Position_Type.OFFER
          ? {
              unitPrice: order.volumeAndUnitPrice?.unitPrice ?? '',
              volume: getOrderVolumeWithTBD(order.volumeAndUnitPrice?.volume),
            }
          : undefined,
      isGrayOutTarget,
      creditCertifiedNumber: order.creditCertifiedNumber ?? '',
      prefectureIds: order.prefectures.map((prefecture) => prefecture.type),
    }
  })
}

function getOrderVolumeWithTBD(volume?: string): string {
  if (volume === undefined) {
    return ''
  }

  if (stringUtils.isEmpty(volume)) {
    return 'TBD'
  }

  return volume
}
