import { z } from 'zod'

import {
  Position_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { createJCreditOrderSchema } from '~/trader/pages/CreateOrder/schemas/createJCreditOrderSchema'
import { createNFCOrderSchema } from '~/trader/pages/CreateOrder/schemas/createNFCOrderSchema'

export const createOrderSchema = z
  .discriminatedUnion('productType', [
    createNFCOrderSchema,
    createJCreditOrderSchema,
  ])
  .transform((arg) => {
    switch (arg.productType) {
      case ProductType_Type.NFC: {
        return arg
      }
      case ProductType_Type.J_CREDIT: {
        if (arg.position.type === Position_Type.BID) {
          return {
            ...arg,
            position: {
              ...arg.position,
              creditCertifiedNumber: undefined,
            },
          }
        }
        return arg
      }
      default: {
        return arg
      }
    }
  })

export type CreateOrderSchema = z.infer<typeof createOrderSchema>
