import * as React from 'react'

import { Flex } from '@chakra-ui/react'

import {
  BorderRadius,
  Divider,
  FlexTable,
  FlexTableHeader,
  Spacing,
} from '@enechain/ecloud-designsystem'
import {
  ChangeVolumeUnitControl,
  EmptyTableBody,
  TableBodyWithLoading,
} from '@enechain/jcex-ui'

import { nonFITOrderHistoryColumnStyles } from '~/trader/pages/HistoryList/components/NonFITNFC/OrderListTable/columnStyles'
import NonFITOrderHistoryListTableBodyRow from '~/trader/pages/HistoryList/components/NonFITNFC/OrderTableRow/body'
import { NonFITOrderHistoryListTableHeaderRow } from '~/trader/pages/HistoryList/components/NonFITNFC/OrderTableRow/header'
import { useNFCOrderHistoryList } from '~/trader/pages/HistoryList/hooks/useNFCOrderHistoryList'
import { FilterNFCOrderHistories } from '~/trader/pages/HistoryList/types/nfcFilter'

type Props = {
  filters: FilterNFCOrderHistories
}

const NonFITOrderHistoryListTable: React.FC<Props> = ({ filters }) => {
  const { nfcData, isLoading } = useNFCOrderHistoryList({
    params: filters.state,
  })

  // memo(shunya): sticky 対応は Design System の I/F にいれる
  return (
    <Flex flexDirection="column" gap={Spacing[4]}>
      <Flex justifyContent="end">
        <ChangeVolumeUnitControl />
      </Flex>
      <FlexTable
        borderWidth={1}
        borderRadius={BorderRadius.radiiLg}
        columnStyles={nonFITOrderHistoryColumnStyles}
      >
        <FlexTableHeader style="multi-line">
          <NonFITOrderHistoryListTableHeaderRow />
        </FlexTableHeader>
        <TableBodyWithLoading isLoading={isLoading} style="multi-line">
          {nfcData.length === 0 ? (
            <EmptyTableBody />
          ) : (
            nfcData.map((data) => (
              <React.Fragment key={data.id}>
                <Divider />
                <NonFITOrderHistoryListTableBodyRow data={data} />
              </React.Fragment>
            ))
          )}
        </TableBodyWithLoading>
      </FlexTable>
    </Flex>
  )
}

export default React.memo(NonFITOrderHistoryListTable)
