import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Grid } from '@chakra-ui/react'

import {
  ColumnContent,
  PrimitiveColors,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { GetOrderHistoryFilterResponse } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import {
  OrderStatus_Type,
  Position_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  FilterCheckboxGroup,
  FilterContent,
  SelectBox,
} from '@enechain/jcex-ui'

import { INITIAL_J_CREDIT_ORDER_LIST_FILTER } from '~/trader/pages/HistoryList/constants/filter'
import {
  FilterJCreditOrderHistories,
  JCreditOrderHistoryFilterProps,
} from '~/trader/pages/HistoryList/types/jCreditFilter'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  filters: FilterJCreditOrderHistories
  options: GetOrderHistoryFilterResponse
  clearFilter: () => void
}

const OrderFilterInputs: React.FC<Props> = ({
  filters,
  options,
  clearFilter,
}) => {
  const { t } = useTranslation('domain')

  const {
    positionTypes,
    productId,
    deliveryFiscalYear,
    creatorId,
    orderStatusTypes,
  } = filters.state

  // todo: shallowEqual なので deepEqual に置き換える
  const isFilterEmpty = filters.state === INITIAL_J_CREDIT_ORDER_LIST_FILTER

  const onChange = (value: JCreditOrderHistoryFilterProps): void => {
    filters.setState(value)
  }

  return (
    <FilterContent clear={clearFilter} isFilterEmpty={isFilterEmpty}>
      <Grid
        gridGap={Spacing[4]}
        gridAutoRows="repeat(2, 1fr)"
        gridTemplateColumns="repeat(3, 1fr)"
        width="90%"
      >
        <ColumnContent label={t('shared.position.title')}>
          <FilterCheckboxGroup<Position_Type>
            options={Object.fromEntries(
              options.positions.length > 0
                ? options.positions.map(({ type, displayName }) => [
                    displayName,
                    type,
                  ])
                : [],
            )}
            selectedItem={positionTypes}
            setSelectedItem={(value: Position_Type[]): void =>
              onChange({
                key: 'positionTypes',
                value,
              })
            }
          />
        </ColumnContent>
        <SelectBox<number>
          maxWidth="25rem"
          backgroundColor={PrimitiveColors.white['']}
          label={t('model.order.column.delivery_fiscal_year')}
          onChange={(value: Maybe<number>): void =>
            onChange({
              key: 'deliveryFiscalYear',
              value: value !== undefined ? Number(value) : undefined,
            })
          }
          selectable={Object.fromEntries(
            options.deliveryFiscalYears.length > 0
              ? options.deliveryFiscalYears.map(({ year, displayName }) => [
                  year,
                  displayName,
                ])
              : [],
          )}
          selectedValue={deliveryFiscalYear}
        />
        <SelectBox<number>
          maxWidth="25rem"
          backgroundColor={PrimitiveColors.white['']}
          label={t('model.order.column.j_credit.product')}
          onChange={(value: Maybe<number>): void =>
            onChange({
              key: 'productId',
              value:
                value !== undefined && !isNaN(value)
                  ? Number(value)
                  : undefined,
            })
          }
          selectable={Object.fromEntries(
            options.products.length > 0
              ? options.products.map(({ id, name }) => [id, name])
              : [],
          )}
          selectedValue={productId}
        />
        <SelectBox
          maxWidth="25rem"
          backgroundColor={PrimitiveColors.white['']}
          label={t('model.order.column.assigned_trader')}
          onChange={(value: string | undefined): void =>
            onChange({ key: 'creatorId', value })
          }
          selectable={Object.fromEntries(
            options.creators.length > 0
              ? options.creators.map(({ id, name }) => [id, name])
              : [],
          )}
          selectedValue={creatorId}
        />
        <ColumnContent label={t('shared.status')}>
          <FilterCheckboxGroup<OrderStatus_Type>
            options={Object.fromEntries(
              options.orderStatuses.length > 0
                ? options.orderStatuses.map(({ type, displayName }) => [
                    displayName,
                    type,
                  ])
                : [],
            )}
            selectedItem={orderStatusTypes}
            setSelectedItem={(value: OrderStatus_Type[]): void =>
              onChange({
                key: 'orderStatusTypes',
                value,
              })
            }
          />
        </ColumnContent>
      </Grid>
    </FilterContent>
  )
}

export default React.memo(OrderFilterInputs)
