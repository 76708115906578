import { Position_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { UpdateOrderSchema } from '~/trader/pages/JCreditOrderDetail/schemas/updateOrderSchema'

export const SAFE_ORDER_FORM_VALUE: Partial<UpdateOrderSchema> = {
  positionType: Position_Type.BID,
  creditCertifiedNumber: undefined,
  jCreditCertificationId: undefined,
  productId: undefined,
  deliveryFiscalYear: undefined,
  prefectureIds: [],
  unitPrice: undefined,
  volume: undefined,
}
