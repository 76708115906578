import { useCallback } from 'react'

import { ConnectError } from '@connectrpc/connect'
import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { useQueryClient } from '@tanstack/react-query'

import {
  createOrder as createJCreditOrder,
  getOrderHistoryFilter as getJCreditOrderHistoryFilter,
  getOrderHistoryList as getJCreditOrderHistoryList,
} from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'
import { CreateOrderRequest as JCreditCreateOrderRequest } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import {
  createOrder as createNFCOrder,
  getOrderHistoryFilter as getNFCOrderHistoryFilter,
  getOrderHistoryList as getNFCOrderHistoryList,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import { CreateOrderRequest as NFCCreateOrderRequest } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'
import { ProductType_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import {
  CreateOrderSchema,
  createOrderSchema,
} from '~/trader/pages/CreateOrder/schemas/createOrderSchema'

type CreateOrderFn = (props: {
  value: CreateOrderSchema
  onSuccess: () => void
  onError: (errorMessage: string) => void
}) => void

type Result = {
  invokeCreateOrder: CreateOrderFn
  isLoading: boolean
}

export function useCreateOrder(): Result {
  const queryClient = useQueryClient()

  const nfcMutation = useMutation(createNFCOrder, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getNFCOrderHistoryFilter),
          refetchType: 'all',
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getNFCOrderHistoryList),
          refetchType: 'all',
        }),
      ])
    },
  })

  const jCreditMutation = useMutation(createJCreditOrder, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getJCreditOrderHistoryFilter),
          refetchType: 'all',
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getJCreditOrderHistoryList),
          refetchType: 'all',
        }),
      ])
    },
  })

  const invokeCreateOrder: CreateOrderFn = useCallback(
    ({ value, onSuccess, onError }): void => {
      const validate = createOrderSchema.safeParse(value)

      if (!validate.success) {
        onError(validate.error.message)
        return
      }

      const deliveryFiscalYear = Number(validate.data.deliveryFiscalYear)
      switch (validate.data.productType) {
        case ProductType_Type.NFC: {
          const request = new NFCCreateOrderRequest({
            ...validate.data,
            deliveryFiscalYear,
          })

          nfcMutation.mutate(request, {
            onSuccess,
            onError: (error: ConnectError) => onError(error.rawMessage),
          })
          break
        }
        case ProductType_Type.J_CREDIT: {
          const request = new JCreditCreateOrderRequest({
            ...validate.data,
            ...validate.data.position,
            positionType: validate.data.position.type,
            deliveryFiscalYear,
          })

          jCreditMutation.mutate(request, {
            onSuccess,
            onError: (error: ConnectError) => onError(error.rawMessage),
          })
          break
        }
      }
    },
    [jCreditMutation, nfcMutation],
  )

  return {
    invokeCreateOrder,
    isLoading: nfcMutation.isPending || jCreditMutation.isPending,
  }
}
