import { startOfYear, subMonths, subYears } from 'date-fns'

import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

import { FILTER_DATE_RANGE_OPTION } from '~/trader/pages/CarbonIndex/constants/filter'
import { FilterDateRange } from '~/trader/pages/CarbonIndex/types/filterDateRange'

export function getDateFromPresetDateRange(value: FilterDateRange): Date {
  const today = new Date()

  switch (value) {
    case FILTER_DATE_RANGE_OPTION.MONTH:
      return subMonths(today, 1)
    case FILTER_DATE_RANGE_OPTION.THREE_MONTH:
      return subMonths(today, 3)
    case FILTER_DATE_RANGE_OPTION.SIX_MONTH:
      return subMonths(today, 6)
    case FILTER_DATE_RANGE_OPTION.YEAR:
      return subYears(today, 1)
    case FILTER_DATE_RANGE_OPTION.THREE_YEAR:
      return subYears(today, 3)
    case FILTER_DATE_RANGE_OPTION.BEGINNING_OF_THE_YEAR:
      return startOfYear(today)
    default:
      return today
  }
}

export const PRODUCT_TYPE_MAP = {
  REUSABLE_ENERGY_YES: 1,
  REUSABLE_ENERGY_NO: 2,
  TRACKING_YES: 3,
  TRACKING_NO: 4,
}

/**
 | 再エネ指定 |  | トラッキング |  | 結果 |
 | --- | --- | --- | --- | --- |
 | あり | なし | あり | なし | (番号は上記のマッピング参照) |
 | ✅ | ✅ | ✅ | ◽️ | １ |
 | ✅ | ◽️ | ✅ | ◽️ | １ |
 | ✅ | ◽️ | ◽️ | ✅ | ２ |
 | ◽️ | ✅ | ◽️ | ✅ | ４ |
 | ◽️ | ✅ | ✅ | ✅ | ４ |
 | ✅ | ◽️ | ✅ | ✅ | １、２ |
 | ✅ | ✅ | ◽️ | ✅ | ２、４ |
 | ✅ | ✅ | ✅ | ✅ | １、２、４ |


 @reference
 https://www.notion.so/enechain/718626ac9c854edc8b94692cc58040e3?pvs=4#d346ce50b4764a55936fe06719ba3298
 */
const PRODUCT_TYPE_CONDITIONS = [
  {
    reusableEnergyYes: true,
    reusableEnergyNo: true,
    trackingYes: true,
    trackingNo: false,
    result: [
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING,
    ],
  },
  {
    reusableEnergyYes: true,
    reusableEnergyNo: false,
    trackingYes: true,
    trackingNo: false,
    result: [
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING,
    ],
  },
  {
    reusableEnergyYes: true,
    reusableEnergyNo: false,
    trackingYes: false,
    trackingNo: true,
    result: [RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY],
  },
  {
    reusableEnergyYes: false,
    reusableEnergyNo: true,
    trackingYes: false,
    trackingNo: true,
    result: [RenewableEnergyAndTrackingStatus_Type.NONE],
  },
  {
    reusableEnergyYes: false,
    reusableEnergyNo: true,
    trackingYes: true,
    trackingNo: true,
    result: [RenewableEnergyAndTrackingStatus_Type.NONE],
  },
  {
    reusableEnergyYes: true,
    reusableEnergyNo: false,
    trackingYes: true,
    trackingNo: true,
    result: [
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING,
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY,
    ],
  },
  {
    reusableEnergyYes: true,
    reusableEnergyNo: true,
    trackingYes: false,
    trackingNo: true,
    result: [
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY,
      RenewableEnergyAndTrackingStatus_Type.NONE,
    ],
  },
  {
    reusableEnergyYes: true,
    reusableEnergyNo: true,
    trackingYes: true,
    trackingNo: true,
    result: [
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING,
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY,
      RenewableEnergyAndTrackingStatus_Type.NONE,
    ],
  },
]

export function calcNFCProductTypes(
  values: number[],
): RenewableEnergyAndTrackingStatus_Type[] {
  const condition = {
    reusableEnergyYes: values.includes(PRODUCT_TYPE_MAP.REUSABLE_ENERGY_YES),
    reusableEnergyNo: values.includes(PRODUCT_TYPE_MAP.REUSABLE_ENERGY_NO),
    trackingYes: values.includes(PRODUCT_TYPE_MAP.TRACKING_YES),
    trackingNo: values.includes(PRODUCT_TYPE_MAP.TRACKING_NO),
  }

  for (const config of PRODUCT_TYPE_CONDITIONS) {
    if (
      condition.reusableEnergyYes === config.reusableEnergyYes &&
      condition.reusableEnergyNo === config.reusableEnergyNo &&
      condition.trackingYes === config.trackingYes &&
      condition.trackingNo === config.trackingNo
    ) {
      return config.result
    }
  }

  return []
}
