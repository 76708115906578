import * as React from 'react'
import { Suspense } from 'react'

import { Await, useLoaderData } from 'react-router-dom'

import { Loading } from '@enechain/ecloud-designsystem'
import { TAB_TYPES, TabKey } from '@enechain/jcex-ui'

import JCreditFilterInputs from '~/trader/pages/OrderBookList/components/JCredit/FilterInputs'
import JCreditListTable from '~/trader/pages/OrderBookList/components/JCredit/ListTable'
import NonFITNFCFilterInputs from '~/trader/pages/OrderBookList/components/NonFITNFC/FilterInputs'
import NonFITNFCListTable from '~/trader/pages/OrderBookList/components/NonFITNFC/ListTable'
import { useOrderBookFilter } from '~/trader/pages/OrderBookList/hooks/useOrderBookFilter'
import { OrderBookListPageLoaderData } from '~/trader/pages/OrderBookList/loader'

type Props = {
  selectedTab: TabKey
}

const TabContainer: React.FC<Props> = ({ selectedTab }) => {
  const { nfc: nfcLoaderData, jCredit: jCreditLoaderData } =
    useLoaderData() as OrderBookListPageLoaderData
  const { nfc, jCredit, clearFilter } = useOrderBookFilter()

  switch (selectedTab) {
    case TAB_TYPES.NFC:
      return (
        <>
          <NonFITNFCFilterInputs
            clearFilter={clearFilter}
            excludeOptions={nfc.excludeOptions}
            filters={nfc.filters}
            grayOutFilters={nfc.grayOutFilters}
            options={nfc.options}
          />
          <Suspense fallback={<Loading />}>
            <Await resolve={nfcLoaderData.listData}>
              {(): React.ReactElement => (
                <NonFITNFCListTable
                  filters={nfc.filters}
                  grayOutFilters={nfc.grayOutFilters}
                />
              )}
            </Await>
          </Suspense>
        </>
      )
    case TAB_TYPES.JCREDIT:
      return (
        <>
          <JCreditFilterInputs
            clearFilter={clearFilter}
            excludeOptions={jCredit.excludeOptions}
            filters={jCredit.filters}
            grayOutFilters={jCredit.grayOutFilters}
            options={jCredit.options}
          />
          <Suspense fallback={<Loading />}>
            <Await resolve={jCreditLoaderData.listData}>
              {(): React.ReactElement => (
                <JCreditListTable
                  filters={jCredit.filters}
                  grayOutFilters={jCredit.grayOutFilters}
                />
              )}
            </Await>
          </Suspense>
        </>
      )
  }
}

export default React.memo(TabContainer)
