import * as React from 'react'
import { memo } from 'react'

import { Icon, IconProps, forwardRef } from '@chakra-ui/react'

const SvgCircleXmark: React.FC<IconProps> = forwardRef<IconProps, 'svg'>(
  function Svg(props: IconProps, ref): JSX.Element {
    return (
      <Icon
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 20 20"
        aria-hidden="true"
        {...props}
      >
        <path
          fill="currentColor"
          d="M13.257 7.564q.3-.365 0-.728-.365-.3-.729 0L10 9.386l-2.55-2.55q-.363-.3-.728 0-.3.363 0 .728l2.55 2.55-2.55 2.57q-.3.366 0 .73.365.3.728 0L10 10.841l2.55 2.55q.363.3.728 0 .3-.365 0-.729l-2.55-2.55z"
        />
      </Icon>
    )
  },
)
const Memo = memo(SvgCircleXmark)
export default Memo
