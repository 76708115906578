import {
  OrderStatus,
  OrderStatus_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { StatusType } from '@enechain/jcex-ui'

import { Maybe } from '~/trader/types/AdvancedTypes'

export type OrderStatusModel = StatusType

const statusTypeUnspecified: OrderStatusModel = {
  statusType: OrderStatus_Type.UNSPECIFIED,
  type: 'neutral',
  displayLabel: '',
}

export function convertOrderStatusModel(
  value: Maybe<OrderStatus>,
): OrderStatusModel {
  if (value === undefined) {
    return statusTypeUnspecified
  }

  switch (value.type) {
    case OrderStatus_Type.ENABLED:
      return {
        statusType: value.type,
        type: 'info',
        displayLabel: value.displayName,
      }
    case OrderStatus_Type.CANCELED:
      return {
        statusType: value.type,
        type: 'error',
        displayLabel: value.displayName,
      }
    case OrderStatus_Type.DEALT:
      return {
        statusType: value.type,
        type: 'positive',
        displayLabel: value.displayName,
      }
    case OrderStatus_Type.EXPIRED:
      return {
        statusType: value.type,
        type: 'error',
        displayLabel: value.displayName,
      }
    case OrderStatus_Type.UNSPECIFIED:
    default:
      return statusTypeUnspecified
  }
}

export function isOrderActive(
  orderStatusModel: Maybe<OrderStatusModel>,
): boolean {
  return orderStatusModel?.statusType === OrderStatus_Type.ENABLED
}
