import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { TertiaryButton } from '@enechain/ecloud-designsystem'
import { FaCopyRegular } from '@enechain/ecloud-icons'

type Props = {
  isDisabled: boolean
  onClick: () => void
}

export const DuplicateOrderButton: React.FC<Props> = ({
  isDisabled,
  onClick,
}) => {
  const { t } = useTranslation('trader')
  return (
    <TertiaryButton
      fontSize={14}
      isDisabled={isDisabled}
      leftIcon={<FaCopyRegular width="24px" height="24px" />}
      onClick={onClick}
      size="sm"
    >
      {t('page.order_book_list.duplicate_order')}
    </TertiaryButton>
  )
}
