import { useCallback } from 'react'

import { ConnectError } from '@connectrpc/connect'
import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'

import {
  cancelOrder,
  getOrderHistoryList,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import { CancelOrderRequest } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'
import { useErrorToast, useSuccessToast } from '@enechain/jcex-ui'

import { OrderDetailPageLoaderData } from '~/trader/pages/NFCOrderDetail/loader'

type Result = {
  invokeCancelOrder: () => Promise<{ isSuccess: boolean }>
  isLoading: boolean
}

export function useCancelOrder(): Result {
  const { t } = useTranslation(['common', 'domain'])
  const queryClient = useQueryClient()
  const { orderId } = useLoaderData() as OrderDetailPageLoaderData
  const { showToast: showSuccessToast } = useSuccessToast({
    toastId: 'useCancelNFCOrder/success',
  })
  const { showToast: showErrorToast } = useErrorToast({
    toastId: 'useCancelNFCOrder/error',
  })

  const mutation = useMutation(cancelOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(getOrderHistoryList),
        refetchType: 'all',
      })
      showSuccessToast({
        toastMessage: t('common:success.cancel', {
          value: t('domain:model.order.title'),
        }),
      })
    },
  })

  const invokeCancelOrder = useCallback(async () => {
    const result = await mutation
      .mutateAsync(new CancelOrderRequest({ orderId }))
      .catch((error: ConnectError) => {
        showErrorToast({ toastMessage: error.rawMessage })
        return { error }
      })

    return { isSuccess: !('error' in result) }
  }, [mutation, orderId, showErrorToast])

  return { invokeCancelOrder, isLoading: mutation.isPending }
}
