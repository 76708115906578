export type NonFITOrderListColumnKey =
  | 'accordion'
  | 'deliveryFiscalYear'
  | 'renewableEnergyAndTrackingStatus'
  | 'bidVolume'
  | 'bidUnitPrice'
  | 'offerUnitPrice'
  | 'offerVolume'
  | 'lastUnitPrice'
  | 'copyOrderAction'

export const nonFITOrderColumnStyles = {
  accordion: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '36px',
    },
  },
  deliveryFiscalYear: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.4,
    },
  },
  renewableEnergyAndTrackingStatus: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  bidVolume: {
    alignment: 'right',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  bidUnitPrice: {
    alignment: 'right',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  offerUnitPrice: {
    alignment: 'right',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  offerVolume: {
    alignment: 'right',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  lastUnitPrice: {
    alignment: 'right',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  copyOrderAction: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '128px',
    },
  },
} as const
