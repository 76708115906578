import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Stack, Text } from '@chakra-ui/react'

import {
  ColumnContent,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
  TabItem,
  Tabs,
} from '@enechain/ecloud-designsystem'
import {
  TAB_TYPES,
  TabKey,
  useTabMutations,
  useTabQueries,
} from '@enechain/jcex-ui'

import { GreenIndexFilterInputs } from '~/trader/pages/CarbonIndex/components/FilterInputs'
import { JCreditPriceContents } from '~/trader/pages/CarbonIndex/components/JCreditPriceContents'
import { LineChart } from '~/trader/pages/CarbonIndex/components/LineChart'
import { NFCPriceContents } from '~/trader/pages/CarbonIndex/components/NFCPriceContents'
import { useGreenIndex } from '~/trader/pages/CarbonIndex/hooks/useGreenIndex'
import { useGreenIndexFilter } from '~/trader/pages/CarbonIndex/hooks/useGreenIndexFilter'

export const CarbonIndexPage: React.FC = () => {
  const { t } = useTranslation(['common', 'domain'])
  const selectedTab = useTabQueries()
  const { setTabState } = useTabMutations()

  const { greenIndexFilter } = useGreenIndexFilter()
  const { greenIndexData, filterOptions } = useGreenIndex({
    filter: greenIndexFilter,
  })

  const breadcrumbItems = [
    { label: t('domain:model.carbon_index.title'), bold: true },
  ]

  const tabItems: Array<TabItem<TabKey>> = [
    {
      label: t('domain:shared.nfc'),
      key: TAB_TYPES.NFC,
    },
    {
      label: t('domain:shared.j_credit'),
      key: TAB_TYPES.JCREDIT,
    },
  ]

  return (
    <SideMenuLayoutContentLayout
      header={
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <SideMenuLayoutBreadcrumb items={breadcrumbItems} />
        </Flex>
      }
    >
      <Flex flexDirection="column" gap={Spacing[6]} width="full" height="full">
        <Tabs<TabKey>
          items={tabItems}
          onSelect={setTabState}
          selected={selectedTab}
        />
        <GreenIndexFilterInputs
          filters={greenIndexFilter}
          options={filterOptions}
          tab={selectedTab}
        />
        {greenIndexData === undefined ? (
          <Text>{t('common:error.empty_data')}</Text>
        ) : (
          <>
            {selectedTab === TAB_TYPES.NFC && (
              <NFCPriceContents data={greenIndexData.nfc.summary} />
            )}
            {selectedTab === TAB_TYPES.JCREDIT && (
              <JCreditPriceContents data={greenIndexData.jCredit.summary} />
            )}
            <ColumnContent
              label={t(
                `domain:shared.unit_price.${
                  selectedTab === 'nfc' ? 'kwh' : 't_co2'
                }`,
              )}
            >
              <Stack spacing={Spacing[6]}>
                <LineChart data={greenIndexData} tab={selectedTab} />
              </Stack>
            </ColumnContent>
          </>
        )}
      </Flex>
    </SideMenuLayoutContentLayout>
  )
}
