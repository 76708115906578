import * as React from 'react'

import { Code, ConnectError } from '@connectrpc/connect'
import { useRouteError } from 'react-router-dom'

import { trimErrorMessage } from '../../../utils/errorMessage'
import { NotFound } from '../NotFound'
import { ProtectedAuthGuard } from '../ProtectedAuthGuard'
import { BasicErrorPage } from './BasicErrorPage'
import { PermissionDeniedErrorPage } from './PermissionDeniedErrorPage'
import { UnauthorizedErrorPage } from './UnauthorizedErrorPage'

const PERMISSION_DENIED_ERROR_MESSAGE = 'PermissionDenied'

const ErrorLayout: React.FC = () => {
  const error = useRouteError()

  const { message: errorMessage } = error as { message: string }
  const trimMessage = trimErrorMessage(errorMessage)
  if (
    errorMessage !== undefined &&
    errorMessage.includes(PERMISSION_DENIED_ERROR_MESSAGE) &&
    trimMessage !== undefined
  ) {
    return <PermissionDeniedErrorPage message={trimMessage} />
  }

  if (!(error instanceof ConnectError)) {
    // TODO: エラーページのデザインが更新されたら対応する
    return <BasicErrorPage />
  }

  switch (error.code) {
    case Code.NotFound:
      return <NotFound />
    case Code.Unauthenticated:
      return <UnauthorizedErrorPage />
    case Code.PermissionDenied:
      return <PermissionDeniedErrorPage message={error.rawMessage} />
    default:
      return <BasicErrorPage />
  }
}

export default ProtectedAuthGuard(ErrorLayout)
