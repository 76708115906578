import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

/**
 * 判例 (./ChartLegend/index.tsx) の width が 240px のため、それに合わせている
 */
const RIGHT_POSITION = '120px'

export const EmptyChart: React.FC = () => {
  const { t } = useTranslation(['common'])

  return (
    <Flex
      position="absolute"
      top="0"
      right={RIGHT_POSITION}
      alignItems="center"
      justifyContent="center"
      width="full"
      height="full"
    >
      <Text>{t('common:error.empty_data')}</Text>
    </Flex>
  )
}
