import { t } from 'i18next'

import { Prefecture } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

type Response = Array<{
  prefectures: Prefecture[]
  displayName: string
}>

// todo: エリアの分け方がマジックナンバーすぎるので、proto などに定義しておきたい...
export function makeRegionsWithPrefectures(
  prefectures: Prefecture[],
): Response {
  return [
    {
      prefectures: prefectures,
      displayName: t('domain:shared.prefecture.region.all'),
    },
    {
      prefectures: prefectures.slice(0, 1),
      displayName: t('domain:shared.prefecture.region.hokkaido'),
    },
    {
      prefectures: prefectures.slice(1, 7),
      displayName: t('domain:shared.prefecture.region.tohoku'),
    },
    {
      prefectures: prefectures.slice(7, 14),
      displayName: t('domain:shared.prefecture.region.kanto'),
    },
    {
      prefectures: prefectures.slice(14, 20),
      displayName: t('domain:shared.prefecture.region.hokuriku_koushinetsu'),
    },
    {
      prefectures: prefectures.slice(20, 24),
      displayName: t('domain:shared.prefecture.region.tokai'),
    },
    {
      prefectures: prefectures.slice(24, 30),
      displayName: t('domain:shared.prefecture.region.kinki'),
    },
    {
      prefectures: prefectures.slice(30, 35),
      displayName: t('domain:shared.prefecture.region.chugoku'),
    },
    {
      prefectures: prefectures.slice(35, 39),
      displayName: t('domain:shared.prefecture.region.shikoku'),
    },
    {
      prefectures: prefectures.slice(39, 46),
      displayName: t('domain:shared.prefecture.region.kyushu'),
    },
    {
      prefectures: prefectures.slice(46, 47),
      displayName: t('domain:shared.prefecture.region.okinawa'),
    },
  ]
}
