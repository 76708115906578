import { GetOrderBookListRequest as GetJCreditOrderBookListRequest } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import { GetOrderBookListRequest as GetNFCOrderBookListRequest } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'
import { Position_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

/**
 * todo: UseMultiSelect は API 対応後削除
 */
export const INITIAL_NFC_ORDER_BOOK_FILTER = new GetNFCOrderBookListRequest({
  positionTypes: [Position_Type.BID, Position_Type.OFFER],
  renewableEnergyAndTrackingStatusType: undefined,
  deliveryFiscalYear: undefined,
  useMultiSelect: true,
})

/**
 * todo: UseMultiSelect は API 対応後削除
 */
export const INITIAL_J_CREDIT_ORDER_BOOK_FILTER =
  new GetJCreditOrderBookListRequest({
    positionTypes: [Position_Type.BID, Position_Type.OFFER],
    productId: undefined,
    deliveryFiscalYear: undefined,
    useMultiSelect: true,
  })
