import { Transport } from '@connectrpc/connect'
import {
  callUnaryMethod,
  createConnectQueryKey,
} from '@connectrpc/connect-query'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunction, defer } from 'react-router-dom'

import { getCreateOrderOption as getJCreditCreateOrderOption } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'
import { GetCreateOrderOptionResponse as GetJCreditCreateOrderOptionResponse } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import { getCreateOrderOption as getNFCCreateOrderOption } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import { GetCreateOrderOptionResponse as GetNFCCreateOrderOptionResponse } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'

export type CreateOrderPageLoaderData = {
  nfcOption: GetNFCCreateOrderOptionResponse
  jCreditOption: GetJCreditCreateOrderOptionResponse
  nfcOptionUpdatedAt: number
  jCreditOptionUpdatedAt: number
}

export function createOrderPageLoader(
  transport: Transport,
  queryClient: QueryClient,
): LoaderFunction {
  return async () => {
    const nfcQueryKey = createConnectQueryKey(getNFCCreateOrderOption)
    const jCreditQueryKey = createConnectQueryKey(getJCreditCreateOrderOption)

    const [nfcOptionResponse, jCreditOptionResponse] = await Promise.all([
      // nfcOptionResponse
      queryClient.ensureQueryData({
        queryKey: nfcQueryKey,
        queryFn: () =>
          callUnaryMethod(getNFCCreateOrderOption, {}, { transport }),
      }),
      // jCreditOptionResponse
      queryClient.ensureQueryData({
        queryKey: jCreditQueryKey,
        queryFn: () =>
          callUnaryMethod(getJCreditCreateOrderOption, {}, { transport }),
      }),
    ])

    const nfcOptionUpdatedAt =
      queryClient.getQueryState(nfcQueryKey)?.dataUpdatedAt ?? 0
    const jCreditOptionUpdatedAt =
      queryClient.getQueryState(jCreditQueryKey)?.dataUpdatedAt ?? 0

    return defer({
      nfcOption: nfcOptionResponse,
      jCreditOption: jCreditOptionResponse,
      nfcOptionUpdatedAt,
      jCreditOptionUpdatedAt,
    })
  }
}
