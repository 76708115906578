import { Big } from 'big.js'

type MaybeObject<T> = T | null | undefined

function isEmpty(str: MaybeObject<string>): boolean {
  if (str === null || str === undefined) {
    return true
  }
  return str.trim() === ''
}

function isNotEmpty(str: MaybeObject<string>): boolean {
  return !isEmpty(str)
}

/**
 * @memo 四捨五入積は以下を参照
 * @docs https://mikemcl.github.io/big.js/#rm
 */
function multiplyAndRound(
  value: string,
  multiplier: string,
  decimal?: number,
): string {
  return new Big(value)
    .times(new Big(multiplier))
    .toFixed(decimal !== undefined ? decimal : 0, 1)
    .toString()
}

export const stringUtils = {
  isEmpty,
  isNotEmpty,
  multiplyAndRound,
}
