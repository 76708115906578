import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { Controller, FieldErrors, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RadioProps, Stack, Text } from '@chakra-ui/react'

import {
  Label,
  PrimitiveColors,
  RadioButton,
  SemanticColors,
  Spacing,
  Term,
} from '@enechain/ecloud-designsystem'
import {
  GeneratorType_Type,
  RenewableEnergyAndTrackingStatus_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import {
  Position_Type,
  Prefecture_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  GeneratorForm,
  NumberInputWithComma,
  PrefectureForm,
  SelectBox,
} from '@enechain/jcex-ui'
import { radioStyle } from '@enechain/jcex-ui/src/constants/radio'

import { useCreateOrderOption } from '~/trader/pages/CreateOrder/hooks/useCreateOrderOption'
import { NFCCreateOrderSchema } from '~/trader/pages/CreateOrder/schemas/createNFCOrderSchema'
import { CreateOrderSchema } from '~/trader/pages/CreateOrder/schemas/createOrderSchema'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  formMethods: UseFormReturn<CreateOrderSchema>
}

const NFCProductForm: React.FC<Props> = ({ formMethods }) => {
  const { t } = useTranslation('domain')

  const watchedPrefectureIds = formMethods.watch('prefectureIds')
  const watchedGeneratorIds = formMethods.watch('generatorIds')
  const watchedPositionType = formMethods.watch('positionType')
  const watchedTrackingStatus = formMethods.watch(
    'renewableEnergyAndTrackingStatusType',
  )

  const {
    options: { nfc: options },
  } = useCreateOrderOption()

  const formErrors = useMemo(() => {
    return formMethods.formState.errors as FieldErrors<NFCCreateOrderSchema>
  }, [formMethods.formState.errors])

  const setAllPrefecturesAndGenerators = useCallback((): void => {
    formMethods.setValue(
      'prefectureIds',
      options.prefectures
        .map(({ type }) => type)
        .filter((type) => type !== Prefecture_Type.UNSPECIFIED) ?? [],
    )
    formMethods.setValue(
      'generatorIds',
      options.generatorTypes
        .map(({ type }) => type)
        .filter((type) => type !== GeneratorType_Type.UNSPECIFIED) ?? [],
    )
  }, [formMethods, options.prefectures, options.generatorTypes])

  const clearPrefecturesAndGenerators = useCallback((): void => {
    formMethods.setValue('prefectureIds', [])
    formMethods.setValue('generatorIds', [])
  }, [formMethods])

  // 買いの場合は、都道府県と発電所全てを選択し、売りの場合は単一の都道府県と発電所を選択する
  const onChangePositionType = (value: number): void => {
    if (
      value === Position_Type.BID &&
      watchedTrackingStatus ===
        RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING
    ) {
      setAllPrefecturesAndGenerators()
    } else {
      clearPrefecturesAndGenerators()
    }
    formMethods.setValue('positionType', value)
  }

  // 買いの場合は、トラッキングなしの場合は全て選択しておくため、初期値として全て選択させておく
  const onChangeRenewableEnergyAndTracking = (value: Maybe<number>): void => {
    if (
      watchedPositionType === Position_Type.BID &&
      value ===
        RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING
    ) {
      setAllPrefecturesAndGenerators()
    } else {
      clearPrefecturesAndGenerators()
    }

    formMethods.setValue('renewableEnergyAndTrackingStatusType', value)
  }

  return (
    <Stack spacing={Spacing[6]}>
      <Controller
        control={formMethods.control}
        name="productType"
        render={({ field: { onChange, value } }): React.ReactElement => (
          <Term>
            <Label required>{t('shared.product_type')}</Label>
            <RadioButton
              onChange={(value): void => onChange(Number(value))}
              radios={options.productTypes.map(
                ({ type, displayName }) =>
                  ({
                    ...radioStyle,
                    value: type.toString(),
                    label: displayName,
                  } as RadioProps & { label: string }),
              )}
              value={value.toString()}
            />
          </Term>
        )}
      />
      <Controller
        control={formMethods.control}
        name="positionType"
        render={({ field: { value } }): React.ReactElement => (
          <Term>
            <Label required>{t('shared.position.title')}</Label>
            <RadioButton
              onChange={(value): void => onChangePositionType(Number(value))}
              radios={options.positions.map(
                ({ type, displayName }) =>
                  ({
                    ...radioStyle,
                    value: type.toString(),
                    label: displayName,
                  } as RadioProps & { label: string }),
              )}
              value={value.toString()}
            />
          </Term>
        )}
      />
      <Controller
        control={formMethods.control}
        name="deliveryFiscalYear"
        render={({ field: { value } }): React.ReactElement => (
          <Term>
            <Label required>
              {t('model.order.column.delivery_fiscal_year')}
            </Label>
            <SelectBox<number>
              maxWidth="25rem"
              backgroundColor={PrimitiveColors.white['']}
              onChange={(value: number | undefined): void =>
                formMethods.setValue(
                  'deliveryFiscalYear',
                  value !== undefined ? Number(value) : undefined,
                )
              }
              selectable={Object.fromEntries(
                options.deliveryFiscalYears.length > 0
                  ? options.deliveryFiscalYears.map(({ year, displayName }) => [
                      Number(year),
                      displayName,
                    ])
                  : [],
              )}
              selectedValue={value}
            />
            <Text color={SemanticColors.Text.negativeMid}>
              {formErrors.deliveryFiscalYear?.message}
            </Text>
          </Term>
        )}
      />
      <Controller
        control={formMethods.control}
        name="renewableEnergyAndTrackingStatusType"
        render={({ field: { value } }): React.ReactElement => (
          <Term>
            <Label required>
              {t('model.order.column.nfc.renewable_energy_and_tracking')}
            </Label>
            <SelectBox<number>
              maxWidth="25rem"
              backgroundColor={PrimitiveColors.white['']}
              onChange={(value: Maybe<number>): void =>
                onChangeRenewableEnergyAndTracking(
                  value !== undefined ? Number(value) : undefined,
                )
              }
              selectable={Object.fromEntries(
                options.renewableEnergyAndTrackingStatuses.length > 0
                  ? options.renewableEnergyAndTrackingStatuses.map(
                      ({ type, displayName }) => [Number(type), displayName],
                    )
                  : [],
              )}
              selectedValue={value}
            />
            <Text color={SemanticColors.Text.negativeMid}>
              {formErrors.renewableEnergyAndTrackingStatusType?.message}
            </Text>
          </Term>
        )}
      />
      <PrefectureForm
        error={formErrors.prefectureIds?.message}
        onChange={(value): void => formMethods.setValue('prefectureIds', value)}
        options={options.prefectures}
        productType={ProductType_Type.NFC}
        selectedPositionType={watchedPositionType}
        selectedTrackingStatus={watchedTrackingStatus}
        values={watchedPrefectureIds}
      />
      <GeneratorForm
        error={formErrors.generatorIds?.message}
        onChange={(value): void => formMethods.setValue('generatorIds', value)}
        options={options.generatorTypes}
        selectedPositionType={watchedPositionType}
        selectedTrackingStatus={watchedTrackingStatus}
        values={watchedGeneratorIds}
      />
      <Controller
        control={formMethods.control}
        name="unitPrice"
        render={({ field: { onChange, value } }): React.ReactElement => (
          <Term>
            <Label required>{t('shared.unit_price.kwh')}</Label>
            <NumberInputWithComma
              error={formErrors.unitPrice?.message}
              onChange={onChange}
              value={value}
            />
          </Term>
        )}
      />
      <Controller
        control={formMethods.control}
        name="volume"
        render={({ field: { onChange, value } }): React.ReactElement => (
          <Term>
            <Label required>{t('shared.volume.kwh')}</Label>
            <NumberInputWithComma
              error={formErrors.volume?.message}
              onChange={onChange}
              value={value}
            />
          </Term>
        )}
      />
    </Stack>
  )
}

export default NFCProductForm
