import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import { Tooltip } from '@enechain/ecloud-designsystem'

type Props = {
  isHidden: boolean
  children: React.ReactNode
}

const TOOLTIP_OFFSET: [number, number] = [130, -20]

export const CertifiedNumberTooltip: React.FC<Props> = ({
  isHidden,
  children,
}) => {
  const { t } = useTranslation(['common', 'domain'])

  return (
    <Tooltip
      hasArrow
      isHidden={isHidden}
      label={t('common:error.first_input', {
        value: t('domain:model.order.column.j_credit.credit_certified_number'),
      })}
      offset={TOOLTIP_OFFSET}
      placement="top-start"
    >
      <Box>{children}</Box>
    </Tooltip>
  )
}
