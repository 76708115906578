import { Position_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

export const SAFE_ORDER_FORM_VALUE = {
  orderId: undefined,
  positionType: Position_Type.BID,
  renewableEnergyAndTrackingStatusType: undefined,
  // todo: 以下の値は仮で入れている
  prefectureIds: [1],
  generatorIds: [1],
  deliveryFiscalYear: undefined,
  unitPrice: undefined,
  volume: undefined,
}
