import * as React from 'react'
import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import {
  Flex,
  RadioGroup,
  Stack,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'

import {
  Input,
  Label,
  PrimaryModal,
  Spacing,
  Term,
  TertiaryButton,
  Tooltip,
  Typography,
} from '@enechain/ecloud-designsystem'
import { JCreditCertification } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/model/v1/model_pb'

import { CreditCertificationCard } from '../CreditCertificationCard'

type Props = {
  value: string | undefined
  selectedCertification: {
    state: JCreditCertification | undefined
    setState: (state: JCreditCertification | undefined) => void
  }
  options: {
    state: JCreditCertification[]
    setState: (state: JCreditCertification[]) => void
  }
  disclosure: UseDisclosureReturn
  onChange: (value: string | undefined) => void
  onClickConfirm: () => void
  onClickSearch: () => Promise<void>
  error?: string
  isDisabled?: boolean
}

const J_CREDIT_HALF_LENGTH_REGEX = /^\d{7}$/

export const CreditCertifiedForm: React.FC<Props> = ({
  value,
  selectedCertification,
  options,
  disclosure,
  onChange,
  onClickConfirm,
  onClickSearch,
  error,
  isDisabled,
}) => {
  const { t } = useTranslation(['common', 'component', 'domain'])

  const isDisabledLength = useMemo(() => {
    return value === undefined || !J_CREDIT_HALF_LENGTH_REGEX.test(value)
  }, [value])

  const onClickCertification = (id: string): void => {
    selectedCertification.setState(
      options.state.find((option) => option.id === id),
    )
  }

  const handleConfirm = (): void => {
    onClickConfirm()
    disclosure.onClose()
  }

  return (
    <>
      <PrimaryModal
        actionText={t('common:action.neutral.select')}
        bodyChildren={
          <Stack overflow="hidden" spacing={Spacing[4]}>
            <Text {...Typography.headerSm}>
              {t('component:features.credit_certified_form.modal.description')}
            </Text>
            <RadioGroup value={selectedCertification.state?.id ?? undefined}>
              <Flex gap={Spacing[4]} overflowX="scroll">
                {options.state.map((certification) => (
                  <CreditCertificationCard
                    key={certification.id}
                    onClick={(): void => onClickCertification(certification.id)}
                    value={certification}
                  />
                ))}
              </Flex>
            </RadioGroup>
          </Stack>
        }
        closeText={t('common:action.negative.cancel')}
        disclosure={disclosure}
        isCentered
        onClick={handleConfirm}
        title={t('component:features.credit_certified_form.modal.title')}
      />
      <Term>
        <Label required>
          {t('domain:model.order.column.j_credit.credit_certified_number')}
        </Label>
        <Flex gap={Spacing[1]}>
          <Input
            width="25rem"
            error={error}
            isDisabled={isDisabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              onChange(event.target.value)
            }}
            placeholder={t('common:form.placeholder.credit_certified_number')}
            value={value ?? ''}
          />
          <Tooltip
            isHidden={!isDisabledLength}
            label={t('component:features.credit_certified_form.tooltip')}
          >
            <TertiaryButton
              isDisabled={isDisabledLength}
              onClick={onClickSearch}
            >
              {t('component:features.credit_certified_form.action')}
            </TertiaryButton>
          </Tooltip>
        </Flex>
      </Term>
    </>
  )
}
