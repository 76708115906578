import { useCallback } from 'react'

import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

import {
  Position_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { CreateOrderSchema } from '~/trader/pages/CreateOrder/schemas/createOrderSchema'
import { JCreditOrderBookModel } from '~/trader/pages/OrderBookList/models/JCredit/orderBook'
import { NFCOrderBookModel } from '~/trader/pages/OrderBookList/models/NonFITNFC/orderBook'
import { Maybe } from '~/trader/types/AdvancedTypes'

type MutationReturnType = {
  setOrder: (value: CreateOrderSchema) => void
  remove: () => void
}

const duplicateOrderState = atom<Maybe<CreateOrderSchema>>({
  key: 'duplicateOrderState',
  default: undefined,
})

export function useDuplicateOrderQueries(): Maybe<CreateOrderSchema> {
  return useRecoilValue(duplicateOrderState)
}

export function useDuplicateOrderMutations(): MutationReturnType {
  const setState = useSetRecoilState(duplicateOrderState)
  const update = useCallback(
    (value: CreateOrderSchema) => {
      setState(value)
    },
    [setState],
  )
  const remove = useCallback(() => {
    setState(undefined)
  }, [setState])
  return {
    setOrder: update,
    remove,
  }
}

/**
 * 複製注文時は isRenderValue が true (アコーディオンが閉じていない) 場合のみ 全て埋める
 * https://www.notion.so/enechain/00390104e94e42c7bcacd4945cdff9a0?pvs=4
 */
export function useSetDuplicateNFCOrder({
  value,
  index,
  isRenderValue,
}: {
  value: NFCOrderBookModel
  index: number
  isRenderValue: boolean
}): () => void {
  const { setOrder } = useDuplicateOrderMutations()
  const targetOrder = value.orders[index]

  return useCallback(
    () =>
      setOrder({
        productType: ProductType_Type.NFC,
        positionType:
          targetOrder?.offer !== undefined && isRenderValue
            ? Position_Type.OFFER
            : Position_Type.BID,
        deliveryFiscalYear: value.deliveryFiscalYear.year,
        renewableEnergyAndTrackingStatusType:
          value.renewableEnergyAndTrackingStatus,
        prefectureIds: isRenderValue ? targetOrder.prefectureIds : [],
        generatorIds: isRenderValue ? targetOrder.generatorIds : [],
        unitPrice: isRenderValue
          ? targetOrder.bid?.unitPrice.replaceAll(',', '') ??
            targetOrder.offer?.unitPrice.replaceAll(',', '') ??
            ''
          : '',
        volume: isRenderValue
          ? targetOrder.bid?.volume.replaceAll(',', '') ??
            targetOrder.offer?.volume.replaceAll(',', '') ??
            ''
          : '',
      }),
    [setOrder, targetOrder, value, isRenderValue],
  )
}

/**
 * 複製注文時は isRenderValue が true (アコーディオンが閉じていない) 場合のみ 全て埋める
 * https://www.notion.so/enechain/00390104e94e42c7bcacd4945cdff9a0?pvs=4
 */
export function useSetDuplicateJCreditOrder({
  value,
  index,
  isRenderValue,
}: {
  value: JCreditOrderBookModel
  index: number
  isRenderValue: boolean
}): () => void {
  const { setOrder } = useDuplicateOrderMutations()
  const targetOrder = value.orders[index]

  return useCallback(
    () =>
      setOrder({
        productType: ProductType_Type.J_CREDIT,
        deliveryFiscalYear: value.deliveryFiscalYear.year,
        productId: value.product.id,
        position:
          isRenderValue || targetOrder?.bid !== undefined
            ? {
                type: Position_Type.BID,
                creditCertifiedNumber: undefined,
              }
            : {
                type: Position_Type.OFFER,
                creditCertifiedNumber: targetOrder?.creditCertifiedNumber,
              },
        prefectureIds: isRenderValue ? targetOrder.prefectureIds : [],
        unitPrice: isRenderValue
          ? targetOrder.bid?.unitPrice.replaceAll(',', '') ??
            targetOrder.offer?.unitPrice.replaceAll(',', '') ??
            ''
          : '',
        volume: isRenderValue
          ? targetOrder.bid?.volume.replaceAll(',', '') ??
            targetOrder.offer?.volume.replaceAll(',', '') ??
            ''
          : '',
      }),
    [setOrder, targetOrder, value, isRenderValue],
  )
}
