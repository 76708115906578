import * as React from 'react'

import { HelmetProvider } from 'react-helmet-async'
import { RecoilRoot } from 'recoil'

import { ChakraProvider } from '@chakra-ui/react'

import { theme } from '../constants'
import GoogleTagProvider from './GoogleTagProvider'

type Props = {
  gtmId: string | undefined
  children: React.ReactNode
}

const LibProvider: React.FC<Props> = ({ gtmId, children }: Props) => {
  return (
    <ChakraProvider theme={theme}>
      <RecoilRoot>
        <HelmetProvider>
          <GoogleTagProvider gtmId={gtmId}>{children}</GoogleTagProvider>
        </HelmetProvider>
      </RecoilRoot>
    </ChakraProvider>
  )
}

export default LibProvider
