export type JCreditDealHistoryListColumnKey =
  | 'position'
  | 'deliveryFiscalYear'
  | 'productName'
  | 'prefectureName'
  | 'creditCertifiedNumber'
  | 'unitPrice'
  | 'volume'
  | 'counterpartyOrganizationName'
  | 'dealtAt'

export const jCreditDealHistoryColumnStyles = {
  position: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '36px',
    },
  },
  deliveryFiscalYear: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.3,
    },
  },
  productName: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  prefectureName: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  creditCertifiedNumber: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  unitPrice: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.3,
    },
  },
  volume: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.3,
    },
  },
  counterpartyOrganizationName: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  dealtAt: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
} as const
