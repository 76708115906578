import * as React from 'react'

import { Flex } from '@chakra-ui/react'

import {
  BorderRadius,
  Divider,
  FlexTable,
  FlexTableHeader,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { EmptyTableBody, TableBodyWithLoading } from '@enechain/jcex-ui'

import { jCreditDealHistoryColumnStyles } from '~/trader/pages/HistoryList/components/JCredit/DealListTable/columnStyles'
import JCreditOrderDealListTableBodyRow from '~/trader/pages/HistoryList/components/JCredit/DealTableRow/body'
import { JCreditDealHistoryListTableHeaderRow } from '~/trader/pages/HistoryList/components/JCredit/DealTableRow/header'
import { useJCreditDealHistoryList } from '~/trader/pages/HistoryList/hooks/useJCreditDealHistoryList'
import { FilterJCreditDealHistories } from '~/trader/pages/HistoryList/types/jCreditFilter'

type Props = {
  filters: FilterJCreditDealHistories
}

const JCreditDealHistoryListTable: React.FC<Props> = ({ filters }) => {
  const { jCreditData, isLoading } = useJCreditDealHistoryList({
    params: filters.state,
  })

  // memo(shunya): sticky 対応は Design System の I/F にいれる
  return (
    <Flex flexDirection="column" gap={Spacing[2]}>
      <FlexTable
        borderWidth={1}
        borderRadius={BorderRadius.radiiLg}
        columnStyles={jCreditDealHistoryColumnStyles}
      >
        <FlexTableHeader style="multi-line">
          <JCreditDealHistoryListTableHeaderRow />
        </FlexTableHeader>
        <TableBodyWithLoading isLoading={isLoading} style="multi-line">
          {jCreditData.length === 0 ? (
            <EmptyTableBody />
          ) : (
            jCreditData.map((data) => (
              <React.Fragment key={data.dealId}>
                <Divider />
                <JCreditOrderDealListTableBodyRow data={data} />
              </React.Fragment>
            ))
          )}
        </TableBodyWithLoading>
      </FlexTable>
    </Flex>
  )
}

export default React.memo(JCreditDealHistoryListTable)
