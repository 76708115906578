import * as React from 'react'

import { Auth0Provider, User } from '@auth0/auth0-react'
import { Transport } from '@connectrpc/connect'
import { QueryClient } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { Helmet } from 'react-helmet-async'

import {
  ApiClientProvider,
  AppRouterProvider,
  LibProvider,
} from '@enechain/jcex-ui'

import '~/trader/i18n'
import '~/trader/libs/DataDog'
import { router } from '~/trader/routes'

const container = document.getElementById('root')

if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <LibProvider gtmId={import.meta.env.VITE_GTM_ID}>
        {import.meta.env.MODE === 'development' && (
          <Helmet>
            <title>[local] JCEX - 日本気候取引所</title>
            <link href="/favicon_local.svg" rel="icon" type="image/svg+xml" />
          </Helmet>
        )}
        <Auth0Provider
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          }}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
        >
          <ApiClientProvider baseUrl={import.meta.env.VITE_API_URL}>
            {(
              transport: Transport,
              queryClient: QueryClient,
              user: User | undefined,
            ): React.ReactNode => (
              <AppRouterProvider
                createRouter={router}
                queryClient={queryClient}
                transport={transport}
                user={user}
              />
            )}
          </ApiClientProvider>
        </Auth0Provider>
      </LibProvider>
    </React.StrictMode>,
  )
}
