import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { appColors } from './colors'

const helpers = createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = helpers.definePartsStyle({
  track: {
    _checked: {
      backgroundColor: appColors.natureGreen,
    },
  },
})

export default helpers.defineMultiStyleConfig({
  baseStyle,
})
