import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'

import { JCreditOrderHistoryListColumnKey } from '~/trader/pages/HistoryList/components/JCredit/OrderListTable/columnStyles'

export const JCreditOrderHistoryListTableHeaderRow: React.FC = () => {
  const { t } = useTranslation(['common', 'domain'])

  return (
    <FlexTableRow>
      <FlexTableCell<JCreditOrderHistoryListColumnKey> columnKey="position" />
      <FlexTableCell<JCreditOrderHistoryListColumnKey>
        columnKey="deliveryFiscalYear"
        contents={{
          text: t('domain:model.order.column.delivery_fiscal_year'),
        }}
      />
      <FlexTableCell<JCreditOrderHistoryListColumnKey>
        columnKey="productName"
        contents={{
          text: t('domain:model.order.column.j_credit.product'),
        }}
      />
      <FlexTableCell<JCreditOrderHistoryListColumnKey>
        columnKey="prefecture"
        contents={{ text: t('domain:shared.prefecture.title') }}
      />
      <FlexTableCell<JCreditOrderHistoryListColumnKey>
        columnKey="assignedTrader"
        contents={{
          text: t('domain:model.order.column.assigned_trader'),
        }}
      />

      <FlexTableCell<JCreditOrderHistoryListColumnKey>
        columnKey="unitPrice"
        contents={{
          text: t('common:unit.yen_per_t_co2'),
        }}
      />
      <FlexTableCell<JCreditOrderHistoryListColumnKey>
        columnKey="volume"
        contents={{
          text: t('common:unit.t_co2'),
        }}
      />
      <FlexTableCell<JCreditOrderHistoryListColumnKey>
        columnKey="createdAt"
        contents={{ text: t('domain:shared.created_at') }}
      />
      <FlexTableCell<JCreditOrderHistoryListColumnKey>
        columnKey="orderStatus"
        contents={{ text: t('domain:shared.status') }}
      />
    </FlexTableRow>
  )
}
