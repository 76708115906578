import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Grid, GridItem, Stack, Text } from '@chakra-ui/react'

import {
  ColumnContent,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'

import { ChangeBadge } from '~/trader/pages/CarbonIndex/components/ChangeBadge'
import { LatestPriceHeader } from '~/trader/pages/CarbonIndex/components/LatestPriceHeader'
import { TypeLabel } from '~/trader/pages/CarbonIndex/components/TypeLabel'
import { JCreditGreenIndexSummaryModel } from '~/trader/pages/CarbonIndex/models/indexSummary/jCredit'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  data: Maybe<JCreditGreenIndexSummaryModel>
}

export const JCreditPriceContents: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation(['common', 'trader'])

  if (data === undefined) {
    return null
  }

  const { averagePrices, latestPrices, latestReferenceDate } = data

  return (
    <>
      <ColumnContent
        flexSize={0}
        label={t('trader:page.carbon_index.title_average_price')}
      >
        {averagePrices.length === 0 && (
          <Text>{t('common:error.empty_data')}</Text>
        )}
        <Grid
          gridGap={Spacing[4]}
          gridTemplateColumns="repeat(6, 1fr)"
          width="100%"
          paddingBottom={Spacing[2]}
        >
          {averagePrices.map((averagePrice) => (
            <GridItem key={averagePrice.productType.id}>
              <TypeLabel value={averagePrice.productType} />
              <Text {...Typography.headerLg}>{averagePrice.price}</Text>
            </GridItem>
          ))}
        </Grid>
      </ColumnContent>
      <Stack spacing={Spacing[2]}>
        <LatestPriceHeader date={latestReferenceDate} />
        {latestPrices.length === 0 && (
          <Text>{t('common:error.empty_data')}</Text>
        )}
        <Grid
          gridGap={Spacing[4]}
          gridTemplateColumns="repeat(6, 1fr)"
          width="100%"
          paddingBottom={Spacing[2]}
        >
          {latestPrices.map((latestPrice) => {
            return (
              <GridItem key={latestPrice.productType.id}>
                <TypeLabel value={latestPrice.productType} />
                <Text {...Typography.headerLg}>{latestPrice.price}</Text>
                <ChangeBadge
                  changeAmount={latestPrice.changeAmount}
                  changeRate={latestPrice.changeRate}
                  isNull={latestPrice.isNull}
                />
              </GridItem>
            )
          })}
        </Grid>
      </Stack>
    </>
  )
}
