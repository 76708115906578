import { useMemo, useState } from 'react'

import {
  TAB_TYPES,
  TabKey,
  useTabMutations,
  useTabQueries,
} from '@enechain/jcex-ui'

import {
  HISTORY_SEGMENT_KEY,
  HistoryTypeSegmentKey,
  defaultHistoryTypeSegmentKey,
} from '~/trader/pages/HistoryList/constants/segmentKey'

type Result = {
  selectedType: HistoryType
  selectedTab: TabType<TabKey>
  selectedSegmentType: TabType<HistoryTypeSegmentKey>
}

export enum HistoryType {
  NFCOrder = 'nfc_order',
  NFCDeal = 'nfc_deal',
  JCreditOrder = 'jcredit_order',
  JCreditDeal = 'jcredit_deal',
}

export type TabType<T extends string> = {
  state: T
  setState: (value: T) => void
}

export function useHistoryType(): Result {
  const selectedTab = useTabQueries()
  const { setTabState } = useTabMutations()

  const [selectedSegmentType, setSelectedSegmentType] =
    useState<HistoryTypeSegmentKey>(defaultHistoryTypeSegmentKey)

  const selectedType = useMemo(() => {
    switch (selectedTab) {
      case TAB_TYPES.NFC:
        switch (selectedSegmentType) {
          case HISTORY_SEGMENT_KEY.ORDER:
            return HistoryType.NFCOrder
          case HISTORY_SEGMENT_KEY.DEAL:
            return HistoryType.NFCDeal
        }
      // eslint-disable-next-line no-fallthrough
      case TAB_TYPES.JCREDIT:
        switch (selectedSegmentType) {
          case HISTORY_SEGMENT_KEY.ORDER:
            return HistoryType.JCreditOrder
          case HISTORY_SEGMENT_KEY.DEAL:
            return HistoryType.JCreditDeal
        }
    }
  }, [selectedTab, selectedSegmentType])

  return {
    selectedType,
    selectedTab: {
      state: selectedTab,
      setState: setTabState,
    },
    selectedSegmentType: {
      state: selectedSegmentType,
      setState: setSelectedSegmentType,
    },
  }
}
