import * as React from 'react'

import { useSideMenuCollapsed } from '@enechain/ecloud-designsystem'
import { JcexHorizontal, JcexSymbol } from '@enechain/ecloud-icons'

export const ProductLogo: React.FC = () => {
  const { collapsed } = useSideMenuCollapsed()

  if (collapsed) {
    return <JcexSymbol width="40px" height="27px" aria-hidden />
  }
  return <JcexHorizontal width="100px" height="18px" aria-hidden />
}
