import { useQuery } from '@connectrpc/connect-query'
import { keepPreviousData } from '@tanstack/react-query'

import { getOrderHistoryList } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'
import { GetOrderHistoryListRequest } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'

import {
  OrderModelForJCreditOrderHistory,
  convertOrderModelListForJCreditOrderHistoryModel,
} from '~/trader/pages/HistoryList/models/JCredit/orderHistory'

type Params = {
  params: GetOrderHistoryListRequest
}

type Result = {
  jCreditData: OrderModelForJCreditOrderHistory[]
  isLoading: boolean
}

export function useJCreditOrderHistoryList({ params }: Params): Result {
  const { data, isPlaceholderData } = useQuery(getOrderHistoryList, params, {
    placeholderData: keepPreviousData,
  })

  const jCreditData = convertOrderModelListForJCreditOrderHistoryModel(
    data?.orders ?? [],
  )

  return {
    jCreditData,
    isLoading: isPlaceholderData,
  }
}
