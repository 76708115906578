import * as React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useKey } from 'react-use'

import { Flex } from '@chakra-ui/react'

import {
  PrimaryButton,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
  TabItem,
  Tabs,
} from '@enechain/ecloud-designsystem'
import { FEATURE_FLAG_SWITCH_KEY } from '@enechain/jcex-libs'
import {
  TAB_TYPES,
  TabKey,
  useTabMutations,
  useTabQueries,
} from '@enechain/jcex-ui'
import { isEnechainUser } from '@enechain/jcex-utils'

import TabContainer from '~/trader/pages/OrderBookList/components/TabContainer'
import {
  PATH_CREATE_ORDER,
  PATH_FLAG_SETTING,
  PATH_ORDER_BOOK,
} from '~/trader/routes/routePaths'

const OrderBookListPage: React.FC = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { user } = useAuth0()
  const navigate = useNavigate()
  const selectedTab = useTabQueries()
  const { setTabState } = useTabMutations()

  const breadcrumbItems = [{ label: PATH_ORDER_BOOK.name, bold: true }]

  const tabItems: Array<TabItem<TabKey>> = [
    {
      label: t('domain:shared.nfc'),
      key: TAB_TYPES.NFC,
    },
    {
      label: t('domain:shared.j_credit'),
      key: TAB_TYPES.JCREDIT,
    },
  ]

  useKey(FEATURE_FLAG_SWITCH_KEY, () => {
    if (!user) {
      return
    }

    if (isEnechainUser(user)) {
      navigate(PATH_FLAG_SETTING.path)
    }
  })

  return (
    <SideMenuLayoutContentLayout
      header={
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <SideMenuLayoutBreadcrumb items={breadcrumbItems} />
          <PrimaryButton
            height="32px"
            onClick={(): void => navigate(PATH_CREATE_ORDER.path)}
          >
            {t('common:action.positive.new', {
              value: t('domain:model.order.title'),
            })}
          </PrimaryButton>
        </Flex>
      }
    >
      <Flex flexDirection="column" gap={Spacing[6]} width="full" height="full">
        <Tabs<TabKey>
          items={tabItems}
          onSelect={setTabState}
          selected={selectedTab}
        />
        <TabContainer selectedTab={selectedTab} />
      </Flex>
    </SideMenuLayoutContentLayout>
  )
}

export default OrderBookListPage
