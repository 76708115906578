import { Product } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/model/v1/model_pb'
import { GreenIndex_JCreditIndexPrice } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/model_pb'

import {
  filterIndexPrice,
  makeUniqueReferenceDates,
} from '~/trader/pages/CarbonIndex/models/graphData/helper'
import { GraphDataIndex } from '~/trader/pages/CarbonIndex/types/graphDataIndex'
import { GetJCreditDataColor } from '~/trader/pages/CarbonIndex/utils/getProductColor'

export type JCreditGreenIndexGraphModel = {
  data: GraphDataIndex
  dataKey: Array<{
    dataKey: number
    color: string
    name: string
  }>
  isEmptyData: boolean
}

export function convertJCreditGreenIndexGraphModel(
  data: GreenIndex_JCreditIndexPrice[],
  allProducts: Product[],
): JCreditGreenIndexGraphModel {
  const referenceDates =
    makeUniqueReferenceDates<GreenIndex_JCreditIndexPrice>(data)
  const graphData: GraphDataIndex = referenceDates.map((referenceDate) => {
    const _data: Record<number, number> = {}

    const filteredData = filterIndexPrice<GreenIndex_JCreditIndexPrice>(
      data,
      referenceDate,
    )

    for (let i = 0; i < filteredData.length; i++) {
      const indexPrice = filteredData[i]
      if (
        indexPrice.price === undefined ||
        indexPrice.product?.id === undefined
      ) {
        continue
      }
      const productKey = indexPrice.product.id
      _data[productKey] = indexPrice.price
    }

    return {
      referenceDate,
      ..._data,
    }
  })

  const dataKey = allProducts.map((product) => ({
    dataKey: product.id,
    color: GetJCreditDataColor(product.id),
    name: product.name,
  }))

  const isEmptyData = graphData.length === 0 || dataKey.length === 0

  return {
    data: graphData,
    dataKey,
    isEmptyData,
  }
}
