import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { Spacing, Tooltip, Typography } from '@enechain/ecloud-designsystem'
import { FaCircleQuestionRegular } from '@enechain/ecloud-icons'

type Props = {
  date: string
}

export const LatestPriceHeader: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation('trader')

  return (
    <Flex alignItems="center" gap={Spacing[2]}>
      <Text {...Typography.headerSm}>
        {t('page.carbon_index.title_latest_price')}
      </Text>
      <Text {...Typography.headerSm}>{date}</Text>
      {/* i18n: https://github.com/enechain/ecompass-app/blob/5c54d2c83093bdbd621f6a265ed642caca078ff3/front/src/locales/ja/translation.json#L535C22-L535C135 */}
      <Tooltip label={t('page.carbon_index.delta_tooltip')}>
        <FaCircleQuestionRegular width="24px" height="24px" />
      </Tooltip>
    </Flex>
  )
}
