import { extendTheme, useTheme as useChakraTheme } from '@chakra-ui/react'
import { WithCSSVar } from '@chakra-ui/system'

import { theme as DSTheme } from '@enechain/ecloud-designsystem'

import Checkbox from './checkbox'
import { SemanticColors } from './colors'
import Radio from './radio'
import Switch from './switch'

export const customTheme = {
  ...DSTheme,
  semanticTokens: {
    colors: SemanticColors,
  },
  components: { Checkbox, Radio, Switch },
}

export const theme = extendTheme(customTheme)

export type UseThemeReturn = WithCSSVar<typeof customTheme>

export function useTheme(): UseThemeReturn {
  return useChakraTheme<typeof customTheme>()
}
