import { RenewableEnergyAndTrackingStatus } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { GreenIndex_NfcIndexPrice } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/model_pb'
import { DeliveryFiscalYear } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import {
  filterIndexPrice,
  makeUniqueReferenceDates,
} from '~/trader/pages/CarbonIndex/models/graphData/helper'
import { filterWithConvertAllProducts } from '~/trader/pages/CarbonIndex/models/productType'
import { GraphDataIndex } from '~/trader/pages/CarbonIndex/types/graphDataIndex'
import { GetNFCDataColor } from '~/trader/pages/CarbonIndex/utils/getProductColor'

export type NFCGreenIndexGraphModel = {
  data: GraphDataIndex
  dataKey: Array<{
    dataKey: string
    color: string
    name: string
  }>
  isEmptyData: boolean
}

export function convertNFCGreenIndexGraphModel(
  data: GreenIndex_NfcIndexPrice[],
  allProducts: RenewableEnergyAndTrackingStatus[],
  allDeliveryFiscalYears: DeliveryFiscalYear[],
): NFCGreenIndexGraphModel {
  const referenceDates =
    makeUniqueReferenceDates<GreenIndex_NfcIndexPrice>(data)
  const graphData: GraphDataIndex = referenceDates.map((referenceDate) => {
    const _data: Record<number, number> = {}

    const filteredData = filterIndexPrice<GreenIndex_NfcIndexPrice>(
      data,
      referenceDate,
    )

    for (let i = 0; i < filteredData.length; i++) {
      const indexPrice = filteredData[i]
      if (
        indexPrice.price === undefined ||
        indexPrice.renewableEnergyAndTrackingStatus?.type === undefined ||
        indexPrice.deliveryFiscalYear?.year === undefined
      ) {
        continue
      }
      const productKey = `${indexPrice.renewableEnergyAndTrackingStatus.type}-${indexPrice.deliveryFiscalYear.year}`
      _data[productKey] = indexPrice.price
    }

    return {
      referenceDate,
      ..._data,
    }
  })

  const dataKey = filterWithConvertAllProducts(
    allProducts,
    allDeliveryFiscalYears,
  ).flatMap((product) => {
    return allDeliveryFiscalYears.map((item) => ({
      dataKey: `${product.type}-${item.year}`,
      color: GetNFCDataColor(product.type),
      name: `${product.displayName} - ${item.displayName}`,
    }))
  })

  const isEmptyData = graphData.length === 0 || dataKey.length === 0

  return {
    data: graphData,
    dataKey,
    isEmptyData,
  }
}
