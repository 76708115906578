// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file jcex/bff/jcredit/trader/v1/service.proto (package jcex.bff.jcredit.trader.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int32Value, Message, proto3, StringValue, UInt32Value } from "@bufbuild/protobuf";
import { DeliveryFiscalYear, OrderStatus, OrderStatus_Type, Position, Position_Type, Prefecture, Prefecture_Type, ProductType, Trader } from "../../../shared/model/v1/model_pb.js";
import { JCreditCertification, Product } from "../../model/v1/model_pb.js";
import { Deal, DealtOrder, Order, OrderBook } from "./model_pb.js";

/**
 * 板一覧フィルター取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetOrderBookFilterResponse
 */
export class GetOrderBookFilterResponse extends Message<GetOrderBookFilterResponse> {
  /**
   * 売買区分
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Position positions = 1;
   */
  positions: Position[] = [];

  /**
   * 商品名
   *
   * @generated from field: repeated jcex.bff.jcredit.model.v1.Product products = 2;
   */
  products: Product[] = [];

  /**
   * 受渡年度
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_years = 3;
   */
  deliveryFiscalYears: DeliveryFiscalYear[] = [];

  constructor(data?: PartialMessage<GetOrderBookFilterResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetOrderBookFilterResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "positions", kind: "message", T: Position, repeated: true },
    { no: 2, name: "products", kind: "message", T: Product, repeated: true },
    { no: 3, name: "delivery_fiscal_years", kind: "message", T: DeliveryFiscalYear, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderBookFilterResponse {
    return new GetOrderBookFilterResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderBookFilterResponse {
    return new GetOrderBookFilterResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderBookFilterResponse {
    return new GetOrderBookFilterResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderBookFilterResponse | PlainMessage<GetOrderBookFilterResponse> | undefined, b: GetOrderBookFilterResponse | PlainMessage<GetOrderBookFilterResponse> | undefined): boolean {
    return proto3.util.equals(GetOrderBookFilterResponse, a, b);
  }
}

/**
 * 板一覧取得のリクエスト
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetOrderBookListRequest
 */
export class GetOrderBookListRequest extends Message<GetOrderBookListRequest> {
  /**
   * 売買区分。気配値以降は非推奨にします。
   *
   * @generated from field: optional jcex.bff.shared.model.v1.Position.Type position_type = 1;
   */
  positionType?: Position_Type;

  /**
   * 商品ID
   *
   * @generated from field: optional google.protobuf.Int32Value product_id = 2;
   */
  productId?: number;

  /**
   * 受渡年度
   *
   * @generated from field: optional google.protobuf.UInt32Value delivery_fiscal_year = 3;
   */
  deliveryFiscalYear?: number;

  /**
   * 売買区分のリスト。気配値以降はこちらを利用します。
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Position.Type position_types = 4;
   */
  positionTypes: Position_Type[] = [];

  /**
   * 複数選択を使うかどうかを判別するフィールド。気配値以降はこちらを使います。
   *
   * @generated from field: bool use_multi_select = 5;
   */
  useMultiSelect = false;

  constructor(data?: PartialMessage<GetOrderBookListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetOrderBookListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "position_type", kind: "enum", T: proto3.getEnumType(Position_Type), opt: true },
    { no: 2, name: "product_id", kind: "message", T: Int32Value, opt: true },
    { no: 3, name: "delivery_fiscal_year", kind: "message", T: UInt32Value, opt: true },
    { no: 4, name: "position_types", kind: "enum", T: proto3.getEnumType(Position_Type), repeated: true },
    { no: 5, name: "use_multi_select", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderBookListRequest {
    return new GetOrderBookListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderBookListRequest {
    return new GetOrderBookListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderBookListRequest {
    return new GetOrderBookListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderBookListRequest | PlainMessage<GetOrderBookListRequest> | undefined, b: GetOrderBookListRequest | PlainMessage<GetOrderBookListRequest> | undefined): boolean {
    return proto3.util.equals(GetOrderBookListRequest, a, b);
  }
}

/**
 * 板一覧取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetOrderBookListResponse
 */
export class GetOrderBookListResponse extends Message<GetOrderBookListResponse> {
  /**
   * 板一覧
   *
   * @generated from field: repeated jcex.bff.jcredit.trader.v1.OrderBook order_books = 1;
   */
  orderBooks: OrderBook[] = [];

  constructor(data?: PartialMessage<GetOrderBookListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetOrderBookListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_books", kind: "message", T: OrderBook, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderBookListResponse {
    return new GetOrderBookListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderBookListResponse {
    return new GetOrderBookListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderBookListResponse {
    return new GetOrderBookListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderBookListResponse | PlainMessage<GetOrderBookListResponse> | undefined, b: GetOrderBookListResponse | PlainMessage<GetOrderBookListResponse> | undefined): boolean {
    return proto3.util.equals(GetOrderBookListResponse, a, b);
  }
}

/**
 * 新規注文作成画面のオプション取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetCreateOrderOptionResponse
 */
export class GetCreateOrderOptionResponse extends Message<GetCreateOrderOptionResponse> {
  /**
   * 商品種別
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.ProductType product_types = 1;
   */
  productTypes: ProductType[] = [];

  /**
   * 売買区分
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Position positions = 2;
   */
  positions: Position[] = [];

  /**
   * 商品
   *
   * @generated from field: repeated jcex.bff.jcredit.model.v1.Product products = 3;
   */
  products: Product[] = [];

  /**
   * 都道府県
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture prefectures = 4;
   */
  prefectures: Prefecture[] = [];

  /**
   * 受渡年度
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_years = 5;
   */
  deliveryFiscalYears: DeliveryFiscalYear[] = [];

  constructor(data?: PartialMessage<GetCreateOrderOptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetCreateOrderOptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_types", kind: "message", T: ProductType, repeated: true },
    { no: 2, name: "positions", kind: "message", T: Position, repeated: true },
    { no: 3, name: "products", kind: "message", T: Product, repeated: true },
    { no: 4, name: "prefectures", kind: "message", T: Prefecture, repeated: true },
    { no: 5, name: "delivery_fiscal_years", kind: "message", T: DeliveryFiscalYear, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreateOrderOptionResponse {
    return new GetCreateOrderOptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreateOrderOptionResponse {
    return new GetCreateOrderOptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreateOrderOptionResponse {
    return new GetCreateOrderOptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreateOrderOptionResponse | PlainMessage<GetCreateOrderOptionResponse> | undefined, b: GetCreateOrderOptionResponse | PlainMessage<GetCreateOrderOptionResponse> | undefined): boolean {
    return proto3.util.equals(GetCreateOrderOptionResponse, a, b);
  }
}

/**
 * 新規注文作成のリクエスト
 *
 * @generated from message jcex.bff.jcredit.trader.v1.CreateOrderRequest
 */
export class CreateOrderRequest extends Message<CreateOrderRequest> {
  /**
   * 売買区分
   *
   * @generated from field: jcex.bff.shared.model.v1.Position.Type position_type = 1;
   */
  positionType = Position_Type.UNSPECIFIED;

  /**
   * クレジット認証番号
   *
   * @generated from field: optional google.protobuf.StringValue credit_certified_number = 2;
   */
  creditCertifiedNumber?: string;

  /**
   * 商品ID
   *
   * @generated from field: int32 product_id = 3;
   */
  productId = 0;

  /**
   * 受渡年度
   *
   * @generated from field: uint32 delivery_fiscal_year = 4;
   */
  deliveryFiscalYear = 0;

  /**
   * 単価
   *
   * @generated from field: string unit_price = 5;
   */
  unitPrice = "";

  /**
   * 数量
   *
   * @generated from field: optional google.protobuf.StringValue volume = 6;
   */
  volume?: string;

  /**
   * 都道府県ID
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture.Type prefecture_ids = 7;
   */
  prefectureIds: Prefecture_Type[] = [];

  /**
   * Jクレジット認証ID。選択したクレジット認証のIDを指定します。
   *
   * @generated from field: optional string j_credit_certification_id = 8;
   */
  jCreditCertificationId?: string;

  constructor(data?: PartialMessage<CreateOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.CreateOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "position_type", kind: "enum", T: proto3.getEnumType(Position_Type) },
    { no: 2, name: "credit_certified_number", kind: "message", T: StringValue, opt: true },
    { no: 3, name: "product_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "delivery_fiscal_year", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "volume", kind: "message", T: StringValue, opt: true },
    { no: 7, name: "prefecture_ids", kind: "enum", T: proto3.getEnumType(Prefecture_Type), repeated: true },
    { no: 8, name: "j_credit_certification_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderRequest | PlainMessage<CreateOrderRequest> | undefined, b: CreateOrderRequest | PlainMessage<CreateOrderRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrderRequest, a, b);
  }
}

/**
 * 注文履歴一覧フィルター取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetOrderHistoryFilterResponse
 */
export class GetOrderHistoryFilterResponse extends Message<GetOrderHistoryFilterResponse> {
  /**
   * 売買区分
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Position positions = 1;
   */
  positions: Position[] = [];

  /**
   * 商品名
   *
   * @generated from field: repeated jcex.bff.jcredit.model.v1.Product products = 2;
   */
  products: Product[] = [];

  /**
   * 受渡年度
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_years = 3;
   */
  deliveryFiscalYears: DeliveryFiscalYear[] = [];

  /**
   * 作成者
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Trader creators = 4;
   */
  creators: Trader[] = [];

  /**
   * 注文ステータス
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.OrderStatus order_statuses = 5;
   */
  orderStatuses: OrderStatus[] = [];

  constructor(data?: PartialMessage<GetOrderHistoryFilterResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetOrderHistoryFilterResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "positions", kind: "message", T: Position, repeated: true },
    { no: 2, name: "products", kind: "message", T: Product, repeated: true },
    { no: 3, name: "delivery_fiscal_years", kind: "message", T: DeliveryFiscalYear, repeated: true },
    { no: 4, name: "creators", kind: "message", T: Trader, repeated: true },
    { no: 5, name: "order_statuses", kind: "message", T: OrderStatus, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderHistoryFilterResponse {
    return new GetOrderHistoryFilterResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderHistoryFilterResponse {
    return new GetOrderHistoryFilterResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderHistoryFilterResponse {
    return new GetOrderHistoryFilterResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderHistoryFilterResponse | PlainMessage<GetOrderHistoryFilterResponse> | undefined, b: GetOrderHistoryFilterResponse | PlainMessage<GetOrderHistoryFilterResponse> | undefined): boolean {
    return proto3.util.equals(GetOrderHistoryFilterResponse, a, b);
  }
}

/**
 * 注文履歴一覧取得のリクエスト
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetOrderHistoryListRequest
 */
export class GetOrderHistoryListRequest extends Message<GetOrderHistoryListRequest> {
  /**
   * 売買区分。気配値以降は非推奨にします。
   *
   * @generated from field: optional jcex.bff.shared.model.v1.Position.Type position_type = 1;
   */
  positionType?: Position_Type;

  /**
   * 商品ID
   *
   * @generated from field: optional google.protobuf.Int32Value product_id = 2;
   */
  productId?: number;

  /**
   * 受渡年度
   *
   * @generated from field: optional google.protobuf.UInt32Value delivery_fiscal_year = 3;
   */
  deliveryFiscalYear?: number;

  /**
   * 作成者ID
   *
   * @generated from field: optional google.protobuf.StringValue creator_id = 4;
   */
  creatorId?: string;

  /**
   * 注文ステータス。気配値以降は非推奨にします。
   *
   * @generated from field: optional jcex.bff.shared.model.v1.OrderStatus.Type order_status_type = 5;
   */
  orderStatusType?: OrderStatus_Type;

  /**
   * 売買区分のリスト。気配値以降はこちらを利用します。
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Position.Type position_types = 6;
   */
  positionTypes: Position_Type[] = [];

  /**
   * 注文ステータスのリスト。気配値以降はこちらを利用します。
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.OrderStatus.Type order_status_types = 7;
   */
  orderStatusTypes: OrderStatus_Type[] = [];

  /**
   * 複数選択を使うかどうかを判別するフィールド。気配値以降はこちらを使います。
   *
   * @generated from field: bool use_multi_select = 8;
   */
  useMultiSelect = false;

  constructor(data?: PartialMessage<GetOrderHistoryListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetOrderHistoryListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "position_type", kind: "enum", T: proto3.getEnumType(Position_Type), opt: true },
    { no: 2, name: "product_id", kind: "message", T: Int32Value, opt: true },
    { no: 3, name: "delivery_fiscal_year", kind: "message", T: UInt32Value, opt: true },
    { no: 4, name: "creator_id", kind: "message", T: StringValue, opt: true },
    { no: 5, name: "order_status_type", kind: "enum", T: proto3.getEnumType(OrderStatus_Type), opt: true },
    { no: 6, name: "position_types", kind: "enum", T: proto3.getEnumType(Position_Type), repeated: true },
    { no: 7, name: "order_status_types", kind: "enum", T: proto3.getEnumType(OrderStatus_Type), repeated: true },
    { no: 8, name: "use_multi_select", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderHistoryListRequest {
    return new GetOrderHistoryListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderHistoryListRequest {
    return new GetOrderHistoryListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderHistoryListRequest {
    return new GetOrderHistoryListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderHistoryListRequest | PlainMessage<GetOrderHistoryListRequest> | undefined, b: GetOrderHistoryListRequest | PlainMessage<GetOrderHistoryListRequest> | undefined): boolean {
    return proto3.util.equals(GetOrderHistoryListRequest, a, b);
  }
}

/**
 * 注文履歴一覧取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetOrderHistoryListResponse
 */
export class GetOrderHistoryListResponse extends Message<GetOrderHistoryListResponse> {
  /**
   * 注文履歴一覧
   *
   * @generated from field: repeated jcex.bff.jcredit.trader.v1.Order orders = 1;
   */
  orders: Order[] = [];

  constructor(data?: PartialMessage<GetOrderHistoryListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetOrderHistoryListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "orders", kind: "message", T: Order, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderHistoryListResponse {
    return new GetOrderHistoryListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderHistoryListResponse {
    return new GetOrderHistoryListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderHistoryListResponse {
    return new GetOrderHistoryListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderHistoryListResponse | PlainMessage<GetOrderHistoryListResponse> | undefined, b: GetOrderHistoryListResponse | PlainMessage<GetOrderHistoryListResponse> | undefined): boolean {
    return proto3.util.equals(GetOrderHistoryListResponse, a, b);
  }
}

/**
 * 約定履歴一覧フィルター取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetDealHistoryFilterResponse
 */
export class GetDealHistoryFilterResponse extends Message<GetDealHistoryFilterResponse> {
  /**
   * 売買区分
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Position positions = 1;
   */
  positions: Position[] = [];

  /**
   * 商品名
   *
   * @generated from field: repeated jcex.bff.jcredit.model.v1.Product products = 2;
   */
  products: Product[] = [];

  /**
   * 受渡年度
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_years = 3;
   */
  deliveryFiscalYears: DeliveryFiscalYear[] = [];

  constructor(data?: PartialMessage<GetDealHistoryFilterResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetDealHistoryFilterResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "positions", kind: "message", T: Position, repeated: true },
    { no: 2, name: "products", kind: "message", T: Product, repeated: true },
    { no: 3, name: "delivery_fiscal_years", kind: "message", T: DeliveryFiscalYear, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDealHistoryFilterResponse {
    return new GetDealHistoryFilterResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDealHistoryFilterResponse {
    return new GetDealHistoryFilterResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDealHistoryFilterResponse {
    return new GetDealHistoryFilterResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDealHistoryFilterResponse | PlainMessage<GetDealHistoryFilterResponse> | undefined, b: GetDealHistoryFilterResponse | PlainMessage<GetDealHistoryFilterResponse> | undefined): boolean {
    return proto3.util.equals(GetDealHistoryFilterResponse, a, b);
  }
}

/**
 * 約定履歴一覧取得のリクエスト
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetDealHistoryListRequest
 */
export class GetDealHistoryListRequest extends Message<GetDealHistoryListRequest> {
  /**
   * 売買区分
   *
   * @generated from field: optional jcex.bff.shared.model.v1.Position.Type position_type = 1;
   */
  positionType?: Position_Type;

  /**
   * 商品ID
   *
   * @generated from field: optional google.protobuf.Int32Value product_id = 2;
   */
  productId?: number;

  /**
   * 受渡年度
   *
   * @generated from field: optional google.protobuf.UInt32Value delivery_fiscal_year = 3;
   */
  deliveryFiscalYear?: number;

  constructor(data?: PartialMessage<GetDealHistoryListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetDealHistoryListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "position_type", kind: "enum", T: proto3.getEnumType(Position_Type), opt: true },
    { no: 2, name: "product_id", kind: "message", T: Int32Value, opt: true },
    { no: 3, name: "delivery_fiscal_year", kind: "message", T: UInt32Value, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDealHistoryListRequest {
    return new GetDealHistoryListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDealHistoryListRequest {
    return new GetDealHistoryListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDealHistoryListRequest {
    return new GetDealHistoryListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDealHistoryListRequest | PlainMessage<GetDealHistoryListRequest> | undefined, b: GetDealHistoryListRequest | PlainMessage<GetDealHistoryListRequest> | undefined): boolean {
    return proto3.util.equals(GetDealHistoryListRequest, a, b);
  }
}

/**
 * 約定履歴一覧取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetDealHistoryListResponse
 */
export class GetDealHistoryListResponse extends Message<GetDealHistoryListResponse> {
  /**
   * 約定一覧
   *
   * @generated from field: repeated jcex.bff.jcredit.trader.v1.DealtOrder dealt_orders = 1;
   */
  dealtOrders: DealtOrder[] = [];

  constructor(data?: PartialMessage<GetDealHistoryListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetDealHistoryListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dealt_orders", kind: "message", T: DealtOrder, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDealHistoryListResponse {
    return new GetDealHistoryListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDealHistoryListResponse {
    return new GetDealHistoryListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDealHistoryListResponse {
    return new GetDealHistoryListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDealHistoryListResponse | PlainMessage<GetDealHistoryListResponse> | undefined, b: GetDealHistoryListResponse | PlainMessage<GetDealHistoryListResponse> | undefined): boolean {
    return proto3.util.equals(GetDealHistoryListResponse, a, b);
  }
}

/**
 * 注文詳細画面のオプション取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetOrderDetailOptionResponse
 */
export class GetOrderDetailOptionResponse extends Message<GetOrderDetailOptionResponse> {
  /**
   * 商品種別
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.ProductType product_types = 1;
   */
  productTypes: ProductType[] = [];

  /**
   * 売買区分
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Position positions = 2;
   */
  positions: Position[] = [];

  /**
   * 商品
   *
   * @generated from field: repeated jcex.bff.jcredit.model.v1.Product products = 3;
   */
  products: Product[] = [];

  /**
   * 都道府県
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture prefectures = 4;
   */
  prefectures: Prefecture[] = [];

  /**
   * 受渡年度
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_years = 5;
   */
  deliveryFiscalYears: DeliveryFiscalYear[] = [];

  constructor(data?: PartialMessage<GetOrderDetailOptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetOrderDetailOptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_types", kind: "message", T: ProductType, repeated: true },
    { no: 2, name: "positions", kind: "message", T: Position, repeated: true },
    { no: 3, name: "products", kind: "message", T: Product, repeated: true },
    { no: 4, name: "prefectures", kind: "message", T: Prefecture, repeated: true },
    { no: 5, name: "delivery_fiscal_years", kind: "message", T: DeliveryFiscalYear, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderDetailOptionResponse {
    return new GetOrderDetailOptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderDetailOptionResponse {
    return new GetOrderDetailOptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderDetailOptionResponse {
    return new GetOrderDetailOptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderDetailOptionResponse | PlainMessage<GetOrderDetailOptionResponse> | undefined, b: GetOrderDetailOptionResponse | PlainMessage<GetOrderDetailOptionResponse> | undefined): boolean {
    return proto3.util.equals(GetOrderDetailOptionResponse, a, b);
  }
}

/**
 * 注文内容取得のリクエスト
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetOrderRequest
 */
export class GetOrderRequest extends Message<GetOrderRequest> {
  /**
   * 注文ID
   *
   * @generated from field: string order_id = 1;
   */
  orderId = "";

  constructor(data?: PartialMessage<GetOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderRequest {
    return new GetOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderRequest {
    return new GetOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderRequest {
    return new GetOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderRequest | PlainMessage<GetOrderRequest> | undefined, b: GetOrderRequest | PlainMessage<GetOrderRequest> | undefined): boolean {
    return proto3.util.equals(GetOrderRequest, a, b);
  }
}

/**
 * 注文内容取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetOrderResponse
 */
export class GetOrderResponse extends Message<GetOrderResponse> {
  /**
   * 注文
   *
   * @generated from field: jcex.bff.jcredit.trader.v1.Order order = 1;
   */
  order?: Order;

  /**
   * 注文の商品種別
   *
   * @generated from field: jcex.bff.shared.model.v1.ProductType product_type = 2;
   */
  productType?: ProductType;

  constructor(data?: PartialMessage<GetOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order", kind: "message", T: Order },
    { no: 2, name: "product_type", kind: "message", T: ProductType },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderResponse {
    return new GetOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderResponse {
    return new GetOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderResponse {
    return new GetOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderResponse | PlainMessage<GetOrderResponse> | undefined, b: GetOrderResponse | PlainMessage<GetOrderResponse> | undefined): boolean {
    return proto3.util.equals(GetOrderResponse, a, b);
  }
}

/**
 * 注文更新のリクエスト
 *
 * @generated from message jcex.bff.jcredit.trader.v1.UpdateOrderRequest
 */
export class UpdateOrderRequest extends Message<UpdateOrderRequest> {
  /**
   * 注文ID
   *
   * @generated from field: string order_id = 1;
   */
  orderId = "";

  /**
   * 売買区分
   *
   * @generated from field: jcex.bff.shared.model.v1.Position.Type position_type = 2;
   */
  positionType = Position_Type.UNSPECIFIED;

  /**
   * クレジット認証番号
   *
   * @generated from field: optional google.protobuf.StringValue credit_certified_number = 3;
   */
  creditCertifiedNumber?: string;

  /**
   * 商品ID
   *
   * @generated from field: int32 product_id = 4;
   */
  productId = 0;

  /**
   * 受渡年度
   *
   * @generated from field: uint32 delivery_fiscal_year = 5;
   */
  deliveryFiscalYear = 0;

  /**
   * 単価
   *
   * @generated from field: string unit_price = 6;
   */
  unitPrice = "";

  /**
   * 数量
   *
   * @generated from field: optional google.protobuf.StringValue volume = 7;
   */
  volume?: string;

  /**
   * 都道府県ID
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture.Type prefecture_ids = 8;
   */
  prefectureIds: Prefecture_Type[] = [];

  /**
   * Jクレジット認証ID。選択したクレジット認証のIDを指定します。
   *
   * @generated from field: optional string j_credit_certification_id = 9;
   */
  jCreditCertificationId?: string;

  constructor(data?: PartialMessage<UpdateOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.UpdateOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "position_type", kind: "enum", T: proto3.getEnumType(Position_Type) },
    { no: 3, name: "credit_certified_number", kind: "message", T: StringValue, opt: true },
    { no: 4, name: "product_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "delivery_fiscal_year", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "volume", kind: "message", T: StringValue, opt: true },
    { no: 8, name: "prefecture_ids", kind: "enum", T: proto3.getEnumType(Prefecture_Type), repeated: true },
    { no: 9, name: "j_credit_certification_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrderRequest {
    return new UpdateOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrderRequest {
    return new UpdateOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOrderRequest {
    return new UpdateOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOrderRequest | PlainMessage<UpdateOrderRequest> | undefined, b: UpdateOrderRequest | PlainMessage<UpdateOrderRequest> | undefined): boolean {
    return proto3.util.equals(UpdateOrderRequest, a, b);
  }
}

/**
 * 注文取り消しのリクエスト
 *
 * @generated from message jcex.bff.jcredit.trader.v1.CancelOrderRequest
 */
export class CancelOrderRequest extends Message<CancelOrderRequest> {
  /**
   * 注文ID
   *
   * @generated from field: string order_id = 1;
   */
  orderId = "";

  constructor(data?: PartialMessage<CancelOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.CancelOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrderRequest {
    return new CancelOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrderRequest {
    return new CancelOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrderRequest {
    return new CancelOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrderRequest | PlainMessage<CancelOrderRequest> | undefined, b: CancelOrderRequest | PlainMessage<CancelOrderRequest> | undefined): boolean {
    return proto3.util.equals(CancelOrderRequest, a, b);
  }
}

/**
 * 約定注文内容の取得のリクエスト
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetDealOrderRequest
 */
export class GetDealOrderRequest extends Message<GetDealOrderRequest> {
  /**
   * 約定ID
   *
   * @generated from field: string deal_id = 1;
   */
  dealId = "";

  constructor(data?: PartialMessage<GetDealOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetDealOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDealOrderRequest {
    return new GetDealOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDealOrderRequest {
    return new GetDealOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDealOrderRequest {
    return new GetDealOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDealOrderRequest | PlainMessage<GetDealOrderRequest> | undefined, b: GetDealOrderRequest | PlainMessage<GetDealOrderRequest> | undefined): boolean {
    return proto3.util.equals(GetDealOrderRequest, a, b);
  }
}

/**
 * 約定注文内容の取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.GetDealOrderResponse
 */
export class GetDealOrderResponse extends Message<GetDealOrderResponse> {
  /**
   * 約定
   *
   * @generated from field: jcex.bff.jcredit.trader.v1.Deal deal = 1;
   */
  deal?: Deal;

  constructor(data?: PartialMessage<GetDealOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.GetDealOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deal", kind: "message", T: Deal },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDealOrderResponse {
    return new GetDealOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDealOrderResponse {
    return new GetDealOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDealOrderResponse {
    return new GetDealOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDealOrderResponse | PlainMessage<GetDealOrderResponse> | undefined, b: GetDealOrderResponse | PlainMessage<GetDealOrderResponse> | undefined): boolean {
    return proto3.util.equals(GetDealOrderResponse, a, b);
  }
}

/**
 * Jクレジット認証一覧取得のリクエスト
 *
 * @generated from message jcex.bff.jcredit.trader.v1.ListJCreditCertificationsRequest
 */
export class ListJCreditCertificationsRequest extends Message<ListJCreditCertificationsRequest> {
  /**
   * @generated from field: jcex.bff.jcredit.trader.v1.ListJCreditCertificationsRequest.Filter filter = 1;
   */
  filter?: ListJCreditCertificationsRequest_Filter;

  constructor(data?: PartialMessage<ListJCreditCertificationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.ListJCreditCertificationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: ListJCreditCertificationsRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListJCreditCertificationsRequest {
    return new ListJCreditCertificationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListJCreditCertificationsRequest {
    return new ListJCreditCertificationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListJCreditCertificationsRequest {
    return new ListJCreditCertificationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListJCreditCertificationsRequest | PlainMessage<ListJCreditCertificationsRequest> | undefined, b: ListJCreditCertificationsRequest | PlainMessage<ListJCreditCertificationsRequest> | undefined): boolean {
    return proto3.util.equals(ListJCreditCertificationsRequest, a, b);
  }
}

/**
 * @generated from message jcex.bff.jcredit.trader.v1.ListJCreditCertificationsRequest.Filter
 */
export class ListJCreditCertificationsRequest_Filter extends Message<ListJCreditCertificationsRequest_Filter> {
  /**
   * クレジット認証番号
   *
   * @generated from field: optional string credit_certified_number = 1;
   */
  creditCertifiedNumber?: string;

  constructor(data?: PartialMessage<ListJCreditCertificationsRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.ListJCreditCertificationsRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credit_certified_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListJCreditCertificationsRequest_Filter {
    return new ListJCreditCertificationsRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListJCreditCertificationsRequest_Filter {
    return new ListJCreditCertificationsRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListJCreditCertificationsRequest_Filter {
    return new ListJCreditCertificationsRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListJCreditCertificationsRequest_Filter | PlainMessage<ListJCreditCertificationsRequest_Filter> | undefined, b: ListJCreditCertificationsRequest_Filter | PlainMessage<ListJCreditCertificationsRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListJCreditCertificationsRequest_Filter, a, b);
  }
}

/**
 * Jクレジット認証一覧取得のレスポンス
 *
 * @generated from message jcex.bff.jcredit.trader.v1.ListJCreditCertificationsResponse
 */
export class ListJCreditCertificationsResponse extends Message<ListJCreditCertificationsResponse> {
  /**
   * Jクレジット認証一覧
   *
   * @generated from field: repeated jcex.bff.jcredit.model.v1.JCreditCertification j_credit_certifications = 1;
   */
  jCreditCertifications: JCreditCertification[] = [];

  constructor(data?: PartialMessage<ListJCreditCertificationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.trader.v1.ListJCreditCertificationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "j_credit_certifications", kind: "message", T: JCreditCertification, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListJCreditCertificationsResponse {
    return new ListJCreditCertificationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListJCreditCertificationsResponse {
    return new ListJCreditCertificationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListJCreditCertificationsResponse {
    return new ListJCreditCertificationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListJCreditCertificationsResponse | PlainMessage<ListJCreditCertificationsResponse> | undefined, b: ListJCreditCertificationsResponse | PlainMessage<ListJCreditCertificationsResponse> | undefined): boolean {
    return proto3.util.equals(ListJCreditCertificationsResponse, a, b);
  }
}

