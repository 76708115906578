import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'

import { JCreditDealHistoryListColumnKey } from '~/trader/pages/HistoryList/components/JCredit/DealListTable/columnStyles'

export const JCreditDealHistoryListTableHeaderRow: React.FC = () => {
  const { t } = useTranslation(['common', 'domain'])

  return (
    <FlexTableRow>
      <FlexTableCell<JCreditDealHistoryListColumnKey> columnKey="position" />
      <FlexTableCell<JCreditDealHistoryListColumnKey>
        columnKey="deliveryFiscalYear"
        contents={{
          text: t('domain:model.order.column.delivery_fiscal_year'),
        }}
      />
      <FlexTableCell<JCreditDealHistoryListColumnKey>
        columnKey="productName"
        contents={{
          text: t('domain:model.order.column.j_credit.product'),
        }}
      />
      <FlexTableCell<JCreditDealHistoryListColumnKey>
        columnKey="prefectureName"
        contents={{ text: t('domain:shared.prefecture.title') }}
      />
      <FlexTableCell<JCreditDealHistoryListColumnKey>
        columnKey="creditCertifiedNumber"
        contents={{
          text: t('domain:model.order.column.j_credit.credit_certified_number'),
        }}
      />
      <FlexTableCell<JCreditDealHistoryListColumnKey>
        columnKey="unitPrice"
        contents={{ text: t('common:unit.yen_per_t_co2') }}
      />
      <FlexTableCell<JCreditDealHistoryListColumnKey>
        columnKey="volume"
        contents={{ text: t('common:unit.t_co2') }}
      />
      <FlexTableCell<JCreditDealHistoryListColumnKey>
        columnKey="counterpartyOrganizationName"
        contents={{ text: t('domain:model.deal.column.counterparty') }}
      />
      <FlexTableCell<JCreditDealHistoryListColumnKey>
        columnKey="dealtAt"
        contents={{ text: t('domain:model.deal.column.dealt_at') }}
      />
    </FlexTableRow>
  )
}
