import { useQuery } from '@connectrpc/connect-query'
import { keepPreviousData } from '@tanstack/react-query'
import { useLoaderData } from 'react-router-dom'

import { getOrderBookList } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import { GetOrderBookListRequest } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'
import { useVolumeUnitQueries } from '@enechain/jcex-ui'

import { OrderBookListPageLoaderData } from '~/trader/pages/OrderBookList/loader'
import {
  NFCOrderBookModel,
  convertNFCOrderBookModelList,
} from '~/trader/pages/OrderBookList/models/NonFITNFC/orderBook'
import { GrayOutFilter } from '~/trader/pages/OrderBookList/types/nfcFilter'

type Params = {
  filters: GetOrderBookListRequest
  grayOutFilters: GrayOutFilter
}

type Result = {
  nfcData: NFCOrderBookModel[]
  isLoading: boolean
}

export function useNFCOrderBookList({
  filters,
  grayOutFilters,
}: Params): Result {
  const { nfc: nfcLoaderData } = useLoaderData() as OrderBookListPageLoaderData
  const selectedUnit = useVolumeUnitQueries()
  const { data, isPlaceholderData } = useQuery(getOrderBookList, filters, {
    placeholderData: keepPreviousData,
  })

  const nfcData = convertNFCOrderBookModelList({
    orderBooks: data?.orderBooks ?? nfcLoaderData.listData.orderBooks,
    filters: grayOutFilters,
    volumeUnit: selectedUnit,
  })

  return {
    nfcData,
    isLoading: isPlaceholderData,
  }
}
