import { Product } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/model/v1/model_pb'
import { RenewableEnergyAndTrackingStatus } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

import {
  GetJCreditDataColor,
  GetNFCDataColor,
} from '~/trader/pages/CarbonIndex/utils/getProductColor'

function isJCredit(
  value: RenewableEnergyAndTrackingStatus | Product,
): value is Product {
  return 'id' in value
}

export function getLabelStyle(
  value: RenewableEnergyAndTrackingStatus | Product,
): { backgroundColor: string; label: string } {
  if (isJCredit(value)) {
    return {
      backgroundColor: GetJCreditDataColor(value.id),
      label: value.name,
    }
  }

  return {
    backgroundColor: GetNFCDataColor(value.type),
    label: value.displayName,
  }
}
