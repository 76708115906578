// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=ts"
// @generated from file jcex/bff/common/v1/service.proto (package jcex.bff.common.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, MethodKind } from "@bufbuild/protobuf";
import { GetUserInfoResponse } from "./service_pb.js";

/**
 * ユーザー情報取得用メソッド
 *
 * @generated from rpc jcex.bff.common.v1.Service.GetUserInfo
 */
export const getUserInfo = {
  localName: "getUserInfo",
  name: "GetUserInfo",
  kind: MethodKind.Unary,
  I: Empty,
  O: GetUserInfoResponse,
  service: {
    typeName: "jcex.bff.common.v1.Service"
  }
} as const;
