import {
  GetDealHistoryListRequest as GetJCreditDealHistoryListRequest,
  GetOrderHistoryListRequest as GetJCreditOrderHistoryListRequest,
} from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import {
  GetDealHistoryListRequest as GetNFCDealHistoryListRequest,
  GetOrderHistoryListRequest as GetNFCOrderHistoryListRequest,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'
import {
  OrderStatus_Type,
  Position_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

/**
 * todo: UseMultiSelect は API 対応後削除
 */
export const INITIAL_NFC_ORDER_LIST_FILTER = new GetNFCOrderHistoryListRequest({
  positionTypes: [Position_Type.BID, Position_Type.OFFER],
  renewableEnergyAndTrackingStatusType: undefined,
  deliveryFiscalYear: undefined,
  orderStatusTypes: [
    OrderStatus_Type.ENABLED,
    OrderStatus_Type.CANCELED,
    OrderStatus_Type.DEALT,
    OrderStatus_Type.EXPIRED,
  ],
  useMultiSelect: true,
})

/**
 * todo: UseMultiSelect は API 対応後削除
 */
export const INITIAL_J_CREDIT_ORDER_LIST_FILTER =
  new GetJCreditOrderHistoryListRequest({
    positionTypes: [Position_Type.BID, Position_Type.OFFER],
    productId: undefined,
    deliveryFiscalYear: undefined,
    orderStatusTypes: [
      OrderStatus_Type.ENABLED,
      OrderStatus_Type.CANCELED,
      OrderStatus_Type.DEALT,
      OrderStatus_Type.EXPIRED,
    ],
    useMultiSelect: true,
  })

export const INITIAL_NFC_DEAL_LIST_FILTER = new GetNFCDealHistoryListRequest({
  positionType: undefined,
  renewableEnergyAndTrackingStatusType: undefined,
  deliveryFiscalYear: undefined,
})

export const INITIAL_J_CREDIT_DEAL_LIST_FILTER =
  new GetJCreditDealHistoryListRequest({
    positionType: undefined,
    productId: undefined,
    deliveryFiscalYear: undefined,
  })
