import { useCallback } from 'react'

import { ToastId } from '@chakra-ui/react'

import { ToastProps, useToast } from '@enechain/ecloud-designsystem'

import { trimErrorMessage } from '../../utils/errorMessage'
import { useToastMutations, useToastQueries } from './useToastState'

type ShowToastProps = {
  status?: ToastProps['status']
  toastMessage?: string
  action?: () => void
}

type Props = {
  toastId: ToastId
  action?: () => void
}

type ReturnType = {
  showToast: ({ status, toastMessage }: ShowToastProps) => void
}

export function useSuccessToast({ toastId, action }: Props): ReturnType {
  const toasts = useToastQueries()
  const { setToast, remove } = useToastMutations()

  const { showToast: _showToast } = useToast({
    toastId,
    toasts,
    add: setToast,
    remove,
    status: 'success',
    toastMessage: 'success',
    isClosable: true,
    action,
  })

  const showToast = useCallback(
    ({
      toastMessage = '画面をリロードしてください。',
      action,
    }: ShowToastProps) => {
      _showToast({
        toastMessage,
        action,
      })
    },
    [_showToast],
  )

  return { showToast }
}

export function useErrorToast({ toastId, action }: Props): ReturnType {
  const toasts = useToastQueries()
  const { setToast, remove } = useToastMutations()

  const { showToast: _showToast } = useToast({
    toastId,
    toasts,
    add: setToast,
    remove,
    status: 'error',
    toastMessage: 'error',
    isClosable: true,
    action,
  })

  const showToast = useCallback(
    ({
      toastMessage = '画面をリロードしてください。',
      action,
    }: ShowToastProps) => {
      _showToast({
        toastMessage: trimErrorMessage(toastMessage),
        action,
      })
    },
    [_showToast],
  )

  return {
    showToast,
  }
}
