import * as React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { ProductType_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import JCreditProductForm from '~/trader/pages/CreateOrder/components/JCreditProductForm'
import NFCProductForm from '~/trader/pages/CreateOrder/components/NFCProductForm'
import { CreateOrderSchema } from '~/trader/pages/CreateOrder/schemas/createOrderSchema'

type Props = {
  formMethods: UseFormReturn<CreateOrderSchema>
}

const ProductForm: React.FC<Props> = ({ formMethods }) => {
  const watchedProductType = formMethods.watch('productType')

  switch (watchedProductType) {
    case ProductType_Type.NFC:
      return <NFCProductForm formMethods={formMethods} />
    case ProductType_Type.J_CREDIT:
      return <JCreditProductForm formMethods={formMethods} />
    default:
      return undefined
  }
}

export default ProductForm
