// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=ts"
// @generated from file jcex/bff/report/v1/service.proto (package jcex.bff.report.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CreateAssessmentSettingRequest, CreateAssessmentSettingResponse, DeleteAssessmentSettingRequest, DeleteAssessmentSettingResponse, DetermineAssessmentSettingResultsRequest, DetermineAssessmentSettingResultsResponse, GetAssessmentJCreditResultRequest, GetAssessmentJCreditResultResponse, GetAssessmentNfcResultRequest, GetAssessmentNfcResultResponse, GetAssessmentSettingRequest, GetAssessmentSettingResponse, GetDenkiShimbunSubmissionTsvRequest, GetDenkiShimbunSubmissionTsvResponse, GetGreenIndexRequest, GetGreenIndexResponse, UpdateAssessmentJCreditResultSourceRequest, UpdateAssessmentJCreditResultSourceResponse, UpdateAssessmentNfcResultSourceRequest, UpdateAssessmentNfcResultSourceResponse } from "./service_pb.js";

/**
 * アセスメント設定の取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetAssessmentSetting
 */
export const getAssessmentSetting = {
  localName: "getAssessmentSetting",
  name: "GetAssessmentSetting",
  kind: MethodKind.Unary,
  I: GetAssessmentSettingRequest,
  O: GetAssessmentSettingResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * アセスメント設定の作成
 *
 * @generated from rpc jcex.bff.report.v1.Service.CreateAssessmentSetting
 */
export const createAssessmentSetting = {
  localName: "createAssessmentSetting",
  name: "CreateAssessmentSetting",
  kind: MethodKind.Unary,
  I: CreateAssessmentSettingRequest,
  O: CreateAssessmentSettingResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * アセスメント設定の削除
 *
 * @generated from rpc jcex.bff.report.v1.Service.DeleteAssessmentSetting
 */
export const deleteAssessmentSetting = {
  localName: "deleteAssessmentSetting",
  name: "DeleteAssessmentSetting",
  kind: MethodKind.Unary,
  I: DeleteAssessmentSettingRequest,
  O: DeleteAssessmentSettingResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * [非化石証書] アセスメント結果の取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetAssessmentNfcResult
 */
export const getAssessmentNfcResult = {
  localName: "getAssessmentNfcResult",
  name: "GetAssessmentNfcResult",
  kind: MethodKind.Unary,
  I: GetAssessmentNfcResultRequest,
  O: GetAssessmentNfcResultResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * [Jクレジット] アセスメント結果の取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetAssessmentJCreditResult
 */
export const getAssessmentJCreditResult = {
  localName: "getAssessmentJCreditResult",
  name: "GetAssessmentJCreditResult",
  kind: MethodKind.Unary,
  I: GetAssessmentJCreditResultRequest,
  O: GetAssessmentJCreditResultResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * [非化石証書]アセスメント計算結果のソース更新
 *
 * @generated from rpc jcex.bff.report.v1.Service.UpdateAssessmentNfcResultSource
 */
export const updateAssessmentNfcResultSource = {
  localName: "updateAssessmentNfcResultSource",
  name: "UpdateAssessmentNfcResultSource",
  kind: MethodKind.Unary,
  I: UpdateAssessmentNfcResultSourceRequest,
  O: UpdateAssessmentNfcResultSourceResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * [Jクレジット]アセスメント計算結果のソース更新
 *
 * @generated from rpc jcex.bff.report.v1.Service.UpdateAssessmentJCreditResultSource
 */
export const updateAssessmentJCreditResultSource = {
  localName: "updateAssessmentJCreditResultSource",
  name: "UpdateAssessmentJCreditResultSource",
  kind: MethodKind.Unary,
  I: UpdateAssessmentJCreditResultSourceRequest,
  O: UpdateAssessmentJCreditResultSourceResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * アセスメント設定の計算結果を確定する
 *
 * @generated from rpc jcex.bff.report.v1.Service.DetermineAssessmentSettingResults
 */
export const determineAssessmentSettingResults = {
  localName: "determineAssessmentSettingResults",
  name: "DetermineAssessmentSettingResults",
  kind: MethodKind.Unary,
  I: DetermineAssessmentSettingResultsRequest,
  O: DetermineAssessmentSettingResultsResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * 電気新聞への提出用TSVデータの取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetDenkiShimbunSubmissionTsv
 */
export const getDenkiShimbunSubmissionTsv = {
  localName: "getDenkiShimbunSubmissionTsv",
  name: "GetDenkiShimbunSubmissionTsv",
  kind: MethodKind.Unary,
  I: GetDenkiShimbunSubmissionTsvRequest,
  O: GetDenkiShimbunSubmissionTsvResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * グリーンインデックスの取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetGreenIndex
 */
export const getGreenIndex = {
  localName: "getGreenIndex",
  name: "GetGreenIndex",
  kind: MethodKind.Unary,
  I: GetGreenIndexRequest,
  O: GetGreenIndexResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;
