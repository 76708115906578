import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Radio, Stack, Text } from '@chakra-ui/react'

import { ColumnContent, Spacing } from '@enechain/ecloud-designsystem'
import { JCreditCertification } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/model/v1/model_pb'

import { Card } from '../../common'

type Props = {
  value: JCreditCertification
  onClick?: () => void
}

export const CreditCertificationCard: React.FC<Props> = ({
  value,
  onClick,
}) => {
  const { t } = useTranslation('domain')

  return (
    <Card
      minWidth="312px"
      cursor={onClick !== undefined ? 'pointer' : 'auto'}
      bodyProps={{
        p: Spacing[4],
        gap: Spacing[4],
        display: 'flex',
      }}
      onClick={onClick}
    >
      {onClick !== undefined && <Radio value={value.id} />}
      <Stack spacing={Spacing[2]}>
        <ColumnContent label={t('model.certification.column.project_abstract')}>
          <Text>{value.projectAbstract}</Text>
        </ColumnContent>
        <ColumnContent
          label={t('model.certification.column.j_credit_methodology')}
        >
          <Text>{value.applicationMethodology}</Text>
        </ColumnContent>
      </Stack>
    </Card>
  )
}
