import { useQuery } from '@connectrpc/connect-query'
import { keepPreviousData } from '@tanstack/react-query'

import { getDealHistoryList } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'
import { GetDealHistoryListRequest } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'

import {
  DealtOrderModelForJCreditOrderHistory,
  convertDealtOrderModelListForJCreditDealHistoryModel,
} from '~/trader/pages/HistoryList/models/JCredit/dealHistory'

type Params = {
  params: GetDealHistoryListRequest
}

type Result = {
  jCreditData: DealtOrderModelForJCreditOrderHistory[]
  isLoading: boolean
}

export function useJCreditDealHistoryList({ params }: Params): Result {
  const { data, isPlaceholderData } = useQuery(getDealHistoryList, params, {
    placeholderData: keepPreviousData,
  })

  const jCreditData = convertDealtOrderModelListForJCreditDealHistoryModel(
    data?.dealtOrders ?? [],
  )

  return {
    jCreditData,
    isLoading: isPlaceholderData,
  }
}
