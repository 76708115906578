import { OrderModelForNFCOrderBook } from '~/trader/pages/OrderBookList/models/NonFITNFC/order'
import { NFCOrderBookModel } from '~/trader/pages/OrderBookList/models/NonFITNFC/orderBook'

type TableRowValue = {
  bidVolume: string
  bidUnitPrice: string
  offerUnitPrice: string
  offerVolume: string
  lastUnitPrice: string
}

export function getNFCOrderBookTableRowValue({
  orderBook,
  isExpanded,
  targetOrder,
}: {
  orderBook: NFCOrderBookModel
  isExpanded: boolean
  targetOrder: OrderModelForNFCOrderBook | undefined
}): TableRowValue {
  // 縮小時
  if (!isExpanded) {
    return {
      bidVolume: orderBook.bestBid?.volume ?? '-',
      bidUnitPrice: orderBook.bestBid?.unitPrice ?? '-',
      offerUnitPrice: orderBook.bestOffer?.unitPrice ?? '-',
      offerVolume: orderBook.bestOffer?.volume ?? '-',
      lastUnitPrice: orderBook.lastUnitPrice ?? '',
    }
  }

  // Expanded && last 表示
  if (targetOrder === undefined) {
    return {
      bidVolume: '',
      bidUnitPrice: '',
      offerUnitPrice: '',
      offerVolume: '',
      lastUnitPrice: orderBook.lastUnitPrice ?? '',
    }
  }

  // Expanded && order[0] 表示
  return {
    bidVolume: targetOrder.bid?.volume ?? '-',
    bidUnitPrice: targetOrder.bid?.unitPrice ?? '-',
    offerUnitPrice: targetOrder.offer?.unitPrice ?? '-',
    offerVolume: targetOrder.offer?.volume ?? '-',
    lastUnitPrice: '',
  }
}
