import * as React from 'react'

import { useQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { Box, Stack, useDisclosure } from '@chakra-ui/react'

import { Spacing } from '@enechain/ecloud-designsystem'
import { JCreditCertification } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/model/v1/model_pb'
import { listJCreditCertifications } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'
import { Prefecture_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  CreditCertifiedForm as BaseCreditCertifiedForm,
  CreditCertificationCard,
  useErrorToast,
} from '@enechain/jcex-ui'

import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  value: Maybe<string>
  selectedCertificationId: Maybe<string>
  onChange: (value: {
    creditCertifiedNumber: Maybe<string>
    productId: Maybe<number>
    prefectureIds: Prefecture_Type[]
    certificationId: Maybe<string>
  }) => void
  initialCertification?: JCreditCertification
  error?: string
  isDisabled?: boolean
}

export const CreditCertifiedForm: React.FC<Props> = ({
  value,
  selectedCertificationId,
  onChange,
  initialCertification,
  error,
  isDisabled,
}) => {
  const { t } = useTranslation(['common', 'domain'])
  const disclosure = useDisclosure()
  const { showToast: showErrorToast } = useErrorToast({
    toastId: 'fetchJCredit/error',
  })

  const [displaySelectedCertification, setDisplaySelectedCertification] =
    React.useState<Maybe<JCreditCertification>>(undefined)
  const [selectedCertification, setSelectedCertification] =
    React.useState<Maybe<JCreditCertification>>(initialCertification)
  const [optionCertifications, setOptionCertifications] = React.useState<
    JCreditCertification[]
  >([])

  const { refetch } = useQuery(
    listJCreditCertifications,
    {
      filter: { creditCertifiedNumber: value },
    },
    {
      enabled: false,
    },
  )

  const handleChange = (inputValue: Maybe<string>): void => {
    setSelectedCertification(undefined)
    onChange({
      creditCertifiedNumber: inputValue,
      productId: undefined,
      prefectureIds: [],
      certificationId: undefined,
    })
  }

  const handleSearch = async (): Promise<void> => {
    const { data } = await refetch()
    if (data !== undefined && data.jCreditCertifications.length === 0) {
      showErrorToast({
        toastMessage: t('error.not_found', {
          value: t('domain:shared.j_credit'),
        }),
      })
      return
    }
    if (data !== undefined && data.jCreditCertifications.length > 1) {
      setOptionCertifications(data.jCreditCertifications)
      setDisplaySelectedCertification(
        selectedCertificationId !== undefined
          ? data.jCreditCertifications.find(
              (option) => option.id === selectedCertificationId,
            )
          : data.jCreditCertifications[0],
      )
      return disclosure.onOpen()
    }
    setSelectedCertification(data?.jCreditCertifications[0])
    onChange({
      creditCertifiedNumber: value,
      productId: data?.jCreditCertifications[0]?.product?.id ?? undefined,
      prefectureIds:
        data?.jCreditCertifications[0]?.prefectures.map(
          (prefecture) => prefecture.type,
        ) ?? [],
      certificationId: data?.jCreditCertifications[0]?.id ?? undefined,
    })
  }

  const handleConfirm = (): void => {
    setSelectedCertification(displaySelectedCertification)
    onChange({
      creditCertifiedNumber: value,
      productId: displaySelectedCertification?.product?.id ?? undefined,
      prefectureIds:
        displaySelectedCertification?.prefectures.map(
          (prefecture) => prefecture.type,
        ) ?? [],
      certificationId: displaySelectedCertification?.id ?? undefined,
    })
  }

  return (
    <Stack spacing={Spacing[6]}>
      <BaseCreditCertifiedForm
        disclosure={disclosure}
        error={error}
        isDisabled={isDisabled}
        onChange={handleChange}
        onClickConfirm={handleConfirm}
        onClickSearch={handleSearch}
        options={{
          state: optionCertifications,
          setState: setOptionCertifications,
        }}
        selectedCertification={{
          state: displaySelectedCertification,
          setState: setDisplaySelectedCertification,
        }}
        value={value}
      />
      {selectedCertification !== undefined && (
        <Box maxWidth="50%">
          <CreditCertificationCard value={selectedCertification} />
        </Box>
      )}
    </Stack>
  )
}
