import { useEffect, useState } from 'react'

import { useQuery } from '@connectrpc/connect-query'
import { keepPreviousData } from '@tanstack/react-query'

import { getGreenIndex } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/service-Service_connectquery'
import { GetGreenIndexRequest } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/service_pb'
import { formatDateToJSTYYYYMMddTimeStamp } from '@enechain/jcex-utils'

import {
  GreenIndexModel,
  convertGreenIndexModel,
} from '~/trader/pages/CarbonIndex/models/greenIndex'
import {
  GreenIndexFilterOptions,
  GreenIndexFilterState,
} from '~/trader/pages/CarbonIndex/types/filterGreenIndex'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Params = {
  filter: GreenIndexFilterState
}

type Result = {
  greenIndexData: Maybe<GreenIndexModel>
  filterOptions: GreenIndexFilterOptions
  isLoading: boolean
}

export function useGreenIndex({ filter }: Params): Result {
  const [initialFilter, setInitialFilter] = useState<boolean>(false)

  const { state: filterState, setState: setFilter } = filter

  const { data, isPending } = useQuery(
    getGreenIndex,
    new GetGreenIndexRequest({
      filter: {
        startDate:
          filterState.fromDate !== undefined
            ? formatDateToJSTYYYYMMddTimeStamp(filterState.fromDate)
            : undefined,
        endDate:
          filterState.toDate !== undefined
            ? formatDateToJSTYYYYMMddTimeStamp(filterState.toDate)
            : undefined,
      },
    }),
    { placeholderData: keepPreviousData },
  )

  const greenIndexModel = convertGreenIndexModel(data?.greenIndex, filterState)
  const filterOptions = {
    nfc: {
      type: data?.greenIndex?.nfcAllProducts ?? [],
      deliveryFiscalYear: data?.greenIndex?.nfcAllDeliveryFiscalYear ?? [],
    },
    jCredit: data?.greenIndex?.jcreditAllProducts ?? [],
  }

  useEffect(() => {
    if (filterOptions.nfc.deliveryFiscalYear.length === 0 || initialFilter) {
      return
    }
    setFilter({
      key: 'nfcDeliveryFiscalYears',
      value: filterOptions.nfc.deliveryFiscalYear.map((item) => item.year),
    })
    setInitialFilter(true)
  }, [filterOptions.nfc.deliveryFiscalYear, initialFilter, setFilter])

  return {
    greenIndexData: greenIndexModel,
    filterOptions,
    isLoading: isPending,
  }
}
