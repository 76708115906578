import * as React from 'react'

import { Tooltip } from 'recharts'

import { Box, Flex, Stack } from '@chakra-ui/react'

import { BorderRadius, Spacing } from '@enechain/ecloud-designsystem'
import { SemanticColors } from '@enechain/jcex-ui'

/**
 * @memo
 * recharts の render timing の関係で renderChartTooltip として export している
 */
export const renderChartTooltip: React.FC = () => {
  return (
    <Tooltip
      content={({ payload }): React.ReactElement => {
        let date = ''
        if (
          payload !== undefined &&
          payload.length > 0 &&
          payload[0].payload !== undefined
        ) {
          const payloadItem = payload[0].payload as { referenceDate: string }
          date = payloadItem.referenceDate
        }
        return (
          <Box
            borderWidth="1px"
            borderStyle="solid"
            borderColor={SemanticColors.Border.midEmphasis}
            borderRadius={BorderRadius.radiiMd}
            backgroundColor={SemanticColors.Surface.primary}
            marginX={Spacing[4]}
          >
            {date && (
              <Flex
                alignItems="center"
                justifyContent="center"
                backgroundColor={SemanticColors.Surface.secondary}
                paddingY={Spacing[1]}
              >
                {date}
              </Flex>
            )}
            <Stack padding={Spacing[2]} spacing={Spacing[2]}>
              {payload !== undefined &&
                payload.map((item, index) => (
                  <Flex
                    key={`item-${index}`}
                    alignItems="center"
                    gap={Spacing[1]}
                  >
                    <Box
                      width="16px"
                      height="16px"
                      backgroundColor={item.color}
                    />
                    {item.name}: {item.value}
                  </Flex>
                ))}
            </Stack>
          </Box>
        )
      }}
    />
  )
}
