import { useCallback, useState } from 'react'

import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

import { INITIAL_GREEN_INDEX_FILTER } from '~/trader/pages/CarbonIndex/constants/filter'
import {
  GreenIndexFilter,
  GreenIndexFilterProps,
  GreenIndexFilterState,
} from '~/trader/pages/CarbonIndex/types/filterGreenIndex'

type Result = {
  greenIndexFilter: GreenIndexFilterState
}

export function useGreenIndexFilter(): Result {
  const [filter, _setFilter] = useState<GreenIndexFilter>(
    INITIAL_GREEN_INDEX_FILTER,
  )

  const setFilter = useCallback(
    ({ key, value }: GreenIndexFilterProps) => {
      const changeValue = {
        ...filter,
        [key]: value,
      }

      return _setFilter(changeValue)
    },
    [_setFilter, filter],
  )

  const setDateState = useCallback(
    (from?: Date, to?: Date) => {
      const changeValue = {
        ...filter,
        fromDate: from,
        toDate: to,
      }

      return _setFilter(changeValue)
    },
    [_setFilter, filter],
  )

  const removeTrackingYear = useCallback(
    ({
      nfcDeliveryFiscalYears,
      nfcProductTypes,
    }: {
      nfcDeliveryFiscalYears: number[]
      nfcProductTypes: RenewableEnergyAndTrackingStatus_Type[]
    }) => {
      const changeValue = {
        ...filter,
        nfcDeliveryFiscalYears,
        nfcProductTypes,
      }

      return _setFilter(changeValue)
    },
    [_setFilter, filter],
  )

  return {
    greenIndexFilter: {
      state: filter,
      setState: setFilter,
      setDateState,
      removeTrackingYear,
    },
  }
}
