import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import {
  FlexTableCell,
  FlexTableRow,
  SemanticColors,
  Typography,
} from '@enechain/ecloud-designsystem'
import {
  Position_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { VolumeAndUnitPriceTableHeader } from '@enechain/jcex-ui'

import { NonFITOrderListColumnKey } from '~/trader/pages/OrderBookList/components/NonFITNFC/ListTable/columnStyles'

export const NonFITOrderBookListTableHeaderRow: React.FC = () => {
  const { t } = useTranslation(['common', 'domain'])

  return (
    <FlexTableRow>
      <FlexTableCell<NonFITOrderListColumnKey> columnKey="accordion" />
      <FlexTableCell<NonFITOrderListColumnKey>
        columnKey="deliveryFiscalYear"
        contents={{ text: t('domain:model.order.column.delivery_fiscal_year') }}
      />
      <FlexTableCell<NonFITOrderListColumnKey>
        columnKey="renewableEnergyAndTrackingStatus"
        contents={{
          text: t(
            'domain:model.order.column.nfc.renewable_energy_and_tracking',
          ),
        }}
      />
      <FlexTableCell<NonFITOrderListColumnKey> columnKey="bidVolume">
        <VolumeAndUnitPriceTableHeader
          position={Position_Type.BID}
          headerType="volume"
          product={ProductType_Type.NFC}
        />
      </FlexTableCell>
      <FlexTableCell<NonFITOrderListColumnKey> columnKey="bidUnitPrice">
        <VolumeAndUnitPriceTableHeader
          position={Position_Type.BID}
          headerType="unitPrice"
          product={ProductType_Type.NFC}
        />
      </FlexTableCell>
      <FlexTableCell<NonFITOrderListColumnKey> columnKey="offerUnitPrice">
        <VolumeAndUnitPriceTableHeader
          position={Position_Type.OFFER}
          headerType="unitPrice"
          product={ProductType_Type.NFC}
        />
      </FlexTableCell>
      <FlexTableCell<NonFITOrderListColumnKey> columnKey="offerVolume">
        <VolumeAndUnitPriceTableHeader
          position={Position_Type.OFFER}
          headerType="volume"
          product={ProductType_Type.NFC}
        />
      </FlexTableCell>
      <FlexTableCell<NonFITOrderListColumnKey> columnKey="lastUnitPrice">
        <Flex
          flexDirection="column"
          overflow="hidden"
          color={SemanticColors.Text.midEmphasis}
        >
          <Text {...Typography.textLabel} alignSelf="end">
            {t('domain:shared.position.last')}
          </Text>
          <Text {...Typography.textLabel}>{t('common:unit.yen_per_kwh')}</Text>
        </Flex>
      </FlexTableCell>
      <FlexTableCell<NonFITOrderListColumnKey> columnKey="copyOrderAction" />
    </FlexTableRow>
  )
}
