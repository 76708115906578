import { RenewableEnergyAndTrackingStatus } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { GreenIndex_Summary } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/model_pb'
import { DeliveryFiscalYear } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { formatYYYYMMdd } from '@enechain/jcex-utils'

import {
  RenewableEnergyAndTrackingStatusModelForCarbonIndex,
  filterWithConvertAllProducts,
} from '~/trader/pages/CarbonIndex/models/productType'
import { Maybe } from '~/trader/types/AdvancedTypes'

type IndexPriceModel = {
  price: string
  changeAmount: number
  changeRate: number
  isNull: boolean
  productType: RenewableEnergyAndTrackingStatusModelForCarbonIndex
  deliveryFiscalYear: string
}

export type NFCGreenIndexSummaryModel = {
  averagePrices: Array<
    Pick<IndexPriceModel, 'price' | 'productType' | 'deliveryFiscalYear'>
  >
  latestPrices: IndexPriceModel[]
  latestReferenceDate: string
}

export function convertNFCGreenIndexSummaryModel(
  greenIndexSummary: Maybe<GreenIndex_Summary>,
  allProducts: RenewableEnergyAndTrackingStatus[],
  allDeliveryFiscalYear: DeliveryFiscalYear[],
): Maybe<NFCGreenIndexSummaryModel> {
  if (greenIndexSummary === undefined) {
    return undefined
  }

  const targetYears = allDeliveryFiscalYear.map((item) => item.year)

  const filteredProductModels = filterWithConvertAllProducts(
    allProducts,
    allDeliveryFiscalYear,
  )

  const averagePrices = filteredProductModels.flatMap((product) => {
    const targetPrices = greenIndexSummary.nfcAveragePrices.filter(
      (averagePrice) =>
        averagePrice.renewableEnergyAndTrackingStatus?.type === product.type &&
        targetYears.includes(averagePrice.deliveryFiscalYear?.year ?? 0),
    )
    return targetPrices.map((targetPrice) => ({
      price: targetPrice?.price?.toString() ?? '-',
      productType: product,
      deliveryFiscalYear: targetPrice?.deliveryFiscalYear?.displayName ?? '',
    }))
  })

  const latestPrices = filteredProductModels.flatMap((product) => {
    const targetPrices = greenIndexSummary.latestNfcIndexPrices.filter(
      (indexPrice) =>
        indexPrice.renewableEnergyAndTrackingStatus?.type === product.type &&
        targetYears.includes(indexPrice.deliveryFiscalYear?.year ?? 0),
    )
    return targetPrices.map((targetPrice) => ({
      price: targetPrice?.indexValue?.price?.toString() ?? '-',
      changeAmount: targetPrice?.indexValue?.changeAmount ?? 0,
      changeRate: targetPrice?.indexValue?.changeRate ?? 0,
      isNull: targetPrice === undefined,
      productType: product,
      deliveryFiscalYear: targetPrice?.deliveryFiscalYear?.displayName ?? '',
    }))
  })

  return {
    averagePrices,
    latestPrices,
    latestReferenceDate:
      greenIndexSummary.latestReferenceDate !== undefined
        ? formatYYYYMMdd(greenIndexSummary.latestReferenceDate.toDate(), '/')
        : '',
  }
}
