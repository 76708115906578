import * as React from 'react'

import { Text } from '@chakra-ui/react'

import {
  BorderRadius,
  SemanticColors,
  Spacing,
  Tooltip,
} from '@enechain/ecloud-designsystem'
import {
  OpenToMarketStatus_Type,
  OrderStatus_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

export type StatusStyleTypes =
  | 'positive'
  | 'error'
  | 'warning'
  | 'info'
  | 'neutral'

export type StatusType = {
  statusType: OrderStatus_Type | OpenToMarketStatus_Type
  type: StatusStyleTypes
  displayLabel: string
}

type Props = {
  value: StatusType
  textStyle?: string
  errorDescription?: string
}

const StatusBadge: React.FC<Props> = ({
  value,
  textStyle = 'textLabel',
  errorDescription,
}) => {
  switch (value.type) {
    case 'positive':
      return (
        <Text
          textStyle={textStyle}
          color={SemanticColors.Text.postiveHigh}
          borderRadius={BorderRadius.radiiSm}
          backgroundColor={SemanticColors.Surface.positiveMid}
          paddingX={Spacing[1]}
          // todo: spacing token に 2px 対応され次第修正
          paddingY={0.5}
        >
          {value.displayLabel}
        </Text>
      )
    case 'error':
      return (
        <Tooltip label={errorDescription}>
          <Text
            textStyle={textStyle}
            color={SemanticColors.Text.negativeHigh}
            borderRadius={BorderRadius.radiiSm}
            backgroundColor={SemanticColors.Surface.negativeMid}
            paddingX={Spacing[1]}
            // todo: spacing token に 2px 対応され次第修正
            paddingY={0.5}
          >
            {value.displayLabel}
          </Text>
        </Tooltip>
      )
    case 'warning':
      return (
        <Text
          textStyle={textStyle}
          color={SemanticColors.Text.warningHigh}
          borderRadius={BorderRadius.radiiSm}
          backgroundColor={SemanticColors.Surface.warningMid}
          paddingX={Spacing[1]}
          // todo: spacing token に 2px 対応され次第修正
          paddingY={0.5}
        >
          {value.displayLabel}
        </Text>
      )
    case 'info':
      return (
        <Text
          textStyle={textStyle}
          color={SemanticColors.Text.infoHigh}
          borderRadius={BorderRadius.radiiSm}
          backgroundColor={SemanticColors.Surface.infoMid}
          paddingX={Spacing[1]}
          // todo: spacing token に 2px 対応され次第修正
          paddingY={0.5}
        >
          {value.displayLabel}
        </Text>
      )
    case 'neutral':
      return (
        <Text
          textStyle={textStyle}
          color={SemanticColors.Text.neutralHigh}
          borderRadius={BorderRadius.radiiSm}
          backgroundColor={SemanticColors.Surface.neutralMid}
          paddingX={Spacing[1]}
          // todo: spacing token に 2px 対応され次第修正
          paddingY={0.5}
        >
          {value.displayLabel}
        </Text>
      )
  }
}

export default React.memo(StatusBadge)
