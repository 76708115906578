import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Checkbox, CheckboxGroup, Grid, Stack, Text } from '@chakra-ui/react'

import { Spacing, Typography } from '@enechain/ecloud-designsystem'
import { Prefecture } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { makeRegionsWithPrefectures } from './helper'

type Props = {
  selectedValue: number[]
  setSelectedValue: (value: number[]) => void
  options: Prefecture[]
}

export const SelectPrefecturesWithRegion: React.FC<Props> = ({
  selectedValue,
  setSelectedValue,
  options,
}) => {
  const { t } = useTranslation('domain')
  const regions = useMemo(() => makeRegionsWithPrefectures(options), [options])

  const isAllPrefecture = (prefectureName: string): boolean => {
    return prefectureName === t('shared.prefecture.region.all')
  }

  const isAllCheckedRegion = useCallback(
    (prefecturesInRegion: Prefecture[]): boolean => {
      return prefecturesInRegion.every((prefecture) =>
        selectedValue.includes(prefecture.type),
      )
    },
    [selectedValue],
  )

  const onChangeRegion = useCallback(
    (prefecturesInRegion: Prefecture[]): void => {
      const prefectureTypes = prefecturesInRegion.map(
        (prefecture) => prefecture.type,
      )

      const setPrefectureValue = isAllCheckedRegion(prefecturesInRegion)
        ? selectedValue.filter(
            (prefecture) => !prefectureTypes.includes(prefecture),
          )
        : [...selectedValue, ...prefectureTypes]

      setSelectedValue(Array.from(new Set(setPrefectureValue)))
    },
    [selectedValue, setSelectedValue, isAllCheckedRegion],
  )

  return (
    <Stack spacing={Spacing[6]}>
      {Object.values(regions).map((region) => (
        <Stack key={region.displayName} spacing={Spacing[2]}>
          <Checkbox
            colorScheme="green"
            isChecked={isAllCheckedRegion(region.prefectures)}
            onChange={(): void => onChangeRegion(region.prefectures)}
          >
            <Text {...Typography.headerSm}>{region.displayName}</Text>
          </Checkbox>
          {!isAllPrefecture(region.displayName) &&
            region.prefectures.length > 1 && (
              <CheckboxGroup
                onChange={(value: string[]): void =>
                  setSelectedValue(value.map((v) => Number(v)))
                }
                value={selectedValue}
              >
                <Grid
                  gridAutoRows="minmax(auto, 1fr)"
                  gridTemplateColumns="repeat(7, 1fr)"
                  width="100%"
                >
                  {region.prefectures.map((prefecture) => (
                    <Checkbox
                      key={prefecture.type}
                      colorScheme="green"
                      value={prefecture.type}
                    >
                      {prefecture.displayName}
                    </Checkbox>
                  ))}
                </Grid>
              </CheckboxGroup>
            )}
        </Stack>
      ))}
    </Stack>
  )
}

export default React.memo(SelectPrefecturesWithRegion)
