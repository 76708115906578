import { Transport } from '@connectrpc/connect'
import {
  callUnaryMethod,
  createConnectQueryKey,
} from '@connectrpc/connect-query'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunction, defer } from 'react-router-dom'

import { getUserInfo } from '@enechain/jcex-proto/proto/jcex/bff/common/v1/service-Service_connectquery'
import { imageUtils } from '@enechain/jcex-utils'

export type MainLayoutLoaderData = {
  name: string
  src: string
}

export function mainLayoutLoader(
  transport: Transport,
  queryClient: QueryClient,
): LoaderFunction {
  return async () => {
    const data = await queryClient.ensureQueryData({
      queryKey: createConnectQueryKey(getUserInfo),
      queryFn: () => callUnaryMethod(getUserInfo, {}, { transport }),
    })

    return defer({
      name: data.userInfo?.organizationName,
      src: imageUtils.imageToBase64(
        data.userInfo?.organizationImage ?? new Uint8Array(),
      ),
    })
  }
}
