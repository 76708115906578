import * as React from 'react'
import { memo } from 'react'

import { Icon, IconProps, forwardRef } from '@chakra-ui/react'

const SvgFaDatabaseRegular: React.FC<IconProps> = forwardRef<IconProps, 'svg'>(
  function Svg(props: IconProps, ref): JSX.Element {
    return (
      <Icon
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 24 24"
        aria-hidden="true"
        {...props}
      >
        <path
          fill="currentColor"
          d="M12 20.558q-3.054-.033-5.08-.74-2.056-.74-2.121-1.832V6.669q.064-1.093 2.122-1.832Q8.946 4.13 12 4.097q3.054.032 5.08.74 2.056.74 2.121 1.832v11.317q-.064 1.093-2.122 1.832-2.025.708-5.079.74m5.658-13.696h-.032q-.226-.257-1.06-.546Q14.86 5.672 12 5.64q-2.862.032-4.565.675-.835.29-1.061.546h-.032v2.861q.643.323 1.768.643 1.672.387 3.89.418 2.218-.031 3.89-.418 1.125-.32 1.768-.643zm0 4.565q-.643.258-1.382.418-1.897.482-4.276.482t-4.276-.482a8.7 8.7 0 0 1-1.382-.418v2.412q.643.321 1.768.642 1.672.387 3.89.418 2.218-.031 3.89-.418 1.125-.32 1.768-.643zM6.374 17.825q.226.193 1.06.514 1.705.643 4.534.675 2.893-.031 4.597-.675.836-.321 1.061-.514l.032-.064v-2.219q-.643.258-1.382.418-1.865.482-4.308.482-2.348 0-4.244-.482a10 10 0 0 1-1.414-.418v2.219zm11.348-.097q0-.032 0 0m-11.444 0q0-.032 0 0"
        />
      </Icon>
    )
  },
)
const Memo = memo(SvgFaDatabaseRegular)
export default Memo
