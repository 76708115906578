import { Transport } from '@connectrpc/connect'
import {
  callUnaryMethod,
  createConnectQueryKey,
} from '@connectrpc/connect-query'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunction, defer, redirect } from 'react-router-dom'

import {
  getOrder,
  getOrderDetailOption,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import {
  GetOrderDetailOptionResponse,
  GetOrderResponse,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'

import { PATH_HISTORY } from '~/trader/routes/routePaths'

export type OrderDetailPageLoaderData = {
  orderId: string
  data: GetOrderResponse
  optionData: GetOrderDetailOptionResponse
}

export function nfcOrderDetailPageLoader(
  transport: Transport,
  queryClient: QueryClient,
): LoaderFunction {
  return async ({ params }) => {
    if (params.id === undefined) {
      return redirect(`${PATH_HISTORY.path}/not-found`)
    }

    const orderId = params.id

    const [data, optionData] = await Promise.all([
      // data
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(getOrder, { orderId }),
        queryFn: () => callUnaryMethod(getOrder, { orderId }, { transport }),
      }),
      // optionData
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(getOrderDetailOption),
        queryFn: () => callUnaryMethod(getOrderDetailOption, {}, { transport }),
      }),
    ])

    return defer({
      orderId,
      data,
      optionData,
    })
  }
}
