import { DealtOrder } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/model_pb'
import {
  Position,
  Position_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { formatYYYYMMddHHmmss } from '@enechain/jcex-utils'

export type DealtOrderModelForJCreditOrderHistory = Pick<
  DealtOrder,
  'dealId' | 'dealCode'
> & {
  position: Position
  deliveryFiscalYear: string
  productName: string
  prefectureName: string
  creditCertifiedNumber: string
  unitPrice: string
  volume: string
  counterpartyOrganizationName: string
  dealAt: string
}

export function convertDealtOrderModelListForJCreditDealHistoryModel(
  orders: DealtOrder[],
): DealtOrderModelForJCreditOrderHistory[] {
  return orders.map((order) => {
    const position =
      order.position ??
      new Position({
        type: Position_Type.UNSPECIFIED,
        displayName: '',
      })
    return {
      dealId: order.dealId,
      dealCode: order.dealCode,
      position,
      deliveryFiscalYear: order?.deliveryFiscalYear?.displayName ?? '',
      productName: order?.product?.name ?? '',
      prefectureName:
        order?.prefectures
          .map((prefecture) => prefecture.displayName)
          .join(', ') ?? '',
      creditCertifiedNumber: order?.creditCertifiedNumber ?? '',
      unitPrice: order.volumeAndUnitPrice?.unitPrice ?? '',
      volume: order.volumeAndUnitPrice?.volume ?? '',
      counterpartyOrganizationName: order.counterPartyOrganization?.name ?? '',
      dealAt:
        order.dealtAt !== undefined
          ? formatYYYYMMddHHmmss(order.dealtAt.toDate())
          : '',
    }
  })
}
