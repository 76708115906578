const ERROR_MESSAGE_START = 'desc = '

/**
 * @description Trims the error message to remove the unnecessary part.
 * @example trimErrorMessage("rpc error: code = InvalidArgument desc = error message") => 'error message'
 * @example trimErrorMessage("error message") => 'error message'
 */
export function trimErrorMessage(
  error: string | undefined,
): string | undefined {
  if (error === undefined) {
    return undefined
  }

  if (!error.includes(ERROR_MESSAGE_START)) {
    return error
  }

  // "desc = " の後に表示したい error message が入っているので、それ以降の文字列を取得する
  const errorStartIndex =
    error.indexOf(ERROR_MESSAGE_START) + ERROR_MESSAGE_START.length
  return error.substring(errorStartIndex)
}
