import * as React from 'react'

import { Flex, Stack, Text } from '@chakra-ui/react'

import {
  Badge,
  BorderRadius,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import { FaArrowDownRegular, FaArrowUpRegular } from '@enechain/ecloud-icons'

type Props = {
  changeAmount: number
  changeRate: number
  isNull?: boolean
}

export const ChangeBadge: React.FC<Props> = ({
  changeAmount,
  changeRate,
  isNull,
}) => {
  if (isNull === true) {
    return (
      <Badge
        width="86px"
        height="44px"
        borderRadius={BorderRadius.radiiBase}
        paddingX={Spacing[2]}
        paddingY={Spacing[1]}
        status="neutral"
      >
        <Flex alignItems="center" justifyContent="center" height="full">
          <Text {...Typography.textLabel}>N/A</Text>
        </Flex>
      </Badge>
    )
  }

  const isZero = changeRate === 0

  if (isZero) {
    return (
      <Badge
        borderRadius={BorderRadius.radiiBase}
        paddingX={Spacing[2]}
        paddingY={Spacing[1]}
        status="neutral"
      >
        <Flex alignItems="center" flexDirection="row" gap={Spacing[6]}>
          <Text {...Typography.headerLg}>-</Text>
          <Stack alignItems="end" spacing={Spacing[0]}>
            <Text {...Typography.textLabel}>{changeAmount}</Text>
            <Text {...Typography.textSm}>{`(${changeRate}%)`}</Text>
          </Stack>
        </Flex>
      </Badge>
    )
  }

  const isPlus = changeRate > 0

  return (
    <Badge
      borderRadius={BorderRadius.radiiBase}
      paddingX={Spacing[2]}
      paddingY={Spacing[1]}
      status={isPlus ? 'positive' : 'negative'}
    >
      <Flex alignItems="center" flexDirection="row" gap={Spacing[1]}>
        {isPlus ? (
          <FaArrowUpRegular width="24px" height="24px" />
        ) : (
          <FaArrowDownRegular width="24px" height="24px" />
        )}
        <Stack alignItems="end" spacing={Spacing[0]}>
          <Text {...Typography.textLabel}>{changeAmount}</Text>
          <Text {...Typography.textSm}>{`(${
            isPlus ? '+' : ''
          }${changeRate}%)`}</Text>
        </Stack>
      </Flex>
    </Badge>
  )
}
