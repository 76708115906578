// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file jcex/bff/nfc/model/v1/model.proto (package jcex.bff.nfc.model.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * 再エネ指定・トラッキングの組み合わせ状態
 * 再エネ指定なし・トラッキングありが存在しないので、4組み合わせのうち1つを除外して3つの値を持つenumを定義
 *
 * @generated from message jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus
 */
export class RenewableEnergyAndTrackingStatus extends Message<RenewableEnergyAndTrackingStatus> {
  /**
   * 再エネ指定・トラッキングの組み合わせ状態
   *
   * @generated from field: jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus.Type type = 1;
   */
  type = RenewableEnergyAndTrackingStatus_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<RenewableEnergyAndTrackingStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(RenewableEnergyAndTrackingStatus_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RenewableEnergyAndTrackingStatus {
    return new RenewableEnergyAndTrackingStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RenewableEnergyAndTrackingStatus {
    return new RenewableEnergyAndTrackingStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RenewableEnergyAndTrackingStatus {
    return new RenewableEnergyAndTrackingStatus().fromJsonString(jsonString, options);
  }

  static equals(a: RenewableEnergyAndTrackingStatus | PlainMessage<RenewableEnergyAndTrackingStatus> | undefined, b: RenewableEnergyAndTrackingStatus | PlainMessage<RenewableEnergyAndTrackingStatus> | undefined): boolean {
    return proto3.util.equals(RenewableEnergyAndTrackingStatus, a, b);
  }
}

/**
 * @generated from enum jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus.Type
 */
export enum RenewableEnergyAndTrackingStatus_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 再エネ指定あり・トラッキングあり
   *
   * @generated from enum value: TYPE_RENEWABLE_ENERGY_AND_TRACKING = 1;
   */
  RENEWABLE_ENERGY_AND_TRACKING = 1,

  /**
   * 再エネ指定あり・トラッキングなし
   *
   * @generated from enum value: TYPE_RENEWABLE_ENERGY = 2;
   */
  RENEWABLE_ENERGY = 2,

  /**
   * 再エネ指定なし
   *
   * @generated from enum value: TYPE_NONE = 3;
   */
  NONE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(RenewableEnergyAndTrackingStatus_Type)
proto3.util.setEnumType(RenewableEnergyAndTrackingStatus_Type, "jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_RENEWABLE_ENERGY_AND_TRACKING" },
  { no: 2, name: "TYPE_RENEWABLE_ENERGY" },
  { no: 3, name: "TYPE_NONE" },
]);

/**
 * 発電種のモデル
 *
 * @generated from message jcex.bff.nfc.model.v1.GeneratorType
 */
export class GeneratorType extends Message<GeneratorType> {
  /**
   * 発電種種別
   *
   * @generated from field: jcex.bff.nfc.model.v1.GeneratorType.Type type = 1;
   */
  type = GeneratorType_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<GeneratorType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.nfc.model.v1.GeneratorType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(GeneratorType_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeneratorType {
    return new GeneratorType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeneratorType {
    return new GeneratorType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeneratorType {
    return new GeneratorType().fromJsonString(jsonString, options);
  }

  static equals(a: GeneratorType | PlainMessage<GeneratorType> | undefined, b: GeneratorType | PlainMessage<GeneratorType> | undefined): boolean {
    return proto3.util.equals(GeneratorType, a, b);
  }
}

/**
 * @generated from enum jcex.bff.nfc.model.v1.GeneratorType.Type
 */
export enum GeneratorType_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 太陽光発電
   *
   * @generated from enum value: TYPE_SOLAR = 1;
   */
  SOLAR = 1,

  /**
   * 風力発電
   *
   * @generated from enum value: TYPE_WIND = 2;
   */
  WIND = 2,

  /**
   * 水力発電
   *
   * @generated from enum value: TYPE_HYDRO = 3;
   */
  HYDRO = 3,

  /**
   * バイオマス発電
   *
   * @generated from enum value: TYPE_BIOMASS = 4;
   */
  BIOMASS = 4,

  /**
   * 地熱発電
   *
   * @generated from enum value: TYPE_GEOTHERMAL = 5;
   */
  GEOTHERMAL = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(GeneratorType_Type)
proto3.util.setEnumType(GeneratorType_Type, "jcex.bff.nfc.model.v1.GeneratorType.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_SOLAR" },
  { no: 2, name: "TYPE_WIND" },
  { no: 3, name: "TYPE_HYDRO" },
  { no: 4, name: "TYPE_BIOMASS" },
  { no: 5, name: "TYPE_GEOTHERMAL" },
]);

