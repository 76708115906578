import * as React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { Flex, Text, useDisclosure } from '@chakra-ui/react'

import {
  PrimaryButton,
  PrimaryModal,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
  TertiaryButton,
  Typography,
} from '@enechain/ecloud-designsystem'
import { ProductType_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  FixedContentLayout,
  FormErrorAlert,
  useErrorToast,
  useScrollRef,
  useSuccessToast,
} from '@enechain/jcex-ui'

import {
  useDuplicateOrderMutations,
  useDuplicateOrderQueries,
} from '~/trader/globalStates/duplicateOrderState'
import ProductForm from '~/trader/pages/CreateOrder/components/ProductForm'
import {
  SAFE_J_CREDIT_ORDER_FORM_VALUE,
  SAFE_NFC_ORDER_FORM_VALUE,
} from '~/trader/pages/CreateOrder/constants/form'
import { useCreateOrder } from '~/trader/pages/CreateOrder/hooks/useCreateOrder'
import {
  CreateOrderSchema,
  createOrderSchema,
} from '~/trader/pages/CreateOrder/schemas/createOrderSchema'
import { PATH_CREATE_ORDER, PATH_ORDER_BOOK } from '~/trader/routes/routePaths'

const CreateOrderPage: React.FC = () => {
  const { t } = useTranslation(['common', 'domain', 'trader'])
  const navigate = useNavigate()
  const disclosure = useDisclosure()
  const { showToast: showSuccessToast } = useSuccessToast({
    toastId: 'createOrder/success',
  })
  const { showToast: showErrorToast } = useErrorToast({
    toastId: 'createOrder/error',
  })

  const { scrollElementRef, handleScroll } = useScrollRef()

  const data = useDuplicateOrderQueries()
  const { remove } = useDuplicateOrderMutations()
  const { invokeCreateOrder, isLoading } = useCreateOrder()

  const formMethods = useForm<CreateOrderSchema>({
    resolver: zodResolver(createOrderSchema),
    defaultValues:
      data !== undefined
        ? {
            ...SAFE_J_CREDIT_ORDER_FORM_VALUE,
            ...SAFE_NFC_ORDER_FORM_VALUE,
            ...data,
          }
        : {
            ...SAFE_J_CREDIT_ORDER_FORM_VALUE,
            ...SAFE_NFC_ORDER_FORM_VALUE,
            productType: ProductType_Type.NFC,
          },
  })

  const breadcrumbItems = [
    {
      label: PATH_ORDER_BOOK.name,
      onClick: () => navigate(PATH_ORDER_BOOK.path),
    },
    { label: PATH_CREATE_ORDER.name, bold: true },
  ]

  const handleError = (): void => {
    handleScroll()
  }

  const onClickConfirm = (): void => {
    disclosure.onOpen()
  }

  const onSubmit = (value: CreateOrderSchema): void => {
    disclosure.onClose()
    invokeCreateOrder({
      value,
      onSuccess: () => {
        showSuccessToast({
          toastMessage: t('common:success.create', {
            value: t('domain:model.order.title'),
          }),
        })
        navigate(PATH_ORDER_BOOK.path)
      },
      onError: (error) => {
        showErrorToast({ toastMessage: error })
      },
    })
  }

  // useEffectOnce で本コンポーネントが開いた後の副作用で 複製用の globalState を reset する
  useEffectOnce(() => {
    remove()
  })

  return (
    <SideMenuLayoutContentLayout
      header={<SideMenuLayoutBreadcrumb items={breadcrumbItems} />}
    >
      <FixedContentLayout>
        <Text {...Typography.headerXL}>
          {t('common:title.create', {
            value: t('domain:model.order.title'),
          })}
        </Text>
        <FormProvider {...formMethods}>
          <PrimaryModal
            actionText={t('common:action.positive.create')}
            bodyChildren={
              <Text whiteSpace="pre-wrap">
                {t('common:description.create', {
                  value:
                    formMethods.getValues('productType') ===
                    ProductType_Type.NFC
                      ? t('trader:shared.nfc_order')
                      : t('trader:shared.j_credit_order'),
                })}
              </Text>
            }
            closeText={t('common:action.negative.cancel')}
            disclosure={disclosure}
            isCentered
            isLoading={isLoading}
            onClick={formMethods.handleSubmit(onSubmit)}
            title={t('common:title.of.create', {
              value: t('domain:model.order.title'),
            })}
          />
          <FormErrorAlert
            error={formMethods.formState.errors}
            scrollRef={scrollElementRef}
          />
          <ProductForm formMethods={formMethods} />
          <Flex justifyContent="flex-end" gap={Spacing[4]}>
            <TertiaryButton
              onClick={(): void => navigate(PATH_ORDER_BOOK.path)}
            >
              {t('common:action.negative.back')}
            </TertiaryButton>
            <PrimaryButton
              isLoading={isLoading}
              onClick={formMethods.handleSubmit(onClickConfirm, handleError)}
            >
              {t('common:action.neutral.check')}
            </PrimaryButton>
          </Flex>
        </FormProvider>
      </FixedContentLayout>
    </SideMenuLayoutContentLayout>
  )
}

export default React.memo(CreateOrderPage)
