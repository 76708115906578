import * as React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { SecondaryButton, Spacing } from '@enechain/ecloud-designsystem'

export const UnauthorizedErrorPage: React.FC = () => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()

  const onClickLogin = async (): Promise<void> => {
    await loginWithRedirect({
      appState: {
        returnTo: window.location.href,
      },
    })
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={Spacing[4]}
      width="100%"
      height="100%"
      paddingX={Spacing[4]}
      paddingY={Spacing[16]}
    >
      <Text>{t('common:label.please_sign_in')}</Text>
      <SecondaryButton onClick={onClickLogin}>
        {t('common:label.sign_in')}
      </SecondaryButton>
    </Flex>
  )
}
