const ignoreMessages = [
  'Network request failed',
  'Authorization header format must be Bearer',
  'abort',
]

export function isIgnoreApplicationMessage(
  message: string | undefined,
): boolean {
  return ignoreMessages.some((ignoreMessage) => {
    const targetMessage = typeof message === 'string' ? message : ''
    return targetMessage.includes(ignoreMessage)
  })
}
