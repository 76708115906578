import { subYears } from 'date-fns'

import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

import { FilterDateRange } from '~/trader/pages/CarbonIndex/types/filterDateRange'
import { GreenIndexFilter } from '~/trader/pages/CarbonIndex/types/filterGreenIndex'

/**
 * @memo
 * トラッキング制度が存在する年度の最大値
 */
export const TRACKABLE_YEAR = 2023

export const INITIAL_GREEN_INDEX_FILTER: GreenIndexFilter = {
  fromDate: subYears(new Date(), 1),
  toDate: new Date(),
  nfcProductTypes: [
    RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING,
    RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY,
    RenewableEnergyAndTrackingStatus_Type.NONE,
  ],
  nfcDeliveryFiscalYears: [],
  // Product は enum ではないので、以下の定義になっている
  jCreditProductTypes: [1, 2, 3, 4, 5, 6],
}

/**
 * @memo i18n のため、value は スネークケースにしている
 */
export const FILTER_DATE_RANGE_OPTION: { [key: string]: FilterDateRange } = {
  MONTH: 'month',
  THREE_MONTH: 'three_month',
  SIX_MONTH: 'six_month',
  YEAR: 'year',
  THREE_YEAR: 'three_year',
  BEGINNING_OF_THE_YEAR: 'beginning_of_the_year',
  CUSTOM: 'custom',
} as const
