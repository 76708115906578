import * as React from 'react'

import { Box } from '@chakra-ui/react'

import { FlexTableBody } from '@enechain/ecloud-designsystem'

import { LoadingWithGray } from '../LoadingWithGray'

type Props = {
  // todo: TableBodyStyle を DesignSystem から export するが、一旦自前で用意
  style: 'form' | 'single-line' | 'multi-line' | 'sub-table'
  isLoading: boolean
  children: React.ReactNode
}

export const TableBodyWithLoading: React.FC<Props> = ({
  style,
  isLoading,
  children,
}) => {
  return (
    <FlexTableBody style={style}>
      <Box position={isLoading ? 'relative' : 'inherit'}>
        {isLoading && <LoadingWithGray />}
        {children}
      </Box>
    </FlexTableBody>
  )
}
