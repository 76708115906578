import { PrimitiveColors } from '@enechain/ecloud-designsystem'
import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

export function GetNFCDataColor(
  value: RenewableEnergyAndTrackingStatus_Type,
): string {
  switch (value) {
    case RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING:
      return PrimitiveColors.blue[700]
    case RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY:
      return PrimitiveColors.purple[400]
    case RenewableEnergyAndTrackingStatus_Type.NONE:
    default:
      return PrimitiveColors.teal[400]
  }
}

/**
 @memo Product は enum ではないので、以下の定義になっている
  { "id": 1, "name": "再エネ・電力" },
  { "id": 2, "name": "再エネ・熱" },
  { "id": 3, "name": "再エネ・混合" },
  { "id": 4, "name": "省エネ" },
  { "id": 5, "name": "森林" },
  { "id": 6, "name": "その他" }
 */

export function GetJCreditDataColor(value: number): string {
  switch (value) {
    case 1:
      return PrimitiveColors.blue[700]
    case 2:
      return PrimitiveColors.purple[400]
    case 3:
      return PrimitiveColors.teal[400]
    case 4:
      return PrimitiveColors.yellow[400]
    case 5:
      return PrimitiveColors.pink[400]
    case 6:
    default:
      return PrimitiveColors.cyan[400]
  }
}
