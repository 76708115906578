import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { Order } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/model_pb'
import { Position_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import {
  OrderInformationModel,
  convertOrderInformationModel,
} from '~/trader/pages/NFCOrderDetail/models/orderInformation'
import { UpdateOrderSchema } from '~/trader/pages/NFCOrderDetail/schemas/updateOrderSchema'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Params = {
  order: Maybe<Order>
}

export type OrderModel = Pick<Order, 'id'> & {
  orderInformation: OrderInformationModel
  formValue: UpdateOrderSchema
}

export function convertOrderModel({ order }: Params): Maybe<OrderModel> {
  if (order === undefined) {
    return undefined
  }

  return {
    id: order.id,
    orderInformation: convertOrderInformationModel(order),
    formValue: {
      orderId: order.id,
      positionType: order.position?.type ?? Position_Type.BID,
      renewableEnergyAndTrackingStatusType:
        order.renewableEnergyAndTrackingStatus?.type ??
        RenewableEnergyAndTrackingStatus_Type.UNSPECIFIED,
      prefectureIds: order.prefectures.map((prefecture) => prefecture.type),
      generatorIds: order.generatorTypes.map(
        (generatorType) => generatorType.type,
      ),
      deliveryFiscalYear:
        order.deliveryFiscalYear?.year ?? new Date().getFullYear(),
      unitPrice: order.volumeAndUnitPrice?.unitPrice.replaceAll(',', '') ?? '',
      volume: order.volumeAndUnitPrice?.volume.replaceAll(',', '') ?? '',
    },
  }
}
