import { GeneratorType_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { Order } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/model_pb'
import {
  Position,
  Position_Type,
  Prefecture_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  VolumeAndUnitPriceModel,
  VolumeUnit,
  convertVolumeAndUnitPriceModel,
} from '@enechain/jcex-ui'

import { GrayOutFilter } from '~/trader/pages/OrderBookList/types/nfcFilter'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Params = {
  orders: Order[]
  filters: GrayOutFilter
  volumeUnit: VolumeUnit
}

export type OrderModelForNFCOrderBook = Pick<Order, 'id'> & {
  bid: Maybe<VolumeAndUnitPriceModel>
  offer: Maybe<VolumeAndUnitPriceModel>
  isGrayOutTarget: boolean
  prefectureIds: Prefecture_Type[]
  generatorIds: GeneratorType_Type[]
}

export function convertOrderModelListForNFCOrderBookModel({
  orders,
  filters,
  volumeUnit,
}: Params): OrderModelForNFCOrderBook[] {
  return orders.map((order) => {
    const position =
      order.position ??
      new Position({
        type: Position_Type.UNSPECIFIED,
        displayName: '',
      })

    const hasMatchingPrefecture = order.prefectures.some((prefecture) =>
      filters.prefectures.includes(prefecture.type),
    )
    const hasMatchingGeneratorType = order.generatorTypes.some(
      (generatorType) => filters.generatorTypes.includes(generatorType.type),
    )

    const isGrayOutTarget = !hasMatchingPrefecture && !hasMatchingGeneratorType

    const volumeAndUnitPrice = convertVolumeAndUnitPriceModel(
      order.volumeAndUnitPrice,
      volumeUnit,
    )

    return {
      id: order.id,
      bid: position.type === Position_Type.BID ? volumeAndUnitPrice : undefined,
      offer:
        position.type === Position_Type.OFFER ? volumeAndUnitPrice : undefined,
      isGrayOutTarget,
      prefectureIds: order.prefectures.map((prefecture) => prefecture.type),
      generatorIds: order.generatorTypes.map(
        (generatorType) => generatorType.type,
      ),
    }
  })
}
