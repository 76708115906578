import {
  Position_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

export const SAFE_NFC_ORDER_FORM_VALUE = {
  productType: ProductType_Type.NFC,
  positionType: Position_Type.BID,
  deliveryFiscalYear: undefined,
  renewableEnergyAndTrackingStatusType: undefined,
  prefectureIds: [],
  generatorIds: [],
  unitPrice: undefined,
  volume: undefined,
}

export const SAFE_J_CREDIT_ORDER_FORM_VALUE = {
  productType: ProductType_Type.J_CREDIT,
  deliveryFiscalYear: undefined,
  productId: undefined,
  position: {
    type: Position_Type.BID,
    creditCertifiedNumber: undefined,
    jCreditCertificationId: undefined,
  },
  prefectureIds: [],
  unitPrice: undefined,
  volume: undefined,
}
