import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'

import {
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
  TabItem,
  Tabs,
} from '@enechain/ecloud-designsystem'
import { TAB_TYPES, TabKey } from '@enechain/jcex-ui'

import HistoryTypeSegmentedControl from '~/trader/pages/HistoryList/components/HistoryTypeSegmentedControl'
import TabContainer from '~/trader/pages/HistoryList/components/TabContainer'
import { useHistoryType } from '~/trader/pages/HistoryList/hooks/useHistoryType'
import { PATH_HISTORY } from '~/trader/routes/routePaths'

const HistoryListPage: React.FC = () => {
  const { t } = useTranslation('domain')
  const { selectedType, selectedTab, selectedSegmentType } = useHistoryType()

  const breadcrumbItems = [{ label: PATH_HISTORY.name, bold: true }]

  const tabItems: Array<TabItem<TabKey>> = [
    {
      label: t('shared.nfc'),
      key: TAB_TYPES.NFC,
    },
    {
      label: t('shared.j_credit'),
      key: TAB_TYPES.JCREDIT,
    },
  ]

  return (
    <SideMenuLayoutContentLayout
      header={<SideMenuLayoutBreadcrumb items={breadcrumbItems} />}
    >
      <Flex flexDirection="column" gap={Spacing[6]} width="full" height="full">
        <Tabs<TabKey>
          items={tabItems}
          onSelect={selectedTab.setState}
          selected={selectedTab.state}
        />
        <HistoryTypeSegmentedControl
          onSelect={selectedSegmentType.setState}
          selectedOption={selectedSegmentType.state}
        />
        <TabContainer selectedType={selectedType} />
      </Flex>
    </SideMenuLayoutContentLayout>
  )
}

export default HistoryListPage
