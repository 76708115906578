export type JCreditOrderHistoryListColumnKey =
  | 'position'
  | 'deliveryFiscalYear'
  | 'productName'
  | 'prefecture'
  | 'assignedTrader'
  | 'unitPrice'
  | 'volume'
  | 'createdAt'
  | 'orderStatus'

export const jCreditOrderHistoryColumnStyles = {
  position: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '36px',
    },
  },
  deliveryFiscalYear: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.3,
    },
  },
  productName: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  prefecture: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.3,
    },
  },
  assignedTrader: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  unitPrice: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.3,
    },
  },
  volume: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.3,
    },
  },
  createdAt: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  orderStatus: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.3,
    },
  },
} as const
