import * as React from 'react'

import { withAuthenticationRequired } from '@auth0/auth0-react'

import { LoadingJCEXLogo } from '@enechain/ecloud-designsystem'

export const ProtectedAuthGuard: (
  component: React.ComponentType,
) => React.FC = (component: React.ComponentType) => {
  return withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingJCEXLogo />,
    loginOptions: {
      appState: {
        returnTo: window.location.href,
      },
    },
  })
}
