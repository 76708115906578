import {
  RenewableEnergyAndTrackingStatus,
  RenewableEnergyAndTrackingStatus_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { DeliveryFiscalYear } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { TRACKABLE_YEAR } from '~/trader/pages/CarbonIndex/constants/filter'

export type RenewableEnergyAndTrackingStatusModelForCarbonIndex =
  RenewableEnergyAndTrackingStatus

function isNonTrackableYears(years: DeliveryFiscalYear[]): boolean {
  return years.every((item) => item.year > TRACKABLE_YEAR)
}

function convertRenewableEnergyAndTrackingStatusModelForCarbonIndex(
  renewableEnergyAndTrackingStatus: RenewableEnergyAndTrackingStatus,
  allDeliveryFiscalYears: DeliveryFiscalYear[],
): RenewableEnergyAndTrackingStatusModelForCarbonIndex {
  // トラッキングの概念が含まれる年が創出年度のフィルター状態にない場合は「トラッキング」の文字列を除外する
  return new RenewableEnergyAndTrackingStatus({
    ...renewableEnergyAndTrackingStatus,
    displayName: isNonTrackableYears(allDeliveryFiscalYears)
      ? renewableEnergyAndTrackingStatus.displayName.split('・')[0]
      : renewableEnergyAndTrackingStatus.displayName,
  })
}

/**
 * トラッキングの概念が含まれる年が創出年度のフィルター状態にない場合は「トラッキングなし」の状態を除外する
 */
export function filterWithConvertAllProducts(
  allProducts: RenewableEnergyAndTrackingStatus[],
  filterDeliveryFiscalYears: DeliveryFiscalYear[],
): RenewableEnergyAndTrackingStatusModelForCarbonIndex[] {
  return allProducts
    .map((product) =>
      convertRenewableEnergyAndTrackingStatusModelForCarbonIndex(
        product,
        filterDeliveryFiscalYears,
      ),
    )
    .filter(
      (product) =>
        !isNonTrackableYears(filterDeliveryFiscalYears) ||
        product.type !== RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY,
    )
}
