import * as React from 'react'
import { Suspense } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { Error, Loading, SideMenuLayout } from '@enechain/ecloud-designsystem'
import { BottomMenu, ProductLogo, ProtectedAuthGuard } from '@enechain/jcex-ui'

import { MainLayoutTopMenu } from '~/trader/components/layouts/MainLayout/topMenu'

export const MainLayout: React.FC = () => {
  const { reset } = useQueryErrorResetBoundary()
  const { logout } = useAuth0()
  const { t } = useTranslation('common')

  return (
    <ErrorBoundary
      FallbackComponent={({ ...rest }): React.ReactElement => {
        return (
          <Error
            {...rest}
            error={{ message: t('error.error_occurred') }}
            logout={(): Promise<void> =>
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              })
            }
          />
        )
      }}
      onReset={reset}
    >
      <SideMenuLayout
        bottomMenu={BottomMenu({ eCloudUrl: import.meta.env.VITE_ECLOUD_URL })}
        defaultMenuLock={false}
        logo={ProductLogo}
        topMenu={MainLayoutTopMenu}
      >
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </SideMenuLayout>
    </ErrorBoundary>
  )
}

export default ProtectedAuthGuard(MainLayout)
