import { Message } from '@bufbuild/protobuf'

import { GetCreateOrderOptionResponse as JCreditGetCreateOrderOptionResponse } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'

export type JCreditCreateOrderOptionModel =
  ExcludedMessage<JCreditGetCreateOrderOptionResponse> & {
    firstProductId: number | undefined
  }

export function convertJCreditCreateOrderOptionModel(
  response: JCreditGetCreateOrderOptionResponse,
): JCreditCreateOrderOptionModel {
  return {
    ...response,
    firstProductId:
      response.products !== undefined && response.products.length > 0
        ? response.products[0].id
        : undefined,
  }
}

// TODO: この型はbufbuildが生成したMessageのクラス全般に用いることができるので、適切な場所に移動する必要がある。
type ExcludedMessage<T extends Message<T>> = Omit<
  T,
  | 'equals'
  | 'clone'
  | 'fromBinary'
  | 'fromJson'
  | 'fromJsonString'
  | 'toBinary'
  | 'toJson'
  | 'toJsonString'
  | 'toJSON'
  | 'getType'
>
