import { useQuery } from '@connectrpc/connect-query'
import { useLoaderData } from 'react-router-dom'

import { getOrder } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'

import { OrderDetailPageLoaderData } from '~/trader/pages/JCreditOrderDetail/loader'
import {
  OrderModel,
  convertOrderModel,
} from '~/trader/pages/JCreditOrderDetail/models/order'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Result = {
  data: Maybe<OrderModel>
}

export function useOrderDetail(): Result {
  const { orderId, data: initialData } =
    useLoaderData() as OrderDetailPageLoaderData

  const { data } = useQuery(
    getOrder,
    { orderId },
    {
      initialData,
    },
  )

  const modelData = convertOrderModel({
    order: data?.order,
    productType: data?.productType,
  })

  return {
    data: modelData,
  }
}
