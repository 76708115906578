import { useCallback, useState } from 'react'

import { useLoaderData } from 'react-router-dom'

import {
  GetDealHistoryFilterResponse as GetJCreditDealHistoryFilterResponse,
  GetDealHistoryListRequest as GetJCreditDealHistoryListRequest,
} from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import {
  GetDealHistoryFilterResponse as GetNFCDealHistoryFilterResponse,
  GetDealHistoryListRequest as GetNFCDealHistoryListRequest,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'

import { DEAL_LIST_FILTER } from '~/trader/constants/localStorageKey'
import {
  INITIAL_J_CREDIT_DEAL_LIST_FILTER,
  INITIAL_NFC_DEAL_LIST_FILTER,
} from '~/trader/pages/HistoryList/constants/filter'
import { HistoryListPageLoaderData } from '~/trader/pages/HistoryList/loader'
import {
  FilterJCreditDealHistories,
  JCreditDealHistoryFilterProps,
} from '~/trader/pages/HistoryList/types/jCreditFilter'
import {
  FilterNFCDealHistories,
  NFCDealHistoryFilterProps,
} from '~/trader/pages/HistoryList/types/nfcFilter'

type Result = {
  nfc: {
    filters: FilterNFCDealHistories
    options: GetNFCDealHistoryFilterResponse
  }
  jCredit: {
    filters: FilterJCreditDealHistories
    options: GetJCreditDealHistoryFilterResponse
  }
  clearFilter: () => void
}

function findFilters(): {
  nfc: GetNFCDealHistoryListRequest
  jCredit: GetJCreditDealHistoryListRequest
} {
  return JSON.parse(localStorage.getItem(DEAL_LIST_FILTER) ?? '{}') as {
    nfc: GetNFCDealHistoryListRequest
    jCredit: GetJCreditDealHistoryListRequest
  }
}

export function useDealHistoryFilter(): Result {
  const { nfc, jCredit } = useLoaderData() as HistoryListPageLoaderData

  const [nfcDealHistoryFilter, _setNfcDealHistoryFilter] =
    useState<GetNFCDealHistoryListRequest>(
      new GetNFCDealHistoryListRequest({
        ...findFilters().nfc,
        ...INITIAL_NFC_DEAL_LIST_FILTER,
      }),
    )

  const [jcreditDealHistoryFilter, _setJCreditDealHistoryFilter] =
    useState<GetJCreditDealHistoryListRequest>(
      new GetJCreditDealHistoryListRequest({
        ...findFilters().jCredit,
        ...INITIAL_J_CREDIT_DEAL_LIST_FILTER,
      }),
    )

  const setNfcDealHistoryFilter = useCallback(
    ({ key, value }: NFCDealHistoryFilterProps) => {
      const changeValue = {
        ...nfcDealHistoryFilter,
        [key]: value,
      }

      localStorage.setItem(
        DEAL_LIST_FILTER,
        JSON.stringify({ nfc: changeValue, jCredit: findFilters().jCredit }),
      )

      return _setNfcDealHistoryFilter(
        new GetNFCDealHistoryListRequest(changeValue),
      )
    },
    [_setNfcDealHistoryFilter, nfcDealHistoryFilter],
  )

  const setJCreditDealHistoryFilter = useCallback(
    ({ key, value }: JCreditDealHistoryFilterProps) => {
      const changeValue = {
        ...jcreditDealHistoryFilter,
        [key]: value,
      }

      localStorage.setItem(
        DEAL_LIST_FILTER,
        JSON.stringify({ nfc: findFilters().nfc, jCredit: changeValue }),
      )

      return _setJCreditDealHistoryFilter(
        new GetJCreditDealHistoryListRequest(changeValue),
      )
    },
    [_setJCreditDealHistoryFilter, jcreditDealHistoryFilter],
  )

  const clearFilter = useCallback(() => {
    localStorage.removeItem(DEAL_LIST_FILTER)
    _setNfcDealHistoryFilter(INITIAL_NFC_DEAL_LIST_FILTER)
    _setJCreditDealHistoryFilter(INITIAL_J_CREDIT_DEAL_LIST_FILTER)
  }, [_setNfcDealHistoryFilter, _setJCreditDealHistoryFilter])

  return {
    nfc: {
      filters: {
        state: nfcDealHistoryFilter,
        setState: setNfcDealHistoryFilter,
      },
      options: nfc.deal.filterData,
    },
    jCredit: {
      filters: {
        state: jcreditDealHistoryFilter,
        setState: setJCreditDealHistoryFilter,
      },
      options: jCredit.deal.filterData,
    },
    clearFilter,
  }
}
