import { i18n } from '@enechain/jcex-i18n'

import enTrader from './locales/en/trader.json'
import jaTrader from './locales/ja/trader.json'

export default i18n({
  resources: {
    en: {
      trader: enTrader,
    },
    ja: {
      trader: jaTrader,
    },
  },
})
