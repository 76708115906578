import { useCallback } from 'react'

import { ConnectError } from '@connectrpc/connect'
import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'

import {
  getOrder,
  getOrderHistoryFilter,
  getOrderHistoryList,
  updateOrder,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import { UpdateOrderRequest } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'
import { useErrorToast, useSuccessToast } from '@enechain/jcex-ui'

import { OrderDetailPageLoaderData } from '~/trader/pages/NFCOrderDetail/loader'
import {
  UpdateOrderSchema,
  updateOrderSchema,
} from '~/trader/pages/NFCOrderDetail/schemas/updateOrderSchema'

type Result = {
  invokeUpdateOrder: (
    data: UpdateOrderSchema,
  ) => Promise<{ isSuccess: boolean }>
  isLoading: boolean
}

export function useUpdateOrder(): Result {
  const { t } = useTranslation(['common', 'domain'])
  const queryClient = useQueryClient()
  const { orderId } = useLoaderData() as OrderDetailPageLoaderData
  const { showToast: showSuccessToast } = useSuccessToast({
    toastId: 'useUpdateNFCOrder/success',
  })
  const { showToast: showErrorToast } = useErrorToast({
    toastId: 'useUpdateNFCOrder/error',
  })

  const mutation = useMutation(updateOrder, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getOrderHistoryFilter),
          refetchType: 'all',
        }),

        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getOrderHistoryList),
          refetchType: 'all',
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getOrder, { orderId }),
        }),
      ])
      showSuccessToast({
        toastMessage: t('common:success.update', {
          value: t('domain:model.order.title'),
        }),
      })
    },
  })

  const invokeUpdateOrder = useCallback(
    async (data: UpdateOrderSchema) => {
      const validate = updateOrderSchema.safeParse(data)

      if (!validate.success) {
        showErrorToast({ toastMessage: validate.error.message })
        return { isSuccess: false }
      }

      const parseData = new UpdateOrderRequest({
        ...validate.data,
        orderId,
        deliveryFiscalYear: Number(validate.data.deliveryFiscalYear),
      })

      const result = await mutation
        .mutateAsync(parseData)
        .catch((error: ConnectError) => {
          showErrorToast({ toastMessage: error.rawMessage })
          return { error }
        })

      return { isSuccess: !('error' in result) }
    },
    [mutation, orderId, showErrorToast],
  )

  return { invokeUpdateOrder, isLoading: mutation.isPending }
}
