import * as React from 'react'

import { Flex } from '@chakra-ui/react'

import { ColumnContent, Spacing } from '@enechain/ecloud-designsystem'

import { Card } from '../../common'

type PropItem = {
  title: string
  children: React.ReactNode
}

type Props = PropItem | { items: PropItem[] }

const CardLayout: React.FC<Props> = (props) => {
  // item の 要素数によって gap を変更する, 5つ以上の場合は一律 8px にする
  const gapSpacing = React.useMemo(() => {
    if (!('items' in props) || props.items.length >= 5) {
      return 8
    }
    return (5 - props.items.length) * 8
  }, [props])

  if ('items' in props) {
    return (
      <Flex justifyContent="space-between" gap={`${gapSpacing}px`}>
        {props.items.map(({ title, children }) => (
          <ColumnContent key={title} headerSize="Lg" label={title}>
            <Card bodyProps={{ p: Spacing[6] }}>{children}</Card>
          </ColumnContent>
        ))}
      </Flex>
    )
  }

  return (
    <ColumnContent headerSize="Lg" label={props.title}>
      <Card bodyProps={{ p: Spacing[6] }}>{props.children}</Card>
    </ColumnContent>
  )
}

export default React.memo(CardLayout)
