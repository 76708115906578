// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file jcex/bff/report/v1/service.proto (package jcex.bff.report.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { AssessmentJCreditResult, AssessmentNfcResult, AssessmentSetting, AssessmentSettingStatus, GreenIndex } from "./model_pb.js";

/**
 * セスメント設定の取得リクエスト
 *
 * @generated from message jcex.bff.report.v1.GetAssessmentSettingRequest
 */
export class GetAssessmentSettingRequest extends Message<GetAssessmentSettingRequest> {
  /**
   * アセスメント設定ID。渡さなかった場合は最新のアセスメント設定を取得する
   *
   * @generated from field: optional string assessment_setting_id = 1;
   */
  assessmentSettingId?: string;

  constructor(data?: PartialMessage<GetAssessmentSettingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetAssessmentSettingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_setting_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentSettingRequest {
    return new GetAssessmentSettingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentSettingRequest {
    return new GetAssessmentSettingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentSettingRequest {
    return new GetAssessmentSettingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentSettingRequest | PlainMessage<GetAssessmentSettingRequest> | undefined, b: GetAssessmentSettingRequest | PlainMessage<GetAssessmentSettingRequest> | undefined): boolean {
    return proto3.util.equals(GetAssessmentSettingRequest, a, b);
  }
}

/**
 * 最新のアセスメント設定の取得レスポンス
 *
 * @generated from message jcex.bff.report.v1.GetAssessmentSettingResponse
 */
export class GetAssessmentSettingResponse extends Message<GetAssessmentSettingResponse> {
  /**
   * アセスメント設定
   *
   * @generated from field: optional jcex.bff.report.v1.AssessmentSetting assessment_setting = 1;
   */
  assessmentSetting?: AssessmentSetting;

  /**
   * 前のアセスメント設定ID
   *
   * @generated from field: optional string prev_assessment_setting_id = 2;
   */
  prevAssessmentSettingId?: string;

  /**
   * 次のアセスメント設定ID
   *
   * @generated from field: optional string next_assessment_setting_id = 3;
   */
  nextAssessmentSettingId?: string;

  constructor(data?: PartialMessage<GetAssessmentSettingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetAssessmentSettingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_setting", kind: "message", T: AssessmentSetting, opt: true },
    { no: 2, name: "prev_assessment_setting_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "next_assessment_setting_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentSettingResponse {
    return new GetAssessmentSettingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentSettingResponse {
    return new GetAssessmentSettingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentSettingResponse {
    return new GetAssessmentSettingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentSettingResponse | PlainMessage<GetAssessmentSettingResponse> | undefined, b: GetAssessmentSettingResponse | PlainMessage<GetAssessmentSettingResponse> | undefined): boolean {
    return proto3.util.equals(GetAssessmentSettingResponse, a, b);
  }
}

/**
 * アセスメント設定の作成リクエスト
 *
 * @generated from message jcex.bff.report.v1.CreateAssessmentSettingRequest
 */
export class CreateAssessmentSettingRequest extends Message<CreateAssessmentSettingRequest> {
  /**
   * 取得日
   *
   * @generated from field: google.protobuf.Timestamp reference_date = 1;
   */
  referenceDate?: Timestamp;

  /**
   * 約定価格取得範囲
   *
   * @generated from field: uint32 deal_range_days = 2;
   */
  dealRangeDays = 0;

  /**
   * 注文取得範囲
   *
   * @generated from field: uint32 order_range_days = 3;
   */
  orderRangeDays = 0;

  /**
   * 気配値取得範囲
   *
   * @generated from field: uint32 indication_range_days = 4;
   */
  indicationRangeDays = 0;

  constructor(data?: PartialMessage<CreateAssessmentSettingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.CreateAssessmentSettingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reference_date", kind: "message", T: Timestamp },
    { no: 2, name: "deal_range_days", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "order_range_days", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "indication_range_days", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAssessmentSettingRequest {
    return new CreateAssessmentSettingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAssessmentSettingRequest {
    return new CreateAssessmentSettingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAssessmentSettingRequest {
    return new CreateAssessmentSettingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAssessmentSettingRequest | PlainMessage<CreateAssessmentSettingRequest> | undefined, b: CreateAssessmentSettingRequest | PlainMessage<CreateAssessmentSettingRequest> | undefined): boolean {
    return proto3.util.equals(CreateAssessmentSettingRequest, a, b);
  }
}

/**
 * アセスメント設定の作成レスポンス
 *
 * @generated from message jcex.bff.report.v1.CreateAssessmentSettingResponse
 */
export class CreateAssessmentSettingResponse extends Message<CreateAssessmentSettingResponse> {
  /**
   * アセスメント設定ID
   *
   * @generated from field: string assessment_setting_id = 1;
   */
  assessmentSettingId = "";

  constructor(data?: PartialMessage<CreateAssessmentSettingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.CreateAssessmentSettingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_setting_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAssessmentSettingResponse {
    return new CreateAssessmentSettingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAssessmentSettingResponse {
    return new CreateAssessmentSettingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAssessmentSettingResponse {
    return new CreateAssessmentSettingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAssessmentSettingResponse | PlainMessage<CreateAssessmentSettingResponse> | undefined, b: CreateAssessmentSettingResponse | PlainMessage<CreateAssessmentSettingResponse> | undefined): boolean {
    return proto3.util.equals(CreateAssessmentSettingResponse, a, b);
  }
}

/**
 * アセスメント設定の削除リクエスト
 *
 * @generated from message jcex.bff.report.v1.DeleteAssessmentSettingRequest
 */
export class DeleteAssessmentSettingRequest extends Message<DeleteAssessmentSettingRequest> {
  /**
   * アセスメント設定ID
   *
   * @generated from field: string assessment_setting_id = 1;
   */
  assessmentSettingId = "";

  constructor(data?: PartialMessage<DeleteAssessmentSettingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.DeleteAssessmentSettingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_setting_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAssessmentSettingRequest {
    return new DeleteAssessmentSettingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAssessmentSettingRequest {
    return new DeleteAssessmentSettingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAssessmentSettingRequest {
    return new DeleteAssessmentSettingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAssessmentSettingRequest | PlainMessage<DeleteAssessmentSettingRequest> | undefined, b: DeleteAssessmentSettingRequest | PlainMessage<DeleteAssessmentSettingRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAssessmentSettingRequest, a, b);
  }
}

/**
 * アセスメント設定の削除レスポンス
 *
 * @generated from message jcex.bff.report.v1.DeleteAssessmentSettingResponse
 */
export class DeleteAssessmentSettingResponse extends Message<DeleteAssessmentSettingResponse> {
  constructor(data?: PartialMessage<DeleteAssessmentSettingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.DeleteAssessmentSettingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAssessmentSettingResponse {
    return new DeleteAssessmentSettingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAssessmentSettingResponse {
    return new DeleteAssessmentSettingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAssessmentSettingResponse {
    return new DeleteAssessmentSettingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAssessmentSettingResponse | PlainMessage<DeleteAssessmentSettingResponse> | undefined, b: DeleteAssessmentSettingResponse | PlainMessage<DeleteAssessmentSettingResponse> | undefined): boolean {
    return proto3.util.equals(DeleteAssessmentSettingResponse, a, b);
  }
}

/**
 * [非化石証書] アセスメント結果の取得リクエスト
 *
 * @generated from message jcex.bff.report.v1.GetAssessmentNfcResultRequest
 */
export class GetAssessmentNfcResultRequest extends Message<GetAssessmentNfcResultRequest> {
  /**
   * [非化石証書] アセスメント結果ID
   *
   * @generated from field: string assessment_nfc_result_id = 1;
   */
  assessmentNfcResultId = "";

  constructor(data?: PartialMessage<GetAssessmentNfcResultRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetAssessmentNfcResultRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_nfc_result_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentNfcResultRequest {
    return new GetAssessmentNfcResultRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentNfcResultRequest {
    return new GetAssessmentNfcResultRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentNfcResultRequest {
    return new GetAssessmentNfcResultRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentNfcResultRequest | PlainMessage<GetAssessmentNfcResultRequest> | undefined, b: GetAssessmentNfcResultRequest | PlainMessage<GetAssessmentNfcResultRequest> | undefined): boolean {
    return proto3.util.equals(GetAssessmentNfcResultRequest, a, b);
  }
}

/**
 * [非化石証書] アセスメント結果の取得レスポンス
 *
 * @generated from message jcex.bff.report.v1.GetAssessmentNfcResultResponse
 */
export class GetAssessmentNfcResultResponse extends Message<GetAssessmentNfcResultResponse> {
  /**
   * [非化石証書] アセスメント結果
   *
   * @generated from field: jcex.bff.report.v1.AssessmentNfcResult assessment_nfc_result = 1;
   */
  assessmentNfcResult?: AssessmentNfcResult;

  /**
   * アセスメント設定のステータス
   *
   * @generated from field: jcex.bff.report.v1.AssessmentSettingStatus assessment_setting_status = 2;
   */
  assessmentSettingStatus?: AssessmentSettingStatus;

  constructor(data?: PartialMessage<GetAssessmentNfcResultResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetAssessmentNfcResultResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_nfc_result", kind: "message", T: AssessmentNfcResult },
    { no: 2, name: "assessment_setting_status", kind: "message", T: AssessmentSettingStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentNfcResultResponse {
    return new GetAssessmentNfcResultResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentNfcResultResponse {
    return new GetAssessmentNfcResultResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentNfcResultResponse {
    return new GetAssessmentNfcResultResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentNfcResultResponse | PlainMessage<GetAssessmentNfcResultResponse> | undefined, b: GetAssessmentNfcResultResponse | PlainMessage<GetAssessmentNfcResultResponse> | undefined): boolean {
    return proto3.util.equals(GetAssessmentNfcResultResponse, a, b);
  }
}

/**
 * [Jクレジット] アセスメント結果の取得リクエスト
 *
 * @generated from message jcex.bff.report.v1.GetAssessmentJCreditResultRequest
 */
export class GetAssessmentJCreditResultRequest extends Message<GetAssessmentJCreditResultRequest> {
  /**
   * [Jクレジット] アセスメント結果ID
   *
   * @generated from field: string assessment_jcredit_result_id = 1;
   */
  assessmentJcreditResultId = "";

  constructor(data?: PartialMessage<GetAssessmentJCreditResultRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetAssessmentJCreditResultRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_jcredit_result_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentJCreditResultRequest {
    return new GetAssessmentJCreditResultRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentJCreditResultRequest {
    return new GetAssessmentJCreditResultRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentJCreditResultRequest {
    return new GetAssessmentJCreditResultRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentJCreditResultRequest | PlainMessage<GetAssessmentJCreditResultRequest> | undefined, b: GetAssessmentJCreditResultRequest | PlainMessage<GetAssessmentJCreditResultRequest> | undefined): boolean {
    return proto3.util.equals(GetAssessmentJCreditResultRequest, a, b);
  }
}

/**
 * [Jクレジット] アセスメント結果の取得レスポンス
 *
 * @generated from message jcex.bff.report.v1.GetAssessmentJCreditResultResponse
 */
export class GetAssessmentJCreditResultResponse extends Message<GetAssessmentJCreditResultResponse> {
  /**
   * [Jクレジット] アセスメント結果
   *
   * @generated from field: jcex.bff.report.v1.AssessmentJCreditResult assessment_jcredit_result = 1;
   */
  assessmentJcreditResult?: AssessmentJCreditResult;

  /**
   * アセスメント設定のステータス
   *
   * @generated from field: jcex.bff.report.v1.AssessmentSettingStatus assessment_setting_status = 2;
   */
  assessmentSettingStatus?: AssessmentSettingStatus;

  constructor(data?: PartialMessage<GetAssessmentJCreditResultResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetAssessmentJCreditResultResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_jcredit_result", kind: "message", T: AssessmentJCreditResult },
    { no: 2, name: "assessment_setting_status", kind: "message", T: AssessmentSettingStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentJCreditResultResponse {
    return new GetAssessmentJCreditResultResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentJCreditResultResponse {
    return new GetAssessmentJCreditResultResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentJCreditResultResponse {
    return new GetAssessmentJCreditResultResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentJCreditResultResponse | PlainMessage<GetAssessmentJCreditResultResponse> | undefined, b: GetAssessmentJCreditResultResponse | PlainMessage<GetAssessmentJCreditResultResponse> | undefined): boolean {
    return proto3.util.equals(GetAssessmentJCreditResultResponse, a, b);
  }
}

/**
 * [非化石証書]アセスメント計算結果のソース更新リクエスト
 *
 * @generated from message jcex.bff.report.v1.UpdateAssessmentNfcResultSourceRequest
 */
export class UpdateAssessmentNfcResultSourceRequest extends Message<UpdateAssessmentNfcResultSourceRequest> {
  /**
   * [非化石証書] アセスメント結果ID
   *
   * @generated from field: string assessment_nfc_result_id = 1;
   */
  assessmentNfcResultId = "";

  /**
   * アセスメントの計算に使用する注文IDのリスト
   *
   * @generated from field: repeated string use_order_ids = 2;
   */
  useOrderIds: string[] = [];

  /**
   * アセスメントの計算に使用する約定IDのリスト
   *
   * @generated from field: repeated string use_deal_ids = 3;
   */
  useDealIds: string[] = [];

  constructor(data?: PartialMessage<UpdateAssessmentNfcResultSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.UpdateAssessmentNfcResultSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_nfc_result_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "use_order_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "use_deal_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssessmentNfcResultSourceRequest {
    return new UpdateAssessmentNfcResultSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssessmentNfcResultSourceRequest {
    return new UpdateAssessmentNfcResultSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssessmentNfcResultSourceRequest {
    return new UpdateAssessmentNfcResultSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssessmentNfcResultSourceRequest | PlainMessage<UpdateAssessmentNfcResultSourceRequest> | undefined, b: UpdateAssessmentNfcResultSourceRequest | PlainMessage<UpdateAssessmentNfcResultSourceRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAssessmentNfcResultSourceRequest, a, b);
  }
}

/**
 * [非化石証書]アセスメント計算結果のソース更新レスポンス
 *
 * @generated from message jcex.bff.report.v1.UpdateAssessmentNfcResultSourceResponse
 */
export class UpdateAssessmentNfcResultSourceResponse extends Message<UpdateAssessmentNfcResultSourceResponse> {
  constructor(data?: PartialMessage<UpdateAssessmentNfcResultSourceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.UpdateAssessmentNfcResultSourceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssessmentNfcResultSourceResponse {
    return new UpdateAssessmentNfcResultSourceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssessmentNfcResultSourceResponse {
    return new UpdateAssessmentNfcResultSourceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssessmentNfcResultSourceResponse {
    return new UpdateAssessmentNfcResultSourceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssessmentNfcResultSourceResponse | PlainMessage<UpdateAssessmentNfcResultSourceResponse> | undefined, b: UpdateAssessmentNfcResultSourceResponse | PlainMessage<UpdateAssessmentNfcResultSourceResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAssessmentNfcResultSourceResponse, a, b);
  }
}

/**
 * [Jクレジット]アセスメント計算結果のソース更新リクエスト
 *
 * @generated from message jcex.bff.report.v1.UpdateAssessmentJCreditResultSourceRequest
 */
export class UpdateAssessmentJCreditResultSourceRequest extends Message<UpdateAssessmentJCreditResultSourceRequest> {
  /**
   * [Jクレジット] アセスメント結果ID
   *
   * @generated from field: string assessment_jcredit_result_id = 1;
   */
  assessmentJcreditResultId = "";

  /**
   * アセスメントの計算に使用する注文IDのリスト
   *
   * @generated from field: repeated string use_order_ids = 2;
   */
  useOrderIds: string[] = [];

  /**
   * アセスメントの計算に使用する約定IDのリスト
   *
   * @generated from field: repeated string use_deal_ids = 3;
   */
  useDealIds: string[] = [];

  constructor(data?: PartialMessage<UpdateAssessmentJCreditResultSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.UpdateAssessmentJCreditResultSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_jcredit_result_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "use_order_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "use_deal_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssessmentJCreditResultSourceRequest {
    return new UpdateAssessmentJCreditResultSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssessmentJCreditResultSourceRequest {
    return new UpdateAssessmentJCreditResultSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssessmentJCreditResultSourceRequest {
    return new UpdateAssessmentJCreditResultSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssessmentJCreditResultSourceRequest | PlainMessage<UpdateAssessmentJCreditResultSourceRequest> | undefined, b: UpdateAssessmentJCreditResultSourceRequest | PlainMessage<UpdateAssessmentJCreditResultSourceRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAssessmentJCreditResultSourceRequest, a, b);
  }
}

/**
 * [Jクレジット]アセスメント計算結果のソース更新レスポンス
 *
 * @generated from message jcex.bff.report.v1.UpdateAssessmentJCreditResultSourceResponse
 */
export class UpdateAssessmentJCreditResultSourceResponse extends Message<UpdateAssessmentJCreditResultSourceResponse> {
  constructor(data?: PartialMessage<UpdateAssessmentJCreditResultSourceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.UpdateAssessmentJCreditResultSourceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssessmentJCreditResultSourceResponse {
    return new UpdateAssessmentJCreditResultSourceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssessmentJCreditResultSourceResponse {
    return new UpdateAssessmentJCreditResultSourceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssessmentJCreditResultSourceResponse {
    return new UpdateAssessmentJCreditResultSourceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssessmentJCreditResultSourceResponse | PlainMessage<UpdateAssessmentJCreditResultSourceResponse> | undefined, b: UpdateAssessmentJCreditResultSourceResponse | PlainMessage<UpdateAssessmentJCreditResultSourceResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAssessmentJCreditResultSourceResponse, a, b);
  }
}

/**
 * アセスメント設定の計算結果を確定するリクエスト
 *
 * @generated from message jcex.bff.report.v1.DetermineAssessmentSettingResultsRequest
 */
export class DetermineAssessmentSettingResultsRequest extends Message<DetermineAssessmentSettingResultsRequest> {
  /**
   * アセスメント設定ID
   *
   * @generated from field: string assessment_setting_id = 1;
   */
  assessmentSettingId = "";

  constructor(data?: PartialMessage<DetermineAssessmentSettingResultsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.DetermineAssessmentSettingResultsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_setting_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DetermineAssessmentSettingResultsRequest {
    return new DetermineAssessmentSettingResultsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DetermineAssessmentSettingResultsRequest {
    return new DetermineAssessmentSettingResultsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DetermineAssessmentSettingResultsRequest {
    return new DetermineAssessmentSettingResultsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DetermineAssessmentSettingResultsRequest | PlainMessage<DetermineAssessmentSettingResultsRequest> | undefined, b: DetermineAssessmentSettingResultsRequest | PlainMessage<DetermineAssessmentSettingResultsRequest> | undefined): boolean {
    return proto3.util.equals(DetermineAssessmentSettingResultsRequest, a, b);
  }
}

/**
 * アセスメント設定の計算結果を確定するレスポンス
 *
 * @generated from message jcex.bff.report.v1.DetermineAssessmentSettingResultsResponse
 */
export class DetermineAssessmentSettingResultsResponse extends Message<DetermineAssessmentSettingResultsResponse> {
  constructor(data?: PartialMessage<DetermineAssessmentSettingResultsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.DetermineAssessmentSettingResultsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DetermineAssessmentSettingResultsResponse {
    return new DetermineAssessmentSettingResultsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DetermineAssessmentSettingResultsResponse {
    return new DetermineAssessmentSettingResultsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DetermineAssessmentSettingResultsResponse {
    return new DetermineAssessmentSettingResultsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DetermineAssessmentSettingResultsResponse | PlainMessage<DetermineAssessmentSettingResultsResponse> | undefined, b: DetermineAssessmentSettingResultsResponse | PlainMessage<DetermineAssessmentSettingResultsResponse> | undefined): boolean {
    return proto3.util.equals(DetermineAssessmentSettingResultsResponse, a, b);
  }
}

/**
 * 電気新聞への提出用TSVデータの取得リクエスト
 *
 * @generated from message jcex.bff.report.v1.GetDenkiShimbunSubmissionTsvRequest
 */
export class GetDenkiShimbunSubmissionTsvRequest extends Message<GetDenkiShimbunSubmissionTsvRequest> {
  /**
   * アセスメント設定ID
   *
   * @generated from field: string assessment_setting_id = 1;
   */
  assessmentSettingId = "";

  constructor(data?: PartialMessage<GetDenkiShimbunSubmissionTsvRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetDenkiShimbunSubmissionTsvRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_setting_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDenkiShimbunSubmissionTsvRequest {
    return new GetDenkiShimbunSubmissionTsvRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDenkiShimbunSubmissionTsvRequest {
    return new GetDenkiShimbunSubmissionTsvRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDenkiShimbunSubmissionTsvRequest {
    return new GetDenkiShimbunSubmissionTsvRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDenkiShimbunSubmissionTsvRequest | PlainMessage<GetDenkiShimbunSubmissionTsvRequest> | undefined, b: GetDenkiShimbunSubmissionTsvRequest | PlainMessage<GetDenkiShimbunSubmissionTsvRequest> | undefined): boolean {
    return proto3.util.equals(GetDenkiShimbunSubmissionTsvRequest, a, b);
  }
}

/**
 * 電気新聞への提出用TSVデータの取得レスポンス
 *
 * @generated from message jcex.bff.report.v1.GetDenkiShimbunSubmissionTsvResponse
 */
export class GetDenkiShimbunSubmissionTsvResponse extends Message<GetDenkiShimbunSubmissionTsvResponse> {
  /**
   * TSVデータ
   *
   * @generated from field: string tsv_data = 1;
   */
  tsvData = "";

  constructor(data?: PartialMessage<GetDenkiShimbunSubmissionTsvResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetDenkiShimbunSubmissionTsvResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tsv_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDenkiShimbunSubmissionTsvResponse {
    return new GetDenkiShimbunSubmissionTsvResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDenkiShimbunSubmissionTsvResponse {
    return new GetDenkiShimbunSubmissionTsvResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDenkiShimbunSubmissionTsvResponse {
    return new GetDenkiShimbunSubmissionTsvResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDenkiShimbunSubmissionTsvResponse | PlainMessage<GetDenkiShimbunSubmissionTsvResponse> | undefined, b: GetDenkiShimbunSubmissionTsvResponse | PlainMessage<GetDenkiShimbunSubmissionTsvResponse> | undefined): boolean {
    return proto3.util.equals(GetDenkiShimbunSubmissionTsvResponse, a, b);
  }
}

/**
 * グリーンインデックスの取得リクエスト
 *
 * @generated from message jcex.bff.report.v1.GetGreenIndexRequest
 */
export class GetGreenIndexRequest extends Message<GetGreenIndexRequest> {
  /**
   * フィルタ
   *
   * @generated from field: optional jcex.bff.report.v1.GetGreenIndexRequest.Filter filter = 1;
   */
  filter?: GetGreenIndexRequest_Filter;

  constructor(data?: PartialMessage<GetGreenIndexRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetGreenIndexRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: GetGreenIndexRequest_Filter, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGreenIndexRequest {
    return new GetGreenIndexRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGreenIndexRequest {
    return new GetGreenIndexRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGreenIndexRequest {
    return new GetGreenIndexRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetGreenIndexRequest | PlainMessage<GetGreenIndexRequest> | undefined, b: GetGreenIndexRequest | PlainMessage<GetGreenIndexRequest> | undefined): boolean {
    return proto3.util.equals(GetGreenIndexRequest, a, b);
  }
}

/**
 * @generated from message jcex.bff.report.v1.GetGreenIndexRequest.Filter
 */
export class GetGreenIndexRequest_Filter extends Message<GetGreenIndexRequest_Filter> {
  /**
   * 開始日
   *
   * @generated from field: google.protobuf.Timestamp start_date = 1;
   */
  startDate?: Timestamp;

  /**
   * 終了日
   *
   * @generated from field: google.protobuf.Timestamp end_date = 2;
   */
  endDate?: Timestamp;

  constructor(data?: PartialMessage<GetGreenIndexRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetGreenIndexRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_date", kind: "message", T: Timestamp },
    { no: 2, name: "end_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGreenIndexRequest_Filter {
    return new GetGreenIndexRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGreenIndexRequest_Filter {
    return new GetGreenIndexRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGreenIndexRequest_Filter {
    return new GetGreenIndexRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: GetGreenIndexRequest_Filter | PlainMessage<GetGreenIndexRequest_Filter> | undefined, b: GetGreenIndexRequest_Filter | PlainMessage<GetGreenIndexRequest_Filter> | undefined): boolean {
    return proto3.util.equals(GetGreenIndexRequest_Filter, a, b);
  }
}

/**
 * グリーンインデックスの取得レスポンス
 *
 * @generated from message jcex.bff.report.v1.GetGreenIndexResponse
 */
export class GetGreenIndexResponse extends Message<GetGreenIndexResponse> {
  /**
   * グリーンインデックス
   *
   * @generated from field: jcex.bff.report.v1.GreenIndex green_index = 1;
   */
  greenIndex?: GreenIndex;

  constructor(data?: PartialMessage<GetGreenIndexResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GetGreenIndexResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "green_index", kind: "message", T: GreenIndex },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGreenIndexResponse {
    return new GetGreenIndexResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGreenIndexResponse {
    return new GetGreenIndexResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGreenIndexResponse {
    return new GetGreenIndexResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetGreenIndexResponse | PlainMessage<GetGreenIndexResponse> | undefined, b: GetGreenIndexResponse | PlainMessage<GetGreenIndexResponse> | undefined): boolean {
    return proto3.util.equals(GetGreenIndexResponse, a, b);
  }
}

