// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file jcex/bff/common/v1/model.proto (package jcex.bff.common.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * ユーザー情報のモデル
 *
 * @generated from message jcex.bff.common.v1.UserInfo
 */
export class UserInfo extends Message<UserInfo> {
  /**
   * ユーザーID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 企業名
   *
   * @generated from field: string company_name = 2;
   */
  companyName = "";

  /**
   * 組織名
   *
   * @generated from field: string organization_name = 3;
   */
  organizationName = "";

  /**
   * ユーザー名
   *
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * メールアドレス
   *
   * @generated from field: string email = 5;
   */
  email = "";

  /**
   * プロフィール画像のURL
   *
   * @generated from field: string profile_image_url = 6;
   */
  profileImageUrl = "";

  /**
   * 組織アイコン画像
   *
   * @generated from field: bytes organization_image = 7;
   */
  organizationImage = new Uint8Array(0);

  constructor(data?: PartialMessage<UserInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.common.v1.UserInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "profile_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "organization_image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInfo {
    return new UserInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJsonString(jsonString, options);
  }

  static equals(a: UserInfo | PlainMessage<UserInfo> | undefined, b: UserInfo | PlainMessage<UserInfo> | undefined): boolean {
    return proto3.util.equals(UserInfo, a, b);
  }
}

