import * as React from 'react'

import { Box, Flex, Stack, forwardRef } from '@chakra-ui/react'

import { Spacing } from '@enechain/ecloud-designsystem'

type Props = {
  children: React.ReactNode
}

const FIXED_WIDTH = 960

const FixedContentLayout = forwardRef<Props, 'div'>(function FixedContentLayout(
  { children },
  ref,
) {
  const [windowWidth, setWindowWidth] = React.useState<number>(0)

  const updateWindowWidth = React.useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  React.useLayoutEffect(() => {
    addEventListener('resize', updateWindowWidth)

    return (): void => removeEventListener('resize', updateWindowWidth)
  }, [updateWindowWidth])

  return (
    <Flex ref={ref} justifyContent="center">
      <Box
        width="full"
        maxWidth={FIXED_WIDTH}
        paddingBottom={Spacing[16]}
        paddingX={windowWidth < FIXED_WIDTH ? Spacing[6] : Spacing[0]}
      >
        <Stack spacing={Spacing[4]}>{children}</Stack>
      </Box>
    </Flex>
  )
})

export default React.memo(FixedContentLayout)
