import * as React from 'react'

import { Flex } from '@chakra-ui/react'

import {
  BorderRadius,
  Divider,
  FlexTable,
  FlexTableHeader,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { EmptyTableBody, TableBodyWithLoading } from '@enechain/jcex-ui'

import { jCreditOrderHistoryColumnStyles } from '~/trader/pages/HistoryList/components/JCredit/OrderListTable/columnStyles'
import JCreditOrderHistoryListTableBodyRow from '~/trader/pages/HistoryList/components/JCredit/OrderTableRow/body'
import { JCreditOrderHistoryListTableHeaderRow } from '~/trader/pages/HistoryList/components/JCredit/OrderTableRow/header'
import { useJCreditOrderHistoryList } from '~/trader/pages/HistoryList/hooks/useJCreditOrderHistoryList'
import { FilterJCreditOrderHistories } from '~/trader/pages/HistoryList/types/jCreditFilter'

type Props = {
  filters: FilterJCreditOrderHistories
}

const JCreditOrderHistoryListTable: React.FC<Props> = ({ filters }) => {
  const { jCreditData, isLoading } = useJCreditOrderHistoryList({
    params: filters.state,
  })

  // memo(shunya): sticky 対応は Design System の I/F にいれる
  return (
    <Flex flexDirection="column" gap={Spacing[2]}>
      <FlexTable
        borderWidth={1}
        borderRadius={BorderRadius.radiiLg}
        columnStyles={jCreditOrderHistoryColumnStyles}
      >
        <FlexTableHeader style="multi-line">
          <JCreditOrderHistoryListTableHeaderRow />
        </FlexTableHeader>
        <TableBodyWithLoading isLoading={isLoading} style="multi-line">
          {jCreditData.length === 0 ? (
            <EmptyTableBody />
          ) : (
            jCreditData.map((data) => (
              <React.Fragment key={data.id}>
                <Divider />
                <JCreditOrderHistoryListTableBodyRow data={data} />
              </React.Fragment>
            ))
          )}
        </TableBodyWithLoading>
      </FlexTable>
    </Flex>
  )
}

export default React.memo(JCreditOrderHistoryListTable)
