// 画像をbase64に変換する関数
function imageToBase64(arr: Uint8Array): string {
  const encodedArr = Array.from(arr)
  const encodedStr = encodedArr
    .map((byte) => String.fromCharCode(byte))
    .join('')
  const base64 = window.btoa(encodedStr)
  return 'data:image/jpeg;base64,' + base64
}

export const imageUtils = {
  imageToBase64,
}
