import { Order } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/model_pb'
import {
  Position_Type,
  ProductType,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import {
  OrderInformationModel,
  convertOrderInformationModel,
} from '~/trader/pages/JCreditOrderDetail/models/orderInformation'
import { UpdateOrderSchema } from '~/trader/pages/JCreditOrderDetail/schemas/updateOrderSchema'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Params = {
  order: Maybe<Order>
  productType: Maybe<ProductType>
}

export type OrderModel = Pick<Order, 'id' | 'jCreditCertification'> & {
  orderInformation: OrderInformationModel
  formValue: UpdateOrderSchema
}

export function convertOrderModel({ order }: Params): Maybe<OrderModel> {
  if (order === undefined) {
    return undefined
  }

  return {
    id: order.id,
    orderInformation: convertOrderInformationModel(order),
    jCreditCertification: order.jCreditCertification,
    formValue: {
      positionType: order.position?.type ?? Position_Type.BID,
      deliveryFiscalYear:
        order.deliveryFiscalYear?.year ?? new Date().getFullYear(),
      creditCertifiedNumber: order.creditCertifiedNumber ?? undefined,
      jCreditCertificationId: order.jCreditCertification?.id ?? undefined,
      productId: order.product?.id ?? undefined,
      prefectureIds: order.prefectures.map(({ type }) => type),
      unitPrice: order.volumeAndUnitPrice?.unitPrice.replaceAll(',', '') ?? '',
      volume: order.volumeAndUnitPrice?.volume.replaceAll(',', '') ?? '',
    },
  }
}
