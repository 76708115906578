import { FeatureFlagSetting } from './types'

export const CARBON_INDEX = 'CARBON_INDEX' as const

export const defaultFlags: FeatureFlagSetting[] = [
  {
    name: CARBON_INDEX,
    description: '24/07 ~ 24/08',
    active: true,
  },
]
