import * as React from 'react'

import { useTranslation } from 'react-i18next'

import {
  BackgroundProps,
  Flex,
  Select,
  Stack,
  StyleProps,
  Text,
} from '@chakra-ui/react'

import {
  BorderRadius,
  SemanticColors,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import { FaEllipsisRegular } from '@enechain/ecloud-icons'

type BaseProps<T extends string | number> = {
  selectedValue: T | undefined
  selectable: Record<T, string>
  label?: string
  isRequired?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  backgroundColor?: BackgroundProps['backgroundColor']
  maxWidth?: StyleProps['maxWidth']
}

type Props<T extends string | number> =
  | (BaseProps<T> & {
      onChange: (value: T) => void
      isRequired: true
    })
  | (BaseProps<T> & {
      onChange: (value: T | undefined) => void
    })

export const SelectBox = <T extends string | number>({
  onChange,
  selectedValue,
  selectable,
  label,
  isRequired,
  isDisabled,
  isLoading,
  backgroundColor,
  maxWidth,
}: Props<T>): React.ReactElement => {
  const { t } = useTranslation('common')
  return (
    <Stack maxWidth={maxWidth}>
      {label != null && (
        <Flex alignItems="center" gap={Spacing[2]}>
          <Text {...Typography.textButton} height="auto" wordBreak="break-word">
            {label}
          </Text>
        </Flex>
      )}
      <Select
        borderColor={SemanticColors.Border.midEmphasis}
        borderRadius={BorderRadius.radiiMd}
        _hover={{
          background: SemanticColors.Surface.secondary,
        }}
        cursor="pointer"
        backgroundColor={backgroundColor}
        icon={isLoading === true ? <FaEllipsisRegular /> : undefined}
        isDisabled={isDisabled}
        onChange={(event): void => {
          if (
            isRequired !== true &&
            event.target.selectedOptions[0].value === ''
          ) {
            onChange(undefined)
            return
          }
          onChange(event.target.selectedOptions[0].value as T)
        }}
        value={selectedValue ?? ''}
      >
        {isRequired !== true && (
          <option value="">{t('label.unspecified')}</option>
        )}
        {(Object.keys(selectable) as T[]).map((key) => (
          <option key={key} value={key}>
            {selectable[key]}
          </option>
        ))}
      </Select>
    </Stack>
  )
}
