import * as React from 'react'
import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Collapse, Flex, Text, useDisclosure } from '@chakra-ui/react'

import {
  Input,
  Label,
  PrimitiveColors,
  SemanticColors,
  Spacing,
  Term,
  TertiaryButton,
} from '@enechain/ecloud-designsystem'
import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import {
  Position_Type,
  Prefecture,
  Prefecture_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { SelectBox } from '../../common'
import { SelectPrefectureModal } from '../SelectPrefectureModal'

type PropsBase = {
  productType: ProductType_Type
  values: Prefecture_Type[]
  options: Prefecture[]
  onChange: (value: Prefecture_Type[]) => void
  selectedPositionType?: Position_Type
  isDisabled?: boolean
  isRequired?: boolean
  error?: string
}

type Props =
  | (PropsBase & {
      productType: ProductType_Type.NFC
      selectedTrackingStatus: RenewableEnergyAndTrackingStatus_Type | undefined
    })
  | (PropsBase & {
      productType: ProductType_Type.J_CREDIT
      selectedTrackingStatus?: undefined
    })

export const PrefectureForm: React.FC<Props> = ({
  productType,
  values,
  options,
  onChange,
  selectedPositionType,
  isDisabled,
  isRequired = true,
  error,
  selectedTrackingStatus,
}) => {
  const disclosure = useDisclosure()
  const { t } = useTranslation(['common', 'domain', 'component'])
  const [selectedPrefectures, setSelectedPrefectures] = React.useState<
    number[]
  >([])

  const excludeUnspecifiedPrefectures = useMemo(() => {
    return options.filter(({ type }) => type !== Prefecture_Type.UNSPECIFIED)
  }, [options])

  const isTrackingStatus = useMemo(
    () =>
      selectedTrackingStatus ===
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING,
    [selectedTrackingStatus],
  )

  const displayValue = useMemo(() => {
    if (values.length === 0) {
      return t('common:form.label.not_select')
    }
    if (
      excludeUnspecifiedPrefectures.every((prefecture) =>
        values.includes(prefecture.type),
      )
    ) {
      return t('common:form.label.no_target')
    }
    return excludeUnspecifiedPrefectures
      .filter((prefecture) => values.includes(prefecture.type))
      .map((prefecture) => prefecture.displayName)
      .join(', ')
  }, [values, t, excludeUnspecifiedPrefectures])

  switch (selectedPositionType) {
    case Position_Type.BID:
      return (
        <Collapse
          animateOpacity
          in={isTrackingStatus || productType === ProductType_Type.J_CREDIT}
        >
          <Term>
            <SelectPrefectureModal
              disclosure={disclosure}
              onClick={(): void => onChange(selectedPrefectures)}
              onCloseComplete={(): void => setSelectedPrefectures([])}
              options={excludeUnspecifiedPrefectures}
              selectedValue={selectedPrefectures}
              setSelectedValue={setSelectedPrefectures}
            />
            <Label required={isRequired}>
              {t('domain:shared.prefecture.title')}
            </Label>
            <Flex gap={Spacing[1]}>
              <Input
                width="25rem"
                _disabled={
                  isDisabled !== true ? { cursor: 'normal' } : undefined
                }
                isDisabled
                value={displayValue}
              />
              <TertiaryButton
                isDisabled={isDisabled}
                onClick={(): void => {
                  disclosure.onOpen()
                  setSelectedPrefectures(values)
                }}
              >
                {t('component:features.prefecture_form.action')}
              </TertiaryButton>
            </Flex>
            <Text color={SemanticColors.Text.negativeMid}>{error}</Text>
          </Term>
        </Collapse>
      )
    case Position_Type.OFFER:
    default:
      return (
        <Collapse
          animateOpacity
          in={isTrackingStatus || productType === ProductType_Type.J_CREDIT}
        >
          <Term>
            <Label required={isRequired}>
              {t('domain:shared.prefecture.title')}
            </Label>
            <SelectBox<number>
              maxWidth="25rem"
              backgroundColor={PrimitiveColors.white['']}
              isDisabled={isDisabled}
              onChange={(value): void =>
                onChange(value !== undefined ? [Number(value)] : [])
              }
              selectable={Object.fromEntries(
                options.length > 0
                  ? options.map(({ type, displayName }) => [
                      Number(type),
                      displayName,
                    ])
                  : [],
              )}
              selectedValue={values[0]}
            />
            <Text color={SemanticColors.Text.negativeMid}>{error}</Text>
          </Term>
        </Collapse>
      )
  }
}
