import * as React from 'react'

import { Button, ButtonProps, forwardRef } from '@chakra-ui/react'

import { PrimitiveColors } from '@enechain/ecloud-designsystem'

import { useTheme } from '../../../constants'

/**
 * @memo
 * DS の DangerButton コンポーネント は outline 版がないので ラップする
 */
const DangerSecondaryButton: React.FC<ButtonProps> = forwardRef(
  ({ children, ...rest }: ButtonProps, ref): React.ReactElement => {
    const theme = useTheme()

    return (
      <Button
        ref={ref}
        {...rest}
        color={theme.semanticTokens.colors.Text.negativeMid}
        borderWidth="1px"
        borderStyle="solid"
        borderColor={theme.semanticTokens.colors.Border.negativeMid}
        _hover={{ backgroundColor: PrimitiveColors.redalpha[50] }}
        _active={{ backgroundColor: PrimitiveColors.redalpha[200] }}
        _focus={{
          boxShadow: PrimitiveColors.red[400],
        }}
        backgroundColor={theme.semanticTokens.colors.Surface.primary}
      >
        {children}
      </Button>
    )
  },
)

export default DangerSecondaryButton
