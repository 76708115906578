import { Transport } from '@connectrpc/connect'
import {
  callUnaryMethod,
  createConnectQueryKey,
} from '@connectrpc/connect-query'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunction, defer } from 'react-router-dom'

import {
  getDealHistoryFilter as getJCreditDealHistoryFilter,
  getDealHistoryList as getJCreditDealHistoryList,
  getOrderHistoryFilter as getJCreditOrderHistoryFilter,
  getOrderHistoryList as getJCreditOrderHistoryList,
} from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'
import {
  GetDealHistoryFilterResponse as GetJCreditDealHistoryFilterResponse,
  GetDealHistoryListResponse as GetJCreditDealHistoryListResponse,
  GetOrderHistoryListResponse as GetJCreditHistoryListResponse,
  GetOrderHistoryFilterResponse as GetJCreditOrderHistoryFilterResponse,
} from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import {
  getDealHistoryFilter as getNFCDealHistoryFilter,
  getDealHistoryList as getNFCDealHistoryList,
  getOrderHistoryFilter as getNFCOrderHistoryFilter,
  getOrderHistoryList as getNFCOrderHistoryList,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import {
  GetDealHistoryFilterResponse as GetNFCDealHistoryFilterResponse,
  GetDealHistoryListResponse as GetNFCDealHistoryListResponse,
  GetOrderHistoryListResponse as GetNFCHistoryListResponse,
  GetOrderHistoryFilterResponse as GetNFCOrderHistoryFilterResponse,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'

import {
  INITIAL_J_CREDIT_ORDER_LIST_FILTER,
  INITIAL_NFC_ORDER_LIST_FILTER,
} from '~/trader/pages/HistoryList/constants/filter'

export type HistoryListPageLoaderData = {
  nfc: {
    order: {
      filterData: GetNFCOrderHistoryFilterResponse
      listData: GetNFCHistoryListResponse
    }
    deal: {
      filterData: GetNFCDealHistoryFilterResponse
      listData: GetNFCDealHistoryListResponse
    }
  }
  jCredit: {
    order: {
      filterData: GetJCreditOrderHistoryFilterResponse
      listData: GetJCreditHistoryListResponse
    }
    deal: {
      filterData: GetJCreditDealHistoryFilterResponse
      listData: GetJCreditDealHistoryListResponse
    }
  }
}

export function historyListPageLoader(
  transport: Transport,
  queryClient: QueryClient,
): LoaderFunction {
  return async () => {
    const [
      filterNFCOrderData,
      filterNFCDealData,
      filterJCreditOrderData,
      filterJCreditDealData,
      nfcOrderHistoryListData,
      nfcDealHistoryListData,
      jCreditOrderHistoryListData,
      jCreditDealHistoryListData,
    ] = await Promise.all([
      // filterNFCOrderData
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(getNFCOrderHistoryFilter),
        queryFn: () =>
          callUnaryMethod(getNFCOrderHistoryFilter, {}, { transport }),
      }),
      // filterNFCDealData
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(getNFCDealHistoryFilter),
        queryFn: () =>
          callUnaryMethod(getNFCDealHistoryFilter, {}, { transport }),
      }),
      // filterJCreditOrderData
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(getJCreditOrderHistoryFilter),
        queryFn: () =>
          callUnaryMethod(getJCreditOrderHistoryFilter, {}, { transport }),
      }),
      // filterJCreditDealData
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(getJCreditDealHistoryFilter),
        queryFn: () =>
          callUnaryMethod(getJCreditDealHistoryFilter, {}, { transport }),
      }),
      // nfcOrderHistoryListData
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(
          getNFCOrderHistoryList,
          INITIAL_NFC_ORDER_LIST_FILTER,
        ),
        queryFn: () =>
          callUnaryMethod(
            getNFCOrderHistoryList,
            INITIAL_NFC_ORDER_LIST_FILTER,
            {
              transport,
            },
          ),
      }),
      // nfcDealHistoryListData
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(getNFCDealHistoryList, {}),
        queryFn: () =>
          callUnaryMethod(getNFCDealHistoryList, {}, { transport }),
      }),
      // jCreditOrderHistoryListData
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(
          getJCreditOrderHistoryList,
          INITIAL_J_CREDIT_ORDER_LIST_FILTER,
        ),
        queryFn: () =>
          callUnaryMethod(
            getJCreditOrderHistoryList,
            INITIAL_J_CREDIT_ORDER_LIST_FILTER,
            { transport },
          ),
      }),
      // jCreditDealHistoryListData
      queryClient.ensureQueryData({
        queryKey: createConnectQueryKey(getJCreditDealHistoryList, {}),
        queryFn: () =>
          callUnaryMethod(getJCreditDealHistoryList, {}, { transport }),
      }),
    ])

    return defer({
      nfc: {
        order: {
          filterData: filterNFCOrderData,
          listData: nfcOrderHistoryListData,
        },
        deal: {
          filterData: filterNFCDealData,
          listData: nfcDealHistoryListData,
        },
      },
      jCredit: {
        order: {
          filterData: filterJCreditOrderData,
          listData: jCreditOrderHistoryListData,
        },
        deal: {
          filterData: filterJCreditDealData,
          listData: jCreditDealHistoryListData,
        },
      },
    })
  }
}
