import * as React from 'react'

import {
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  CardBodyProps as ChakraCardCodyProps,
  CardProps as ChakraCardProps,
} from '@chakra-ui/react'

import { BorderRadius, SemanticColors } from '@enechain/ecloud-designsystem'

type Props = ChakraCardProps & {
  children: React.ReactNode
  bodyProps?: ChakraCardCodyProps
}

export const Card: React.FC<Props> = ({ children, bodyProps, ...rest }) => {
  return (
    <ChakraCard
      border="1px solid"
      borderColor={SemanticColors.Border.lowEmphasis}
      borderRadius={BorderRadius.radiiXl}
      backgroundColor="transparent"
      variant="outline"
      {...rest}
    >
      <ChakraCardBody width="full" {...bodyProps}>
        {children}
      </ChakraCardBody>
    </ChakraCard>
  )
}
