import { t } from 'i18next'
import { z } from 'zod'

import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

export const updateOrderSchema = z
  .object({
    orderId: z.string(),
    positionType: z.number(),
    renewableEnergyAndTrackingStatusType: z.number().refine((v) => v !== 0, {
      params: { i18n: 'validation:errors.custom.required_select' },
    }),
    prefectureIds: z.array(z.number()),
    generatorIds: z.array(z.number()),
    deliveryFiscalYear: z.number({
      required_error: t('validation:errors.custom.required_select'),
    }),
    unitPrice: z
      .string()
      .refine((v) => !isNaN(Number(v)) && v?.length > 0 && Number(v) > 0, {
        params: { i18n: 'validation:errors.custom.positive_value' },
      })
      .refine(
        (v) => {
          const decimalRegex = /^\d+(\.\d{1,2})?$/
          return !isNaN(Number(v)) && v?.length > 0 && decimalRegex.test(v)
        },
        {
          params: {
            i18n: {
              key: 'validation:errors.custom.too_small',
              values: { value: `0.01 ${t('common:unit.yen_per_kwh')}` },
            },
          },
        },
      ),
    volume: z
      .string()
      .refine(
        (v) =>
          !isNaN(Number(v)) && Number(v) > 0 && Number.isInteger(Number(v)),
        {
          params: { i18n: 'validation:errors.custom.positive_integer' },
        },
      ),
  })
  .refine(
    (v) => {
      return (
        v.renewableEnergyAndTrackingStatusType !==
          RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING ||
        v.prefectureIds.length > 0
      )
    },
    {
      params: { i18n: 'validation:errors.custom.required_select' },
      path: ['prefectureIds'],
    },
  )
  .refine(
    (v) => {
      return (
        v.renewableEnergyAndTrackingStatusType !==
          RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING ||
        v.generatorIds.length > 0
      )
    },
    {
      params: { i18n: 'validation:errors.custom.required_select' },
      path: ['generatorIds'],
    },
  )

export type UpdateOrderSchema = z.infer<typeof updateOrderSchema>
