// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file jcex/bff/jcredit/model/v1/model.proto (package jcex.bff.jcredit.model.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Prefecture } from "../../../shared/model/v1/model_pb.js";

/**
 * 商品
 *
 * @generated from message jcex.bff.jcredit.model.v1.Product
 */
export class Product extends Message<Product> {
  /**
   * ID
   *
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * 名前
   *
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<Product>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.model.v1.Product";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Product {
    return new Product().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Product {
    return new Product().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Product {
    return new Product().fromJsonString(jsonString, options);
  }

  static equals(a: Product | PlainMessage<Product> | undefined, b: Product | PlainMessage<Product> | undefined): boolean {
    return proto3.util.equals(Product, a, b);
  }
}

/**
 * @generated from message jcex.bff.jcredit.model.v1.JCreditCertification
 */
export class JCreditCertification extends Message<JCreditCertification> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * プロジェクト番号
   *
   * @generated from field: string project_number = 2;
   */
  projectNumber = "";

  /**
   * プロジェクト概要
   *
   * @generated from field: string project_abstract = 3;
   */
  projectAbstract = "";

  /**
   * 都道府県(プロジェクト実施地域)
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture prefectures = 4;
   */
  prefectures: Prefecture[] = [];

  /**
   * 適用方法論
   *
   * @generated from field: string application_methodology = 5;
   */
  applicationMethodology = "";

  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 6;
   */
  product?: Product;

  constructor(data?: PartialMessage<JCreditCertification>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.model.v1.JCreditCertification";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_abstract", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "prefectures", kind: "message", T: Prefecture, repeated: true },
    { no: 5, name: "application_methodology", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "product", kind: "message", T: Product },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JCreditCertification {
    return new JCreditCertification().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JCreditCertification {
    return new JCreditCertification().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JCreditCertification {
    return new JCreditCertification().fromJsonString(jsonString, options);
  }

  static equals(a: JCreditCertification | PlainMessage<JCreditCertification> | undefined, b: JCreditCertification | PlainMessage<JCreditCertification> | undefined): boolean {
    return proto3.util.equals(JCreditCertification, a, b);
  }
}

/**
 * ファイルアップロードステータスのモデル
 *
 * @generated from message jcex.bff.jcredit.model.v1.FileUploadStatus
 */
export class FileUploadStatus extends Message<FileUploadStatus> {
  /**
   * ファイルアップロードステータス種別
   *
   * @generated from field: jcex.bff.jcredit.model.v1.FileUploadStatus.Type type = 1;
   */
  type = FileUploadStatus_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * 失敗理由
   *
   * @generated from field: string failed_reason = 3;
   */
  failedReason = "";

  constructor(data?: PartialMessage<FileUploadStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.model.v1.FileUploadStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(FileUploadStatus_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "failed_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileUploadStatus {
    return new FileUploadStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileUploadStatus {
    return new FileUploadStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileUploadStatus {
    return new FileUploadStatus().fromJsonString(jsonString, options);
  }

  static equals(a: FileUploadStatus | PlainMessage<FileUploadStatus> | undefined, b: FileUploadStatus | PlainMessage<FileUploadStatus> | undefined): boolean {
    return proto3.util.equals(FileUploadStatus, a, b);
  }
}

/**
 * @generated from enum jcex.bff.jcredit.model.v1.FileUploadStatus.Type
 */
export enum FileUploadStatus_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * アップロードURLを発行済み
   *
   * @generated from enum value: TYPE_UPLOAD_URL_ISSUED = 1;
   */
  UPLOAD_URL_ISSUED = 1,

  /**
   * 処理中
   *
   * @generated from enum value: TYPE_PROCESSING = 2;
   */
  PROCESSING = 2,

  /**
   * アップロード処理が成功
   *
   * @generated from enum value: TYPE_SUCCEEDED = 3;
   */
  SUCCEEDED = 3,

  /**
   * アップロード処理に失敗
   *
   * @generated from enum value: TYPE_FAILED = 4;
   */
  FAILED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(FileUploadStatus_Type)
proto3.util.setEnumType(FileUploadStatus_Type, "jcex.bff.jcredit.model.v1.FileUploadStatus.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_UPLOAD_URL_ISSUED" },
  { no: 2, name: "TYPE_PROCESSING" },
  { no: 3, name: "TYPE_SUCCEEDED" },
  { no: 4, name: "TYPE_FAILED" },
]);

/**
 * ファイルダウンロードステータスのモデル
 *
 * @generated from message jcex.bff.jcredit.model.v1.FileDownloadStatus
 */
export class FileDownloadStatus extends Message<FileDownloadStatus> {
  /**
   * 種別
   *
   * @generated from field: jcex.bff.jcredit.model.v1.FileDownloadStatus.Type type = 1;
   */
  type = FileDownloadStatus_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * 失敗理由
   *
   * @generated from field: string failed_reason = 3;
   */
  failedReason = "";

  constructor(data?: PartialMessage<FileDownloadStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.jcredit.model.v1.FileDownloadStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(FileDownloadStatus_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "failed_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileDownloadStatus {
    return new FileDownloadStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileDownloadStatus {
    return new FileDownloadStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileDownloadStatus {
    return new FileDownloadStatus().fromJsonString(jsonString, options);
  }

  static equals(a: FileDownloadStatus | PlainMessage<FileDownloadStatus> | undefined, b: FileDownloadStatus | PlainMessage<FileDownloadStatus> | undefined): boolean {
    return proto3.util.equals(FileDownloadStatus, a, b);
  }
}

/**
 * @generated from enum jcex.bff.jcredit.model.v1.FileDownloadStatus.Type
 */
export enum FileDownloadStatus_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * リクエスト受付済
   *
   * @generated from enum value: TYPE_REQUEST_ACCEPTED = 1;
   */
  REQUEST_ACCEPTED = 1,

  /**
   * 処理中
   *
   * @generated from enum value: TYPE_PROCESSING = 2;
   */
  PROCESSING = 2,

  /**
   * ダウンロードファイルを作成済み
   *
   * @generated from enum value: TYPE_DOWNLOAD_FILE_CREATED = 3;
   */
  DOWNLOAD_FILE_CREATED = 3,

  /**
   * ダウンロードURLを発行済
   *
   * @generated from enum value: TYPE_DOWNLOAD_URL_ISSUED = 4;
   */
  DOWNLOAD_URL_ISSUED = 4,

  /**
   * 作成処理に失敗
   *
   * @generated from enum value: TYPE_FAILED = 5;
   */
  FAILED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(FileDownloadStatus_Type)
proto3.util.setEnumType(FileDownloadStatus_Type, "jcex.bff.jcredit.model.v1.FileDownloadStatus.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_REQUEST_ACCEPTED" },
  { no: 2, name: "TYPE_PROCESSING" },
  { no: 3, name: "TYPE_DOWNLOAD_FILE_CREATED" },
  { no: 4, name: "TYPE_DOWNLOAD_URL_ISSUED" },
  { no: 5, name: "TYPE_FAILED" },
]);

