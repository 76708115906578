import * as React from 'react'
import { Suspense } from 'react'

import { Await, useLoaderData } from 'react-router-dom'

import { Loading } from '@enechain/ecloud-designsystem'

import JCreditDealFilterInputs from '~/trader/pages/HistoryList/components/JCredit/DealFilterInputs'
import JCreditDealHistoryListTable from '~/trader/pages/HistoryList/components/JCredit/DealListTable'
import JCreditOrderFilterInputs from '~/trader/pages/HistoryList/components/JCredit/OrderFilterInputs'
import JCreditOrderHistoryListTable from '~/trader/pages/HistoryList/components/JCredit/OrderListTable'
import NonFITDealFilterInputs from '~/trader/pages/HistoryList/components/NonFITNFC/DealFilterInputs'
import NonFITDealHistoryListTable from '~/trader/pages/HistoryList/components/NonFITNFC/DealListTable'
import NonFITOrderFilterInputs from '~/trader/pages/HistoryList/components/NonFITNFC/OrderFilterInputs'
import NonFITOrderHistoryListTable from '~/trader/pages/HistoryList/components/NonFITNFC/OrderListTable'
import { useDealHistoryFilter } from '~/trader/pages/HistoryList/hooks/useDealHistoryFilter'
import { HistoryType } from '~/trader/pages/HistoryList/hooks/useHistoryType'
import { useOrderHistoryFilter } from '~/trader/pages/HistoryList/hooks/useOrderHistoryFilter'
import { HistoryListPageLoaderData } from '~/trader/pages/HistoryList/loader'

type Props = {
  selectedType: HistoryType
}

const TabContainer: React.FC<Props> = ({ selectedType }) => {
  const { nfc: nfcLoaderData, jCredit: jCreditLoaderData } =
    useLoaderData() as HistoryListPageLoaderData
  const {
    nfc: nfcOrder,
    jCredit: jCreditOrder,
    clearFilter: clearOrderFilter,
  } = useOrderHistoryFilter()
  const {
    nfc: nfcDeal,
    jCredit: jCreditDeal,
    clearFilter: clearDealFilter,
  } = useDealHistoryFilter()

  switch (selectedType) {
    case HistoryType.NFCOrder:
      return (
        <>
          <NonFITOrderFilterInputs
            clearFilter={clearOrderFilter}
            filters={nfcOrder.filters}
            options={nfcOrder.options}
          />
          <Suspense fallback={<Loading />}>
            <Await resolve={nfcLoaderData.order.listData}>
              {(): React.ReactElement => (
                <NonFITOrderHistoryListTable filters={nfcOrder.filters} />
              )}
            </Await>
          </Suspense>
        </>
      )
    case HistoryType.NFCDeal:
      return (
        <>
          <NonFITDealFilterInputs
            clearFilter={clearDealFilter}
            filters={nfcDeal.filters}
            options={nfcDeal.options}
          />
          <Suspense fallback={<Loading />}>
            <Await resolve={nfcLoaderData.deal.listData}>
              {(): React.ReactElement => (
                <NonFITDealHistoryListTable filters={nfcDeal.filters} />
              )}
            </Await>
          </Suspense>
        </>
      )
    case HistoryType.JCreditOrder:
      return (
        <>
          <JCreditOrderFilterInputs
            clearFilter={clearOrderFilter}
            filters={jCreditOrder.filters}
            options={jCreditOrder.options}
          />
          <Suspense fallback={<Loading />}>
            <Await resolve={jCreditLoaderData.order.listData}>
              {(): React.ReactElement => (
                <JCreditOrderHistoryListTable filters={jCreditOrder.filters} />
              )}
            </Await>
          </Suspense>
        </>
      )
    case HistoryType.JCreditDeal:
      return (
        <>
          <JCreditDealFilterInputs
            clearFilter={clearDealFilter}
            filters={jCreditDeal.filters}
            options={jCreditDeal.options}
          />
          <Suspense fallback={<Loading />}>
            <Await resolve={jCreditLoaderData.deal.listData}>
              {(): React.ReactElement => (
                <JCreditDealHistoryListTable filters={jCreditDeal.filters} />
              )}
            </Await>
          </Suspense>
        </>
      )
  }
}

export default TabContainer
