import { ReactNode } from 'react'

import { User } from '@auth0/auth0-react'

import { isActiveFeature } from './helper'

type Props = {
  featureName: string
  user: User | undefined
  currentFeature: ReactNode
  newFeature: ReactNode
}

export const FeatureFlagContainer = ({
  featureName,
  user,
  currentFeature,
  newFeature,
}: Props): ReactNode => {
  return isActiveFeature({ featureName, user }) ? newFeature : currentFeature
}
