import { Big } from 'big.js'

import { VolumeAndUnitPrice } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { stringUtils } from '@enechain/jcex-utils'

import { VOLUME_UNIT_TYPES, VolumeUnit } from '../../hooks'

export type VolumeAndUnitPriceModel = {
  unitPrice: string
  volume: string
}

const DECIMAL = 2
const ONE_DAY_HOURS = 24
const ONE_YEAR_DAYS = 365

export function convertKWHToHundredGWH(volume: string): string {
  if (stringUtils.isEmpty(volume)) {
    return ''
  }
  return new Big(volume.replace(/,/g, ''))
    .div(100 * 1000 * 1000)
    .toFixed(DECIMAL, 1)
    .toString()
}

export function convertKWHToMW(volume: string): string {
  if (stringUtils.isEmpty(volume)) {
    return ''
  }
  // 1MW = 1 * 1/1000 * 1/24 * 1/365
  // https://www.notion.so/enechain/FIT-cda72a926caa431d82e7797e08d38eda?pvs=4#517bd5c086394fd698a4d0b72dc04ccf
  return new Big(volume.replace(/,/g, ''))
    .div(1000 * ONE_DAY_HOURS * ONE_YEAR_DAYS)
    .toFixed(DECIMAL, 1)
    .toString()
}

function getOrderVolumeWithTBD(
  value: VolumeAndUnitPrice | undefined,
  unit?: VolumeUnit,
): string {
  if (value === undefined) {
    return ''
  }

  const { unitPrice, volume } = value

  // 価格が存在する場合、注文が存在することに帰着するので、TBDを返す
  // https://www.notion.so/enechain/best-bid-offer-TBD-8ae5d64a0f434d198afbda6ea3ea1c55?pvs=4
  if (stringUtils.isEmpty(volume)) {
    return stringUtils.isNotEmpty(unitPrice) ? 'TBD' : ''
  }

  switch (unit) {
    case VOLUME_UNIT_TYPES.HUNDRED_MILLION_KWH:
      return convertKWHToHundredGWH(volume)
    case VOLUME_UNIT_TYPES.MW:
      return convertKWHToMW(volume)
    default:
      return volume
  }
}

export function convertVolumeAndUnitPriceModel(
  value: VolumeAndUnitPrice | undefined,
  unit?: VolumeUnit,
): VolumeAndUnitPriceModel {
  return {
    unitPrice: value?.unitPrice ?? '',
    volume: getOrderVolumeWithTBD(value, unit),
  }
}
