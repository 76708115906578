import { useQuery } from '@connectrpc/connect-query'
import { keepPreviousData } from '@tanstack/react-query'

import { getOrderHistoryList } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import { GetOrderHistoryListRequest } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'
import { useVolumeUnitQueries } from '@enechain/jcex-ui'

import {
  OrderModelForNFCOrderHistory,
  convertOrderModelListForNFCOrderHistoryModel,
} from '~/trader/pages/HistoryList/models/NonFITNFC/orderHistory'

type Params = {
  params: GetOrderHistoryListRequest
}

type Result = {
  nfcData: OrderModelForNFCOrderHistory[]
  isLoading: boolean
}

export function useNFCOrderHistoryList({ params }: Params): Result {
  const selectedUnit = useVolumeUnitQueries()
  const { data, isPlaceholderData } = useQuery(getOrderHistoryList, params, {
    placeholderData: keepPreviousData,
  })

  const nfcData = convertOrderModelListForNFCOrderHistoryModel(
    data?.orders ?? [],
    selectedUnit,
  )

  return {
    nfcData,
    isLoading: isPlaceholderData,
  }
}
