import { VolumeAndUnitPrice } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { stringUtils } from '@enechain/jcex-utils'

import { OrderModelForJCreditOrderBook } from '~/trader/pages/OrderBookList/models/JCredit/order'
import { JCreditOrderBookModel } from '~/trader/pages/OrderBookList/models/JCredit/orderBook'

type TableRowValue = {
  bidVolume: string
  bidUnitPrice: string
  offerUnitPrice: string
  offerVolume: string
  lastUnitPrice: string
}

function getBestVolumeWithTBD(volumeAndUnitPrice?: VolumeAndUnitPrice): string {
  if (volumeAndUnitPrice === undefined) {
    return '-'
  }

  if (
    stringUtils.isNotEmpty(volumeAndUnitPrice.unitPrice) &&
    stringUtils.isEmpty(volumeAndUnitPrice.volume)
  ) {
    return 'TBD'
  }

  return volumeAndUnitPrice.volume
}

export function getJCreditOrderBookTableRowValue({
  orderBook,
  isExpanded,
  targetOrder,
}: {
  orderBook: JCreditOrderBookModel
  isExpanded: boolean
  targetOrder: OrderModelForJCreditOrderBook | undefined
}): TableRowValue {
  // 縮小時
  if (!isExpanded) {
    return {
      bidVolume: getBestVolumeWithTBD(orderBook.bestBid),
      bidUnitPrice: orderBook.bestBid?.unitPrice ?? '-',
      offerUnitPrice: orderBook.bestOffer?.unitPrice ?? '-',
      offerVolume: getBestVolumeWithTBD(orderBook.bestOffer),
      lastUnitPrice: orderBook.lastUnitPrice ?? '',
    }
  }

  // Expanded && last 表示
  if (targetOrder === undefined) {
    return {
      bidVolume: '',
      bidUnitPrice: '',
      offerUnitPrice: '',
      offerVolume: '',
      lastUnitPrice: orderBook.lastUnitPrice ?? '',
    }
  }

  // Expanded && order[0] 表示
  return {
    bidVolume: targetOrder.bid?.volume ?? '-',
    bidUnitPrice: targetOrder.bid?.unitPrice ?? '-',
    offerUnitPrice: targetOrder.offer?.unitPrice ?? '-',
    offerVolume: targetOrder.offer?.volume ?? '-',
    lastUnitPrice: '',
  }
}
