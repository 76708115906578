import * as React from 'react'

import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Flex,
} from '@chakra-ui/react'

import {
  BorderRadius,
  Divider,
  FlexTable,
  FlexTableBody,
  FlexTableHeader,
  SemanticColors,
  Spacing,
  useAccordion,
} from '@enechain/ecloud-designsystem'
import {
  AccordionAllButton,
  EmptyTableBody,
  TableBodyWithLoading,
} from '@enechain/jcex-ui'

import { jCreditColumnStyles } from '~/trader/pages/OrderBookList/components/JCredit/ListTable/columnStyles'
import JCreditOrderBookListTableBodyRow from '~/trader/pages/OrderBookList/components/JCredit/TableRow/body'
import JCreditOrderBookListTableBodyRowWithAccordion from '~/trader/pages/OrderBookList/components/JCredit/TableRow/bodyWithAccordion'
import { JCreditOrderBookListTableHeaderRow } from '~/trader/pages/OrderBookList/components/JCredit/TableRow/header'
import { useJCreditOrderBookList } from '~/trader/pages/OrderBookList/hooks/useJCreditOrderBookList'
import {
  FilterJCreditOrderBooks,
  GrayOutFilterJCreditOrderBooks,
} from '~/trader/pages/OrderBookList/types/jCreditFilter'

type Props = {
  filters: FilterJCreditOrderBooks
  grayOutFilters: GrayOutFilterJCreditOrderBooks
}

const JCreditOrderBookListTable: React.FC<Props> = ({
  filters,
  grayOutFilters,
}) => {
  const { jCreditData, isLoading } = useJCreditOrderBookList({
    filters: filters.state,
    grayOutFilters: grayOutFilters.state,
  })

  const {
    onCollapseAll,
    onExpandAll,
    index: expandedIndexes,
    onChange,
    isAllCollapsed,
  } = useAccordion(jCreditData)

  // memo(shunya): sticky 対応は Design System の I/F にいれる
  return (
    <Flex flexDirection="column" gap={Spacing[2]}>
      <Flex alignItems="center" justifyContent="end">
        <AccordionAllButton
          isAllCollapsed={isAllCollapsed}
          onClickCollapseAll={onCollapseAll}
          onClickExpandAll={onExpandAll}
        />
      </Flex>
      <FlexTable
        borderWidth={1}
        borderRadius={BorderRadius.radiiLg}
        columnStyles={jCreditColumnStyles}
      >
        <FlexTableHeader style="multi-line">
          <JCreditOrderBookListTableHeaderRow />
        </FlexTableHeader>
        <TableBodyWithLoading isLoading={isLoading} style="multi-line">
          {jCreditData.length === 0 ? (
            <EmptyTableBody />
          ) : (
            <Accordion
              borderColor={SemanticColors.Border.midEmphasis}
              allowMultiple
              index={expandedIndexes}
              onChange={onChange}
            >
              {jCreditData.map((item) => (
                <AccordionItem
                  key={`${item.deliveryFiscalYear.year ?? 0}-${
                    item.product.id ?? 0
                  }`}
                  _last={{ borderBottomWidth: 0 }}
                >
                  {({ isExpanded }): React.ReactElement => (
                    <>
                      <Divider />
                      <JCreditOrderBookListTableBodyRowWithAccordion
                        data={item}
                        isExpanded={isExpanded}
                        // 0 の FadeOut は isExpanded = true ならそのまま [0] で check, false なら items isFadeOut を all check する
                        isGrayOutEnabled={
                          isExpanded
                            ? grayOutFilters.state.isGrayOutEnabled
                            : grayOutFilters.state.isGrayOutEnabled &&
                              item.isGrayOutAllItem
                        }
                      />
                      <AccordionPanel padding={Spacing[0]}>
                        <FlexTableBody style="multi-line">
                          {item.orders.length > 0 &&
                            item.orders
                              .slice(item.lastUnitPrice !== undefined ? 0 : 1)
                              .map((order, orderIndex) => (
                                <JCreditOrderBookListTableBodyRow
                                  key={order.id}
                                  data={item}
                                  index={
                                    orderIndex +
                                    (item.lastUnitPrice !== undefined ? 0 : 1)
                                  }
                                  isGrayOutEnabled={
                                    grayOutFilters.state.isGrayOutEnabled
                                  }
                                />
                              ))}
                        </FlexTableBody>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </TableBodyWithLoading>
      </FlexTable>
    </Flex>
  )
}

export default React.memo(JCreditOrderBookListTable)
