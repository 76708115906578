import * as React from 'react'
import { useMemo } from 'react'

import { useNavigate } from 'react-router-dom'

import { Flex, Stack, Text } from '@chakra-ui/react'

import {
  AccordionIconButton,
  FlexTableCell,
  FlexTableRow,
  Spacing,
  Tooltip,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'
import { FaCalendarRegular } from '@enechain/ecloud-icons'

import { useSetDuplicateJCreditOrder } from '~/trader/globalStates/duplicateOrderState'
import { DuplicateOrderButton } from '~/trader/pages/OrderBookList/components/DuplicateOrderButton'
import { JCreditOrderListColumnKey } from '~/trader/pages/OrderBookList/components/JCredit/ListTable/columnStyles'
import { JCreditOrderBookModel } from '~/trader/pages/OrderBookList/models/JCredit/orderBook'
import { PATH_CREATE_ORDER } from '~/trader/routes/routePaths'

import { getJCreditOrderBookTableRowValue } from './helper'

type Props = {
  data: JCreditOrderBookModel
  isExpanded: boolean
  isGrayOutEnabled: boolean
}

const JCreditOrderBookListTableBodyRowWithAccordion: React.FC<Props> = ({
  data,
  isExpanded,
  isGrayOutEnabled,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const targetOrder =
    data.lastUnitPrice === undefined ? data.orders[0] : undefined

  const isDisabled = useMemo(
    () =>
      isGrayOutEnabled &&
      (targetOrder?.isGrayOutTarget === true || targetOrder === undefined),
    [isGrayOutEnabled, targetOrder],
  )

  const setDuplicateOrder = useSetDuplicateJCreditOrder({
    value: data,
    index: 0,
    isRenderValue: targetOrder !== undefined && isExpanded,
  })

  const getRowValue = getJCreditOrderBookTableRowValue({
    orderBook: data,
    isExpanded,
    targetOrder,
  })

  const onClickDuplicate = (): void => {
    setDuplicateOrder()
    navigate(PATH_CREATE_ORDER.path)
  }

  return (
    <Stack
      color={isDisabled ? theme.semanticTokens.colors.Text.disabled : ''}
      backgroundColor={
        isDisabled ? theme.semanticTokens.colors.Surface.secondary : ''
      }
    >
      <FlexTableRow>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="accordion">
          <Flex marginStart="-8px">
            <AccordionIconButton />
          </Flex>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="deliveryFiscalYear">
          <Text
            color={
              isDisabled
                ? 'inherit'
                : theme.semanticTokens.colors.Text.highEmphasis
            }
            {...Typography.textMd}
          >
            {data.deliveryFiscalYear.displayName}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="productName">
          <Text
            color={
              isDisabled
                ? 'inherit'
                : theme.semanticTokens.colors.Text.highEmphasis
            }
            {...Typography.textMd}
          >
            {data.product.name}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="bidVolume">
          <Text
            color={
              isDisabled ? 'inherit' : theme.semanticTokens.colors.Text.bid
            }
            {...Typography.textMd}
          >
            {getRowValue.bidVolume}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey>
          backgroundColor={theme.semanticTokens.colors.Surface.bid}
          columnKey="bidUnitPrice"
        >
          <Text
            color={
              isDisabled ? 'inherit' : theme.semanticTokens.colors.Text.bid
            }
            {...Typography.textMd}
            // https://www.figma.com/file/8zv5HckuxXZyTu1FHNDoJL?node-id=4463:84672&mode=design#644471460
            fontWeight={
              isExpanded &&
              targetOrder?.bid?.unitPrice === data.bestBid?.unitPrice
                ? 'bold'
                : 'normal'
            }
          >
            {getRowValue.bidUnitPrice}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey>
          backgroundColor={theme.semanticTokens.colors.Surface.offer}
          columnKey="offerUnitPrice"
        >
          <Text
            color={
              isDisabled ? 'inherit' : theme.semanticTokens.colors.Text.offer
            }
            {...Typography.textMd}
            fontWeight={
              isExpanded &&
              targetOrder?.offer?.unitPrice === data.bestOffer?.unitPrice
                ? 'bold'
                : 'normal'
            }
          >
            {getRowValue.offerUnitPrice}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="offerVolume">
          <Text
            color={
              isDisabled ? 'inherit' : theme.semanticTokens.colors.Text.offer
            }
            {...Typography.textMd}
          >
            {getRowValue.offerVolume}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="lastUnitPrice">
          <Flex alignItems="center" gap={Spacing[1]} overflow="hidden">
            <Text
              color={
                isDisabled
                  ? 'inherit'
                  : theme.semanticTokens.colors.Text.highEmphasis
              }
              {...Typography.textMd}
            >
              {getRowValue.lastUnitPrice}
            </Text>
            {data.lastDealtAt !== undefined && (
              <Tooltip label={data.lastDealtAt}>
                <FaCalendarRegular
                  width="24px"
                  height="24px"
                  fillOpacity={0.25}
                />
              </Tooltip>
            )}
          </Flex>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="copyOrderAction">
          <DuplicateOrderButton
            isDisabled={isDisabled}
            onClick={onClickDuplicate}
          />
        </FlexTableCell>
      </FlexTableRow>
    </Stack>
  )
}

export default React.memo(JCreditOrderBookListTableBodyRowWithAccordion)
