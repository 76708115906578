import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Grid, Stack } from '@chakra-ui/react'

import { ColumnContent, Spacing } from '@enechain/ecloud-designsystem'
import { CardLayout, StatusBadge } from '@enechain/jcex-ui'

import { useOrderDetail } from '~/trader/pages/JCreditOrderDetail/hooks/useOrderDetail'

const OrderInformation: React.FC = () => {
  const { t } = useTranslation(['domain', 'trader'])
  const { data } = useOrderDetail()

  const orderInformation = data?.orderInformation

  if (orderInformation === undefined) {
    return null
  }

  return (
    <CardLayout title={t('trader:shared.history')}>
      <Stack spacing={Spacing[6]}>
        <Grid
          gridAutoRows="minmax(auto, 1fr)"
          gridTemplateColumns="repeat(5, 1fr)"
          width="full"
        >
          <ColumnContent label={t('domain:model.order.column.assigned_trader')}>
            {orderInformation.assignedTraderName}
          </ColumnContent>
          <ColumnContent label={t('domain:shared.updater')}>
            {orderInformation.updaterName}
          </ColumnContent>
          <ColumnContent label={t('domain:shared.created_at')}>
            {orderInformation.createdAt}
          </ColumnContent>
          <ColumnContent label={t('domain:shared.updated_at')}>
            {orderInformation.lastUpdatedAt}
          </ColumnContent>
          <ColumnContent label={t('domain:shared.status')}>
            <Box width="2rem">
              <StatusBadge value={orderInformation.orderStatus} />
            </Box>
          </ColumnContent>
        </Grid>
      </Stack>
    </CardLayout>
  )
}

export default React.memo(OrderInformation)
