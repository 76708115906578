// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=ts"
// @generated from file jcex/bff/jcredit/trader/v1/service.proto (package jcex.bff.jcredit.trader.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, MethodKind } from "@bufbuild/protobuf";
import { CancelOrderRequest, CreateOrderRequest, GetCreateOrderOptionResponse, GetDealHistoryFilterResponse, GetDealHistoryListRequest, GetDealHistoryListResponse, GetDealOrderRequest, GetDealOrderResponse, GetOrderBookFilterResponse, GetOrderBookListRequest, GetOrderBookListResponse, GetOrderDetailOptionResponse, GetOrderHistoryFilterResponse, GetOrderHistoryListRequest, GetOrderHistoryListResponse, GetOrderRequest, GetOrderResponse, ListJCreditCertificationsRequest, ListJCreditCertificationsResponse, UpdateOrderRequest } from "./service_pb.js";

/**
 * 板一覧フィルターの取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetOrderBookFilter
 */
export const getOrderBookFilter = {
  localName: "getOrderBookFilter",
  name: "GetOrderBookFilter",
  kind: MethodKind.Unary,
  I: Empty,
  O: GetOrderBookFilterResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 板一覧の取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetOrderBookList
 */
export const getOrderBookList = {
  localName: "getOrderBookList",
  name: "GetOrderBookList",
  kind: MethodKind.Unary,
  I: GetOrderBookListRequest,
  O: GetOrderBookListResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 新規注文作成画面オプションの取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetCreateOrderOption
 */
export const getCreateOrderOption = {
  localName: "getCreateOrderOption",
  name: "GetCreateOrderOption",
  kind: MethodKind.Unary,
  I: Empty,
  O: GetCreateOrderOptionResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 新規注文作成
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.CreateOrder
 */
export const createOrder = {
  localName: "createOrder",
  name: "CreateOrder",
  kind: MethodKind.Unary,
  I: CreateOrderRequest,
  O: Empty,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 注文履歴一覧フィルターの取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetOrderHistoryFilter
 */
export const getOrderHistoryFilter = {
  localName: "getOrderHistoryFilter",
  name: "GetOrderHistoryFilter",
  kind: MethodKind.Unary,
  I: Empty,
  O: GetOrderHistoryFilterResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 注文履歴一覧の取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetOrderHistoryList
 */
export const getOrderHistoryList = {
  localName: "getOrderHistoryList",
  name: "GetOrderHistoryList",
  kind: MethodKind.Unary,
  I: GetOrderHistoryListRequest,
  O: GetOrderHistoryListResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 約定一覧フィルターの取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetDealHistoryFilter
 */
export const getDealHistoryFilter = {
  localName: "getDealHistoryFilter",
  name: "GetDealHistoryFilter",
  kind: MethodKind.Unary,
  I: Empty,
  O: GetDealHistoryFilterResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 約定一覧の取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetDealHistoryList
 */
export const getDealHistoryList = {
  localName: "getDealHistoryList",
  name: "GetDealHistoryList",
  kind: MethodKind.Unary,
  I: GetDealHistoryListRequest,
  O: GetDealHistoryListResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 注文詳細画面オプションの取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetOrderDetailOption
 */
export const getOrderDetailOption = {
  localName: "getOrderDetailOption",
  name: "GetOrderDetailOption",
  kind: MethodKind.Unary,
  I: Empty,
  O: GetOrderDetailOptionResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 注文内容の取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetOrder
 */
export const getOrder = {
  localName: "getOrder",
  name: "GetOrder",
  kind: MethodKind.Unary,
  I: GetOrderRequest,
  O: GetOrderResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 注文の更新
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.UpdateOrder
 */
export const updateOrder = {
  localName: "updateOrder",
  name: "UpdateOrder",
  kind: MethodKind.Unary,
  I: UpdateOrderRequest,
  O: Empty,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 注文の取り消し
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.CancelOrder
 */
export const cancelOrder = {
  localName: "cancelOrder",
  name: "CancelOrder",
  kind: MethodKind.Unary,
  I: CancelOrderRequest,
  O: Empty,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * 約定注文内容の取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.GetDealOrder
 */
export const getDealOrder = {
  localName: "getDealOrder",
  name: "GetDealOrder",
  kind: MethodKind.Unary,
  I: GetDealOrderRequest,
  O: GetDealOrderResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;

/**
 * Jクレジット認証一覧の取得
 *
 * @generated from rpc jcex.bff.jcredit.trader.v1.Service.ListJCreditCertifications
 */
export const listJCreditCertifications = {
  localName: "listJCreditCertifications",
  name: "ListJCreditCertifications",
  kind: MethodKind.Unary,
  I: ListJCreditCertificationsRequest,
  O: ListJCreditCertificationsResponse,
  service: {
    typeName: "jcex.bff.jcredit.trader.v1.Service"
  }
} as const;
