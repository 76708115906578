// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file jcex/bff/report/v1/model.proto (package jcex.bff.report.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Broker, DeliveryFiscalYear, OpenToMarketStatus, OrderStatus, Organization, Position, Prefecture, Sign, Trader, VolumeAndUnitPrice } from "../../shared/model/v1/model_pb.js";
import { GeneratorType, RenewableEnergyAndTrackingStatus } from "../../nfc/model/v1/model_pb.js";
import { Product } from "../../jcredit/model/v1/model_pb.js";

/**
 * アセスメント値
 *
 * @generated from message jcex.bff.report.v1.AssessmentValue
 */
export class AssessmentValue extends Message<AssessmentValue> {
  /**
   * アセスメント値
   *
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * 符号
   *
   * @generated from field: jcex.bff.shared.model.v1.Sign sign = 2;
   */
  sign = Sign.UNSPECIFIED;

  constructor(data?: PartialMessage<AssessmentValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sign", kind: "enum", T: proto3.getEnumType(Sign) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentValue {
    return new AssessmentValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentValue {
    return new AssessmentValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentValue {
    return new AssessmentValue().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentValue | PlainMessage<AssessmentValue> | undefined, b: AssessmentValue | PlainMessage<AssessmentValue> | undefined): boolean {
    return proto3.util.equals(AssessmentValue, a, b);
  }
}

/**
 * アセスメント変化値
 *
 * @generated from message jcex.bff.report.v1.AssessmentChangeValue
 */
export class AssessmentChangeValue extends Message<AssessmentChangeValue> {
  /**
   * アセスメント変化値
   *
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * 符号
   *
   * @generated from field: jcex.bff.shared.model.v1.Sign sign = 2;
   */
  sign = Sign.UNSPECIFIED;

  constructor(data?: PartialMessage<AssessmentChangeValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentChangeValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sign", kind: "enum", T: proto3.getEnumType(Sign) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentChangeValue {
    return new AssessmentChangeValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentChangeValue {
    return new AssessmentChangeValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentChangeValue {
    return new AssessmentChangeValue().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentChangeValue | PlainMessage<AssessmentChangeValue> | undefined, b: AssessmentChangeValue | PlainMessage<AssessmentChangeValue> | undefined): boolean {
    return proto3.util.equals(AssessmentChangeValue, a, b);
  }
}

/**
 * アセスメント変化率
 *
 * @generated from message jcex.bff.report.v1.AssessmentChangeRate
 */
export class AssessmentChangeRate extends Message<AssessmentChangeRate> {
  /**
   * アセスメント変化率
   *
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * 符号
   *
   * @generated from field: jcex.bff.shared.model.v1.Sign sign = 2;
   */
  sign = Sign.UNSPECIFIED;

  constructor(data?: PartialMessage<AssessmentChangeRate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentChangeRate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sign", kind: "enum", T: proto3.getEnumType(Sign) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentChangeRate {
    return new AssessmentChangeRate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentChangeRate {
    return new AssessmentChangeRate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentChangeRate {
    return new AssessmentChangeRate().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentChangeRate | PlainMessage<AssessmentChangeRate> | undefined, b: AssessmentChangeRate | PlainMessage<AssessmentChangeRate> | undefined): boolean {
    return proto3.util.equals(AssessmentChangeRate, a, b);
  }
}

/**
 * [非化石証書] アセスメント計算の元になった注文
 *
 * @generated from message jcex.bff.report.v1.AssessmentNfcSrcOrder
 */
export class AssessmentNfcSrcOrder extends Message<AssessmentNfcSrcOrder> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * この注文が関連するアセスメント結果のID
   *
   * @generated from field: string assessment_nfc_result_id = 2;
   */
  assessmentNfcResultId = "";

  /**
   * 計算に使うかどうかのフラグ
   *
   * @generated from field: bool is_use_calc = 3;
   */
  isUseCalc = false;

  /**
   * 注文ID
   *
   * @generated from field: string order_id = 4;
   */
  orderId = "";

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 5;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * 売買区分
   *
   * @generated from field: jcex.bff.shared.model.v1.Position position = 6;
   */
  position?: Position;

  /**
   * 再エネ指定・トラッキングの組み合わせ状態
   *
   * @generated from field: jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus renewable_energy_and_tracking_status = 7;
   */
  renewableEnergyAndTrackingStatus?: RenewableEnergyAndTrackingStatus;

  /**
   * 発電種
   *
   * @generated from field: repeated jcex.bff.nfc.model.v1.GeneratorType generator_types = 8;
   */
  generatorTypes: GeneratorType[] = [];

  /**
   * 全発電種選択フラグ
   *
   * @generated from field: bool is_all_generator_types_selected = 9;
   */
  isAllGeneratorTypesSelected = false;

  /**
   * 都道府県
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture prefectures = 10;
   */
  prefectures: Prefecture[] = [];

  /**
   * 全都道府県選択フラグ
   *
   * @generated from field: bool is_all_prefectures_selected = 11;
   */
  isAllPrefecturesSelected = false;

  /**
   * 組織
   *
   * @generated from field: jcex.bff.shared.model.v1.Organization organization = 12;
   */
  organization?: Organization;

  /**
   * 担当トレーダー
   *
   * @generated from field: optional jcex.bff.shared.model.v1.Trader trader = 13;
   */
  trader?: Trader;

  /**
   * 数量・単価
   *
   * @generated from field: jcex.bff.shared.model.v1.VolumeAndUnitPrice volume_and_unit_price = 14;
   */
  volumeAndUnitPrice?: VolumeAndUnitPrice;

  /**
   * 注文ステータス
   *
   * @generated from field: jcex.bff.shared.model.v1.OrderStatus order_status = 15;
   */
  orderStatus?: OrderStatus;

  /**
   * 公開ステータス
   *
   * @generated from field: jcex.bff.shared.model.v1.OpenToMarketStatus open_to_market_status = 16;
   */
  openToMarketStatus?: OpenToMarketStatus;

  constructor(data?: PartialMessage<AssessmentNfcSrcOrder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentNfcSrcOrder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "assessment_nfc_result_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_use_calc", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 6, name: "position", kind: "message", T: Position },
    { no: 7, name: "renewable_energy_and_tracking_status", kind: "message", T: RenewableEnergyAndTrackingStatus },
    { no: 8, name: "generator_types", kind: "message", T: GeneratorType, repeated: true },
    { no: 9, name: "is_all_generator_types_selected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "prefectures", kind: "message", T: Prefecture, repeated: true },
    { no: 11, name: "is_all_prefectures_selected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "organization", kind: "message", T: Organization },
    { no: 13, name: "trader", kind: "message", T: Trader, opt: true },
    { no: 14, name: "volume_and_unit_price", kind: "message", T: VolumeAndUnitPrice },
    { no: 15, name: "order_status", kind: "message", T: OrderStatus },
    { no: 16, name: "open_to_market_status", kind: "message", T: OpenToMarketStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentNfcSrcOrder {
    return new AssessmentNfcSrcOrder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentNfcSrcOrder {
    return new AssessmentNfcSrcOrder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentNfcSrcOrder {
    return new AssessmentNfcSrcOrder().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentNfcSrcOrder | PlainMessage<AssessmentNfcSrcOrder> | undefined, b: AssessmentNfcSrcOrder | PlainMessage<AssessmentNfcSrcOrder> | undefined): boolean {
    return proto3.util.equals(AssessmentNfcSrcOrder, a, b);
  }
}

/**
 * [非化石証書] アセスメント計算の元になった約定
 *
 * @generated from message jcex.bff.report.v1.AssessmentNfcSrcDeal
 */
export class AssessmentNfcSrcDeal extends Message<AssessmentNfcSrcDeal> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * この約定が関連するアセスメント結果のID
   *
   * @generated from field: string assessment_nfc_result_id = 2;
   */
  assessmentNfcResultId = "";

  /**
   * 計算に使うかどうかのフラグ
   *
   * @generated from field: bool is_use_calc = 3;
   */
  isUseCalc = false;

  /**
   * 約定ID
   *
   * @generated from field: string deal_id = 4;
   */
  dealId = "";

  /**
   * 成約ID
   *
   * @generated from field: string deal_code = 5;
   */
  dealCode = "";

  /**
   * 約定日時
   *
   * @generated from field: google.protobuf.Timestamp dealt_at = 6;
   */
  dealtAt?: Timestamp;

  /**
   * 数量・単価
   *
   * @generated from field: jcex.bff.shared.model.v1.VolumeAndUnitPrice volume_and_unit_price = 7;
   */
  volumeAndUnitPrice?: VolumeAndUnitPrice;

  /**
   * 公開ステータス
   *
   * @generated from field: jcex.bff.shared.model.v1.OpenToMarketStatus open_to_market_status = 8;
   */
  openToMarketStatus?: OpenToMarketStatus;

  /**
   * 発電種
   *
   * @generated from field: repeated jcex.bff.nfc.model.v1.GeneratorType generator_types = 9;
   */
  generatorTypes: GeneratorType[] = [];

  /**
   * 都道府県
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture prefectures = 10;
   */
  prefectures: Prefecture[] = [];

  /**
   * 買主の組織
   *
   * @generated from field: jcex.bff.shared.model.v1.Organization bid_organization = 11;
   */
  bidOrganization?: Organization;

  /**
   * 売主の組織
   *
   * @generated from field: jcex.bff.shared.model.v1.Organization offer_organization = 12;
   */
  offerOrganization?: Organization;

  constructor(data?: PartialMessage<AssessmentNfcSrcDeal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentNfcSrcDeal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "assessment_nfc_result_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_use_calc", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "deal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "dealt_at", kind: "message", T: Timestamp },
    { no: 7, name: "volume_and_unit_price", kind: "message", T: VolumeAndUnitPrice },
    { no: 8, name: "open_to_market_status", kind: "message", T: OpenToMarketStatus },
    { no: 9, name: "generator_types", kind: "message", T: GeneratorType, repeated: true },
    { no: 10, name: "prefectures", kind: "message", T: Prefecture, repeated: true },
    { no: 11, name: "bid_organization", kind: "message", T: Organization },
    { no: 12, name: "offer_organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentNfcSrcDeal {
    return new AssessmentNfcSrcDeal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentNfcSrcDeal {
    return new AssessmentNfcSrcDeal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentNfcSrcDeal {
    return new AssessmentNfcSrcDeal().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentNfcSrcDeal | PlainMessage<AssessmentNfcSrcDeal> | undefined, b: AssessmentNfcSrcDeal | PlainMessage<AssessmentNfcSrcDeal> | undefined): boolean {
    return proto3.util.equals(AssessmentNfcSrcDeal, a, b);
  }
}

/**
 * [非化石証書] アセスメント結果
 *
 * @generated from message jcex.bff.report.v1.AssessmentNfcResult
 */
export class AssessmentNfcResult extends Message<AssessmentNfcResult> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * アセスメント設定ID
   *
   * @generated from field: string assessment_setting_id = 2;
   */
  assessmentSettingId = "";

  /**
   * アセスメント値
   *
   * @generated from field: jcex.bff.report.v1.AssessmentValue assessment_value = 3;
   */
  assessmentValue?: AssessmentValue;

  /**
   * アセスメント変化値
   *
   * @generated from field: jcex.bff.report.v1.AssessmentChangeValue assessment_change_value = 4;
   */
  assessmentChangeValue?: AssessmentChangeValue;

  /**
   * アセスメント変化率
   *
   * @generated from field: jcex.bff.report.v1.AssessmentChangeRate assessment_change_rate = 5;
   */
  assessmentChangeRate?: AssessmentChangeRate;

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 6;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * 再エネ指定・トラッキングの組み合わせ状態
   *
   * @generated from field: jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus renewable_energy_and_tracking_status = 7;
   */
  renewableEnergyAndTrackingStatus?: RenewableEnergyAndTrackingStatus;

  /**
   * アセスメント計算の元になった注文一覧
   *
   * @generated from field: repeated jcex.bff.report.v1.AssessmentNfcSrcOrder assessment_nfc_src_orders = 8;
   */
  assessmentNfcSrcOrders: AssessmentNfcSrcOrder[] = [];

  /**
   * アセスメント計算の元になった約定一覧
   *
   * @generated from field: repeated jcex.bff.report.v1.AssessmentNfcSrcDeal assessment_nfc_src_deals = 9;
   */
  assessmentNfcSrcDeals: AssessmentNfcSrcDeal[] = [];

  /**
   * 作成日時
   *
   * @generated from field: google.protobuf.Timestamp created_at = 10;
   */
  createdAt?: Timestamp;

  /**
   * 最終更新日時
   *
   * @generated from field: google.protobuf.Timestamp last_updated_at = 11;
   */
  lastUpdatedAt?: Timestamp;

  constructor(data?: PartialMessage<AssessmentNfcResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentNfcResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "assessment_setting_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "assessment_value", kind: "message", T: AssessmentValue },
    { no: 4, name: "assessment_change_value", kind: "message", T: AssessmentChangeValue },
    { no: 5, name: "assessment_change_rate", kind: "message", T: AssessmentChangeRate },
    { no: 6, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 7, name: "renewable_energy_and_tracking_status", kind: "message", T: RenewableEnergyAndTrackingStatus },
    { no: 8, name: "assessment_nfc_src_orders", kind: "message", T: AssessmentNfcSrcOrder, repeated: true },
    { no: 9, name: "assessment_nfc_src_deals", kind: "message", T: AssessmentNfcSrcDeal, repeated: true },
    { no: 10, name: "created_at", kind: "message", T: Timestamp },
    { no: 11, name: "last_updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentNfcResult {
    return new AssessmentNfcResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentNfcResult {
    return new AssessmentNfcResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentNfcResult {
    return new AssessmentNfcResult().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentNfcResult | PlainMessage<AssessmentNfcResult> | undefined, b: AssessmentNfcResult | PlainMessage<AssessmentNfcResult> | undefined): boolean {
    return proto3.util.equals(AssessmentNfcResult, a, b);
  }
}

/**
 * [Jクレジット] アセスメント計算の元になった注文
 *
 * @generated from message jcex.bff.report.v1.AssessmentJCreditSrcOrder
 */
export class AssessmentJCreditSrcOrder extends Message<AssessmentJCreditSrcOrder> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * この注文が関連するアセスメント結果のID
   *
   * @generated from field: string assessment_jcredit_result_id = 2;
   */
  assessmentJcreditResultId = "";

  /**
   * 計算に使うかどうかのフラグ
   *
   * @generated from field: bool is_use_calc = 3;
   */
  isUseCalc = false;

  /**
   * 注文ID
   *
   * @generated from field: string order_id = 4;
   */
  orderId = "";

  /**
   * 売買区分
   *
   * @generated from field: jcex.bff.shared.model.v1.Position position = 5;
   */
  position?: Position;

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 6;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 7;
   */
  product?: Product;

  /**
   * 都道府県
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture prefectures = 8;
   */
  prefectures: Prefecture[] = [];

  /**
   * 全都道府県選択フラグ
   *
   * @generated from field: bool is_all_prefectures_selected = 9;
   */
  isAllPrefecturesSelected = false;

  /**
   * 組織
   *
   * @generated from field: jcex.bff.shared.model.v1.Organization organization = 10;
   */
  organization?: Organization;

  /**
   * 担当トレーダー
   *
   * @generated from field: optional jcex.bff.shared.model.v1.Trader trader = 11;
   */
  trader?: Trader;

  /**
   * 数量・単価
   *
   * @generated from field: jcex.bff.shared.model.v1.VolumeAndUnitPrice volume_and_unit_price = 12;
   */
  volumeAndUnitPrice?: VolumeAndUnitPrice;

  /**
   * 注文ステータス
   *
   * @generated from field: jcex.bff.shared.model.v1.OrderStatus order_status = 13;
   */
  orderStatus?: OrderStatus;

  /**
   * 公開ステータス
   *
   * @generated from field: jcex.bff.shared.model.v1.OpenToMarketStatus open_to_market_status = 14;
   */
  openToMarketStatus?: OpenToMarketStatus;

  constructor(data?: PartialMessage<AssessmentJCreditSrcOrder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentJCreditSrcOrder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "assessment_jcredit_result_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_use_calc", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "position", kind: "message", T: Position },
    { no: 6, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 7, name: "product", kind: "message", T: Product },
    { no: 8, name: "prefectures", kind: "message", T: Prefecture, repeated: true },
    { no: 9, name: "is_all_prefectures_selected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "organization", kind: "message", T: Organization },
    { no: 11, name: "trader", kind: "message", T: Trader, opt: true },
    { no: 12, name: "volume_and_unit_price", kind: "message", T: VolumeAndUnitPrice },
    { no: 13, name: "order_status", kind: "message", T: OrderStatus },
    { no: 14, name: "open_to_market_status", kind: "message", T: OpenToMarketStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentJCreditSrcOrder {
    return new AssessmentJCreditSrcOrder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentJCreditSrcOrder {
    return new AssessmentJCreditSrcOrder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentJCreditSrcOrder {
    return new AssessmentJCreditSrcOrder().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentJCreditSrcOrder | PlainMessage<AssessmentJCreditSrcOrder> | undefined, b: AssessmentJCreditSrcOrder | PlainMessage<AssessmentJCreditSrcOrder> | undefined): boolean {
    return proto3.util.equals(AssessmentJCreditSrcOrder, a, b);
  }
}

/**
 * [Jクレジット] アセスメント計算の元になった約定
 *
 * @generated from message jcex.bff.report.v1.AssessmentJCreditSrcDeal
 */
export class AssessmentJCreditSrcDeal extends Message<AssessmentJCreditSrcDeal> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * この約定が関連するアセスメント結果のID
   *
   * @generated from field: string assessment_jcredit_result_id = 2;
   */
  assessmentJcreditResultId = "";

  /**
   * 計算に使うかどうかのフラグ
   *
   * @generated from field: bool is_use_calc = 3;
   */
  isUseCalc = false;

  /**
   * 約定ID
   *
   * @generated from field: string deal_id = 4;
   */
  dealId = "";

  /**
   * 成約ID
   *
   * @generated from field: string deal_code = 5;
   */
  dealCode = "";

  /**
   * 約定日時
   *
   * @generated from field: google.protobuf.Timestamp dealt_at = 6;
   */
  dealtAt?: Timestamp;

  /**
   * 数量・単価
   *
   * @generated from field: jcex.bff.shared.model.v1.VolumeAndUnitPrice volume_and_unit_price = 7;
   */
  volumeAndUnitPrice?: VolumeAndUnitPrice;

  /**
   * 公開ステータス
   *
   * @generated from field: jcex.bff.shared.model.v1.OpenToMarketStatus open_to_market_status = 8;
   */
  openToMarketStatus?: OpenToMarketStatus;

  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 9;
   */
  product?: Product;

  /**
   * 都道府県
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.Prefecture prefectures = 10;
   */
  prefectures: Prefecture[] = [];

  /**
   * 買主
   *
   * @generated from field: jcex.bff.shared.model.v1.Organization bid_organization = 11;
   */
  bidOrganization?: Organization;

  /**
   * 売主の組織
   *
   * @generated from field: jcex.bff.shared.model.v1.Organization offer_organization = 12;
   */
  offerOrganization?: Organization;

  constructor(data?: PartialMessage<AssessmentJCreditSrcDeal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentJCreditSrcDeal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "assessment_jcredit_result_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_use_calc", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "deal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "dealt_at", kind: "message", T: Timestamp },
    { no: 7, name: "volume_and_unit_price", kind: "message", T: VolumeAndUnitPrice },
    { no: 8, name: "open_to_market_status", kind: "message", T: OpenToMarketStatus },
    { no: 9, name: "product", kind: "message", T: Product },
    { no: 10, name: "prefectures", kind: "message", T: Prefecture, repeated: true },
    { no: 11, name: "bid_organization", kind: "message", T: Organization },
    { no: 12, name: "offer_organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentJCreditSrcDeal {
    return new AssessmentJCreditSrcDeal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentJCreditSrcDeal {
    return new AssessmentJCreditSrcDeal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentJCreditSrcDeal {
    return new AssessmentJCreditSrcDeal().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentJCreditSrcDeal | PlainMessage<AssessmentJCreditSrcDeal> | undefined, b: AssessmentJCreditSrcDeal | PlainMessage<AssessmentJCreditSrcDeal> | undefined): boolean {
    return proto3.util.equals(AssessmentJCreditSrcDeal, a, b);
  }
}

/**
 * [Jクレジット] アセスメント結果
 *
 * @generated from message jcex.bff.report.v1.AssessmentJCreditResult
 */
export class AssessmentJCreditResult extends Message<AssessmentJCreditResult> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * アセスメント設定ID
   *
   * @generated from field: string assessment_setting_id = 2;
   */
  assessmentSettingId = "";

  /**
   * アセスメント値
   *
   * @generated from field: jcex.bff.report.v1.AssessmentValue assessment_value = 3;
   */
  assessmentValue?: AssessmentValue;

  /**
   * アセスメント変化値
   *
   * @generated from field: jcex.bff.report.v1.AssessmentChangeValue assessment_change_value = 4;
   */
  assessmentChangeValue?: AssessmentChangeValue;

  /**
   * アセスメント変化率
   *
   * @generated from field: jcex.bff.report.v1.AssessmentChangeRate assessment_change_rate = 5;
   */
  assessmentChangeRate?: AssessmentChangeRate;

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 6;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 7;
   */
  product?: Product;

  /**
   * アセスメント計算の元になった注文一覧
   *
   * @generated from field: repeated jcex.bff.report.v1.AssessmentJCreditSrcOrder assessment_jcredit_src_orders = 8;
   */
  assessmentJcreditSrcOrders: AssessmentJCreditSrcOrder[] = [];

  /**
   * アセスメント計算の元になった約定一覧
   *
   * @generated from field: repeated jcex.bff.report.v1.AssessmentJCreditSrcDeal assessment_jcredit_src_deals = 9;
   */
  assessmentJcreditSrcDeals: AssessmentJCreditSrcDeal[] = [];

  /**
   * 作成日時
   *
   * @generated from field: google.protobuf.Timestamp created_at = 10;
   */
  createdAt?: Timestamp;

  /**
   * 最終更新日時
   *
   * @generated from field: google.protobuf.Timestamp last_updated_at = 11;
   */
  lastUpdatedAt?: Timestamp;

  constructor(data?: PartialMessage<AssessmentJCreditResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentJCreditResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "assessment_setting_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "assessment_value", kind: "message", T: AssessmentValue },
    { no: 4, name: "assessment_change_value", kind: "message", T: AssessmentChangeValue },
    { no: 5, name: "assessment_change_rate", kind: "message", T: AssessmentChangeRate },
    { no: 6, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 7, name: "product", kind: "message", T: Product },
    { no: 8, name: "assessment_jcredit_src_orders", kind: "message", T: AssessmentJCreditSrcOrder, repeated: true },
    { no: 9, name: "assessment_jcredit_src_deals", kind: "message", T: AssessmentJCreditSrcDeal, repeated: true },
    { no: 10, name: "created_at", kind: "message", T: Timestamp },
    { no: 11, name: "last_updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentJCreditResult {
    return new AssessmentJCreditResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentJCreditResult {
    return new AssessmentJCreditResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentJCreditResult {
    return new AssessmentJCreditResult().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentJCreditResult | PlainMessage<AssessmentJCreditResult> | undefined, b: AssessmentJCreditResult | PlainMessage<AssessmentJCreditResult> | undefined): boolean {
    return proto3.util.equals(AssessmentJCreditResult, a, b);
  }
}

/**
 * アセスメント設定のステータス
 *
 * @generated from message jcex.bff.report.v1.AssessmentSettingStatus
 */
export class AssessmentSettingStatus extends Message<AssessmentSettingStatus> {
  /**
   * ステータス
   *
   * @generated from field: jcex.bff.report.v1.AssessmentSettingStatus.Type type = 1;
   */
  type = AssessmentSettingStatus_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<AssessmentSettingStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentSettingStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(AssessmentSettingStatus_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentSettingStatus {
    return new AssessmentSettingStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentSettingStatus {
    return new AssessmentSettingStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentSettingStatus {
    return new AssessmentSettingStatus().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentSettingStatus | PlainMessage<AssessmentSettingStatus> | undefined, b: AssessmentSettingStatus | PlainMessage<AssessmentSettingStatus> | undefined): boolean {
    return proto3.util.equals(AssessmentSettingStatus, a, b);
  }
}

/**
 * @generated from enum jcex.bff.report.v1.AssessmentSettingStatus.Type
 */
export enum AssessmentSettingStatus_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 集約中
   *
   * @generated from enum value: TYPE_AGGREGATING = 1;
   */
  AGGREGATING = 1,

  /**
   * 集約済み
   *
   * @generated from enum value: TYPE_AGGREGATED = 2;
   */
  AGGREGATED = 2,

  /**
   * 確定済み
   *
   * @generated from enum value: TYPE_DETERMINED = 3;
   */
  DETERMINED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AssessmentSettingStatus_Type)
proto3.util.setEnumType(AssessmentSettingStatus_Type, "jcex.bff.report.v1.AssessmentSettingStatus.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_AGGREGATING" },
  { no: 2, name: "TYPE_AGGREGATED" },
  { no: 3, name: "TYPE_DETERMINED" },
]);

/**
 * アセスメントの設定
 *
 * @generated from message jcex.bff.report.v1.AssessmentSetting
 */
export class AssessmentSetting extends Message<AssessmentSetting> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 取得日
   *
   * @generated from field: google.protobuf.Timestamp reference_date = 2;
   */
  referenceDate?: Timestamp;

  /**
   * 約定価格取得範囲
   *
   * @generated from field: uint32 deal_range_days = 3;
   */
  dealRangeDays = 0;

  /**
   * 注文取得範囲
   *
   * @generated from field: uint32 order_range_days = 4;
   */
  orderRangeDays = 0;

  /**
   * 気配値取得範囲
   *
   * @generated from field: uint32 indication_range_days = 5;
   */
  indicationRangeDays = 0;

  /**
   * [非化石証書] アセスメント結果
   *
   * @generated from field: repeated jcex.bff.report.v1.AssessmentNfcResult assessment_nfc_results = 6;
   */
  assessmentNfcResults: AssessmentNfcResult[] = [];

  /**
   * [Jクレジット] アセスメント結果
   *
   * @generated from field: repeated jcex.bff.report.v1.AssessmentJCreditResult assessment_jcredit_results = 7;
   */
  assessmentJcreditResults: AssessmentJCreditResult[] = [];

  /**
   * 作成日時
   *
   * @generated from field: google.protobuf.Timestamp created_at = 8;
   */
  createdAt?: Timestamp;

  /**
   * 作成者(ブローカー)
   *
   * @generated from field: jcex.bff.shared.model.v1.Broker created_by = 9;
   */
  createdBy?: Broker;

  /**
   * ステータス
   *
   * @generated from field: jcex.bff.report.v1.AssessmentSettingStatus status = 10;
   */
  status?: AssessmentSettingStatus;

  constructor(data?: PartialMessage<AssessmentSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.AssessmentSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reference_date", kind: "message", T: Timestamp },
    { no: 3, name: "deal_range_days", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "order_range_days", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "indication_range_days", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "assessment_nfc_results", kind: "message", T: AssessmentNfcResult, repeated: true },
    { no: 7, name: "assessment_jcredit_results", kind: "message", T: AssessmentJCreditResult, repeated: true },
    { no: 8, name: "created_at", kind: "message", T: Timestamp },
    { no: 9, name: "created_by", kind: "message", T: Broker },
    { no: 10, name: "status", kind: "message", T: AssessmentSettingStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentSetting {
    return new AssessmentSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentSetting {
    return new AssessmentSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentSetting {
    return new AssessmentSetting().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentSetting | PlainMessage<AssessmentSetting> | undefined, b: AssessmentSetting | PlainMessage<AssessmentSetting> | undefined): boolean {
    return proto3.util.equals(AssessmentSetting, a, b);
  }
}

/**
 * @generated from message jcex.bff.report.v1.GreenIndex
 */
export class GreenIndex extends Message<GreenIndex> {
  /**
   * サマリ
   *
   * @generated from field: jcex.bff.report.v1.GreenIndex.Summary summary = 1;
   */
  summary?: GreenIndex_Summary;

  /**
   * [NFC] インデックス価格
   *
   * @generated from field: repeated jcex.bff.report.v1.GreenIndex.NfcIndexPrice nfc_index_prices = 2;
   */
  nfcIndexPrices: GreenIndex_NfcIndexPrice[] = [];

  /**
   * [Jクレジット] インデックス価格
   *
   * @generated from field: repeated jcex.bff.report.v1.GreenIndex.JCreditIndexPrice jcredit_index_prices = 3;
   */
  jcreditIndexPrices: GreenIndex_JCreditIndexPrice[] = [];

  /**
   * [NFC] 全商品
   *
   * @generated from field: repeated jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus nfc_all_products = 4;
   */
  nfcAllProducts: RenewableEnergyAndTrackingStatus[] = [];

  /**
   * [NFC] 受渡年度
   *
   * @generated from field: repeated jcex.bff.shared.model.v1.DeliveryFiscalYear nfc_all_delivery_fiscal_year = 5;
   */
  nfcAllDeliveryFiscalYear: DeliveryFiscalYear[] = [];

  /**
   * [Jクレジット] 全商品
   *
   * @generated from field: repeated jcex.bff.jcredit.model.v1.Product jcredit_all_products = 6;
   */
  jcreditAllProducts: Product[] = [];

  constructor(data?: PartialMessage<GreenIndex>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GreenIndex";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "summary", kind: "message", T: GreenIndex_Summary },
    { no: 2, name: "nfc_index_prices", kind: "message", T: GreenIndex_NfcIndexPrice, repeated: true },
    { no: 3, name: "jcredit_index_prices", kind: "message", T: GreenIndex_JCreditIndexPrice, repeated: true },
    { no: 4, name: "nfc_all_products", kind: "message", T: RenewableEnergyAndTrackingStatus, repeated: true },
    { no: 5, name: "nfc_all_delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear, repeated: true },
    { no: 6, name: "jcredit_all_products", kind: "message", T: Product, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GreenIndex {
    return new GreenIndex().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GreenIndex {
    return new GreenIndex().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GreenIndex {
    return new GreenIndex().fromJsonString(jsonString, options);
  }

  static equals(a: GreenIndex | PlainMessage<GreenIndex> | undefined, b: GreenIndex | PlainMessage<GreenIndex> | undefined): boolean {
    return proto3.util.equals(GreenIndex, a, b);
  }
}

/**
 * @generated from message jcex.bff.report.v1.GreenIndex.IndexValue
 */
export class GreenIndex_IndexValue extends Message<GreenIndex_IndexValue> {
  /**
   * 価格
   *
   * @generated from field: optional double price = 1;
   */
  price?: number;

  /**
   * 変化量
   *
   * @generated from field: optional double change_amount = 2;
   */
  changeAmount?: number;

  /**
   * 変化率
   *
   * @generated from field: optional double change_rate = 3;
   */
  changeRate?: number;

  constructor(data?: PartialMessage<GreenIndex_IndexValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GreenIndex.IndexValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 2, name: "change_amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "change_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GreenIndex_IndexValue {
    return new GreenIndex_IndexValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GreenIndex_IndexValue {
    return new GreenIndex_IndexValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GreenIndex_IndexValue {
    return new GreenIndex_IndexValue().fromJsonString(jsonString, options);
  }

  static equals(a: GreenIndex_IndexValue | PlainMessage<GreenIndex_IndexValue> | undefined, b: GreenIndex_IndexValue | PlainMessage<GreenIndex_IndexValue> | undefined): boolean {
    return proto3.util.equals(GreenIndex_IndexValue, a, b);
  }
}

/**
 * @generated from message jcex.bff.report.v1.GreenIndex.LatestNfcIndexPrice
 */
export class GreenIndex_LatestNfcIndexPrice extends Message<GreenIndex_LatestNfcIndexPrice> {
  /**
   * 取得日
   *
   * @generated from field: google.protobuf.Timestamp reference_date = 1;
   */
  referenceDate?: Timestamp;

  /**
   * 再エネ指定・トラッキングの組み合わせ状態
   *
   * @generated from field: jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus renewable_energy_and_tracking_status = 2;
   */
  renewableEnergyAndTrackingStatus?: RenewableEnergyAndTrackingStatus;

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 3;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * インデックス価格
   *
   * @generated from field: jcex.bff.report.v1.GreenIndex.IndexValue index_value = 4;
   */
  indexValue?: GreenIndex_IndexValue;

  constructor(data?: PartialMessage<GreenIndex_LatestNfcIndexPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GreenIndex.LatestNfcIndexPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reference_date", kind: "message", T: Timestamp },
    { no: 2, name: "renewable_energy_and_tracking_status", kind: "message", T: RenewableEnergyAndTrackingStatus },
    { no: 3, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 4, name: "index_value", kind: "message", T: GreenIndex_IndexValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GreenIndex_LatestNfcIndexPrice {
    return new GreenIndex_LatestNfcIndexPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GreenIndex_LatestNfcIndexPrice {
    return new GreenIndex_LatestNfcIndexPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GreenIndex_LatestNfcIndexPrice {
    return new GreenIndex_LatestNfcIndexPrice().fromJsonString(jsonString, options);
  }

  static equals(a: GreenIndex_LatestNfcIndexPrice | PlainMessage<GreenIndex_LatestNfcIndexPrice> | undefined, b: GreenIndex_LatestNfcIndexPrice | PlainMessage<GreenIndex_LatestNfcIndexPrice> | undefined): boolean {
    return proto3.util.equals(GreenIndex_LatestNfcIndexPrice, a, b);
  }
}

/**
 * @generated from message jcex.bff.report.v1.GreenIndex.LatestJCreditIndexPrice
 */
export class GreenIndex_LatestJCreditIndexPrice extends Message<GreenIndex_LatestJCreditIndexPrice> {
  /**
   * 取得日
   *
   * @generated from field: google.protobuf.Timestamp reference_date = 1;
   */
  referenceDate?: Timestamp;

  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 2;
   */
  product?: Product;

  /**
   * インデックス価格
   *
   * @generated from field: jcex.bff.report.v1.GreenIndex.IndexValue index_value = 3;
   */
  indexValue?: GreenIndex_IndexValue;

  constructor(data?: PartialMessage<GreenIndex_LatestJCreditIndexPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GreenIndex.LatestJCreditIndexPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reference_date", kind: "message", T: Timestamp },
    { no: 2, name: "product", kind: "message", T: Product },
    { no: 3, name: "index_value", kind: "message", T: GreenIndex_IndexValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GreenIndex_LatestJCreditIndexPrice {
    return new GreenIndex_LatestJCreditIndexPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GreenIndex_LatestJCreditIndexPrice {
    return new GreenIndex_LatestJCreditIndexPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GreenIndex_LatestJCreditIndexPrice {
    return new GreenIndex_LatestJCreditIndexPrice().fromJsonString(jsonString, options);
  }

  static equals(a: GreenIndex_LatestJCreditIndexPrice | PlainMessage<GreenIndex_LatestJCreditIndexPrice> | undefined, b: GreenIndex_LatestJCreditIndexPrice | PlainMessage<GreenIndex_LatestJCreditIndexPrice> | undefined): boolean {
    return proto3.util.equals(GreenIndex_LatestJCreditIndexPrice, a, b);
  }
}

/**
 * [NFC] 表示期間の平均単価
 *
 * @generated from message jcex.bff.report.v1.GreenIndex.NfcAveragePrice
 */
export class GreenIndex_NfcAveragePrice extends Message<GreenIndex_NfcAveragePrice> {
  /**
   * 再エネ指定・トラッキングの組み合わせ状態
   *
   * @generated from field: jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus renewable_energy_and_tracking_status = 1;
   */
  renewableEnergyAndTrackingStatus?: RenewableEnergyAndTrackingStatus;

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 2;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * 価格
   *
   * @generated from field: optional double price = 3;
   */
  price?: number;

  constructor(data?: PartialMessage<GreenIndex_NfcAveragePrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GreenIndex.NfcAveragePrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "renewable_energy_and_tracking_status", kind: "message", T: RenewableEnergyAndTrackingStatus },
    { no: 2, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 3, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GreenIndex_NfcAveragePrice {
    return new GreenIndex_NfcAveragePrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GreenIndex_NfcAveragePrice {
    return new GreenIndex_NfcAveragePrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GreenIndex_NfcAveragePrice {
    return new GreenIndex_NfcAveragePrice().fromJsonString(jsonString, options);
  }

  static equals(a: GreenIndex_NfcAveragePrice | PlainMessage<GreenIndex_NfcAveragePrice> | undefined, b: GreenIndex_NfcAveragePrice | PlainMessage<GreenIndex_NfcAveragePrice> | undefined): boolean {
    return proto3.util.equals(GreenIndex_NfcAveragePrice, a, b);
  }
}

/**
 * [Jクレジット] 表示期間の平均単価
 *
 * @generated from message jcex.bff.report.v1.GreenIndex.JCreditAveragePrice
 */
export class GreenIndex_JCreditAveragePrice extends Message<GreenIndex_JCreditAveragePrice> {
  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 1;
   */
  product?: Product;

  /**
   * 価格
   *
   * @generated from field: optional double price = 2;
   */
  price?: number;

  constructor(data?: PartialMessage<GreenIndex_JCreditAveragePrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GreenIndex.JCreditAveragePrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product", kind: "message", T: Product },
    { no: 2, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GreenIndex_JCreditAveragePrice {
    return new GreenIndex_JCreditAveragePrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GreenIndex_JCreditAveragePrice {
    return new GreenIndex_JCreditAveragePrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GreenIndex_JCreditAveragePrice {
    return new GreenIndex_JCreditAveragePrice().fromJsonString(jsonString, options);
  }

  static equals(a: GreenIndex_JCreditAveragePrice | PlainMessage<GreenIndex_JCreditAveragePrice> | undefined, b: GreenIndex_JCreditAveragePrice | PlainMessage<GreenIndex_JCreditAveragePrice> | undefined): boolean {
    return proto3.util.equals(GreenIndex_JCreditAveragePrice, a, b);
  }
}

/**
 * @generated from message jcex.bff.report.v1.GreenIndex.Summary
 */
export class GreenIndex_Summary extends Message<GreenIndex_Summary> {
  /**
   * 直近の取得日
   *
   * @generated from field: google.protobuf.Timestamp latest_reference_date = 1;
   */
  latestReferenceDate?: Timestamp;

  /**
   * [NFC] 直近の最終値
   *
   * @generated from field: repeated jcex.bff.report.v1.GreenIndex.LatestNfcIndexPrice latest_nfc_index_prices = 2;
   */
  latestNfcIndexPrices: GreenIndex_LatestNfcIndexPrice[] = [];

  /**
   * [Jクレジット] 直近の最終値
   *
   * @generated from field: repeated jcex.bff.report.v1.GreenIndex.LatestJCreditIndexPrice latest_jcredit_index_prices = 3;
   */
  latestJcreditIndexPrices: GreenIndex_LatestJCreditIndexPrice[] = [];

  /**
   * [NFC] 表示期間の平均単価
   *
   * @generated from field: repeated jcex.bff.report.v1.GreenIndex.NfcAveragePrice nfc_average_prices = 4;
   */
  nfcAveragePrices: GreenIndex_NfcAveragePrice[] = [];

  /**
   * [JCredit] 表示期間の平均単価
   *
   * @generated from field: repeated jcex.bff.report.v1.GreenIndex.JCreditAveragePrice jcredit_average_prices = 5;
   */
  jcreditAveragePrices: GreenIndex_JCreditAveragePrice[] = [];

  constructor(data?: PartialMessage<GreenIndex_Summary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GreenIndex.Summary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "latest_reference_date", kind: "message", T: Timestamp },
    { no: 2, name: "latest_nfc_index_prices", kind: "message", T: GreenIndex_LatestNfcIndexPrice, repeated: true },
    { no: 3, name: "latest_jcredit_index_prices", kind: "message", T: GreenIndex_LatestJCreditIndexPrice, repeated: true },
    { no: 4, name: "nfc_average_prices", kind: "message", T: GreenIndex_NfcAveragePrice, repeated: true },
    { no: 5, name: "jcredit_average_prices", kind: "message", T: GreenIndex_JCreditAveragePrice, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GreenIndex_Summary {
    return new GreenIndex_Summary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GreenIndex_Summary {
    return new GreenIndex_Summary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GreenIndex_Summary {
    return new GreenIndex_Summary().fromJsonString(jsonString, options);
  }

  static equals(a: GreenIndex_Summary | PlainMessage<GreenIndex_Summary> | undefined, b: GreenIndex_Summary | PlainMessage<GreenIndex_Summary> | undefined): boolean {
    return proto3.util.equals(GreenIndex_Summary, a, b);
  }
}

/**
 * @generated from message jcex.bff.report.v1.GreenIndex.NfcIndexPrice
 */
export class GreenIndex_NfcIndexPrice extends Message<GreenIndex_NfcIndexPrice> {
  /**
   * 取得日
   *
   * @generated from field: google.protobuf.Timestamp reference_date = 1;
   */
  referenceDate?: Timestamp;

  /**
   * 再エネ指定・トラッキングの組み合わせ状態
   *
   * @generated from field: jcex.bff.nfc.model.v1.RenewableEnergyAndTrackingStatus renewable_energy_and_tracking_status = 2;
   */
  renewableEnergyAndTrackingStatus?: RenewableEnergyAndTrackingStatus;

  /**
   * 受渡年度
   *
   * @generated from field: jcex.bff.shared.model.v1.DeliveryFiscalYear delivery_fiscal_year = 3;
   */
  deliveryFiscalYear?: DeliveryFiscalYear;

  /**
   * 価格
   *
   * @generated from field: optional double price = 4;
   */
  price?: number;

  constructor(data?: PartialMessage<GreenIndex_NfcIndexPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GreenIndex.NfcIndexPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reference_date", kind: "message", T: Timestamp },
    { no: 2, name: "renewable_energy_and_tracking_status", kind: "message", T: RenewableEnergyAndTrackingStatus },
    { no: 3, name: "delivery_fiscal_year", kind: "message", T: DeliveryFiscalYear },
    { no: 4, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GreenIndex_NfcIndexPrice {
    return new GreenIndex_NfcIndexPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GreenIndex_NfcIndexPrice {
    return new GreenIndex_NfcIndexPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GreenIndex_NfcIndexPrice {
    return new GreenIndex_NfcIndexPrice().fromJsonString(jsonString, options);
  }

  static equals(a: GreenIndex_NfcIndexPrice | PlainMessage<GreenIndex_NfcIndexPrice> | undefined, b: GreenIndex_NfcIndexPrice | PlainMessage<GreenIndex_NfcIndexPrice> | undefined): boolean {
    return proto3.util.equals(GreenIndex_NfcIndexPrice, a, b);
  }
}

/**
 * @generated from message jcex.bff.report.v1.GreenIndex.JCreditIndexPrice
 */
export class GreenIndex_JCreditIndexPrice extends Message<GreenIndex_JCreditIndexPrice> {
  /**
   * 取得日
   *
   * @generated from field: google.protobuf.Timestamp reference_date = 1;
   */
  referenceDate?: Timestamp;

  /**
   * 商品
   *
   * @generated from field: jcex.bff.jcredit.model.v1.Product product = 2;
   */
  product?: Product;

  /**
   * 価格
   *
   * @generated from field: optional double price = 3;
   */
  price?: number;

  constructor(data?: PartialMessage<GreenIndex_JCreditIndexPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.report.v1.GreenIndex.JCreditIndexPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reference_date", kind: "message", T: Timestamp },
    { no: 2, name: "product", kind: "message", T: Product },
    { no: 3, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GreenIndex_JCreditIndexPrice {
    return new GreenIndex_JCreditIndexPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GreenIndex_JCreditIndexPrice {
    return new GreenIndex_JCreditIndexPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GreenIndex_JCreditIndexPrice {
    return new GreenIndex_JCreditIndexPrice().fromJsonString(jsonString, options);
  }

  static equals(a: GreenIndex_JCreditIndexPrice | PlainMessage<GreenIndex_JCreditIndexPrice> | undefined, b: GreenIndex_JCreditIndexPrice | PlainMessage<GreenIndex_JCreditIndexPrice> | undefined): boolean {
    return proto3.util.equals(GreenIndex_JCreditIndexPrice, a, b);
  }
}

