import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Grid } from '@chakra-ui/react'

import {
  ColumnContent,
  PrimitiveColors,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { GetDealHistoryFilterResponse } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import { FilterContent, FilterRadioGroup, SelectBox } from '@enechain/jcex-ui'
import { stringUtils } from '@enechain/jcex-utils'

import {
  FilterJCreditDealHistories,
  JCreditDealHistoryFilterProps,
} from '~/trader/pages/HistoryList/types/jCreditFilter'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  filters: FilterJCreditDealHistories
  options: GetDealHistoryFilterResponse
  clearFilter: () => void
}

const DealFilterInputs: React.FC<Props> = ({
  filters,
  options,
  clearFilter,
}) => {
  const { t } = useTranslation('domain')
  const { positionType, productId, deliveryFiscalYear } = filters.state

  const isFilterEmpty = Object.values(filters.state).every(
    (value) => value === undefined,
  )
  const onChange = (value: JCreditDealHistoryFilterProps): void => {
    filters.setState(value)
  }

  return (
    <FilterContent clear={clearFilter} isFilterEmpty={isFilterEmpty}>
      <Grid
        gridGap={Spacing[4]}
        gridAutoRows="minmax(auto, 1fr)"
        gridTemplateColumns="repeat(3, 1fr)"
        width="90%"
      >
        <ColumnContent label={t('shared.position.title')}>
          <FilterRadioGroup
            onChange={(value): void =>
              onChange({
                key: 'positionType',
                value: stringUtils.isNotEmpty(value)
                  ? Number(value)
                  : undefined,
              })
            }
            selectable={Object.fromEntries(
              options.positions.length > 0
                ? options.positions.map(({ type, displayName }) => [
                    type,
                    displayName,
                  ])
                : [],
            )}
            selectedValue={
              positionType !== undefined ? positionType.toString() : ''
            }
          />
        </ColumnContent>
        <SelectBox<number>
          maxWidth="25rem"
          backgroundColor={PrimitiveColors.white['']}
          label={t('model.order.column.delivery_fiscal_year')}
          onChange={(value: Maybe<number>): void =>
            onChange({
              key: 'deliveryFiscalYear',
              value: value !== undefined ? Number(value) : undefined,
            })
          }
          selectable={Object.fromEntries(
            options.deliveryFiscalYears.length > 0
              ? options.deliveryFiscalYears.map(({ year, displayName }) => [
                  year,
                  displayName,
                ])
              : [],
          )}
          selectedValue={deliveryFiscalYear}
        />
        <SelectBox<number>
          maxWidth="25rem"
          backgroundColor={PrimitiveColors.white['']}
          label={t('model.order.column.j_credit.product')}
          onChange={(value: Maybe<number>): void =>
            onChange({
              key: 'productId',
              value:
                value !== undefined && !isNaN(value)
                  ? Number(value)
                  : undefined,
            })
          }
          selectable={Object.fromEntries(
            options.products.length > 0
              ? options.products.map(({ id, name }) => [id, name])
              : [],
          )}
          selectedValue={productId}
        />
      </Grid>
    </FilterContent>
  )
}

export default React.memo(DealFilterInputs)
