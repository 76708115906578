import * as React from 'react'

import { useTranslation } from 'react-i18next'

import {
  Flex,
  Grid,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import {
  ColumnContent,
  PrimitiveColors,
  Spacing,
  TextButton,
  Typography,
} from '@enechain/ecloud-designsystem'
import { FaGearRegular } from '@enechain/ecloud-icons'
import { GeneratorType } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { GetOrderBookFilterResponse } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'
import {
  Position_Type,
  Prefecture,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  FilterCheckboxGroup,
  FilterContent,
  SelectBox,
} from '@enechain/jcex-ui'

import SelectFilterModal from '~/trader/pages/OrderBookList/components/NonFITNFC/SelectFilterModal'
import { INITIAL_NFC_ORDER_BOOK_FILTER } from '~/trader/pages/OrderBookList/constants/filter'
import {
  FilterNFCOrderBooks,
  GrayOutFilterNFCOrderBooks,
  NFCOrderBookFilterProps,
  NFCOrderBookGrayOutProps,
} from '~/trader/pages/OrderBookList/types/nfcFilter'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  filters: FilterNFCOrderBooks
  grayOutFilters: GrayOutFilterNFCOrderBooks
  options: GetOrderBookFilterResponse
  excludeOptions: {
    generatorTypes: GeneratorType[]
    prefectures: Prefecture[]
  }
  clearFilter: () => void
}
const FilterInputs: React.FC<Props> = ({
  filters,
  grayOutFilters,
  options,
  excludeOptions,
  clearFilter,
}) => {
  const { t } = useTranslation(['domain', 'trader'])
  const disclosure = useDisclosure()

  const {
    positionTypes,
    renewableEnergyAndTrackingStatusType,
    deliveryFiscalYear,
  } = filters.state

  // todo: shallowEqual なので deepEqual に置き換える
  const isFilterEmpty = filters.state === INITIAL_NFC_ORDER_BOOK_FILTER

  const { isGrayOutEnabled, generatorTypes, prefectures } = grayOutFilters.state

  const onChangeFilter = (value: NFCOrderBookFilterProps): void => {
    filters.setState(value)
  }

  const onChangeGrayOutFilter = (value: NFCOrderBookGrayOutProps): void => {
    grayOutFilters.setState(value)
  }

  return (
    <>
      <SelectFilterModal
        disclosure={disclosure}
        excludeOptions={excludeOptions}
        onClick={(value): void => {
          onChangeGrayOutFilter({
            key: 'generatorTypes',
            value: value.generatorTypes,
          })
          onChangeGrayOutFilter({
            key: 'prefectures',
            value: value.prefectures,
          })
        }}
        selectedValue={{
          prefectures,
          generatorTypes,
        }}
      />
      <Flex
        alignItems="flex-end"
        justifyContent="space-between"
        gap={Spacing[6]}
      >
        <Stack flexGrow={1}>
          <FilterContent clear={clearFilter} isFilterEmpty={isFilterEmpty}>
            <Grid
              gridGap={Spacing[4]}
              gridAutoRows="repeat(2, 1fr)"
              gridTemplateColumns="repeat(3, 0.7fr)"
              width="100%"
            >
              <ColumnContent label={t('domain:shared.position.title')}>
                <FilterCheckboxGroup<Position_Type>
                  options={Object.fromEntries(
                    options.positions.length > 0
                      ? options.positions.map(({ type, displayName }) => [
                          displayName,
                          type,
                        ])
                      : [],
                  )}
                  selectedItem={positionTypes}
                  setSelectedItem={(value: Position_Type[]): void =>
                    onChangeFilter({
                      key: 'positionTypes',
                      value,
                    })
                  }
                />
              </ColumnContent>
              <SelectBox<number>
                maxWidth="25rem"
                backgroundColor={PrimitiveColors.white['']}
                label={t(
                  'domain:model.order.column.nfc.renewable_energy_and_tracking',
                )}
                onChange={(value: Maybe<number>): void =>
                  onChangeFilter({
                    key: 'renewableEnergyAndTrackingStatusType',
                    value:
                      value !== undefined && !isNaN(value)
                        ? Number(value)
                        : undefined,
                  })
                }
                selectable={Object.fromEntries(
                  options.renewableEnergyAndTrackingStatuses.length > 0
                    ? options.renewableEnergyAndTrackingStatuses.map(
                        ({ type, displayName }) => [type, displayName],
                      )
                    : [],
                )}
                selectedValue={renewableEnergyAndTrackingStatusType}
              />
              <SelectBox<number>
                maxWidth="25rem"
                backgroundColor={PrimitiveColors.white['']}
                label={t('domain:model.order.column.delivery_fiscal_year')}
                onChange={(value: Maybe<number>): void =>
                  onChangeFilter({
                    key: 'deliveryFiscalYear',
                    value: value !== undefined ? Number(value) : undefined,
                  })
                }
                selectable={Object.fromEntries(
                  options.deliveryFiscalYears.length > 0
                    ? options.deliveryFiscalYears.map(
                        ({ year, displayName }) => [year, displayName],
                      )
                    : [],
                )}
                selectedValue={deliveryFiscalYear}
              />
            </Grid>
          </FilterContent>
        </Stack>
        <Flex alignItems="center" gap={Spacing[2]}>
          <Flex gap={Spacing[4]}>
            <Text {...Typography.headerSm}>
              {t('trader:page.order_book_list.gray_out')}
            </Text>
            <Switch
              isChecked={isGrayOutEnabled}
              onChange={(): void =>
                onChangeGrayOutFilter({
                  key: 'isGrayOutEnabled',
                  value: !isGrayOutEnabled,
                })
              }
            />
          </Flex>
          <TextButton
            leftIcon={<FaGearRegular width="24px" height="24px" />}
            onClick={disclosure.onOpen}
          >
            {t('trader:page.order_book_list.target_of_trade')}
          </TextButton>
        </Flex>
      </Flex>
    </>
  )
}

export default React.memo(FilterInputs)
