import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'
import { useVolumeUnitQueries } from '@enechain/jcex-ui'

import { NonFITDealHistoryListColumnKey } from '~/trader/pages/HistoryList/components/NonFITNFC/DealListTable/columnStyles'

export const NonFITDealHistoryListTableHeaderRow: React.FC = () => {
  const { t } = useTranslation(['common', 'domain'])

  const selectedUnit = useVolumeUnitQueries()

  return (
    <FlexTableRow>
      <FlexTableCell<NonFITDealHistoryListColumnKey> columnKey="position" />
      <FlexTableCell<NonFITDealHistoryListColumnKey>
        columnKey="deliveryFiscalYear"
        contents={{
          text: t('domain:model.order.column.delivery_fiscal_year'),
        }}
      />
      <FlexTableCell<NonFITDealHistoryListColumnKey>
        columnKey="renewableEnergyAndTrackingStatus"
        contents={{
          text: t(
            'domain:model.order.column.nfc.renewable_energy_and_tracking',
          ),
        }}
      />
      <FlexTableCell<NonFITDealHistoryListColumnKey>
        columnKey="prefecture"
        contents={{ text: t('domain:shared.prefecture.title') }}
      />
      <FlexTableCell<NonFITDealHistoryListColumnKey>
        columnKey="generatorType"
        contents={{ text: t('domain:shared.generator.title') }}
      />
      <FlexTableCell<NonFITDealHistoryListColumnKey>
        columnKey="unitPrice"
        contents={{ text: t('common:unit.yen_per_kwh') }}
      />
      <FlexTableCell<NonFITDealHistoryListColumnKey>
        columnKey="volume"
        contents={{ text: t(`common:unit.${selectedUnit}`) }}
      />
      <FlexTableCell<NonFITDealHistoryListColumnKey>
        columnKey="counterpartyOrganizationName"
        contents={{ text: t('domain:model.deal.column.counterparty') }}
      />
      <FlexTableCell<NonFITDealHistoryListColumnKey>
        columnKey="dealtAt"
        contents={{ text: t('domain:model.deal.column.dealt_at') }}
      />
    </FlexTableRow>
  )
}
