import { useCallback } from 'react'

import { useEffectOnce } from 'react-use'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

export type TabKey = 'nfc' | 'j_credit'

export const TAB_TYPES = {
  NFC: 'nfc',
  JCREDIT: 'j_credit',
} as const

const DEFAULT_TAB: TabKey = TAB_TYPES.NFC

const TABS_KEY = 'tabs'

function findTab(): TabKey {
  const currentTab = localStorage.getItem(TABS_KEY)

  if (currentTab == null) {
    localStorage.setItem(TABS_KEY, DEFAULT_TAB)
    return DEFAULT_TAB
  }

  // 以前 tab の state を上書きしたので、バッチ的に更新するように対応
  // before: nonFIT-NFC, J-Credit
  // after: nfc, j_credit
  // https://github.com/enechain/jcex-app/blob/f3ede034c59fedb426ad923afea262517e44d711/frontend/packages/ui/src/hooks/tab/useTabState.ts#L9-L10
  if (currentTab === 'nonFIT-NFC' || currentTab === 'J-Credit') {
    localStorage.setItem(TABS_KEY, DEFAULT_TAB)
    return DEFAULT_TAB
  }

  return currentTab as TabKey
}

const tabState = atom<TabKey>({
  key: TABS_KEY,
  default: findTab(),
})

type MutationReturnType = {
  setTabState: (value: TabKey) => void
}

export function useTabQueries(): TabKey {
  const tabKey = useRecoilValue(tabState)
  const setState = useSetRecoilState(tabState)

  const localStorageTabItem = findTab()

  // 正常動作では発火しないが、念の為互換性を保つために残しておき、diff があれば再レンダリングされる
  useEffectOnce((): void => {
    if (tabKey !== localStorageTabItem) {
      setState(localStorageTabItem)
    }
  })

  return tabKey
}

export function useTabMutations(): MutationReturnType {
  const setState = useSetRecoilState(tabState)

  const update = useCallback(
    (value: TabKey) => {
      localStorage.setItem(TABS_KEY, value)
      setState(value)
    },
    [setState],
  )

  return {
    setTabState: update,
  }
}
