import * as React from 'react'

import {
  FaDropletRegular,
  FaSunBrightRegular,
  FaTreeRegular,
  FaVolcanoRegular,
  FaWindRegular,
} from '@enechain/ecloud-icons'
import { GeneratorType_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

type Props = {
  type: GeneratorType_Type
  color?: string | undefined
}

const GeneratorIcon: React.FC<Props> = ({ type, color = undefined }) => {
  switch (type) {
    case GeneratorType_Type.SOLAR:
      return (
        <FaSunBrightRegular
          width="20px"
          height="20px"
          color={color ?? '#F39200'}
        />
      )
    case GeneratorType_Type.WIND:
      return (
        <FaWindRegular
          width="20px"
          height="20px"
          color={color ?? '#00000042'}
        />
      )
    case GeneratorType_Type.HYDRO:
      return (
        <FaDropletRegular
          width="20px"
          height="20px"
          color={color ?? '#597EFF'}
        />
      )
    case GeneratorType_Type.BIOMASS:
      return (
        <FaTreeRegular width="20px" height="20px" color={color ?? '#3CB358'} />
      )
    case GeneratorType_Type.GEOTHERMAL:
      return (
        <FaVolcanoRegular
          width="20px"
          height="20px"
          color={color ?? '#FF4E52'}
        />
      )
    default:
      return <></>
  }
}

export default React.memo(GeneratorIcon)
