import { OrderBook } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/broker/v1/model_pb'
import { formatYYYYMMdd, stringUtils } from '@enechain/jcex-utils'

import {
  OrderModelForJCreditOrderBook,
  convertOrderModelForJCreditOrderBook,
} from '~/trader/pages/OrderBookList/models/JCredit/order'
import { GrayOutFilter } from '~/trader/pages/OrderBookList/types/jCreditFilter'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Params = {
  orderBooks: OrderBook[]
  filters: GrayOutFilter
}

export type JCreditOrderBookModel = Pick<OrderBook, 'bestBid' | 'bestOffer'> & {
  product: {
    id: number
    name: string
  }
  deliveryFiscalYear: {
    year: number
    displayName: string
  }
  orders: OrderModelForJCreditOrderBook[]
  lastUnitPrice: Maybe<string>
  lastDealtAt: Maybe<string>
  isGrayOutAllItem: boolean
}

export function convertJCreditOrderBookModel({
  orderBooks,
  filters,
}: Params): JCreditOrderBookModel[] {
  return orderBooks.map((order) => {
    const orderModel = convertOrderModelForJCreditOrderBook({
      orders: order.orders,
      filters,
    })

    return {
      product: {
        id: order.product?.id ?? 0,
        name: order.product?.name ?? '',
      },
      deliveryFiscalYear: {
        year: order.deliveryFiscalYear?.year ?? 0,
        displayName: order.deliveryFiscalYear?.displayName ?? '',
      },
      orders: orderModel,
      bestBid: order.bestBid,
      bestOffer: order.bestOffer,
      lastUnitPrice:
        order.last?.unitPrice !== undefined &&
        stringUtils.isNotEmpty(order.last.unitPrice)
          ? order.last.unitPrice
          : undefined,
      lastDealtAt:
        order.lastDealtAt !== undefined
          ? formatYYYYMMdd(order.lastDealtAt.toDate(), '/')
          : undefined,
      isGrayOutAllItem: orderModel.every((order) => order.isGrayOutTarget),
    }
  })
}
