import { useCallback, useState } from 'react'

import { useLoaderData } from 'react-router-dom'

import {
  GetOrderHistoryFilterResponse as GetJCreditOrderHistoryFilterResponse,
  GetOrderHistoryListRequest as GetJCreditOrderHistoryListRequest,
} from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import {
  GetOrderHistoryFilterResponse as GetNFCOrderHistoryFilterResponse,
  GetOrderHistoryListRequest as GetNFCOrderHistoryListRequest,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'

import { ORDER_LIST_FILTER } from '~/trader/constants/localStorageKey'
import {
  INITIAL_J_CREDIT_ORDER_LIST_FILTER,
  INITIAL_NFC_ORDER_LIST_FILTER,
} from '~/trader/pages/HistoryList/constants/filter'
import { HistoryListPageLoaderData } from '~/trader/pages/HistoryList/loader'
import {
  FilterJCreditOrderHistories,
  JCreditOrderHistoryFilterProps,
} from '~/trader/pages/HistoryList/types/jCreditFilter'
import {
  FilterNFCOrderHistories,
  NFCOrderHistoryFilterProps,
} from '~/trader/pages/HistoryList/types/nfcFilter'

type Result = {
  nfc: {
    filters: FilterNFCOrderHistories
    options: GetNFCOrderHistoryFilterResponse
  }
  jCredit: {
    filters: FilterJCreditOrderHistories
    options: GetJCreditOrderHistoryFilterResponse
  }
  clearFilter: () => void
}

function findFilters(): {
  nfc: GetNFCOrderHistoryListRequest
  jCredit: GetJCreditOrderHistoryListRequest
} {
  return JSON.parse(localStorage.getItem(ORDER_LIST_FILTER) ?? '{}') as {
    nfc: GetNFCOrderHistoryListRequest
    jCredit: GetJCreditOrderHistoryListRequest
  }
}

export function useOrderHistoryFilter(): Result {
  const { nfc, jCredit } = useLoaderData() as HistoryListPageLoaderData

  const [nfcOrderHistoryFilter, _setNfcOrderHistoryFilter] =
    useState<GetNFCOrderHistoryListRequest>(
      new GetNFCOrderHistoryListRequest({
        ...findFilters().nfc,
        ...INITIAL_NFC_ORDER_LIST_FILTER,
      }),
    )

  const [jCreditOrderHistoryFilter, _setJCreditOrderHistoryFilter] =
    useState<GetJCreditOrderHistoryListRequest>(
      new GetJCreditOrderHistoryListRequest({
        ...findFilters().jCredit,
        ...INITIAL_J_CREDIT_ORDER_LIST_FILTER,
      }),
    )

  const setNfcOrderHistoryFilter = useCallback(
    ({ key, value }: NFCOrderHistoryFilterProps) => {
      const changeValue = {
        ...nfcOrderHistoryFilter,
        [key]: value,
      }

      localStorage.setItem(
        ORDER_LIST_FILTER,
        JSON.stringify({ nfc: changeValue, jCredit: findFilters().jCredit }),
      )

      return _setNfcOrderHistoryFilter(
        new GetNFCOrderHistoryListRequest(changeValue),
      )
    },
    [_setNfcOrderHistoryFilter, nfcOrderHistoryFilter],
  )

  const setJCreditOrderHistoryFilter = useCallback(
    ({ key, value }: JCreditOrderHistoryFilterProps) => {
      const changeValue = {
        ...jCreditOrderHistoryFilter,
        [key]: value,
      }

      localStorage.setItem(
        ORDER_LIST_FILTER,
        JSON.stringify({ nfc: findFilters().nfc, jCredit: changeValue }),
      )

      return _setJCreditOrderHistoryFilter(
        new GetJCreditOrderHistoryListRequest(changeValue),
      )
    },
    [_setJCreditOrderHistoryFilter, jCreditOrderHistoryFilter],
  )

  const clearFilter = useCallback(() => {
    localStorage.removeItem(ORDER_LIST_FILTER)
    _setNfcOrderHistoryFilter(INITIAL_NFC_ORDER_LIST_FILTER)
    _setJCreditOrderHistoryFilter(INITIAL_J_CREDIT_ORDER_LIST_FILTER)
  }, [_setNfcOrderHistoryFilter, _setJCreditOrderHistoryFilter])

  return {
    nfc: {
      filters: {
        state: nfcOrderHistoryFilter,
        setState: setNfcOrderHistoryFilter,
      },
      options: nfc.order.filterData,
    },
    jCredit: {
      filters: {
        state: jCreditOrderHistoryFilter,
        setState: setJCreditOrderHistoryFilter,
      },
      options: jCredit.order.filterData,
    },
    clearFilter,
  }
}
