import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { Order } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/model_pb'
import {
  Position,
  Position_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  VolumeAndUnitPriceModel,
  VolumeUnit,
  convertVolumeAndUnitPriceModel,
} from '@enechain/jcex-ui'
import { formatYYYYMMddHHmmss } from '@enechain/jcex-utils'

import {
  OrderStatusModel,
  convertOrderStatusModel,
} from '~/trader/models/orderStatus'

export type OrderModelForNFCOrderHistory = Pick<
  Order,
  'id' | 'generatorTypes'
> & {
  renewableEnergyAndTrackingStatus: RenewableEnergyAndTrackingStatus_Type
  displayPrefecturesName: string
  deliveryFiscalYear: string
  assignedTraderName: string
  position: Position
  volumeAndUnitPrice: VolumeAndUnitPriceModel
  createdAt: string
  orderStatus: OrderStatusModel
}

export function convertOrderModelListForNFCOrderHistoryModel(
  orders: Order[],
  volumeUnit: VolumeUnit,
): OrderModelForNFCOrderHistory[] {
  return orders.map((order) => {
    const position =
      order.position ??
      new Position({
        type: Position_Type.UNSPECIFIED,
        displayName: '',
      })

    return {
      id: order.id,
      generatorTypes: order.generatorTypes,
      renewableEnergyAndTrackingStatus:
        order.renewableEnergyAndTrackingStatus?.type ??
        RenewableEnergyAndTrackingStatus_Type.UNSPECIFIED,
      displayPrefecturesName: order.prefectures
        .map((prefecture) => prefecture.displayName)
        .join(', '),
      deliveryFiscalYear: order.deliveryFiscalYear?.displayName ?? '',
      assignedTraderName: order.assignedTrader?.name ?? '',
      position,
      volumeAndUnitPrice: convertVolumeAndUnitPriceModel(
        order.volumeAndUnitPrice,
        volumeUnit,
      ),
      createdAt:
        order.createdAt !== undefined
          ? formatYYYYMMddHHmmss(order.createdAt.toDate())
          : '',
      orderStatus: convertOrderStatusModel(order.orderStatus),
    }
  })
}
