import { Order } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/model_pb'
import { Position_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { formatYYYYMMddHHmmss } from '@enechain/jcex-utils'

import {
  OrderStatusModel,
  convertOrderStatusModel,
} from '~/trader/models/orderStatus'

export type OrderInformationModel = {
  position: Position_Type
  assignedTraderName: string
  createdAt: string
  updaterName: string
  lastUpdatedAt: string
  orderStatus: OrderStatusModel
}

export function convertOrderInformationModel(
  order: Order,
): OrderInformationModel {
  const orderStatus = convertOrderStatusModel(order.orderStatus)

  return {
    position: order.position?.type ?? Position_Type.UNSPECIFIED,
    assignedTraderName: order.assignedTrader?.name ?? '',
    createdAt:
      order.createdAt !== undefined
        ? formatYYYYMMddHHmmss(order.createdAt.toDate())
        : '',
    updaterName: order.lastUpdater?.name ?? '',
    lastUpdatedAt:
      order.lastUpdatedAt !== undefined
        ? formatYYYYMMddHHmmss(order.lastUpdatedAt.toDate())
        : '',
    orderStatus,
  }
}
