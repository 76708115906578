import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'
import { useVolumeUnitQueries } from '@enechain/jcex-ui'

import { NonFITOrderHistoryListColumnKey } from '~/trader/pages/HistoryList/components/NonFITNFC/OrderListTable/columnStyles'

export const NonFITOrderHistoryListTableHeaderRow: React.FC = () => {
  const { t } = useTranslation(['common', 'domain'])

  const selectedUnit = useVolumeUnitQueries()

  return (
    <FlexTableRow>
      <FlexTableCell<NonFITOrderHistoryListColumnKey> columnKey="position" />
      <FlexTableCell<NonFITOrderHistoryListColumnKey>
        columnKey="deliveryFiscalYear"
        contents={{
          text: t('domain:model.order.column.delivery_fiscal_year'),
        }}
      />
      <FlexTableCell<NonFITOrderHistoryListColumnKey>
        columnKey="renewableEnergyAndTrackingStatus"
        contents={{
          text: t(
            'domain:model.order.column.nfc.renewable_energy_and_tracking',
          ),
        }}
      />
      <FlexTableCell<NonFITOrderHistoryListColumnKey>
        columnKey="prefecture"
        contents={{ text: t('domain:shared.prefecture.title') }}
      />
      <FlexTableCell<NonFITOrderHistoryListColumnKey>
        columnKey="generatorType"
        contents={{ text: t('domain:shared.generator.title') }}
      />
      <FlexTableCell<NonFITOrderHistoryListColumnKey>
        columnKey="assignedTrader"
        contents={{
          text: t('domain:model.order.column.assigned_trader'),
        }}
      />

      <FlexTableCell<NonFITOrderHistoryListColumnKey>
        columnKey="unitPrice"
        contents={{ text: t('common:unit.yen_per_kwh') }}
      />
      <FlexTableCell<NonFITOrderHistoryListColumnKey>
        columnKey="volume"
        contents={{ text: t(`common:unit.${selectedUnit}`) }}
      />
      <FlexTableCell<NonFITOrderHistoryListColumnKey>
        columnKey="createdAt"
        contents={{ text: t('domain:shared.created_at') }}
      />
      <FlexTableCell<NonFITOrderHistoryListColumnKey>
        columnKey="orderStatus"
        contents={{ text: t('domain:shared.status') }}
      />
    </FlexTableRow>
  )
}
