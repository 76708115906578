import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { DealtOrder } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/model_pb'
import {
  Position,
  Position_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  VolumeAndUnitPriceModel,
  VolumeUnit,
  convertVolumeAndUnitPriceModel,
} from '@enechain/jcex-ui'
import { formatYYYYMMddHHmmss } from '@enechain/jcex-utils'

export type DealtOrderModelForNFCOrderHistory = Pick<
  DealtOrder,
  'dealId' | 'dealCode'
> & {
  position: Position
  renewableEnergyAndTrackingStatus: RenewableEnergyAndTrackingStatus_Type
  deliveryFiscalYear: string
  displayPrefectureName: string
  displayGeneratorTypeName: string
  volumeAndUnitPrice: VolumeAndUnitPriceModel
  dealAt: string
  counterpartyOrganizationName: string
}

export function convertDealtOrderModelListForNFCDealHistoryModel(
  orders: DealtOrder[],
  volumeUnit: VolumeUnit,
): DealtOrderModelForNFCOrderHistory[] {
  return orders.map((order) => {
    const position =
      order.position ??
      new Position({
        type: Position_Type.UNSPECIFIED,
        displayName: '',
      })

    return {
      dealId: order.dealId,
      dealCode: order.dealCode,
      generatorType: order.generatorType,
      position,
      renewableEnergyAndTrackingStatus:
        order.renewableEnergyAndTrackingStatus?.type ??
        RenewableEnergyAndTrackingStatus_Type.UNSPECIFIED,
      deliveryFiscalYear: order.deliveryFiscalYear?.displayName ?? '',
      displayPrefectureName: order.prefecture?.displayName ?? '',
      displayGeneratorTypeName: order.generatorType?.displayName ?? '',
      volumeAndUnitPrice: convertVolumeAndUnitPriceModel(
        order.volumeAndUnitPrice,
        volumeUnit,
      ),
      dealAt:
        order.dealtAt !== undefined
          ? formatYYYYMMddHHmmss(order.dealtAt.toDate())
          : '',
      counterpartyOrganizationName: order.counterPartyOrganization?.name ?? '',
    }
  })
}
