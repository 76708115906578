import * as React from 'react'

import { useTranslation } from 'react-i18next'

import {
  Flex,
  Grid,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import {
  ColumnContent,
  PrimitiveColors,
  Spacing,
  TextButton,
  Typography,
} from '@enechain/ecloud-designsystem'
import { FaGearRegular } from '@enechain/ecloud-icons'
import { GetOrderBookFilterResponse } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import {
  Position_Type,
  Prefecture,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  FilterCheckboxGroup,
  FilterContent,
  SelectBox,
} from '@enechain/jcex-ui'

import SelectFilterModal from '~/trader/pages/OrderBookList/components/JCredit/SelectFilterModal'
import { INITIAL_J_CREDIT_ORDER_BOOK_FILTER } from '~/trader/pages/OrderBookList/constants/filter'
import {
  FilterJCreditOrderBooks,
  GrayOutFilterJCreditOrderBooks,
  JCreditOrderBookFilterProps,
  JCreditOrderBookGrayOutProps,
} from '~/trader/pages/OrderBookList/types/jCreditFilter'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  filters: FilterJCreditOrderBooks
  grayOutFilters: GrayOutFilterJCreditOrderBooks
  options: GetOrderBookFilterResponse
  excludeOptions: {
    prefectures: Prefecture[]
  }
  clearFilter: () => void
}
const FilterInputs: React.FC<Props> = ({
  filters,
  grayOutFilters,
  options,
  excludeOptions,
  clearFilter,
}) => {
  const { t } = useTranslation(['domain', 'trader'])
  const disclosure = useDisclosure()

  const { positionTypes, deliveryFiscalYear, productId } = filters.state

  // todo: shallowEqual なので deepEqual に置き換える
  const isFilterEmpty = filters.state === INITIAL_J_CREDIT_ORDER_BOOK_FILTER

  const { isGrayOutEnabled, prefectures } = grayOutFilters.state

  const onChangeFilter = (value: JCreditOrderBookFilterProps): void => {
    filters.setState(value)
  }

  const onChangeGrayOutFilter = (value: JCreditOrderBookGrayOutProps): void => {
    grayOutFilters.setState(value)
  }

  return (
    <>
      <SelectFilterModal
        disclosure={disclosure}
        excludeOptions={excludeOptions}
        onClick={(value): void => {
          onChangeGrayOutFilter({
            key: 'prefectures',
            value: value.prefectures,
          })
        }}
        selectedValue={{
          prefectures,
        }}
      />
      <Flex
        alignItems="flex-end"
        justifyContent="space-between"
        gap={Spacing[6]}
      >
        <Stack flexGrow={1}>
          <FilterContent clear={clearFilter} isFilterEmpty={isFilterEmpty}>
            <Grid
              gridGap={Spacing[4]}
              gridAutoRows="repeat(2, 1fr)"
              gridTemplateColumns="repeat(3, 1fr)"
              width="100%"
            >
              <ColumnContent label={t('domain:shared.position.title')}>
                <FilterCheckboxGroup<Position_Type>
                  options={Object.fromEntries(
                    options.positions.length > 0
                      ? options.positions.map(({ type, displayName }) => [
                          displayName,
                          type,
                        ])
                      : [],
                  )}
                  selectedItem={positionTypes}
                  setSelectedItem={(value: Position_Type[]): void =>
                    onChangeFilter({
                      key: 'positionTypes',
                      value,
                    })
                  }
                />
              </ColumnContent>
              <SelectBox<number>
                maxWidth="25rem"
                backgroundColor={PrimitiveColors.white['']}
                label={t('domain:model.order.column.j_credit.product')}
                onChange={(value: Maybe<number>): void =>
                  onChangeFilter({
                    key: 'productId',
                    value:
                      value !== undefined && !isNaN(value)
                        ? Number(value)
                        : undefined,
                  })
                }
                selectable={Object.fromEntries(
                  options.products.length > 0
                    ? options.products.map(({ id, name }) => [id, name])
                    : [],
                )}
                selectedValue={productId}
              />
              <SelectBox<number>
                maxWidth="25rem"
                backgroundColor={PrimitiveColors.white['']}
                label={t('domain:model.order.column.delivery_fiscal_year')}
                onChange={(value: Maybe<number>): void =>
                  onChangeFilter({
                    key: 'deliveryFiscalYear',
                    value: value !== undefined ? Number(value) : undefined,
                  })
                }
                selectable={Object.fromEntries(
                  options.deliveryFiscalYears.length > 0
                    ? options.deliveryFiscalYears.map(
                        ({ year, displayName }) => [year, displayName],
                      )
                    : [],
                )}
                selectedValue={deliveryFiscalYear}
              />
            </Grid>
          </FilterContent>
        </Stack>
        <Flex alignItems="center" gap={Spacing[2]}>
          <Flex gap={Spacing[4]}>
            <Text {...Typography.headerSm}>
              {t('trader:page.order_book_list.gray_out')}
            </Text>
            <Switch
              colorScheme="green"
              isChecked={isGrayOutEnabled}
              onChange={(): void =>
                onChangeGrayOutFilter({
                  key: 'isGrayOutEnabled',
                  value: !isGrayOutEnabled,
                })
              }
            />
          </Flex>
          <TextButton
            leftIcon={<FaGearRegular width="24px" height="24px" />}
            onClick={disclosure.onOpen}
          >
            {t('trader:page.order_book_list.target_of_trade')}
          </TextButton>
        </Flex>
      </Flex>
    </>
  )
}

export default React.memo(FilterInputs)
