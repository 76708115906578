import { Transport } from '@connectrpc/connect'
import {
  callUnaryMethod,
  createConnectQueryKey,
} from '@connectrpc/connect-query'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunction, defer } from 'react-router-dom'

import {
  getOrderBookFilter as getJCreditOrderBookFilter,
  getOrderBookList as getJCreditOrderBookList,
} from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'
import {
  GetOrderBookFilterResponse as GetJCreditOrderBookFilterResponse,
  GetOrderBookListResponse as GetJCreditOrderBookListResponse,
} from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import {
  getOrderBookFilter as getNFCOrderBookFilter,
  getOrderBookList as getNFCOrderBookList,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import {
  GetOrderBookFilterResponse as GetNFCOrderBookFilterResponse,
  GetOrderBookListResponse as GetNFCOrderBookListResponse,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'

import {
  INITIAL_J_CREDIT_ORDER_BOOK_FILTER,
  INITIAL_NFC_ORDER_BOOK_FILTER,
} from '~/trader/pages/OrderBookList/constants/filter'

export type OrderBookListPageLoaderData = {
  nfc: {
    filterData: GetNFCOrderBookFilterResponse
    listData: GetNFCOrderBookListResponse
  }
  jCredit: {
    filterData: GetJCreditOrderBookFilterResponse
    listData: GetJCreditOrderBookListResponse
  }
}

export function orderBookListPageLoader(
  transport: Transport,
  queryClient: QueryClient,
): LoaderFunction {
  return async () => {
    const [filterNFCData, filterJCreditData, listNFCData, listJCreditData] =
      await Promise.all([
        // filterNFCData
        queryClient.ensureQueryData({
          queryKey: createConnectQueryKey(getNFCOrderBookFilter),
          queryFn: () =>
            callUnaryMethod(getNFCOrderBookFilter, {}, { transport }),
        }),
        // filterJCreditData
        queryClient.ensureQueryData({
          queryKey: createConnectQueryKey(getJCreditOrderBookFilter),
          queryFn: () =>
            callUnaryMethod(getJCreditOrderBookFilter, {}, { transport }),
        }),
        // listNFCData
        queryClient.ensureQueryData({
          queryKey: createConnectQueryKey(getNFCOrderBookList),
          queryFn: () =>
            callUnaryMethod(
              getNFCOrderBookList,
              INITIAL_NFC_ORDER_BOOK_FILTER,
              {
                transport,
              },
            ),
        }),
        // listJCreditData
        queryClient.ensureQueryData({
          queryKey: createConnectQueryKey(getJCreditOrderBookList),
          queryFn: () =>
            callUnaryMethod(
              getJCreditOrderBookList,
              INITIAL_J_CREDIT_ORDER_BOOK_FILTER,
              { transport },
            ),
        }),
      ])

    return defer({
      nfc: {
        filterData: filterNFCData,
        listData: listNFCData,
      },
      jCredit: {
        filterData: filterJCreditData,
        listData: listJCreditData,
      },
    })
  }
}
