export type NonFITOrderHistoryListColumnKey =
  | 'position'
  | 'deliveryFiscalYear'
  | 'renewableEnergyAndTrackingStatus'
  | 'prefecture'
  | 'generatorType'
  | 'assignedTrader'
  | 'unitPrice'
  | 'volume'
  | 'createdAt'
  | 'orderStatus'

export const nonFITOrderHistoryColumnStyles = {
  position: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '36px',
    },
  },
  deliveryFiscalYear: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.4,
    },
  },
  renewableEnergyAndTrackingStatus: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  prefecture: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.7,
    },
  },
  generatorType: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.7,
    },
  },
  assignedTrader: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.4,
    },
  },
  unitPrice: {
    alignment: 'right',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  volume: {
    alignment: 'right',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  createdAt: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  orderStatus: {
    alignment: 'center',
    width: {
      type: 'ratio',
      ratio: 0.3,
    },
  },
} as const
