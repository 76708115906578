import * as React from 'react'

import { Text } from '@chakra-ui/react'

import {
  BorderRadius,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import { Product } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/model/v1/model_pb'
import { RenewableEnergyAndTrackingStatus } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { useTheme } from '@enechain/jcex-ui'

import { getLabelStyle } from '~/trader/pages/CarbonIndex/components/TypeLabel/helper'

type Props = {
  value: RenewableEnergyAndTrackingStatus | Product
  deliveryFiscalYear?: string
}

export const TypeLabel: React.FC<Props> = ({ value, deliveryFiscalYear }) => {
  const theme = useTheme()
  const { backgroundColor, label } = getLabelStyle(value)

  return (
    <Text
      width="fit-content"
      color={theme.semanticTokens.colors.Text.highEmphasisInverse}
      textAlign="center"
      background={backgroundColor}
      borderRadius={BorderRadius.radiiBase}
      paddingX={Spacing[1]}
      paddingY={Spacing[0.5]}
      {...Typography.textLabel}
    >
      {label}
      {deliveryFiscalYear !== undefined ? ` - ${deliveryFiscalYear}` : ''}
    </Text>
  )
}
