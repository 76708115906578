import { useQuery } from '@connectrpc/connect-query'
import { useLoaderData } from 'react-router-dom'

import { getCreateOrderOption as getJCreditCreateOrderOption } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'
import { GetCreateOrderOptionResponse as JCreditGetCreateOrderOptionResponse } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import { getCreateOrderOption as getNFCCreateOrderOption } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service-Service_connectquery'
import { GetCreateOrderOptionResponse as NFCGetCreateOrderOptionResponse } from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'

import { CreateOrderPageLoaderData } from '~/trader/pages/CreateOrder/loader'
import {
  JCreditCreateOrderOptionModel,
  convertJCreditCreateOrderOptionModel,
} from '~/trader/pages/CreateOrder/models/JCreditCreateOrderOption'

type CreateOrderFormOptions = {
  nfc: NFCGetCreateOrderOptionResponse
  jCredit: JCreditCreateOrderOptionModel
}

type Result = {
  options: CreateOrderFormOptions
}

export function useCreateOrderOption(): Result {
  const {
    nfcOption,
    jCreditOption,
    nfcOptionUpdatedAt,
    jCreditOptionUpdatedAt,
  } = useLoaderData() as CreateOrderPageLoaderData

  const { data: nfcData } = useQuery(getNFCCreateOrderOption, {
    initialData: nfcOption,
    initialDataUpdatedAt: nfcOptionUpdatedAt,
  })

  const { data: jCreditData } = useQuery(getJCreditCreateOrderOption, {
    initialData: jCreditOption,
    initialDataUpdatedAt: jCreditOptionUpdatedAt,
  })

  return {
    options: {
      nfc: nfcData ?? new NFCGetCreateOrderOptionResponse(undefined),
      jCredit: convertJCreditCreateOrderOptionModel(
        jCreditData ?? new JCreditGetCreateOrderOptionResponse(undefined),
      ),
    },
  }
}
