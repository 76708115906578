import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Flex, Stack, UseDisclosureReturn } from '@chakra-ui/react'

import {
  Link,
  PrimaryModal,
  Spacing,
  useTheme,
} from '@enechain/ecloud-designsystem'
import { CircleXmark } from '@enechain/jcex-icons'
import { Prefecture } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { SelectPrefecturesWithRegion } from '../SelectPrefecturesWithRegion'

type Props = {
  selectedValue: number[]
  setSelectedValue: (value: number[]) => void
  options: Prefecture[]
  disclosure: UseDisclosureReturn
  onClick: () => void
  onCloseComplete: () => void
}

export const SelectPrefectureModal: React.FC<Props> = ({
  selectedValue,
  setSelectedValue,
  options,
  disclosure,
  onClick,
  onCloseComplete,
}) => {
  const { t } = useTranslation(['common', 'domain'])
  const theme = useTheme()

  const handleClick = (): void => {
    onClick()
    disclosure.onClose()
  }

  return (
    <PrimaryModal
      actionText={t('common:action.positive.save')}
      bodyChildren={
        <Stack spacing={Spacing[4]}>
          <SelectPrefecturesWithRegion
            options={options}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
          <Box position="relative" left={Spacing['-1']}>
            <Link
              as="button"
              color={theme.semanticTokens.colors.Text.link}
              onClick={(): void => setSelectedValue([])}
            >
              <Flex gap={Spacing[2]}>
                <CircleXmark width="24px" height="24px" />
                {t('common:action.neutral.check_all_clear')}
              </Flex>
            </Link>
          </Box>
        </Stack>
      }
      closeText={t('common:action.negative.cancel')}
      disclosure={disclosure}
      isCentered
      onClick={handleClick}
      onCloseComplete={onCloseComplete}
      size="3xl"
      title={t('common:title.of.choice', {
        value: t('domain:shared.prefecture.title'),
      })}
    />
  )
}
