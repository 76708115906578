import { Checkbox, CheckboxGroup, Flex, Text } from '@chakra-ui/react'

import { Spacing } from '@enechain/ecloud-designsystem'

type SelectProps<T> = {
  options: Record<string, T>
  selectedItem: T[]
  setSelectedItem: (value: T[]) => void
  isDisabled?: boolean
}

export const FilterCheckboxGroup = <T extends string | number>({
  options,
  selectedItem,
  setSelectedItem,
  isDisabled,
}: SelectProps<T>): React.ReactElement => {
  return (
    <CheckboxGroup
      isDisabled={isDisabled}
      onChange={(value: string[]): void => {
        // HTMLInputElement.value は常に string[] を返すので、options の value の最初の要素を見て 型 cast を隠蔽する
        if (typeof options[Object.keys(options)[0]] === 'number') {
          setSelectedItem(value.map((v) => Number(v)) as T[])
          return
        }

        setSelectedItem(value as T[])
      }}
      value={selectedItem}
    >
      <Flex gap={Spacing[2]} height="full">
        {Object.entries(options).map(([key, value]) => (
          <Checkbox key={key} value={value}>
            <Text whiteSpace="nowrap">{key}</Text>
          </Checkbox>
        ))}
      </Flex>
    </CheckboxGroup>
  )
}
