import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { Controller, FieldErrors, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'

import { Stack, Text } from '@chakra-ui/react'

import {
  Label,
  PrimitiveColors,
  RadioButton,
  SemanticColors,
  Spacing,
  Term,
} from '@enechain/ecloud-designsystem'
import {
  Position_Type,
  Prefecture_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  CertifiedNumberTooltip,
  NumberInputWithComma,
  PrefectureForm,
  SelectBox,
} from '@enechain/jcex-ui'
import { radioStyle } from '@enechain/jcex-ui/src/constants/radio'

import { CreditCertifiedForm } from '~/trader/components/features/CreditCertifiedForm'
import { J_CREDIT_CREDIT_CERTIFIED_NUMBER_LENGTH } from '~/trader/constants/jCredit'
import { useCreateOrderOption } from '~/trader/pages/CreateOrder/hooks/useCreateOrderOption'
import { JCreditCreateOrderSchema } from '~/trader/pages/CreateOrder/schemas/createJCreditOrderSchema'
import { CreateOrderSchema } from '~/trader/pages/CreateOrder/schemas/createOrderSchema'

type Props = {
  formMethods: UseFormReturn<CreateOrderSchema>
}

const JCreditProductForm: React.FC<Props> = ({ formMethods }) => {
  const { t } = useTranslation('domain')

  const watchedPositionType = formMethods.watch('position.type')
  const watchedPrefectureIds = formMethods.watch('prefectureIds')

  const {
    options: { jCredit: options },
  } = useCreateOrderOption()

  const formErrors = useMemo(() => {
    return formMethods.formState.errors as FieldErrors<JCreditCreateOrderSchema>
  }, [formMethods.formState.errors])

  const setAllPrefectures = useCallback((): void => {
    formMethods.setValue(
      'prefectureIds',
      options.prefectures
        .map(({ type }) => type)
        .filter((type) => type !== Prefecture_Type.UNSPECIFIED) ?? [],
    )
  }, [formMethods, options.prefectures])

  const isCertificatedLength =
    watchedPositionType === Position_Type.BID ||
    formMethods.watch('position.creditCertifiedNumber')?.length ===
      J_CREDIT_CREDIT_CERTIFIED_NUMBER_LENGTH

  useEffectOnce((): void => {
    setAllPrefectures()
  })

  return (
    <Stack spacing={Spacing[6]}>
      <Controller
        control={formMethods.control}
        name="productType"
        render={({ field: { onChange, value } }): React.ReactElement => (
          <Term>
            <Label required>{t('shared.product_type')}</Label>
            <RadioButton
              onChange={(value): void => onChange(Number(value))}
              radios={options.productTypes.map(({ type, displayName }) => ({
                ...radioStyle,
                value: type.toString(),
                label: displayName,
              }))}
              value={value.toString()}
            />
          </Term>
        )}
      />
      <Controller
        control={formMethods.control}
        name="position.type"
        render={({ field: { onChange, value } }): React.ReactElement => (
          <Term>
            <Label required>{t('shared.position.title')}</Label>
            <RadioButton
              onChange={(value): void => onChange(Number(value))}
              radios={options.positions.map(({ type, displayName }) => ({
                ...radioStyle,
                value: type.toString(),
                label: displayName,
              }))}
              value={
                watchedPositionType === Position_Type.BID ||
                watchedPositionType === Position_Type.OFFER
                  ? value.toString()
                  : ''
              }
            />
            <Text color={SemanticColors.Text.negativeMid}>
              {formErrors.position?.message}
            </Text>
          </Term>
        )}
      />
      <Controller
        control={formMethods.control}
        name="deliveryFiscalYear"
        render={({ field: { value } }): React.ReactElement => (
          <Term>
            <Label required>
              {t('model.order.column.delivery_fiscal_year')}
            </Label>
            <SelectBox<number>
              maxWidth="25rem"
              backgroundColor={PrimitiveColors.white['']}
              onChange={(value: number | undefined): void =>
                formMethods.setValue(
                  'deliveryFiscalYear',
                  value !== undefined ? Number(value) : undefined,
                )
              }
              selectable={Object.fromEntries(
                options.deliveryFiscalYears.length > 0
                  ? options.deliveryFiscalYears.map(({ year, displayName }) => [
                      Number(year),
                      displayName,
                    ])
                  : [],
              )}
              selectedValue={value}
            />
            <Text color={SemanticColors.Text.negativeMid}>
              {formErrors.deliveryFiscalYear?.message}
            </Text>
          </Term>
        )}
      />
      {watchedPositionType === Position_Type.OFFER && (
        <Controller
          control={formMethods.control}
          name="position.creditCertifiedNumber"
          render={({ field: { onChange, value } }): React.ReactElement => (
            <CreditCertifiedForm
              error={formErrors.position?.creditCertifiedNumber?.message}
              onChange={(value): void => {
                onChange(value.creditCertifiedNumber)
                formMethods.setValue('productId', value.productId)
                formMethods.setValue('prefectureIds', value.prefectureIds)
                formMethods.setValue(
                  'position.jCreditCertificationId',
                  value.certificationId,
                )
              }}
              selectedCertificationId={formMethods.watch(
                'position.jCreditCertificationId',
              )}
              value={value}
            />
          )}
        />
      )}
      <CertifiedNumberTooltip isHidden={isCertificatedLength}>
        <Controller
          control={formMethods.control}
          name="productId"
          render={({ field: { onChange, value } }): React.ReactElement => (
            <Term>
              <Label required>{t('model.order.column.j_credit.product')}</Label>
              <SelectBox<number>
                maxWidth="25rem"
                backgroundColor={PrimitiveColors.white['']}
                isDisabled={!isCertificatedLength}
                onChange={(value): void =>
                  onChange(value !== undefined ? Number(value) : undefined)
                }
                selectable={Object.fromEntries(
                  options.products.length > 0
                    ? options.products.map(({ id, name }) => [id, name])
                    : [],
                )}
                selectedValue={value}
              />
              <Text color={SemanticColors.Text.negativeMid}>
                {formErrors.productId?.message}
              </Text>
            </Term>
          )}
        />
      </CertifiedNumberTooltip>
      <CertifiedNumberTooltip isHidden={isCertificatedLength}>
        <PrefectureForm
          error={formErrors.prefectureIds?.message}
          isDisabled={!isCertificatedLength}
          onChange={(value): void =>
            formMethods.setValue('prefectureIds', value)
          }
          options={options.prefectures}
          productType={ProductType_Type.J_CREDIT}
          selectedPositionType={Position_Type.BID}
          values={watchedPrefectureIds ?? []}
        />
      </CertifiedNumberTooltip>
      <Controller
        control={formMethods.control}
        name="unitPrice"
        render={({ field: { onChange, value } }): React.ReactElement => (
          <Term>
            <Label required>{t('shared.unit_price.t_co2')}</Label>
            <NumberInputWithComma
              error={formErrors.unitPrice?.message}
              onChange={onChange}
              value={value}
            />
          </Term>
        )}
      />
      <Controller
        control={formMethods.control}
        name="volume"
        render={({ field: { onChange, value } }): React.ReactElement => (
          <Term>
            <Label required>{t('shared.volume.t_co2')}</Label>
            <NumberInputWithComma
              error={formErrors.volume?.message}
              onChange={onChange}
              value={value}
            />
          </Term>
        )}
      />
    </Stack>
  )
}

export default JCreditProductForm
