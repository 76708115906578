import * as React from 'react'
import { useMemo } from 'react'

import { useNavigate } from 'react-router-dom'

import { Stack, Text } from '@chakra-ui/react'

import {
  FlexTableCell,
  FlexTableRow,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'

import { useSetDuplicateJCreditOrder } from '~/trader/globalStates/duplicateOrderState'
import { DuplicateOrderButton } from '~/trader/pages/OrderBookList/components/DuplicateOrderButton'
import { JCreditOrderListColumnKey } from '~/trader/pages/OrderBookList/components/JCredit/ListTable/columnStyles'
import { JCreditOrderBookModel } from '~/trader/pages/OrderBookList/models/JCredit/orderBook'
import { PATH_CREATE_ORDER } from '~/trader/routes/routePaths'

type Props = {
  data: JCreditOrderBookModel
  index: number
  isGrayOutEnabled: boolean
}

const JCreditOrderBookListTableBodyRow: React.FC<Props> = ({
  data,
  index,
  isGrayOutEnabled,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const targetOrder = data.orders[index]

  const isDisabled = useMemo(
    () => isGrayOutEnabled && targetOrder.isGrayOutTarget,
    [isGrayOutEnabled, targetOrder.isGrayOutTarget],
  )

  const setDuplicateOrder = useSetDuplicateJCreditOrder({
    value: data,
    index,
    isRenderValue: true,
  })

  const onClickDuplicate = (): void => {
    setDuplicateOrder()
    navigate(PATH_CREATE_ORDER.path)
  }

  return (
    <Stack
      color={isDisabled ? theme.semanticTokens.colors.Text.disabled : ''}
      backgroundColor={
        isDisabled ? theme.semanticTokens.colors.Surface.secondary : ''
      }
    >
      <FlexTableRow>
        <FlexTableCell<JCreditOrderListColumnKey>
          columnKey="accordion"
          contents={{ text: '' }}
        />
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="deliveryFiscalYear">
          <Text
            color={
              isDisabled
                ? 'inherit'
                : theme.semanticTokens.colors.Text.highEmphasis
            }
            {...Typography.textMd}
          >
            {data.deliveryFiscalYear.displayName}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="productName">
          <Text
            color={
              isDisabled
                ? 'inherit'
                : theme.semanticTokens.colors.Text.highEmphasis
            }
            {...Typography.textMd}
          >
            {data.product.name}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="bidVolume">
          <Text
            color={
              isDisabled ? 'inherit' : theme.semanticTokens.colors.Text.bid
            }
            {...Typography.textMd}
          >
            {targetOrder.bid?.volume ?? '-'}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey>
          backgroundColor={theme.semanticTokens.colors.Surface.bid}
          columnKey="bidUnitPrice"
        >
          <Text
            color={
              isDisabled ? 'inherit' : theme.semanticTokens.colors.Text.bid
            }
            {...Typography.textMd}
            // https://www.figma.com/file/8zv5HckuxXZyTu1FHNDoJL?node-id=4463:84672&mode=design#644471460
            fontWeight={
              targetOrder.bid?.unitPrice === data.bestBid?.unitPrice
                ? 'bold'
                : 'normal'
            }
          >
            {targetOrder.bid?.unitPrice ?? '-'}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey>
          backgroundColor={theme.semanticTokens.colors.Surface.offer}
          columnKey="offerUnitPrice"
        >
          <Text
            color={
              isDisabled ? 'inherit' : theme.semanticTokens.colors.Text.offer
            }
            {...Typography.textMd}
            fontWeight={
              targetOrder.offer?.unitPrice === data.bestOffer?.unitPrice
                ? 'bold'
                : 'normal'
            }
          >
            {targetOrder.offer?.unitPrice ?? '-'}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="offerVolume">
          <Text
            color={
              isDisabled ? 'inherit' : theme.semanticTokens.colors.Text.offer
            }
            {...Typography.textMd}
          >
            {targetOrder.offer?.volume ?? '-'}
          </Text>
        </FlexTableCell>
        <FlexTableCell<JCreditOrderListColumnKey>
          columnKey="lastUnitPrice"
          contents={{ text: '' }}
        />
        <FlexTableCell<JCreditOrderListColumnKey> columnKey="copyOrderAction">
          <DuplicateOrderButton
            isDisabled={isDisabled}
            onClick={onClickDuplicate}
          />
        </FlexTableCell>
      </FlexTableRow>
    </Stack>
  )
}

export default React.memo(JCreditOrderBookListTableBodyRow)
