import { useQuery } from '@connectrpc/connect-query'
import { keepPreviousData } from '@tanstack/react-query'
import { useLoaderData } from 'react-router-dom'

import { getOrderBookList } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service-Service_connectquery'
import { GetOrderBookListRequest } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'

import { OrderBookListPageLoaderData } from '~/trader/pages/OrderBookList/loader'
import {
  JCreditOrderBookModel,
  convertJCreditOrderBookModel,
} from '~/trader/pages/OrderBookList/models/JCredit/orderBook'
import { GrayOutFilter } from '~/trader/pages/OrderBookList/types/jCreditFilter'

type Params = {
  filters: GetOrderBookListRequest
  grayOutFilters: GrayOutFilter
}

type Result = {
  jCreditData: JCreditOrderBookModel[]
  isLoading: boolean
}

export function useJCreditOrderBookList({
  filters,
  grayOutFilters,
}: Params): Result {
  const { jCredit: jCreditLoaderData } =
    useLoaderData() as OrderBookListPageLoaderData
  const { data, isPlaceholderData } = useQuery(getOrderBookList, filters, {
    placeholderData: keepPreviousData,
  })

  const jCreditData = convertJCreditOrderBookModel({
    orderBooks: data?.orderBooks ?? jCreditLoaderData.listData.orderBooks,
    filters: grayOutFilters,
  })

  return {
    jCreditData,
    isLoading: isPlaceholderData,
  }
}
