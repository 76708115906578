import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { SegmentedControl } from '@enechain/ecloud-designsystem'

import { HistoryTypeSegmentKey } from '~/trader/pages/HistoryList/constants/segmentKey'

type Props = {
  selectedOption: HistoryTypeSegmentKey
  onSelect: (option: HistoryTypeSegmentKey) => void
}

const HistoryTypeSegmentedControl: React.FC<Props> = ({
  selectedOption,
  onSelect,
}) => {
  const { t } = useTranslation('domain')
  const args = {
    name: 'historyType',
    options: [
      {
        label: t('model.order.title'),
        value: 'order' as HistoryTypeSegmentKey,
      },
      {
        label: t('model.deal.title'),
        value: 'deal' as HistoryTypeSegmentKey,
      },
    ],
    defaultValue: selectedOption,
  }

  return (
    <SegmentedControl<HistoryTypeSegmentKey>
      {...args}
      onSelect={onSelect}
      selected={selectedOption}
    />
  )
}

export default React.memo(HistoryTypeSegmentedControl)
