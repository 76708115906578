// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file jcex/bff/shared/model/v1/model.proto (package jcex.bff.shared.model.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * 符号
 *
 * @generated from enum jcex.bff.shared.model.v1.Sign
 */
export enum Sign {
  /**
   * 未指定
   *
   * @generated from enum value: SIGN_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * プラス
   *
   * @generated from enum value: SIGN_PLUS = 1;
   */
  PLUS = 1,

  /**
   * マイナス
   *
   * @generated from enum value: SIGN_MINUS = 2;
   */
  MINUS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Sign)
proto3.util.setEnumType(Sign, "jcex.bff.shared.model.v1.Sign", [
  { no: 0, name: "SIGN_UNSPECIFIED" },
  { no: 1, name: "SIGN_PLUS" },
  { no: 2, name: "SIGN_MINUS" },
]);

/**
 * 売買区分のモデル
 *
 * @generated from message jcex.bff.shared.model.v1.Position
 */
export class Position extends Message<Position> {
  /**
   * 売買区分種別
   *
   * @generated from field: jcex.bff.shared.model.v1.Position.Type type = 1;
   */
  type = Position_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<Position>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.Position";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(Position_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Position {
    return new Position().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Position {
    return new Position().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Position {
    return new Position().fromJsonString(jsonString, options);
  }

  static equals(a: Position | PlainMessage<Position> | undefined, b: Position | PlainMessage<Position> | undefined): boolean {
    return proto3.util.equals(Position, a, b);
  }
}

/**
 * @generated from enum jcex.bff.shared.model.v1.Position.Type
 */
export enum Position_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 売り
   *
   * @generated from enum value: TYPE_BID = 1;
   */
  BID = 1,

  /**
   * 買い
   *
   * @generated from enum value: TYPE_OFFER = 2;
   */
  OFFER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Position_Type)
proto3.util.setEnumType(Position_Type, "jcex.bff.shared.model.v1.Position.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_BID" },
  { no: 2, name: "TYPE_OFFER" },
]);

/**
 * 商品区分のモデル
 *
 * @generated from message jcex.bff.shared.model.v1.ProductType
 */
export class ProductType extends Message<ProductType> {
  /**
   * 商品区分種別
   *
   * @generated from field: jcex.bff.shared.model.v1.ProductType.Type type = 1;
   */
  type = ProductType_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<ProductType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.ProductType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ProductType_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductType {
    return new ProductType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductType {
    return new ProductType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductType {
    return new ProductType().fromJsonString(jsonString, options);
  }

  static equals(a: ProductType | PlainMessage<ProductType> | undefined, b: ProductType | PlainMessage<ProductType> | undefined): boolean {
    return proto3.util.equals(ProductType, a, b);
  }
}

/**
 * @generated from enum jcex.bff.shared.model.v1.ProductType.Type
 */
export enum ProductType_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 非FIT非化石証書
   *
   * @generated from enum value: TYPE_NFC = 1;
   */
  NFC = 1,

  /**
   * J-クレジット
   *
   * @generated from enum value: TYPE_J_CREDIT = 2;
   */
  J_CREDIT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductType_Type)
proto3.util.setEnumType(ProductType_Type, "jcex.bff.shared.model.v1.ProductType.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_NFC" },
  { no: 2, name: "TYPE_J_CREDIT" },
]);

/**
 * 　注文ステータスのモデル
 *
 * @generated from message jcex.bff.shared.model.v1.OrderStatus
 */
export class OrderStatus extends Message<OrderStatus> {
  /**
   * ステータス種別
   *
   * @generated from field: jcex.bff.shared.model.v1.OrderStatus.Type type = 1;
   */
  type = OrderStatus_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<OrderStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.OrderStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(OrderStatus_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderStatus {
    return new OrderStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderStatus {
    return new OrderStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderStatus {
    return new OrderStatus().fromJsonString(jsonString, options);
  }

  static equals(a: OrderStatus | PlainMessage<OrderStatus> | undefined, b: OrderStatus | PlainMessage<OrderStatus> | undefined): boolean {
    return proto3.util.equals(OrderStatus, a, b);
  }
}

/**
 * @generated from enum jcex.bff.shared.model.v1.OrderStatus.Type
 */
export enum OrderStatus_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 有効
   *
   * @generated from enum value: TYPE_ENABLED = 1;
   */
  ENABLED = 1,

  /**
   * 取消
   *
   * @generated from enum value: TYPE_CANCELED = 2;
   */
  CANCELED = 2,

  /**
   * 約定
   *
   * @generated from enum value: TYPE_DEALT = 3;
   */
  DEALT = 3,

  /**
   * 期限切れ
   *
   * @generated from enum value: TYPE_EXPIRED = 4;
   */
  EXPIRED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(OrderStatus_Type)
proto3.util.setEnumType(OrderStatus_Type, "jcex.bff.shared.model.v1.OrderStatus.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_ENABLED" },
  { no: 2, name: "TYPE_CANCELED" },
  { no: 3, name: "TYPE_DEALT" },
  { no: 4, name: "TYPE_EXPIRED" },
]);

/**
 * 受渡年度
 *
 * @generated from message jcex.bff.shared.model.v1.DeliveryFiscalYear
 */
export class DeliveryFiscalYear extends Message<DeliveryFiscalYear> {
  /**
   * 年度
   *
   * @generated from field: uint32 year = 1;
   */
  year = 0;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<DeliveryFiscalYear>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.DeliveryFiscalYear";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeliveryFiscalYear {
    return new DeliveryFiscalYear().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeliveryFiscalYear {
    return new DeliveryFiscalYear().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeliveryFiscalYear {
    return new DeliveryFiscalYear().fromJsonString(jsonString, options);
  }

  static equals(a: DeliveryFiscalYear | PlainMessage<DeliveryFiscalYear> | undefined, b: DeliveryFiscalYear | PlainMessage<DeliveryFiscalYear> | undefined): boolean {
    return proto3.util.equals(DeliveryFiscalYear, a, b);
  }
}

/**
 * 組織
 *
 * @generated from message jcex.bff.shared.model.v1.Organization
 */
export class Organization extends Message<Organization> {
  /**
   * 組織ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 組織名
   *
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<Organization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.Organization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization {
    return new Organization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJsonString(jsonString, options);
  }

  static equals(a: Organization | PlainMessage<Organization> | undefined, b: Organization | PlainMessage<Organization> | undefined): boolean {
    return proto3.util.equals(Organization, a, b);
  }
}

/**
 * マーケットへの公開ステータス
 *
 * @generated from message jcex.bff.shared.model.v1.OpenToMarketStatus
 */
export class OpenToMarketStatus extends Message<OpenToMarketStatus> {
  /**
   * 公開ステータス
   *
   * @generated from field: jcex.bff.shared.model.v1.OpenToMarketStatus.Type type = 1;
   */
  type = OpenToMarketStatus_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<OpenToMarketStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.OpenToMarketStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(OpenToMarketStatus_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OpenToMarketStatus {
    return new OpenToMarketStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OpenToMarketStatus {
    return new OpenToMarketStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OpenToMarketStatus {
    return new OpenToMarketStatus().fromJsonString(jsonString, options);
  }

  static equals(a: OpenToMarketStatus | PlainMessage<OpenToMarketStatus> | undefined, b: OpenToMarketStatus | PlainMessage<OpenToMarketStatus> | undefined): boolean {
    return proto3.util.equals(OpenToMarketStatus, a, b);
  }
}

/**
 * @generated from enum jcex.bff.shared.model.v1.OpenToMarketStatus.Type
 */
export enum OpenToMarketStatus_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 公開
   *
   * @generated from enum value: TYPE_OPEN = 1;
   */
  OPEN = 1,

  /**
   * 非公開
   *
   * @generated from enum value: TYPE_CLOSE = 2;
   */
  CLOSE = 2,

  /**
   * 気配値。気配値を作成する場合はこちらを指定してください。
   *
   * @generated from enum value: TYPE_INDICATION = 3;
   */
  INDICATION = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(OpenToMarketStatus_Type)
proto3.util.setEnumType(OpenToMarketStatus_Type, "jcex.bff.shared.model.v1.OpenToMarketStatus.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_OPEN" },
  { no: 2, name: "TYPE_CLOSE" },
  { no: 3, name: "TYPE_INDICATION" },
]);

/**
 * 数量と単価のセット
 *
 * @generated from message jcex.bff.shared.model.v1.VolumeAndUnitPrice
 */
export class VolumeAndUnitPrice extends Message<VolumeAndUnitPrice> {
  /**
   * 数量 カンマ区切りでフォーマットされた状態でレスポンスされる
   *
   * @generated from field: string volume = 1;
   */
  volume = "";

  /**
   * 単価 カンマ区切りでフォーマットされた状態でレスポンスされる
   *
   * @generated from field: string unit_price = 2;
   */
  unitPrice = "";

  constructor(data?: PartialMessage<VolumeAndUnitPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.VolumeAndUnitPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VolumeAndUnitPrice {
    return new VolumeAndUnitPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VolumeAndUnitPrice {
    return new VolumeAndUnitPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VolumeAndUnitPrice {
    return new VolumeAndUnitPrice().fromJsonString(jsonString, options);
  }

  static equals(a: VolumeAndUnitPrice | PlainMessage<VolumeAndUnitPrice> | undefined, b: VolumeAndUnitPrice | PlainMessage<VolumeAndUnitPrice> | undefined): boolean {
    return proto3.util.equals(VolumeAndUnitPrice, a, b);
  }
}

/**
 * トレーダー・作成者
 *
 * @generated from message jcex.bff.shared.model.v1.Trader
 */
export class Trader extends Message<Trader> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 名前
   *
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<Trader>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.Trader";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Trader {
    return new Trader().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Trader {
    return new Trader().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Trader {
    return new Trader().fromJsonString(jsonString, options);
  }

  static equals(a: Trader | PlainMessage<Trader> | undefined, b: Trader | PlainMessage<Trader> | undefined): boolean {
    return proto3.util.equals(Trader, a, b);
  }
}

/**
 * ブローカー
 *
 * @generated from message jcex.bff.shared.model.v1.Broker
 */
export class Broker extends Message<Broker> {
  /**
   * ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 名前
   *
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<Broker>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.Broker";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Broker {
    return new Broker().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Broker {
    return new Broker().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Broker {
    return new Broker().fromJsonString(jsonString, options);
  }

  static equals(a: Broker | PlainMessage<Broker> | undefined, b: Broker | PlainMessage<Broker> | undefined): boolean {
    return proto3.util.equals(Broker, a, b);
  }
}

/**
 * 取引の参加者
 *
 * @generated from message jcex.bff.shared.model.v1.DealParticipant
 */
export class DealParticipant extends Message<DealParticipant> {
  /**
   * 売買区分
   *
   * @generated from field: jcex.bff.shared.model.v1.Position position = 1;
   */
  position?: Position;

  /**
   * 確認書ID
   *
   * @generated from field: string recap_id = 2;
   */
  recapId = "";

  /**
   * 組織の名前
   *
   * @generated from field: string organization_name = 3;
   */
  organizationName = "";

  /**
   * トレーダーの名前
   *
   * @generated from field: string trader_name = 4;
   */
  traderName = "";

  /**
   * 仲介手数料 カンマ区切りでフォーマットされた状態でレスポンスされる
   *
   * @generated from field: string broking_fee = 5;
   */
  brokingFee = "";

  /**
   * 仲介高合計 カンマ区切りでフォーマットされた状態でレスポンスされる
   *
   * @generated from field: string total_broking_fee = 6;
   */
  totalBrokingFee = "";

  constructor(data?: PartialMessage<DealParticipant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.DealParticipant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "position", kind: "message", T: Position },
    { no: 2, name: "recap_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "trader_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "broking_fee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "total_broking_fee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DealParticipant {
    return new DealParticipant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DealParticipant {
    return new DealParticipant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DealParticipant {
    return new DealParticipant().fromJsonString(jsonString, options);
  }

  static equals(a: DealParticipant | PlainMessage<DealParticipant> | undefined, b: DealParticipant | PlainMessage<DealParticipant> | undefined): boolean {
    return proto3.util.equals(DealParticipant, a, b);
  }
}

/**
 * 都道府県のモデル
 *
 * @generated from message jcex.bff.shared.model.v1.Prefecture
 */
export class Prefecture extends Message<Prefecture> {
  /**
   * 都道府県種別
   *
   * @generated from field: jcex.bff.shared.model.v1.Prefecture.Type type = 1;
   */
  type = Prefecture_Type.UNSPECIFIED;

  /**
   * 表示名
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<Prefecture>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.Prefecture";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(Prefecture_Type) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Prefecture {
    return new Prefecture().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Prefecture {
    return new Prefecture().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Prefecture {
    return new Prefecture().fromJsonString(jsonString, options);
  }

  static equals(a: Prefecture | PlainMessage<Prefecture> | undefined, b: Prefecture | PlainMessage<Prefecture> | undefined): boolean {
    return proto3.util.equals(Prefecture, a, b);
  }
}

/**
 * @generated from enum jcex.bff.shared.model.v1.Prefecture.Type
 */
export enum Prefecture_Type {
  /**
   * 未指定
   *
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * 北海道
   *
   * @generated from enum value: TYPE_HOKKAIDO = 1;
   */
  HOKKAIDO = 1,

  /**
   * 青森県
   *
   * @generated from enum value: TYPE_AOMORI = 2;
   */
  AOMORI = 2,

  /**
   * 岩手県
   *
   * @generated from enum value: TYPE_IWATE = 3;
   */
  IWATE = 3,

  /**
   * 宮城県
   *
   * @generated from enum value: TYPE_MIYAGI = 4;
   */
  MIYAGI = 4,

  /**
   * 秋田県
   *
   * @generated from enum value: TYPE_AKITA = 5;
   */
  AKITA = 5,

  /**
   * 山形県
   *
   * @generated from enum value: TYPE_YAMAGATA = 6;
   */
  YAMAGATA = 6,

  /**
   * 福島県
   *
   * @generated from enum value: TYPE_FUKUSHIMA = 7;
   */
  FUKUSHIMA = 7,

  /**
   * 茨城県
   *
   * @generated from enum value: TYPE_IBARAKI = 8;
   */
  IBARAKI = 8,

  /**
   * 栃木県
   *
   * @generated from enum value: TYPE_TOCHIGI = 9;
   */
  TOCHIGI = 9,

  /**
   * 群馬県
   *
   * @generated from enum value: TYPE_GUNMA = 10;
   */
  GUNMA = 10,

  /**
   * 埼玉県
   *
   * @generated from enum value: TYPE_SAITAMA = 11;
   */
  SAITAMA = 11,

  /**
   * 千葉県
   *
   * @generated from enum value: TYPE_CHIBA = 12;
   */
  CHIBA = 12,

  /**
   * 東京都
   *
   * @generated from enum value: TYPE_TOKYO = 13;
   */
  TOKYO = 13,

  /**
   * 神奈川県
   *
   * @generated from enum value: TYPE_KANAGAWA = 14;
   */
  KANAGAWA = 14,

  /**
   * 新潟県
   *
   * @generated from enum value: TYPE_NIIGATA = 15;
   */
  NIIGATA = 15,

  /**
   * 富山県
   *
   * @generated from enum value: TYPE_TOYAMA = 16;
   */
  TOYAMA = 16,

  /**
   * 石川県
   *
   * @generated from enum value: TYPE_ISHIKAWA = 17;
   */
  ISHIKAWA = 17,

  /**
   * 福井県
   *
   * @generated from enum value: TYPE_FUKUI = 18;
   */
  FUKUI = 18,

  /**
   * 山梨県
   *
   * @generated from enum value: TYPE_YAMANASHI = 19;
   */
  YAMANASHI = 19,

  /**
   * 長野県
   *
   * @generated from enum value: TYPE_NAGANO = 20;
   */
  NAGANO = 20,

  /**
   * 岐阜県
   *
   * @generated from enum value: TYPE_GIFU = 21;
   */
  GIFU = 21,

  /**
   * 静岡県
   *
   * @generated from enum value: TYPE_SHIZUOKA = 22;
   */
  SHIZUOKA = 22,

  /**
   * 愛知県
   *
   * @generated from enum value: TYPE_AICHI = 23;
   */
  AICHI = 23,

  /**
   * 三重県
   *
   * @generated from enum value: TYPE_MIE = 24;
   */
  MIE = 24,

  /**
   * 滋賀県
   *
   * @generated from enum value: TYPE_SHIGA = 25;
   */
  SHIGA = 25,

  /**
   * 京都府
   *
   * @generated from enum value: TYPE_KYOTO = 26;
   */
  KYOTO = 26,

  /**
   * 大阪府
   *
   * @generated from enum value: TYPE_OSAKA = 27;
   */
  OSAKA = 27,

  /**
   * 兵庫県
   *
   * @generated from enum value: TYPE_HYOGO = 28;
   */
  HYOGO = 28,

  /**
   * 奈良県
   *
   * @generated from enum value: TYPE_NARA = 29;
   */
  NARA = 29,

  /**
   * 和歌山県
   *
   * @generated from enum value: TYPE_WAKAYAMA = 30;
   */
  WAKAYAMA = 30,

  /**
   * 鳥取県
   *
   * @generated from enum value: TYPE_TOTTORI = 31;
   */
  TOTTORI = 31,

  /**
   * 島根県
   *
   * @generated from enum value: TYPE_SHIMANE = 32;
   */
  SHIMANE = 32,

  /**
   * 岡山県
   *
   * @generated from enum value: TYPE_OKAYAMA = 33;
   */
  OKAYAMA = 33,

  /**
   * 広島県
   *
   * @generated from enum value: TYPE_HIROSHIMA = 34;
   */
  HIROSHIMA = 34,

  /**
   * 山口県
   *
   * @generated from enum value: TYPE_YAMAGUCHI = 35;
   */
  YAMAGUCHI = 35,

  /**
   * 徳島県
   *
   * @generated from enum value: TYPE_TOKUSHIMA = 36;
   */
  TOKUSHIMA = 36,

  /**
   * 香川県
   *
   * @generated from enum value: TYPE_KAGAWA = 37;
   */
  KAGAWA = 37,

  /**
   * 愛媛県
   *
   * @generated from enum value: TYPE_EHIME = 38;
   */
  EHIME = 38,

  /**
   * 高知県
   *
   * @generated from enum value: TYPE_KOCHI = 39;
   */
  KOCHI = 39,

  /**
   * 福岡県
   *
   * @generated from enum value: TYPE_FUKUOKA = 40;
   */
  FUKUOKA = 40,

  /**
   * 佐賀県
   *
   * @generated from enum value: TYPE_SAGA = 41;
   */
  SAGA = 41,

  /**
   * 長崎県
   *
   * @generated from enum value: TYPE_NAGASAKI = 42;
   */
  NAGASAKI = 42,

  /**
   * 熊本県
   *
   * @generated from enum value: TYPE_KUMAMOTO = 43;
   */
  KUMAMOTO = 43,

  /**
   * 大分県
   *
   * @generated from enum value: TYPE_OITA = 44;
   */
  OITA = 44,

  /**
   * 宮崎県
   *
   * @generated from enum value: TYPE_MIYAZAKI = 45;
   */
  MIYAZAKI = 45,

  /**
   * 鹿児島県
   *
   * @generated from enum value: TYPE_KAGOSHIMA = 46;
   */
  KAGOSHIMA = 46,

  /**
   * 沖縄県
   *
   * @generated from enum value: TYPE_OKINAWA = 47;
   */
  OKINAWA = 47,
}
// Retrieve enum metadata with: proto3.getEnumType(Prefecture_Type)
proto3.util.setEnumType(Prefecture_Type, "jcex.bff.shared.model.v1.Prefecture.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_HOKKAIDO" },
  { no: 2, name: "TYPE_AOMORI" },
  { no: 3, name: "TYPE_IWATE" },
  { no: 4, name: "TYPE_MIYAGI" },
  { no: 5, name: "TYPE_AKITA" },
  { no: 6, name: "TYPE_YAMAGATA" },
  { no: 7, name: "TYPE_FUKUSHIMA" },
  { no: 8, name: "TYPE_IBARAKI" },
  { no: 9, name: "TYPE_TOCHIGI" },
  { no: 10, name: "TYPE_GUNMA" },
  { no: 11, name: "TYPE_SAITAMA" },
  { no: 12, name: "TYPE_CHIBA" },
  { no: 13, name: "TYPE_TOKYO" },
  { no: 14, name: "TYPE_KANAGAWA" },
  { no: 15, name: "TYPE_NIIGATA" },
  { no: 16, name: "TYPE_TOYAMA" },
  { no: 17, name: "TYPE_ISHIKAWA" },
  { no: 18, name: "TYPE_FUKUI" },
  { no: 19, name: "TYPE_YAMANASHI" },
  { no: 20, name: "TYPE_NAGANO" },
  { no: 21, name: "TYPE_GIFU" },
  { no: 22, name: "TYPE_SHIZUOKA" },
  { no: 23, name: "TYPE_AICHI" },
  { no: 24, name: "TYPE_MIE" },
  { no: 25, name: "TYPE_SHIGA" },
  { no: 26, name: "TYPE_KYOTO" },
  { no: 27, name: "TYPE_OSAKA" },
  { no: 28, name: "TYPE_HYOGO" },
  { no: 29, name: "TYPE_NARA" },
  { no: 30, name: "TYPE_WAKAYAMA" },
  { no: 31, name: "TYPE_TOTTORI" },
  { no: 32, name: "TYPE_SHIMANE" },
  { no: 33, name: "TYPE_OKAYAMA" },
  { no: 34, name: "TYPE_HIROSHIMA" },
  { no: 35, name: "TYPE_YAMAGUCHI" },
  { no: 36, name: "TYPE_TOKUSHIMA" },
  { no: 37, name: "TYPE_KAGAWA" },
  { no: 38, name: "TYPE_EHIME" },
  { no: 39, name: "TYPE_KOCHI" },
  { no: 40, name: "TYPE_FUKUOKA" },
  { no: 41, name: "TYPE_SAGA" },
  { no: 42, name: "TYPE_NAGASAKI" },
  { no: 43, name: "TYPE_KUMAMOTO" },
  { no: 44, name: "TYPE_OITA" },
  { no: 45, name: "TYPE_MIYAZAKI" },
  { no: 46, name: "TYPE_KAGOSHIMA" },
  { no: 47, name: "TYPE_OKINAWA" },
]);

/**
 * 署名付きURL
 *
 * @generated from message jcex.bff.shared.model.v1.SignedUrl
 */
export class SignedUrl extends Message<SignedUrl> {
  /**
   * 署名付きURL
   *
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * URLの期限
   *
   * @generated from field: google.protobuf.Timestamp url_expiration = 2;
   */
  urlExpiration?: Timestamp;

  constructor(data?: PartialMessage<SignedUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "jcex.bff.shared.model.v1.SignedUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url_expiration", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrl {
    return new SignedUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrl {
    return new SignedUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrl {
    return new SignedUrl().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrl | PlainMessage<SignedUrl> | undefined, b: SignedUrl | PlainMessage<SignedUrl> | undefined): boolean {
    return proto3.util.equals(SignedUrl, a, b);
  }
}

