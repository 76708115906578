import * as React from 'react'

import { t } from 'i18next'

import { Flex, Grid, Text, Tooltip } from '@chakra-ui/react'

import { Spacing, Typography } from '@enechain/ecloud-designsystem'
import { GeneratorType_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

import { SemanticColors } from '../../../constants'
import GeneratorIcon from '../GeneratorIcon'

type Props = {
  children: React.ReactNode
}

const generatorTypes = [
  {
    type: GeneratorType_Type.SOLAR,
    label: t('domain:shared.generator.type.solar'),
  },
  {
    type: GeneratorType_Type.WIND,
    label: t('domain:shared.generator.type.wind'),
  },
  {
    type: GeneratorType_Type.HYDRO,
    label: t('domain:shared.generator.type.hydro'),
  },
  {
    type: GeneratorType_Type.BIOMASS,
    label: t('domain:shared.generator.type.biomass'),
  },
  {
    type: GeneratorType_Type.GEOTHERMAL,
    label: t('domain:shared.generator.type.geothermal'),
  },
]

const GeneratorTypesTooltip: React.FC<Props> = ({ children }) => {
  return (
    <Tooltip
      label={
        <Grid
          gridGap={Spacing[4]}
          gridRowGap={Spacing[4]}
          gridTemplateColumns="repeat(3, 1fr)"
          padding={Spacing[2]}
        >
          {generatorTypes.map((generatorType) => (
            <Flex key={generatorType.type} alignItems="center" gap={Spacing[1]}>
              <GeneratorIcon color="#FFFFFF" type={generatorType.type} />
              <Text
                color={SemanticColors.Text.highEmphasisInverse}
                {...Typography.textSm}
              >
                {generatorType.label}
              </Text>
            </Flex>
          ))}
        </Grid>
      }
    >
      {children}
    </Tooltip>
  )
}

export default React.memo(GeneratorTypesTooltip)
