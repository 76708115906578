import * as React from 'react'
import { useCallback } from 'react'

type Result = {
  scrollElementRef: React.RefObject<HTMLDivElement>
  handleScroll: () => void
}

export function useScrollRef(): Result {
  const ref = React.createRef<HTMLDivElement>()

  const scroll = useCallback(() => {
    if (ref.current == null) {
      return
    }

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }, [ref])

  return {
    scrollElementRef: ref,
    handleScroll: scroll,
  }
}
