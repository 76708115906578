import { useCallback, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { Box, FormControl, Stack, Switch, Text } from '@chakra-ui/react'

import { Spacing, Typography } from '@enechain/ecloud-designsystem'
import { findFeatures, toggleFeature } from '@enechain/jcex-libs'
import { isEnechainUser } from '@enechain/jcex-utils'

export const FlagSettingPage: React.FC = () => {
  const { user } = useAuth0()
  const { t } = useTranslation('component')
  const [features, setFeatures] = useState(findFeatures())

  const handleChange = useCallback((name: string) => {
    setFeatures(toggleFeature(name))
  }, [])

  if (user === undefined || !isEnechainUser(user)) {
    return <Navigate replace to="/" />
  }

  return (
    <Box
      paddingTop={Spacing[2]}
      paddingBottom={Spacing[8]}
      paddingX={Spacing[6]}
    >
      <Text {...Typography.headerXL} paddingBottom={Spacing[4]}>
        {t('layouts.flag_setting_page.title')}
      </Text>
      <Stack spacing={Spacing[4]}>
        {features.map((feature) => {
          return (
            <Stack key={feature.name} spacing={Spacing[2]}>
              <FormControl alignItems="center" gap={Spacing[4]} display="flex">
                <Text {...Typography.headerMd}>{feature.name}</Text>
                <Switch
                  id={feature.name}
                  isChecked={feature.active}
                  onChange={(): void => handleChange(feature.name)}
                  size="md"
                />
              </FormControl>
              <Text {...Typography.textMd}>{feature.description}</Text>
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
}
