import * as React from 'react'

import { Flex, SkeletonText, Text } from '@chakra-ui/react'

import { Spacing, Typography } from '@enechain/ecloud-designsystem'

import ProfileImage from '../../../common/ProfileImage'

export type OrganizationProfileHeaderProps = {
  name: string | undefined
  src: string | undefined
}

const OrganizationProfileHeader: React.FC<OrganizationProfileHeaderProps> = ({
  name,
  src,
}: OrganizationProfileHeaderProps) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="row"
      gap={Spacing[4]}
      paddingX={Spacing[2]}
    >
      <ProfileImage size="24px" src={src} />

      {name != null ? (
        <Text flexGrow={1} {...Typography.headerSm}>
          {name}
        </Text>
      ) : (
        <SkeletonText flexGrow={1} noOfLines={1} />
      )}
    </Flex>
  )
}

export default React.memo(OrganizationProfileHeader)
