import * as React from 'react'

import { Flex } from '@chakra-ui/react'

import {
  BorderRadius,
  Divider,
  FlexTable,
  FlexTableHeader,
  Spacing,
} from '@enechain/ecloud-designsystem'
import {
  ChangeVolumeUnitControl,
  EmptyTableBody,
  TableBodyWithLoading,
} from '@enechain/jcex-ui'

import { nonFITDealHistoryColumnStyles } from '~/trader/pages/HistoryList/components/NonFITNFC/DealListTable/columnStyles'
import NonFITOrderDealListTableBodyRow from '~/trader/pages/HistoryList/components/NonFITNFC/DealTableRow/body'
import { NonFITDealHistoryListTableHeaderRow } from '~/trader/pages/HistoryList/components/NonFITNFC/DealTableRow/header'
import { useNFCDealHistoryList } from '~/trader/pages/HistoryList/hooks/useNFCDealHistoryList'
import { FilterNFCDealHistories } from '~/trader/pages/HistoryList/types/nfcFilter'

type Props = {
  filters: FilterNFCDealHistories
}

const NonFITDealHistoryListTable: React.FC<Props> = ({ filters }) => {
  const { nfcData, isLoading } = useNFCDealHistoryList({
    params: filters.state,
  })

  // memo(shunya): sticky 対応は Design System の I/F にいれる
  return (
    <Flex flexDirection="column" gap={Spacing[4]}>
      <Flex justifyContent="end">
        <ChangeVolumeUnitControl />
      </Flex>
      <FlexTable
        borderWidth={1}
        borderRadius={BorderRadius.radiiLg}
        columnStyles={nonFITDealHistoryColumnStyles}
      >
        <FlexTableHeader style="multi-line">
          <NonFITDealHistoryListTableHeaderRow />
        </FlexTableHeader>
        <TableBodyWithLoading isLoading={isLoading} style="multi-line">
          {nfcData.length === 0 ? (
            <EmptyTableBody />
          ) : (
            nfcData.map((data) => (
              <React.Fragment key={data.dealId}>
                <Divider />
                <NonFITOrderDealListTableBodyRow data={data} />
              </React.Fragment>
            ))
          )}
        </TableBodyWithLoading>
      </FlexTable>
    </Flex>
  )
}

export default React.memo(NonFITDealHistoryListTable)
