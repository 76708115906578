import { t } from 'i18next'
import { z } from 'zod'

import {
  Position_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { J_CREDIT_CREDIT_CERTIFIED_NUMBER_LENGTH } from '~/trader/constants/jCredit'

const jCreditCreateBidOrderSchema = z.object({
  type: z.literal(Position_Type.BID),
  creditCertifiedNumber: z.string().optional(),
  jCreditCertificationId: z.string().optional(),
})

const jCreditCreateOfferOrderSchema = z.object({
  type: z.literal(Position_Type.OFFER),
  creditCertifiedNumber: z
    .string()
    .min(J_CREDIT_CREDIT_CERTIFIED_NUMBER_LENGTH, {
      message: t('validation:errors.custom.credit_certified_number'),
    })
    .max(J_CREDIT_CREDIT_CERTIFIED_NUMBER_LENGTH, {
      message: t('validation:errors.custom.credit_certified_number'),
    }),
  jCreditCertificationId: z.string().optional(),
})

export const createJCreditOrderSchema = z.object({
  productType: z.literal(ProductType_Type.J_CREDIT),
  deliveryFiscalYear: z
    .number()
    .optional()
    .refine((v) => v !== undefined, {
      params: { i18n: 'validation:errors.custom.required_select' },
    }),
  productId: z
    .number()
    .optional()
    .refine((v) => v !== undefined, {
      params: { i18n: 'validation:errors.custom.required_select' },
    }),
  prefectureIds: z
    .array(z.number(), {
      required_error: t('validation:errors.custom.required_select'),
    })
    .min(1, {
      message: t('validation:errors.custom.required_select'),
    }),
  unitPrice: z
    .string()
    .refine(
      (v) => !isNaN(Number(v)) && Number.isInteger(Number(v)) && Number(v) > 0,
      {
        params: { i18n: 'validation:errors.custom.positive_integer' },
      },
    ),
  volume: z
    .string()
    .refine(
      (v) => !isNaN(Number(v)) && Number(v) > 0 && Number.isInteger(Number(v)),
      {
        params: { i18n: 'validation:errors.custom.positive_integer' },
      },
    ),
  position: z.discriminatedUnion('type', [
    jCreditCreateBidOrderSchema,
    jCreditCreateOfferOrderSchema,
  ]),
})

export type JCreditCreateOrderSchema = z.infer<typeof createJCreditOrderSchema>
