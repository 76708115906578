import { useCallback, useMemo, useState } from 'react'

import { useLoaderData } from 'react-router-dom'

import {
  GetOrderBookFilterResponse as GetJCreditOrderBookFilterResponse,
  GetOrderBookListRequest as GetJCreditOrderBookListRequest,
} from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/service_pb'
import {
  GeneratorType,
  GeneratorType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import {
  GetOrderBookFilterResponse as GetNFCOrderBookFilterResponse,
  GetOrderBookListRequest as GetNFCOrderBookListRequest,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/trader/v1/service_pb'
import {
  Prefecture,
  Prefecture_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { ORDER_BOOK_FILTER } from '~/trader/constants/localStorageKey'
import {
  INITIAL_J_CREDIT_ORDER_BOOK_FILTER,
  INITIAL_NFC_ORDER_BOOK_FILTER,
} from '~/trader/pages/OrderBookList/constants/filter'
import { OrderBookListPageLoaderData } from '~/trader/pages/OrderBookList/loader'
import {
  FilterJCreditOrderBooks,
  GrayOutFilterJCreditOrderBooks,
  JCreditOrderBookFilterProps,
  JCreditOrderBookGrayOutProps,
} from '~/trader/pages/OrderBookList/types/jCreditFilter'
import {
  FilterNFCOrderBooks,
  GrayOutFilter,
  GrayOutFilterNFCOrderBooks,
  NFCOrderBookFilterProps,
  NFCOrderBookGrayOutProps,
} from '~/trader/pages/OrderBookList/types/nfcFilter'

type Result = {
  nfc: {
    filters: FilterNFCOrderBooks
    grayOutFilters: GrayOutFilterNFCOrderBooks
    options: GetNFCOrderBookFilterResponse
    // todo: Response から exclude したタイミングで統合する
    excludeOptions: {
      prefectures: Prefecture[]
      generatorTypes: GeneratorType[]
    }
  }
  jCredit: {
    filters: FilterJCreditOrderBooks
    grayOutFilters: GrayOutFilterJCreditOrderBooks
    options: GetJCreditOrderBookFilterResponse
    // todo: Response から exclude したタイミングで統合する
    excludeOptions: {
      prefectures: Prefecture[]
    }
  }
  clearFilter: () => void
}

function findFilters(): {
  nfc: GetNFCOrderBookListRequest
  jCredit: GetJCreditOrderBookListRequest
} {
  return JSON.parse(localStorage.getItem(ORDER_BOOK_FILTER) ?? '{}') as {
    nfc: GetNFCOrderBookListRequest
    jCredit: GetJCreditOrderBookListRequest
  }
}

export function useOrderBookFilter(): Result {
  const { nfc, jCredit } = useLoaderData() as OrderBookListPageLoaderData

  const excludeUnspecifiedPrefectures = useMemo(() => {
    return nfc.filterData.prefectures.filter(
      ({ type }) => type !== Prefecture_Type.UNSPECIFIED,
    )
  }, [nfc.filterData.prefectures])

  const excludeUnspecifiedGeneratorTypes = useMemo(() => {
    return nfc.filterData.generatorTypes.filter(
      ({ type }) => type !== GeneratorType_Type.UNSPECIFIED,
    )
  }, [nfc.filterData.generatorTypes])

  const [nfcOrderBookFilter, _setNfcOrderBookFilter] =
    useState<GetNFCOrderBookListRequest>(
      new GetNFCOrderBookListRequest({
        ...findFilters().nfc,
        ...INITIAL_NFC_ORDER_BOOK_FILTER,
      }),
    )

  const [jCreditOrderBookFilter, _setJCreditOrderBookFilter] =
    useState<GetJCreditOrderBookListRequest>(
      new GetJCreditOrderBookListRequest({
        ...findFilters().jCredit,
        ...INITIAL_J_CREDIT_ORDER_BOOK_FILTER,
      }),
    )

  const setNfcOrderBookFilter = useCallback(
    ({ key, value }: NFCOrderBookFilterProps) => {
      const changeValue = {
        ...nfcOrderBookFilter,
        [key]: value,
      }

      localStorage.setItem(
        ORDER_BOOK_FILTER,
        JSON.stringify({ nfc: changeValue, jCredit: findFilters().jCredit }),
      )

      return _setNfcOrderBookFilter(new GetNFCOrderBookListRequest(changeValue))
    },
    [_setNfcOrderBookFilter, nfcOrderBookFilter],
  )

  const setJCreditOrderBookFilter = useCallback(
    ({ key, value }: JCreditOrderBookFilterProps) => {
      const changeValue = {
        ...jCreditOrderBookFilter,
        [key]: value,
      }

      localStorage.setItem(
        ORDER_BOOK_FILTER,
        JSON.stringify({ nfc: findFilters().nfc, jCredit: changeValue }),
      )

      return _setJCreditOrderBookFilter(
        new GetJCreditOrderBookListRequest(changeValue),
      )
    },
    [_setJCreditOrderBookFilter, jCreditOrderBookFilter],
  )

  const clearFilter = useCallback(() => {
    localStorage.removeItem(ORDER_BOOK_FILTER)
    _setNfcOrderBookFilter(INITIAL_NFC_ORDER_BOOK_FILTER)
    _setJCreditOrderBookFilter(INITIAL_J_CREDIT_ORDER_BOOK_FILTER)
  }, [_setNfcOrderBookFilter, _setJCreditOrderBookFilter])

  const [nfcOrderBookGrayOutFilter, _setNfcOrderBookGrayOutFilter] =
    useState<GrayOutFilter>({
      isGrayOutEnabled: false,
      generatorTypes: [],
      prefectures: [],
    })

  const [jCreditOrderBookGrayOutFilter, _setJCreditOrderBookGrayOutFilter] =
    useState<GrayOutFilter>({
      isGrayOutEnabled: false,
      generatorTypes: [],
      prefectures: [],
    })

  const setNfcOrderBookGrayOutFilter = useCallback(
    ({ key, value }: NFCOrderBookGrayOutProps) => {
      return _setNfcOrderBookGrayOutFilter((prev) => {
        return {
          ...prev,
          [key]: value,
        }
      })
    },
    [_setNfcOrderBookGrayOutFilter],
  )

  const setJCreditOrderBookGrayOutFilter = useCallback(
    ({ key, value }: JCreditOrderBookGrayOutProps) => {
      return _setJCreditOrderBookGrayOutFilter((prev) => {
        return {
          ...prev,
          [key]: value,
        }
      })
    },
    [_setJCreditOrderBookGrayOutFilter],
  )

  return {
    nfc: {
      filters: {
        state: nfcOrderBookFilter,
        setState: setNfcOrderBookFilter,
      },
      grayOutFilters: {
        state: nfcOrderBookGrayOutFilter,
        setState: setNfcOrderBookGrayOutFilter,
      },
      options: nfc.filterData,
      excludeOptions: {
        prefectures: excludeUnspecifiedPrefectures,
        generatorTypes: excludeUnspecifiedGeneratorTypes,
      },
    },
    jCredit: {
      filters: {
        state: jCreditOrderBookFilter,
        setState: setJCreditOrderBookFilter,
      },
      grayOutFilters: {
        state: jCreditOrderBookGrayOutFilter,
        setState: setJCreditOrderBookGrayOutFilter,
      },
      options: jCredit.filterData,
      excludeOptions: {
        // nfc, jcredit で prefectures は同じなので nfc のものを使う
        prefectures: excludeUnspecifiedPrefectures,
      },
    },
    clearFilter,
  }
}
