import { Order } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/trader/v1/model_pb'
import {
  Position,
  Position_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { formatYYYYMMddHHmmss, stringUtils } from '@enechain/jcex-utils'

import {
  OrderStatusModel,
  convertOrderStatusModel,
} from '~/trader/models/orderStatus'

export type OrderModelForJCreditOrderHistory = Pick<Order, 'id'> & {
  position: Position
  deliveryFiscalYear: string
  productName: string
  displayPrefecturesName: string
  assignedTraderName: string
  unitPrice: string
  volume: string
  createdAt: string
  orderStatus: OrderStatusModel
}

export function convertOrderModelListForJCreditOrderHistoryModel(
  orders: Order[],
): OrderModelForJCreditOrderHistory[] {
  return orders.map((order) => {
    const position =
      order.position ??
      new Position({
        type: Position_Type.UNSPECIFIED,
        displayName: '',
      })

    return {
      id: order.id,
      position,
      deliveryFiscalYear: order.deliveryFiscalYear?.displayName ?? '',
      productName: order.product?.name ?? '',
      displayPrefecturesName: order.prefectures
        .map((prefecture) => prefecture.displayName)
        .join(', '),
      assignedTraderName: order.assignedTrader?.name ?? '',
      unitPrice: order.volumeAndUnitPrice?.unitPrice ?? '',
      volume: getOrderVolumeWithTBD(order.volumeAndUnitPrice?.volume),
      createdAt:
        order.createdAt !== undefined
          ? formatYYYYMMddHHmmss(order.createdAt.toDate())
          : '',
      orderStatus: convertOrderStatusModel(order.orderStatus),
    }
  })
}

function getOrderVolumeWithTBD(volume?: string): string {
  if (volume === undefined) {
    return ''
  }
  if (stringUtils.isEmpty(volume)) {
    return 'TBD'
  }
  return volume
}
