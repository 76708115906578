import * as React from 'react'

import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Flex,
} from '@chakra-ui/react'

import {
  BorderRadius,
  Divider,
  FlexTable,
  FlexTableBody,
  FlexTableHeader,
  SemanticColors,
  Spacing,
  useAccordion,
} from '@enechain/ecloud-designsystem'
import {
  AccordionAllButton,
  ChangeVolumeUnitControl,
  EmptyTableBody,
  TableBodyWithLoading,
} from '@enechain/jcex-ui'

import { nonFITOrderColumnStyles } from '~/trader/pages/OrderBookList/components/NonFITNFC/ListTable/columnStyles'
import NonFITOrderBookListTableBodyRow from '~/trader/pages/OrderBookList/components/NonFITNFC/TableRow/body'
import NonFITOrderBookListTableBodyRowWithAccordion from '~/trader/pages/OrderBookList/components/NonFITNFC/TableRow/bodyWithAccordion'
import { NonFITOrderBookListTableHeaderRow } from '~/trader/pages/OrderBookList/components/NonFITNFC/TableRow/header'
import { useNFCOrderBookList } from '~/trader/pages/OrderBookList/hooks/useNFCOrderBookList'
import {
  FilterNFCOrderBooks,
  GrayOutFilterNFCOrderBooks,
} from '~/trader/pages/OrderBookList/types/nfcFilter'

type Props = {
  filters: FilterNFCOrderBooks
  grayOutFilters: GrayOutFilterNFCOrderBooks
}

const NonFITOrderBookListTable: React.FC<Props> = ({
  filters,
  grayOutFilters,
}) => {
  const { nfcData, isLoading } = useNFCOrderBookList({
    filters: filters.state,
    grayOutFilters: grayOutFilters.state,
  })

  const {
    onCollapseAll,
    onExpandAll,
    index: expandedIndexes,
    onChange,
    isAllCollapsed,
  } = useAccordion(nfcData)

  // memo(shunya): sticky 対応は Design System の I/F にいれる
  return (
    <Flex flexDirection="column" gap={Spacing[2]}>
      <Flex alignItems="center" justifyContent="end" gap={Spacing[4]}>
        <ChangeVolumeUnitControl />
        <AccordionAllButton
          isAllCollapsed={isAllCollapsed}
          onClickCollapseAll={onCollapseAll}
          onClickExpandAll={onExpandAll}
        />
      </Flex>
      <FlexTable
        borderWidth={1}
        borderRadius={BorderRadius.radiiLg}
        columnStyles={nonFITOrderColumnStyles}
      >
        <FlexTableHeader style="multi-line">
          <NonFITOrderBookListTableHeaderRow />
        </FlexTableHeader>
        <TableBodyWithLoading isLoading={isLoading} style="multi-line">
          {nfcData.length === 0 ? (
            <EmptyTableBody />
          ) : (
            <Accordion
              borderColor={SemanticColors.Border.midEmphasis}
              allowMultiple
              index={expandedIndexes}
              onChange={onChange}
            >
              {nfcData.map((item) => (
                <AccordionItem
                  key={`${item.deliveryFiscalYear.year ?? 0}-${
                    item.renewableEnergyAndTrackingStatus
                  }`}
                  _last={{ borderBottomWidth: 0 }}
                >
                  {({ isExpanded }): React.ReactElement => (
                    <>
                      <Divider />
                      <NonFITOrderBookListTableBodyRowWithAccordion
                        data={item}
                        isExpanded={isExpanded}
                        // 0 の FadeOut は isExpanded = true ならそのまま [0] で check, false なら items isFadeOut を all check する
                        isGrayOutEnabled={
                          isExpanded
                            ? grayOutFilters.state.isGrayOutEnabled
                            : grayOutFilters.state.isGrayOutEnabled &&
                              item.isGrayOutAllItem
                        }
                      />
                      <AccordionPanel padding={Spacing[0]}>
                        <FlexTableBody style="multi-line">
                          {item.orders.length > 0 &&
                            item.orders
                              .slice(item.lastUnitPrice !== undefined ? 0 : 1)
                              .map((order, orderIndex) => (
                                <NonFITOrderBookListTableBodyRow
                                  key={order.id}
                                  data={item}
                                  index={
                                    orderIndex +
                                    (item.lastUnitPrice !== undefined ? 0 : 1)
                                  }
                                  isGrayOutEnabled={
                                    grayOutFilters.state.isGrayOutEnabled
                                  }
                                />
                              ))}
                        </FlexTableBody>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </TableBodyWithLoading>
      </FlexTable>
    </Flex>
  )
}

export default React.memo(NonFITOrderBookListTable)
