import { Timestamp, protoInt64 } from '@bufbuild/protobuf'
import { format } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

export function formatYYM(date: Date | string): string {
  return format(new Date(date), `yy/M`)
}

export function formatYYYYM(date: Date | string): string {
  return format(new Date(date), `yyyy/M`)
}

export function formatYYYYMM(date: Date | string): string {
  return format(new Date(date), `yyyy/MM`)
}

export function formatMd(date: Date | string): string {
  return format(new Date(date), `M/d`)
}

export function formatYYYY(date: Date | string): string {
  return format(new Date(date), `yyyy`)
}

export function formatYYYYMMJp(date: Date | string): string {
  return format(new Date(date), `yyyy年MM月`)
}

export function formatYYYYMMddJp(date: Date | string): string {
  return format(new Date(date), `yyyy年MM月dd日`)
}

export function formatYYYYMMdd(
  date: Date | string,
  delimiter?: '/' | '-',
): string {
  return format(
    new Date(date),
    delimiter ? `yyyy${delimiter}MM${delimiter}dd` : 'yyyyMMdd',
  )
}

export function formatYYYYMMddHHmmss(date: Date | string): string {
  return format(new Date(date), 'yyyy/MM/dd HH:mm:ss')
}

export function formatYYYYMMdd_HHmm(date: Date | string): string {
  return format(new Date(date), 'yyyyMMdd-HHmm')
}

/**
 * Date から yyyy/mm/dd を抽出して、ISOString の形式に変換する
 * 時差情報を無くし、当日の 00.00.00 にする, カレンダーの i18n に合わせて追従が必要
 */
export function formatISODate(date: Date | string): string {
  return formatYYYYMMdd(date, '-') + 'T00:00:00.000Z'
}

/**
 * TimeStampを受け取り、年月日だけを採用して、JSTのDateとして変換する
 */
export function formatTimeStampToJSTYYYYMMddDate(timestamp: Timestamp): Date {
  const date = timestamp.toDate()

  const utcYearString = String(date.getUTCFullYear()).padStart(4, '0')
  const utcMonthString = String(date.getUTCMonth() + 1).padStart(2, '0')
  const utcDateString = String(date.getUTCDate()).padStart(2, '0')
  const utcHourString = String(date.getUTCHours()).padStart(2, '0')

  return toZonedTime(
    new Date(
      `${utcYearString}-${utcMonthString}-${utcDateString}:${utcHourString}:00:00.000Z`,
    ),
    'Asia/Tokyo',
  )
}

/**
 * Dateを受け取り、年月日だけを採用し強制的にJSTのZoneでのTimeStampに変換する
 */
export function formatDateToJSTYYYYMMddTimeStamp(date: Date): Timestamp {
  const yearString = String(date.getFullYear()).padStart(4, '0')
  const monthString = String(date.getMonth() + 1).padStart(2, '0')
  const dateString = String(date.getDate()).padStart(2, '0')

  const jstDate = new Date(
    `${yearString}-${monthString}-${dateString}T00:00:00.000+09:00`,
  )

  const ms = jstDate.getTime()
  return new Timestamp({
    seconds: protoInt64.parse(Math.floor(ms / 1000)),
    nanos: (ms % 1000) * 1000000,
  })
}
