import { User } from '@auth0/auth0-react'

import { isEnechainUser } from '@enechain/jcex-utils'

import { defaultFlags } from './flags'
import { FeatureFlagSetting } from './types'

const FEATURE_FLAGS_KEY = 'featureFlags'
export const FEATURE_FLAG_SWITCH_KEY = 'F1'

export function findFeatures(): FeatureFlagSetting[] {
  const storedFlags = localStorage.getItem(FEATURE_FLAGS_KEY)
  const currentFlags =
    storedFlags != null
      ? (JSON.parse(storedFlags) as FeatureFlagSetting[])
      : defaultFlags

  return defaultFlags.map((defaultFlag) => {
    const current = currentFlags.find(
      (currentFlag) => currentFlag.name === defaultFlag.name,
    )
    return {
      ...defaultFlag,
      active: current ? current.active : defaultFlag.active,
    }
  })
}

export function findFeature(name: string): FeatureFlagSetting | undefined {
  const features = findFeatures()

  return features.find((f) => f.name === name)
}

export function isActiveFeature({
  featureName,
  user,
}: {
  featureName: string
  user: User | undefined
}): boolean {
  if (user === undefined || !isEnechainUser(user)) {
    return false
  }

  const feature = findFeature(featureName)
  return feature?.active ?? false
}

export function toggleFeature(name: string): FeatureFlagSetting[] {
  const changeFeatures = findFeatures().map((f) => {
    if (f.name === name) {
      return {
        ...f,
        active: !f.active,
      }
    }
    return f
  })

  localStorage.setItem(FEATURE_FLAGS_KEY, JSON.stringify(changeFeatures))
  return changeFeatures
}
