import * as React from 'react'

import { Flex } from '@chakra-ui/react'

import { Link, Spacing, useTheme } from '@enechain/ecloud-designsystem'
import { CircleXmark } from '@enechain/jcex-icons'

type Props = {
  label: string
  clear: () => void
}

export const ClearLink: React.FC<Props> = ({ label, clear }) => {
  const theme = useTheme()

  return (
    <Link
      as="button"
      width="fit-content"
      color={theme.semanticTokens.colors.Text.link}
      onClick={clear}
    >
      <Flex alignItems="center" gap={Spacing[1]}>
        <CircleXmark width="24px" height="24px" />
        {label}
      </Flex>
    </Link>
  )
}
