import { useCallback } from 'react'

import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

import { ToastId } from '@chakra-ui/react'

type MutationReturnType = {
  setToast: (id: ToastId) => void
  remove: (id: ToastId) => void
}

const toastIds = atom<ToastId[]>({
  key: 'toastIds',
  default: [],
})

export function useToastQueries(): ToastId[] {
  return useRecoilValue(toastIds)
}

export function useToastMutations(): MutationReturnType {
  const setState = useSetRecoilState(toastIds)
  const update = useCallback(
    (id: ToastId) => {
      setState((currVal) => [...currVal, id])
    },
    [setState],
  )
  const remove = useCallback(
    (id: ToastId) => {
      setState((currVal) => currVal.filter((v) => v !== id))
    },
    [setState],
  )
  return {
    setToast: update,
    remove,
  }
}
