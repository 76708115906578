import * as React from 'react'
import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Stack,
  UseDisclosureReturn,
} from '@chakra-ui/react'

import {
  ColumnContent,
  PrimaryModal,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { GeneratorType } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { Prefecture } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { ClearLink, SelectPrefecturesWithRegion } from '@enechain/jcex-ui'

type FilterType = {
  prefectures: number[]
  generatorTypes: number[]
}

type Props = {
  disclosure: UseDisclosureReturn
  excludeOptions: {
    prefectures: Prefecture[]
    generatorTypes: GeneratorType[]
  }
  selectedValue: FilterType
  onClick: (value: FilterType) => void
}
const SelectFilterModal: React.FC<Props> = ({
  disclosure,
  excludeOptions,
  selectedValue,
  onClick,
}) => {
  const { t } = useTranslation(['common', 'domain', 'trader'])
  const [selectedPrefectures, setSelectedPrefectures] = useState<number[]>(
    selectedValue.prefectures,
  )
  const [selectedGeneratorTypes, setSelectedGeneratorTypes] = useState<
    number[]
  >(selectedValue.generatorTypes)

  const onSave = (): void => {
    disclosure.onClose()
    onClick({
      generatorTypes: selectedGeneratorTypes,
      prefectures: selectedPrefectures,
    })
  }

  const onClear = (): void => {
    setSelectedGeneratorTypes([])
    setSelectedPrefectures([])
  }

  return (
    <PrimaryModal
      actionText={t('common:action.positive.save')}
      bodyChildren={
        <Stack spacing={Spacing[4]}>
          <Stack spacing={Spacing[6]}>
            <ColumnContent
              headerSize="Md"
              label={t('domain:shared.prefecture.title')}
            >
              <SelectPrefecturesWithRegion
                options={excludeOptions.prefectures}
                selectedValue={selectedPrefectures}
                setSelectedValue={setSelectedPrefectures}
              />
            </ColumnContent>
            <ColumnContent
              headerSize="Md"
              label={t('domain:shared.generator.title')}
            >
              <CheckboxGroup
                onChange={(value: string[]): void => {
                  setSelectedGeneratorTypes(value.map((v) => Number(v)))
                }}
                value={selectedGeneratorTypes}
              >
                <Flex gap={Spacing[4]}>
                  {Object.values(excludeOptions.generatorTypes).map(
                    (generatorType) => (
                      <Checkbox
                        key={generatorType.type}
                        colorScheme="green"
                        value={generatorType.type}
                      >
                        {generatorType.displayName}
                      </Checkbox>
                    ),
                  )}
                </Flex>
              </CheckboxGroup>
            </ColumnContent>
          </Stack>
          <Box position="relative" left={Spacing['-1']}>
            <ClearLink
              clear={onClear}
              label={t('common:action.neutral.check_all_clear')}
            />
          </Box>
        </Stack>
      }
      closeText={t('common:action.negative.cancel')}
      disclosure={disclosure}
      isCentered
      onClick={onSave}
      onCloseComplete={(): void => {
        setSelectedPrefectures(selectedValue.prefectures)
        setSelectedGeneratorTypes(selectedValue.generatorTypes)
      }}
      size="3xl"
      title={t('trader:page.order_book_list.target_of_trade')}
    />
  )
}

export default React.memo(SelectFilterModal)
