export type NonFITDealHistoryListColumnKey =
  | 'position'
  | 'deliveryFiscalYear'
  | 'renewableEnergyAndTrackingStatus'
  | 'prefecture'
  | 'generatorType'
  | 'unitPrice'
  | 'volume'
  | 'counterpartyOrganizationName'
  | 'dealtAt'

export const nonFITDealHistoryColumnStyles = {
  position: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '36px',
    },
  },
  deliveryFiscalYear: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.4,
    },
  },
  renewableEnergyAndTrackingStatus: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  prefecture: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.7,
    },
  },
  generatorType: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.7,
    },
  },
  unitPrice: {
    alignment: 'right',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  volume: {
    alignment: 'right',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  counterpartyOrganizationName: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.7,
    },
  },
  dealtAt: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.7,
    },
  },
} as const
