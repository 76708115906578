import * as React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'

import { useTheme } from '@enechain/ecloud-designsystem'

export const LoadingWithGray: React.FC = () => {
  const theme = useTheme()

  return (
    <Flex
      position="absolute"
      top={theme.space[0]}
      right={theme.space[0]}
      bottom={theme.space[0]}
      left={theme.space[0]}
      alignItems="center"
      justifyContent="center"
      backgroundColor={theme.semanticTokens.colors.Surface.neutralMid}
    >
      <Spinner color={theme.semanticTokens.colors.Object.midEmphasis} />
    </Flex>
  )
}
